import { CardModule } from './card/card.module';
import { GroupModule } from './group/group.module';
import { RouterModule } from '@angular/router';
import { ClickOutsideModule } from 'ng-click-outside';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { CardComponent } from './card/card.component';
import { AppRoutingModule } from './../app-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelComponent } from './panel.component';
import { DirectivesModule } from '../_directives/directives.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {SamDragAndDropGridModule} from '@sam-1994/ngx-drag-and-drop-grid';



@NgModule({
  imports: [
    AppRoutingModule,
    CommonModule,
    DragDropModule,GroupModule,CardModule,
    DirectivesModule,ClickOutsideModule,
    FormsModule,
    Ng2SearchPipeModule,SamDragAndDropGridModule,

    ReactiveFormsModule,
    RouterModule
  ],
  declarations: [
    PanelComponent
  ],
  providers: [],
  exports: [PanelComponent]  
})
export class PanelModule { }

import { stringify } from 'querystring';
import { TranslateJsonService } from './../_services/translate-json.service';
import { SanitizeHtmlPipe } from './sanitizeHtmlPipe';

import { Pipe } from '@angular/core';
import { Component, OnInit, HostListener } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as data from './../fusohorario.json';
import { PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
const word = data.default;
@Pipe({ name: 'safePipe' })
export class Safe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
        this.sanitizer = sanitizer;
    }

    transform(style) {
        return this.sanitizer.bypassSecurityTrustHtml(style);
    }
}
@Component({
    moduleId: module.id,
    selector: 'app-alert',
    templateUrl: 'alert.component.html',
    styleUrls: ['./alert.component.css'],
    providers: [SanitizeHtmlPipe]
})

export class AlertComponent implements OnInit {
    public imagem: any = {
        text: 'IMAGEM',
        show: false
    };
    public controller: any = {
        text: '',
        show: false
    };
    public radioOption: any = {
        text: '',
        show: false
    };

    public title: any;
    public message: any;
    public devices: any;
    language;
    jsonWord;
    public globalClass: string
    public buttonCancel: any = {
        show: false,
        text: 'CANCELAR'
    };
    public buttonConfirm: any = {
        show: false,
        text: 'Savar'
    };
    public buttonBlock: any = {
        show: false,
        text: 'Savar',
        value: false
    };
    public buttonOk: any = {
        show: false,
        text: 'OK'
    };
    public buttonSave: any = {
        show: false,
        text: 'OK'
    };
    public checkbox: any = {
        show: false,
        text: 'Li e aceito os termos e condições'
    };
    public edit: Boolean;
    public inputText1: any = {
        show: false,
        value: null,
        type: 'text',
        placeholder: 'Digite o valor'
    };
    public inputText2: any = {
        show: false,
        value: null,
        type: 'text',
        disabled: false,
        placeholder: 'Digite o valor'
    };
    public offset: any = {
        show: false,
        value: null,
        type: 'text',
    };
    public offsetOff: any = {
        show: false,
        value: null,
        type: 'text',
    }
    public isDst: any = {
        show: false,
        value: false,
        type: 'checkbox',
    };
    isEdit: any = {
        show: false
    }
    role
    optionCal = "open";
    public type
    public typeB
    public isDelete: Boolean = false

    public object: any
    public isAtBottom: boolean = false
    public acceptTerm: boolean = false
    public showCloseButton: boolean = true
    public onClose: Subject<any>;
    //public offsetOff
    public fusoChosen// = "Escolha um fuso horário";
    public fusoHorario = word;
    valorOffset;
    notification: any = {
        show: false,
        value: false,
        type: 'checkbox',
    };
    errorNot: any = {
        show: false,
        value: false,
        type: 'checkbox',
    };
    eventNot: any = {
        show: false,
        value: false,
        type: 'checkbox',
    };
    alertNot: any = {
        show: false,
        value: false,
        type: 'checkbox',
    };
    dev1
    dev2
    devicesFound
    arrowTF = [false, false, false, false, false, false, false, false, false, false, false];

    @HostListener('window:keyup', ['$event'])
    enterKeyUpEvent(e) {
        e.stopPropagation()
        if (e.keyCode == 13 && this.buttonOk.show) {
            this.onConfirm()
        }
    }

    constructor(private _bsModalRef: BsModalRef, public translateJson: TranslateJsonService, private _sanitizer: DomSanitizer, private modalService: BsModalService) {
        // this.fusoChosen = this.jsonWord.label.choseTimezone
    }

    public ngOnInit(): void {
        this.language = localStorage.getItem('language')
        this.jsonWord = this.translateJson.changeLanguage(this.language)
        // console.log(this.fusoHorario[0])
        this.title = "teste"
        this.onClose = new Subject();
        // console.log(this.isDst.value)
        this.fusoChosen = this.fusoHorario[24].text
    }


    public onConfirm(): void {
        if (this.controller.show) {
            this.onClose.next(this.optionCal)
        } else {
            this.onClose.next(this.object ? this.object : true);
        }
        this._bsModalRef.hide();
    }
    public onSave() {
        let body
        if (this.offset.show) {
            if (!this.inputText1.value || !this.inputText2.value) {
                this._bsModalRef = this.modalService.show(AlertComponent);
                this._bsModalRef.content.title = this.jsonWord.alert.attention
                this._bsModalRef.content.message = this.jsonWord.error.requiredFields //"Escolha o fuso horario"
            } else {
                var index = this.fusoHorario.findIndex(fuso => fuso.text == this.fusoChosen)
                let holdElemId = [], holdChecked = []
                if(this.devicesFound){
                for (let i = 0; i < this.devicesFound.length; i++) {
                    holdElemId.push(<any>document.getElementById(this.devicesFound[i].ieeeAddr))
                    if (holdElemId[i] && !holdElemId[i].checked) {//classList[holdElemId[i].classList.length - 1] === "mat-checkbox-checked") {
                        holdChecked.push(this.devicesFound[i].ieeeAddr)
                    }
                }
            }
                body = {
                    "name": this.inputText1.value,
                    "serial": this.inputText2.value,
                    "offset": this.fusoChosen ? this.fusoChosen : this.fusoHorario[24].text,
                    "offsetOff": this.fusoHorario[this.fusoHorario.findIndex(fuso => fuso.text == this.fusoChosen)].offset,
                    //"notification": this.notification.value
                    "notification": {
                        "alert": this.eventNot.value,
                        "error": this.errorNot.value,
                        "event": this.alertNot.value,
                        "notSendDevices":
                            holdChecked
                    }
                }
            }
            this.onClose.next((body))

        } else {
            this.onClose.next(true);
        }

        this._bsModalRef.hide();
    }
    public onCancel(): void {
        this.onClose.next(false)
        this._bsModalRef.hide();
    }

    onScroll(event: Event) {
        let el = event.srcElement as Element
        this.isAtBottom = el.scrollHeight - el.scrollTop - el.clientHeight > 20 ? false : true
        /// this.isAtBottom = el.scrollHeight - el['scrollTop'] - el['clientHeight'] > 20 ? false : true
        // Precisa ser 20 (ou > 0), se não não detecta rolagem com zoom na página 
    }

    getisDst(valor) {
        this.isDst.value = valor ? valor : false;
    }
    getAlertNotification(valor) {
        this.alertNot.value = valor ? valor : false;
        this.statusNots()
    }
    getEventNotification(valor) {
        this.eventNot.value = valor ? valor : false;
        this.statusNots()
    }
    getErrorNotification(valor) {
        this.errorNot.value = valor ? valor : false;
        this.statusNots()
    }
    getNotification(valor) {
        this.notification.value = valor ? valor : false;
        if (this.notification.value == true) {
            this.errorNot.value = true
            this.eventNot.value = true
            this.alertNot.value = true
        } else {
            this.errorNot.value = false
            this.eventNot.value = false
            this.alertNot.value = false
        }
    }

    statusNots() {
        if (!this.errorNot.value || !this.eventNot.value || !this.alertNot.value) {
            this.notification.value = false
        }
        if (this.errorNot.value && this.eventNot.value && this.alertNot.value) {
            this.notification.value = true
        }
    }

    getEnvChosen(fuso, i) {
        this.fusoChosen = fuso;
        this.valorOffset = this.fusoHorario[i].offset
        //this.offsetOff.value = 
    }

    getOptionCalibration() {
        let values = <any>document.getElementsByName('controller')
        for (let value of values) {
            if (value.checked) {
                this.optionCal = value.value;
            }
        }
    }


    setArrow(i) {
        this.arrowTF[i] = !this.arrowTF[i]
        switch (i) {
            case (0):
                if (this.arrowTF[i])
                    document.getElementById('arrowS').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
                else
                    document.getElementById('arrowS').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
                break;
            case (1):
                if (this.arrowTF[i])
                    document.getElementById('arrowInt').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
                else
                    document.getElementById('arrowInt').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
                break;
            case (2):
                if (this.arrowTF[i])
                    document.getElementById('arrowSL').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
                else
                    document.getElementById('arrowSL').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
                break;
            case (3):
                if (this.arrowTF[i])
                    document.getElementById('arrowAF').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
                else
                    document.getElementById('arrowAF').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
                break;
            case (4):
                if (this.arrowTF[i])
                    document.getElementById('arrowSP').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
                else
                    document.getElementById('arrowSP').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
                break;
            case (5):
                if (this.arrowTF[i])
                    document.getElementById('arrowLamp').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
                else
                    document.getElementById('arrowLamp').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
                break;
            case (6):
                if (this.arrowTF[i])
                    document.getElementById('arrowLed').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
                else
                    document.getElementById('arrowLed').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
                break;
            case (7):
                if (this.arrowTF[i])
                    document.getElementById('arrowLum').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
                else
                    document.getElementById('arrowLum').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
                break;
            case (8):
                if (this.arrowTF[i])
                    document.getElementById('arrowP').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
                else
                    document.getElementById('arrowP').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
                break;
            case (9):
                if (this.arrowTF[i])
                    document.getElementById('arrowTH').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
                else
                    document.getElementById('arrowTH').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
                break;
            case (10):
                if (this.arrowTF[i])
                    document.getElementById('arrowSPP').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
                else
                    document.getElementById('arrowSPP').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
                break;
            case (11):
                if (this.arrowTF[i])
                    document.getElementById('arrowSSw').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
                else
                    document.getElementById('arrowSSw').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
                break;
            case (12):
                if (this.arrowTF[i])
                    document.getElementById('arrowSPu').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
                else
                    document.getElementById('arrowSPu').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
                break;
            case (13):
                if (this.arrowTF[i])
                    document.getElementById('arrowTurn').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
                else
                    document.getElementById('arrowTurn').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
                break;
            case (14):
                if (this.arrowTF[i])
                    document.getElementById('arrowWS').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
                else
                    document.getElementById('arrowWS').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
                break;
        }
    }
}

import { AppRoutingModule } from "./../../app-routing.module";
import { AppComponent } from "./../../app.component";
import { ChartsModule } from "ng2-charts";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { LumchartComponent } from "./lumchart.component";
// Use fcRoot function to inject FusionCharts library, and the modules you want to use

@NgModule({
  imports: [
    AppRoutingModule,
    RouterModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    ChartsModule
    // Include in imports
  ],
  declarations: [LumchartComponent],
  providers: [],
  exports: [LumchartComponent]
})
export class LumchartModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnvironmentConfigModule } from './environment-config/environment-config.module';
import { EnvironmentComponent } from './environment.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '../_directives/directives.module';


@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    EnvironmentConfigModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule
  ],
  declarations: [EnvironmentComponent],
  exports: [EnvironmentComponent]
})
export class EnvironmentModule { }

import { AppRoutingModule } from './../../app-routing.module';
import { NgModule, Pipe, PipeTransform} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card.component';
import { DeviceService } from './../../_services/device.service';
import { DirectivesModule } from '../../_directives/directives.module';


@NgModule({
  imports: [
    AppRoutingModule,
    CommonModule,
    DirectivesModule
  ],
  declarations: [
    CardComponent,

  ],
  providers: [DeviceService],
  exports: [CardComponent]
})
export class CardModule { }

import { RuleService } from './../../_services/rule.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './../../app-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RuleEditComponent } from './rule-edit.component';
import { DirectivesModule } from '../../_directives/directives.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DateTimeFormatPipe } from './pipe-date';


@NgModule({
  imports: [
    AppRoutingModule,
    CommonModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot()
  ],
  declarations: [
    RuleEditComponent,DateTimeFormatPipe
  ],
  providers: [RuleService],
  exports: [RuleEditComponent,DateTimeFormatPipe]
})
export class RuleEditModule { }

import { TranslateJsonService } from './../../_services/translate-json.service';
import { UserService } from './../../_services/user.service';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { BsModalRef, BsModalService, BsDatepickerConfig } from 'ngx-bootstrap';
import { LoadingModalComponent } from '../../_directives/loading-modal/loading-modal.component';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.css']
})
export class InviteUserComponent implements OnInit {

  public inviteUserForm: FormGroup
  public email: FormControl
  public modalRef: BsModalRef
  public loading = false
  datepickerModel
  daterangepickerModel
  datePickerConfig: Partial<BsDatepickerConfig>;
  option = 'ADMIN';
  myDate;
  startDay;
  endDay;
  @Input() emailInput
  keys = Object.keys
  body;
  count = 0;
  public language;
  public jsonWord;
  @HostListener('window:keyup', ['$event'])
  enterKeyUpEvent(e) {
    e.stopPropagation()
    if (e.keyCode == 13) {
      this.inviteUser()
    }
  }
  constructor(
    private userService: UserService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private readonly location: Location,
    private translateJson: TranslateJsonService,
    private router: Router,
  ) {
    this.email = new FormControl(null, [Validators.required,
    Validators.minLength(8),
    Validators.maxLength(60),
    Validators.pattern(/^([A-Za-z0-9._-]{2,})(@[A-Za-z0-9_-]{2,})(\.[A-Za-z]{2,6})+$/),
    ])
    this.inviteUserForm = new FormGroup({
      email: this.email
    })
  }
  ngOnInit() {
    this.language = localStorage.getItem('language')
    this.jsonWord = this.translateJson.changeLanguage(this.language)
    this.location.replaceState('/')
    //this.datepickerModel = Date.now()
    //this.daterangepickerModel = Date.now()

    this.datePickerConfig = Object.assign(
      {},
      {
        containerClass: 'theme-green',
        showWeekNumbers: false,
        rangeInputFormat: 'DD/MM/YYYY',
        minDate: new Date(),
        //placement: 'right',
      })

    this.myDate = this.formBuilder.group({
      date: null,
      range: this.daterangepickerModel
    });
  }

  openLoadingModal(message) {
    this.modalRef = this.modalService.show(
      LoadingModalComponent,
      {
        keyboard: false,
        backdrop: 'static',
        class: 'waiting-modal modal-sm'
      })
    this.modalRef.content.message = message;
  }

  getValue() {
    let values = <any>document.getElementsByName('invite')
    for (let value of values) {
      if (value.checked) {
        this.option = value.value;
      }
    }
  }

  inviteUser() {
    if (this.option === 'INVITED') {
      console.log('INVITED', this.daterangepickerModel)
      if (this.daterangepickerModel) {
        this.body = [{
          "op": "add",
          "path": "/registeredUsers/",
          "value": {
            "role": this.option,
            "startDate": moment(this.daterangepickerModel[0]).format('YYYY-MM-DD'),
            "endDate": moment(this.daterangepickerModel[1]).format('YYYY-MM-DD')
          }
        }/*,{
          "op": "add",
          "path": "/devices/",
          "value": {
            "idList": [“addr1”]
          }
        },{
          "op": "add",
          "path": "/scenarios/",
          "value": {
            "idList": [“id”]
          }
        }*/]
      } else { console.log("coloque um periodo") }
    } else {
      this.body = [{
        "op": "add",
        "path": "/registeredUsers/",
        "value": {
          "role": this.option,
          "startDate": "2018-11-30",
          "endDate": "2020-12-01",
        }
      }/*,{
        "op": "add",
        "path": "/devices/",
        "value": {
          "idList": [“addr1”]
        }
      },{
        "op": "add",
        "path": "/scenarios/",
        "value": {
          "idList": [“id”]
        }
      }*/]
    }

    this.loading = true
    this.openLoadingModal(this.jsonWord.loading.sendingInvite)
    this.userService.invite(this.body, this.inviteUserForm.value.email)
      .pipe(finalize(() => {
        this.loading = false
        this.modalRef.content.waiting = false
      }))
      .subscribe(
        res => {
          this.modalRef.content.message = this.jsonWord.toast.successToAssociateUser
          this.modalRef.content.success = true
          setTimeout(() => {
            this.modalRef.hide()
            this.goBack();
          }, 1500);
        },
        err => {
          let message = ''
          switch (err.status) {
            case 0:
              message = this.jsonWord.error.noConnection//'Formulário de convidar usuário inválido.'
              break
            case 400:
              message = this.jsonWord.error.invalidFormat//'Formulário de convidar usuário inválido.'
              break
            case 401:
              message = this.jsonWord.error.notAuthorized//'Você não tem permissão para convidar usuários.'
              break
            case 403:
              message = this.jsonWord.error.userDisabled//'Usuário está desativado.'
              break
            case 404:
              message = this.jsonWord.error.userNotFound//'Usuário não encontrado ou número serial de gateway não registrado.'
              break
            case 408:
              message = this.jsonWord.error.timeoutServer;//'Tempo limite de conexão atingido.'
              break
            case 409:
              message = this.jsonWord.error.userAlreadyInGtw//'Usuário já associado ao gateway.'
              break
            case 412:
              message = this.jsonWord.error.limitUserExceeded//'Usuário já associado ao gateway.'
              break
            case 500:
              message = this.jsonWord.error.inviteNotSend//'Não foi possível enviar convite.'
              break
            default:
              message = this.jsonWord.error.failOperation

          }
          this.modalRef.content.message = this.jsonWord.error.error + ': ' + message
          this.modalRef.content.success = false
          this.modalRef.content.waiting = false
          setTimeout(() => {
            this.modalRef.hide()
          }, 2000);
          //this.openErrorDialog(err.message)
        })
  }
  goBack(): void {
    this.router.navigate(['home/users']);
  }
}

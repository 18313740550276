import { TranslateJsonService } from './../../_services/translate-json.service';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { LoginService } from '../login.service';
import { LoadingModalComponent } from '../../_directives/loading-modal/loading-modal.component';
import { AlertComponent } from '../../_directives/alert.component';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { getLocaleNumberSymbol } from '@angular/common';
import { TranslateDirective, TranslatePipe, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {

  private registerForm: FormGroup
  private modalRef: BsModalRef
  public confirmEmail;
  language
  jsonWord
  isExplorer
  private token: String

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private translateJson: TranslateJsonService,
    private translate: TranslateService,
    private router: Router,
    private renderer: Renderer2

  ) {
    renderer.setStyle(
      document.getElementsByTagName('body')[0],
      'background-image',
      'url(assets/img/bg/3.jpg/)'
    )
    this.token = route.snapshot.params['_token']
    /*loginService.checkRecoveryToken(route.snapshot.params['_token']).subscribe(
      _ => {
        this.openAlert('Token inválido ou expirado!')
        router.navigateByUrl('/login')
      },
      err => {
        if (err.status == 401) {
          console.log('Redirecting', err)
          this.openAlert('Token inválido ou expirado!')
          router.navigateByUrl('/login')
        }
      }
    )*/
  }

  openAlert(message) {
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
    this.modalRef.content.title = this.jsonWord.label.confirmRegister//"{{'label.confirmRegister'| translate}}";
    this.modalRef.content.type = 'remove'
    this.modalRef.content.message = /*'<i class="fa fa-lg fa-times text-danger"> </i> ' + */message;
  }

  ngOnInit() {
    this.isExplorer = /^((?!chrome|android).)*isIE10/i.test(navigator.userAgent);
    this.language = localStorage.getItem('language') ? localStorage.getItem('language') : 'pt'
    this.jsonWord = this.translateJson.changeLanguage(this.language)

    this.translate.get('label.confirmRegister').subscribe((res: string) => {
      this.confirmEmail = res
      this.openConfirmDialog(this.jsonWord.text.click + " "+this.jsonWord.button.confirm +" " + this.jsonWord.text.toCompleteRegister, this.confirmEmail)//'Clique em "Confirmar" para completar o cadastro'
    })
  }

  openLoadingModal(message) {
    this.modalRef = this.modalService.show(
      LoadingModalComponent,
      {
        keyboard: false,
        backdrop: 'static',
        class: 'waiting-modal modal-sm'
      })
    this.modalRef.content.message = message;
  }

  openConfirmDialog(message, message2) {
    this.modalRef = this.modalService.show(AlertComponent, { backdrop: 'static' });
    this.modalRef.content.buttonConfirm = { show: false };
    //this.modalRef.content.buttonCancel = { show: true, text: 'Cancelar' };
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.confirm };//'Confirmar'
    this.modalRef.content.title = message2;
    this.modalRef.content.message = message;
    this.modalRef.content.showCloseButton = false;
    this.modalRef.content.globalClass = 'text-center';
    this.modalRef.content.onClose.subscribe(result => {
      if (result) {
        this.openLoadingModal(this.jsonWord.loading.message)//'Realizando confirmação de cadastro...')
        this.modalRef.content.onClose.subscribe(_ => this.router.navigateByUrl('/login'))
        this.loginService.confirm(this.token).subscribe(
          res => {
            let timer = setTimeout(() => {
              this.router.navigateByUrl('/login'),
                this.modalRef.hide()
            }, 2000)
            this.modalRef.content.message = this.jsonWord.alert.titleToCreateAccount//"Cadastro confirmado. Redirecionando para a página de login..."
            this.modalRef.content.waiting = false
            this.modalRef.content.success = true
          },
          err => {
            console.log(err)
            let message = ''
            switch (err.status) {
              case 0:
                message = this.jsonWord.error.noConnection;//'Campos de email ou senha inválidos.'
                //  this.noInternetAlert()
                break
              case 400:
                message = this.jsonWord.error.invalidToken//'Token de confirmação de cadastro inválido.'
                break
              case 401:
                message = this.jsonWord.error.notAuthorized//'Você não tem permissão para realizar a confirmação do cadastro.'
                break
              case 404:
                message = this.jsonWord.error.userNotFound///'Usuário não encontrado.'
                break
              case 408:
                message = this.jsonWord.error.timeoutServer//'Usuário não encontrado ou número serial de gateway não registrado.'
                break
              case 412:
                message = this.jsonWord.error.userAlreadyInGtw
                break
              case 500:
                message = this.jsonWord.toast.errorToRecoverPassword// 'Não foi possível realizar a operação.'
                break
              default:
                message = this.jsonWord.error.failOperation
            }
            this.modalRef.content.message = this.jsonWord.error.error + ": " + message
            this.modalRef.content.waiting = false
            this.modalRef.content.success = false
          }
        )
      } else this.router.navigateByUrl('/login')
    });
  }
}

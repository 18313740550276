import { Directive, HostListener, SimpleChanges, ElementRef } from '@angular/core';

@Directive({
  selector: '[appPhoneMask]'
})
export class PhoneMaskDirective {

  inputField: HTMLInputElement
  cursorOffset : number

  constructor(private inputFieldRef: ElementRef) {
    this.inputField = this.inputFieldRef.nativeElement as HTMLInputElement
  }

  @HostListener('input', ['$event']) onInputEvent(event: Event) {
    var inputValue: String = this.inputField['value'];
    this.cursorOffset = this.inputField.selectionStart
    this.applyMask(inputValue)
  }

  applyMask(inputValue: String) {
    switch (inputValue.length) {
      case 5:
        //console.log(`case ${inputValue.length}`, inputValue)
        if (inputValue.match(/^\-\d{4}$/)) break
        inputValue = inputValue.replace(/[^\d]/g, '');
        if (inputValue.length == 5)
          this.inputField['value'] = `${inputValue.slice(0, 1)}-${inputValue.slice(1)}`
        else this.applyMask(inputValue);
        break
      case 6:
        //console.log(`case ${inputValue.length}`, inputValue)
        if (inputValue.match(/^\d\-\d{4}$/)) break
        inputValue = inputValue.replace(/[^\d]/g, '');
        if (inputValue.length == 6)
          this.inputField['value'] = `${inputValue.slice(0, 2)}-${inputValue.slice(2)}`
        else
          this.applyMask(inputValue);
        break
      case 7:
        //console.log(`case ${inputValue.length}`, inputValue)
        if (inputValue.match(/^\d{2}\-\d{4}$/)) break
        inputValue = inputValue.replace(/[^\d]/g, '');
        if (inputValue.length == 7)
          this.inputField['value'] = `${inputValue.slice(0, 3)}-${inputValue.slice(3)}`
        else
          this.applyMask(inputValue);
        break
      case 8:
        //console.log(`case ${inputValue.length}`, inputValue)
        if (inputValue.match(/^\d{3}\-\d{4}$/)) break
        inputValue = inputValue.replace(/[^\d]/g, '');
        if (inputValue.length == 8)
          this.inputField['value'] = `${inputValue.slice(0, 4)}-${inputValue.slice(4)}`
        else
          this.applyMask(inputValue);
        break
      case 9:
        //console.log(`case ${inputValue.length}`, inputValue)
        if (inputValue.match(/^\d{4}\-\d{4}$/)) break
        inputValue = inputValue.replace(/[^\d]/g, '');
        if (inputValue.length == 9)
          this.inputField['value'] = `${inputValue.slice(0, 5)}-${inputValue.slice(5)}`
        else
          this.applyMask(inputValue);
        break
      case 10:
        //console.log(`case ${inputValue.length}`, inputValue)
        if (inputValue.match(/^\d{5}\-\d{4}$/)) break
        inputValue = inputValue.replace(/[^\d]/g, '');
        if (inputValue.length == 10)
          this.inputField['value'] = `(${inputValue.slice(0, 2)}) ${inputValue.slice(2, 6)}-${inputValue.slice(6)}`
        else
          this.applyMask(inputValue);
        break
      case 11:
        //console.log(`case ${inputValue.length}`, inputValue)
        // nao tem configuração valida
        inputValue = inputValue.replace(/[^\d]/g, '');
        if (inputValue.length == 11)
          this.inputField['value'] = `(${inputValue.slice(0, 2)}) ${inputValue.slice(2, 7)}-${inputValue.slice(7)}`
        else
          this.applyMask(inputValue);
        break
      case 12:
        //console.log(`case ${inputValue.length}`, inputValue)
        // nao tem configuração valida
        inputValue = inputValue.replace(/[^\d]/g, '');
        if (inputValue.length > 11)
          inputValue = inputValue.slice(0, 11)
        this.applyMask(inputValue);
        break
      case 13:
        //console.log(`case ${inputValue.length}`, inputValue)
        if (inputValue.match(/^\(\d\) \d{4}\-\d{4}$/)) break
        inputValue = inputValue.replace(/[^\d]/g, '');
        if (inputValue.length > 11)
          inputValue = inputValue.slice(0, 11)
        this.applyMask(inputValue);
        break
      case 14:
        //console.log(`case ${inputValue.length}`, inputValue)
        if (inputValue.match(/^\(\d\d\) \d{4}\-\d{4}$/)) break
        inputValue = inputValue.replace(/[^\d]/g, '');
        if (inputValue.length > 11)
          inputValue = inputValue.slice(0, 11)
        this.applyMask(inputValue);
        break
      case 15:
        //console.log(`case ${inputValue.length}`, inputValue)
        if (inputValue.match(/^\(\d\d\) \d{5}\-\d{4}$/)) break
        inputValue = inputValue.replace(/[^\d]/g, '');
        if (inputValue.length > 11)
          inputValue = inputValue.slice(0, 11)
        this.applyMask(inputValue);
        break
      case 16:
        //console.log(`case ${inputValue.length}`, inputValue)
        // nao tem configuração valida
        inputValue = inputValue.replace(/[^\d]/g, '');
        if (inputValue.length > 11)
          inputValue = inputValue.slice(0, 11)
        this.applyMask(inputValue);
        break
      default:
        //console.log(`default`, inputValue)
        //if (inputValue.match(/^\d*$/)) break
        this.inputField['value'] = inputValue.replace(/[^\d]/g, '');
        break
    }
  }
}

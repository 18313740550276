import { RuleService } from './../../_services/rule.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './../../app-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InviteUserComponent } from './invite-user.component';
import { DirectivesModule } from '../../_directives/directives.module';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap';


@NgModule({
  imports: [
    AppRoutingModule,
    CommonModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    DatepickerModule.forRoot() 
  ],
  declarations: [
    InviteUserComponent
  ],
  providers: [RuleService],
  exports: [InviteUserComponent]
})
export class InviteUserModule { }

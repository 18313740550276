import { IconsScenarioModalComponent } from './../../_directives/icons-scenario-modal/icons-scenario-modal.component';
import { LoadingModalComponent } from './../../_directives/loading-modal/loading-modal.component';
import { ResponseService } from './../../_services/response.service';
import { TranslateJsonService } from './../../_services/translate-json.service';
import { ScenarioService } from './../../_services/scenario.service';
import { RuleService } from './../../_services/rule.service';
import { AlertComponent } from './../../_directives/alert.component';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService, BsDatepickerConfig, defineLocale } from 'ngx-bootstrap';
import { enGbLocale, ptBrLocale, esLocale } from 'ngx-bootstrap/locale';

import { Card } from './../../models/card.model';

import { DeviceService } from './../../_services/device.service';
import * as config from '../../config';
import { Device } from './../../models/device.model';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { Subscription } from 'rxjs/Subscription';
import { OnInit, OnDestroy, ChangeDetectorRef, Component, Input, ViewChild, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { RulesModel } from '../../models/rules.model';
import * as moment from 'moment';
import { TimepickerModule, BsLocaleService } from 'ngx-bootstrap';
import { TimepickerConfig, TimepickerStore, TimepickerActions } from './../../../../node_modules/ngx-bootstrap/timepicker';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { timeoutWith, finalize } from 'rxjs/operators';
import { HomeComponent } from '../../home/home.component';
import { RuleOpstCardModalComponent } from 'app/_directives/ruleopst-card-modal/ruleopst-card-modal.component';

const utf8Decoder = new TextDecoder("utf-8")
const TIMEOUT = 10000

@Component({
	selector: 'vr-rule-config',
	templateUrl: './rule-config.component.html',
	styleUrls: ['./rule-config.component.css'],
	providers: [RuleService],
	//host: { '[@routeTransition]': '' }
})

export class RuleConfigComponent implements OnInit, OnDestroy {
	selectedIndex = 0;
	lastIndex = 6;
	//whiteOrColorful = [];
	backgroundColor = "rgba(146, 202, 83, 0.8)";
	color = "black";
	devName;
	devices;
	devChecked;
	checkedDevices;
	checkedScenarios;
	id: number;
	scenarios;
	checkedDevices2;
	colorText = [];
	lightLevel = [];
	devsScnToEdited;
	input = [];
	output = [["nada", "Desativa", "50", "1"]];
	outPut = [];
	isCheckedT = [];
	isCheckedU = [];
	isCheckedP = [];
	isCheckedL = [];
	valueT = [];
	valueU = [];
	optionT = [];
	optionU = [];
	optionP = [];
	optionL = [];
	state = [];
	condition = [];
	onOff = [];
	scenChecked;
	scnName = [];
	agendamento = false;
	schedule;
	@Input() option = "sim";
	@Input() horaStarts = "00:00:01";
	@Input() horasEnds = "23:59:59";
	@Input() startDay;
	dateNow;
	weekdays;
	interval;
	outputR;
	inputR;
	valueP = [];
	valueL = [];
	allInput = [];
	condSignal = [];
	daysTrueFalse = [true, true, true, true, true, true, true];
	jsonDays = [];
	horasOk = true;
	count1 = 0;
	count2 = 0;
	holdCheckedRule = []
	daysChecked = 0;
	devChecked2;
	uuid = require('uuid/v4');
	gtw; //this.appService.localStorage()
	white = [];
	colorful = [];
	withoutValueP = true;
	checkP = true;
	checkL = true;
	checkU = true;
	checkT = true;
	devsToOutput = [];
	devsToInput = [];
	inputToCheck = [];
	@Input() i;
	nome;
	allRules;
	rulesToOutput = []
	saveClicked = 0;
	page = 1;
	index = "";
	whiteLight = [];
	flipCard = [];
	cardFlipTimer: any;
	vectorTypes = [null, null, null, null, null];
	vectorTypesIn = [null, null, null, null, null];
	currentGateway;
	card;
	devConfigd;
	newRule;
	messageEdit;
	cardInRule = [];
	levelToShow = [];
	public modalRefRuleOpt: BsModalRef;
	public modalRef: BsModalRef;
	public loadingModalRef: BsModalRef;
	next;
	count;
	countFinal = 0;
	outRule = [];
	inRule = [];
	isActiveRule = []
	configRuleJson
	//minDate
	datePickerConfig: Partial<BsDatepickerConfig>;
	startDayFinal;
	countPage6 = 0;
	countPage1 = 0
	public language;
	public jsonWord;

	public umidityForm: FormGroup
	public TemperatureForm: FormArray
	public PowerForm: FormArray
	virgula
	public arrowTF = [false, false, false, false, false, false, false, false, false, false, false, false, false];
	nameRule
	imgsScenario = []
	iconScenario = 'icon_1'
	iconScenarioWhite = 'icon_1_white.svg'
	@HostListener('window:keyup', ['$event'])
	enterKeyUpEvent(e) {
		e.stopPropagation()
		if (e.keyCode == 188) {
			this.virgula = true
		}
	}
	constructor(
		private devicesService: DeviceService,
		private location: Location,
		private fb: FormBuilder,
		private rulesService: RuleService,
		private cd: ChangeDetectorRef,
		private modalService: BsModalService,
		private localeService: BsLocaleService,
		private translateJson: TranslateJsonService,
		private scenariosS: ScenarioService,
		private router: Router, ) {

		this.weekdays //= ["D", "S", "T", "Q", "Q", "S", "S"];
		this.daysTrueFalse = [true, true, true, true, true, true, true];
		this.interval = [];// new IntervalModel();
		this.outputR = []//new OutputModel();
		this.inputR = []//new InputModel();
		this.schedule //new RulesModel();
		if (localStorage.getItem('language') == 'pt') {
			ptBrLocale.invalidDate = 'Custom label';
			defineLocale('custom locale', ptBrLocale);
			this.localeService.use('custom locale');
		}
		if (localStorage.getItem('language') == 'es') {
			esLocale.invalidDate = 'Custom label';
			defineLocale('custom locale', esLocale);
			this.localeService.use('custom locale');
		}
		if (localStorage.getItem('language') == 'en') {
			enGbLocale.invalidDate = 'Custom label';
			defineLocale('custom locale', enGbLocale);
			this.localeService.use('custom locale');
		}


		/*this.umidityForm = fb.array([fb.group({
					valueU: ['', [
					  Validators.required,
					  Validators.minLength(2),
					  Validators.maxLength(4),
					  Validators.pattern(/^(([a-zA-Z\u00C0-\u00FF][\s\w\u00C0-\u00FF]{0,59})|([A-Za-z0-9._-]{2,})(@[A-Za-z0-9_-]{2,})(\.[A-Za-z]{2,6})+)$/)
					]]
				})])*/

		/*
				this.devices = devicesService.listDevices();
				this.devices.subscribe(res => {
					this.devName = res
					let resp: Device
					/*	let device14: Device = new Device({
							name: "Sensor de agua",
							_id: "123123141",
							ieeeAddr: "123123141",
							devId: "264",
							status: "online",
							epList: [6],
							type: "route",
							isActive: true
						})
						let deviceP: Device = new Device({
							name: "INTERRUPTOR",
							_id: "123123141",
							ieeeAddr: "123123141",
							devId: "265",
							epList: [6],
							status: "online",
							type: "route",
							isActive: true
						})
						let deviceSS = new Device({
							name: "SS",
							_id: "123123141",
							epList: [6],
							ieeeAddr: "123123141",
							devId: "772",
							status: "online",
							type: "route",
							isActive: true
						})
						let deviceL = new Device({
							name: "Luminosidade",
							_id: "123123141",
							epList: [6],
							ieeeAddr: "123123141",
							devId: "262",
							status: "online",
							type: "route",
							isActive: true
						})
						let deviceA = new Device({
							name: "Sensor de agua",
							_id: "123123141ABD",
							epList: [6],
							ieeeAddr: "123123141ABD",
							devId: "774",
							status: "online",
							type: "route",
							isActive: true
						})
						res.push(deviceSS)
						res.push(deviceL)
						res.push(deviceA)
						res.push(device14)
						res.push(deviceP)
					for (resp of res) {
						this.card = new Card(resp);
						if ((this.card.devId != '2') && (this.card.devId != '6') &&
							(this.card.devId != '258') && (this.card.devId != '528') &&
							(this.card.devId != '261') && (this.card.devId != '771') &&
							(this.card.devId != '264') && (this.card.devId != '265') && (this.card.devId != '772')) {
							this.inputToCheck.push(this.card)
							this.inRule.push(false)
							this.optionU.push(false)
							this.optionT.push(false)
							this.optionP.push(false)
							this.optionL.push(false)
							this.isCheckedT.push(true);
							this.isCheckedU.push(true);
							this.isCheckedP.push(true);
							this.isCheckedL.push(true);
						}
						if ((this.card.devId == '2') ||
							(this.card.devId == '258') || (this.card.devId == '528') || (this.card.devId == '264') ||
							(this.card.devId == '261') || (this.card.devId == '256') || (this.card.devId == '265') ||
							(this.card.devId == '81') || (this.card.devId == '771') || (this.card.devId == '772')) {
							this.card.isActive = (this.card.isActive && !this.card.invertOnOff) || (!this.card.isActive && this.card.invertOnOff)
							this.devsToOutput.push(this.card);
							this.flipCard.push(true)
							this.outRule.push(false)
						}
					}
					this.checkDevsType(this.devsToOutput)
		
					this.checkDevsTypeIn(this.inputToCheck)
					this.cd.markForCheck()
				});*/
		this.scenarios = scenariosS.listScenarios();
		this.scenarios.subscribe(res => { //pego cada cenario
			for (let i = 0; i < res.length; i++) {
				this.scnName[i] = ([res[i], false])
			}
		});
	}

	ngOnInit(): void {
		for (let i = 1; i <= 33; i++) {
			var img = 'icon_' + i + '_white.svg'
			this.imgsScenario.push(img)

		}
		this.iconScenario = 'icon_1'
		this.iconScenarioWhite = 'icon_1_white.svg'
		this.language = localStorage.getItem('language')
		this.location.replaceState('/')
		this.jsonWord = this.translateJson.changeLanguage(this.language)
		this.weekdays = [this.jsonWord.label.sundayInitial, this.jsonWord.label.mondayInitial, this.jsonWord.label.tuesdayInitial, this.jsonWord.label.wednesdayInitial,
		this.jsonWord.label.thursdayInitial, this.jsonWord.label.fridayInitial, this.jsonWord.label.saturdayInitial];

		this.currentGateway = (localStorage.getItem('currentGateway'));
		console.log
		this.startDay = moment(Date.now()).format('YYYY-MM-DD');
		this.datePickerConfig = Object.assign(
			{},
			{

				containerClass: 'theme-green',
				showWeekNumbers: false,
				rangeInputFormat: 'YYYY-MM-DD',
				minDate: new Date(),
			})
		this.condition = ["teste", "teste"]
		this.colorText = [];
		this.lightLevel = [];

		this.devices = this.devicesService.listDevices();
		this.devices.subscribe(res => {
			this.devName = res
			let resp: Device
			/*	let device14: Device = new Device({
					name: "Sensor de agua",
					_id: "123123141",
					ieeeAddr: "123123141",
					devId: "264",
					status: "online",
					epList: [6],
					type: "route",
					isActive: true
				})
				let deviceP: Device = new Device({
					name: "INTERRUPTOR",
					_id: "123123141",
					ieeeAddr: "123123141",
					devId: "265",
					epList: [6],
					status: "online",
					type: "route",
					isActive: true
				})
				let deviceSS = new Device({
					name: "SS",
					_id: "123123141",
					epList: [6],
					ieeeAddr: "123123141",
					devId: "772",
					status: "online",
					type: "route",
					isActive: true
				})
				let deviceL = new Device({
					name: "Luminosidade",
					_id: "123123141",
					epList: [6],
					ieeeAddr: "123123141",
					devId: "262",
					status: "online",
					type: "route",
					isActive: true
				})
				let deviceA = new Device({
					name: "Sensor de agua",
					_id: "123123141ABD",
					epList: [6],
					ieeeAddr: "123123141ABD",
					devId: "774",
					status: "online",
					type: "route",
					isActive: true
				})
				res.push(deviceSS)
				res.push(deviceL)
				res.push(deviceA)
				res.push(device14)
				res.push(deviceP)*/
			for (resp of res) {
				this.card = new Card(resp);
				if ((this.card.devId != '2') && (this.card.devId != '6') &&
					(this.card.devId != '258') && (this.card.devId != '528') &&
					(this.card.devId != '261') && (this.card.devId != '771') &&
					(this.card.devId != '264') && (this.card.devId != '265') && (this.card.devId != '772')) {
					this.inputToCheck.push(this.card)
					this.inRule.push(false)
					this.optionU.push(false)
					this.optionT.push(false)
					this.optionP.push(false)
					this.optionL.push(false)
					this.isCheckedT.push(true);
					this.isCheckedU.push(true);
					this.isCheckedP.push(true);
					this.isCheckedL.push(true);
				}
				if ((this.card.devId == '2') ||
					(this.card.devId == '258') || (this.card.devId == '528') || (this.card.devId == '264') ||
					(this.card.devId == '261') || (this.card.devId == '256') || (this.card.devId == '265') ||
					(this.card.devId == '81') || (this.card.devId == '771') || (this.card.devId == '772')) {
					this.card.isActive = (this.card.isActive && !this.card.invertOnOff) || (!this.card.isActive && this.card.invertOnOff)
					this.devsToOutput.push(this.card);
					this.flipCard.push(true)
					this.outRule.push(false)
				}
			}
			this.checkDevsType(this.devsToOutput)

			this.checkDevsTypeIn(this.inputToCheck)
			this.cd.markForCheck()
		});
		this.scheduleConfig();
		this.rulesService.listRules().subscribe(res => {
			this.allRules = res;
			for (let i = 0; i < res.length; i++) {
				this.rulesToOutput[i] = ([res[i], false])
				this.isActiveRule[i] = res[i].isActive
			}
		})
	}

	datepickerValueChange(event) {
		this.startDay = moment(this.startDay).format('YYYY-MM-DD')// ? event : moment(event).format('YYYY-MM-DD');
	}
	ngOnDestroy(): void { }
	checkDevsType(devsToOutput) {
		this.vectorTypes = [null, null, null, null, null, null, null, null, null];
		let count0 = [], count1 = [], count2 = [], count3 = [], count4 = [], count5 = [], count6 = [], count7 = [];
		for (let i = 0; i < devsToOutput.length; i++) {
			if (this.devsToOutput[i].devId == '81' || this.devsToOutput[i].devId === '256') count0.push([devsToOutput[i], this.outRule[i]]);
			if (this.devsToOutput[i].devId == '2') count1.push([devsToOutput[i], this.outRule[i]]); //2
			if (this.devsToOutput[i].devId == '258' || this.devsToOutput[i].devId === '528') { count2.push([devsToOutput[i], this.outRule[i]]); this.whiteLight[i] = false; }
			if (this.devsToOutput[i].devId == '771') count3.push([devsToOutput[i], this.outRule[i]]);
			if (this.devsToOutput[i].devId == '261') { count4.push([devsToOutput[i], this.outRule[i]]); this.whiteLight[i] = false; }//1
			if (this.devsToOutput[i].devId == '772') count5.push([devsToOutput[i], this.outRule[i]]);
			if (this.devsToOutput[i].devId == '264') count6.push([devsToOutput[i], this.outRule[i]]); //smartswitch
			if (this.devsToOutput[i].devId == '265') count7.push([devsToOutput[i], this.outRule[i]]); //smartpulse
		}
		this.vectorTypes[0] = count0;
		this.vectorTypes[1] = count1;
		this.vectorTypes[2] = count2;
		this.vectorTypes[3] = count3;
		this.vectorTypes[4] = count4;
		this.vectorTypes[5] = count5;
		this.vectorTypes[6] = count6;
		this.vectorTypes[7] = count7;
	}

	checkDevsTypeIn(inputToCheck) {
		let count5 = [], count6 = [], count7 = [], count8 = [], count9 = [], count10 = [];
		this.vectorTypesIn = [null, null, null, null, null, null];
		for (let i = 0; i < inputToCheck.length; i++) {
			if (this.inputToCheck[i].devId == '770') { count5.push([inputToCheck[i], this.inRule[i]]); this.optionU[i] = false; this.optionT[i] = false }
			if (this.inputToCheck[i].devId == '12') count6.push([inputToCheck[i], this.inRule[i]]);  //3
			if (this.inputToCheck[i].devId == '263') { count7.push([inputToCheck[i], this.inRule[i]]); }
			if (this.inputToCheck[i].devId == '81' || this.inputToCheck[i].devId === '256') { count8.push([inputToCheck[i], this.inRule[i]]); this.optionP[i] = false }
			if (this.inputToCheck[i].devId == '262') count9.push([inputToCheck[i], this.inRule[i]]);
			if (this.inputToCheck[i].devId == '774') count10.push([inputToCheck[i], this.inRule[i]]);  //3
		}
		this.vectorTypesIn[0] = count5;
		this.vectorTypesIn[1] = count6;
		this.vectorTypesIn[2] = count7;
		this.vectorTypesIn[3] = count8;
		this.vectorTypesIn[4] = count9;
		this.vectorTypesIn[5] = count10;
	}
	setFocus(input) {
		let searchFocus = <HTMLElement>document.getElementById(input);
		searchFocus.focus();
	}

	goToPage(page: number) {
		let holdOut = [], holdElemId = [], holdCheckedName = [];
		switch (page) {
			case 1:
				this.index = "#1a"
				this.page = 1;
				break
			case 2:
				if (!this.nameRule) {
					this.showSimpleDialog(this.jsonWord.error.requiredName)
					this.nome = false;
					//this.setFocus('nameInput')
					let searchFocus = <HTMLElement>document.getElementById('nameInput');
					searchFocus.focus();
					return;
				} else {
					this.nome = true;
				}
				//Verify if scenario name alredy exists, and an alert will be sent
				if (this.allRules) {
					for (let item of this.allRules) {
						if (item.name === this.nameRule) {
							this.nome = false;
							this.showSimpleDialog(this.jsonWord.toast.ruleAlreadyRegistered)
							return;
						} else {
							this.nome = true;
						}
					}
				}
				this.count = 0;
				this.verifyIfHasOneDay(0) //need this
				if (this.scheduleConfig() && this.nome) {
					this.index = "#2a"
					this.page = 2;
				} else {
					this.showSimpleDialog(this.jsonWord.alert.validHour)
					this.index = "#1a"
					this.page = 1;
					this.count = 0;
				}
				break
			case 3:
				let holdChecked = []
				for (let i = 0; i < this.inputToCheck.length; i++) {
					holdElemId.push(<any>document.getElementById(this.inputToCheck[i].devIeeeAddr))
					if (holdElemId[i] && holdElemId[i].checked) {//classList[holdElemId[i].classList.length - 1] === "mat-checkbox-checked") {
						holdChecked.push(this.inputToCheck[i])
						this.inRule[i] = true
					}
				}
				this.checkedDevices = holdChecked
				if (this.checkedDevices.length < 1) {
					this.count = 0
				}
				if (this.checkedDevices && this.checkedDevices.length >= 1) {
					this.checkedDevices = this.checkedDevices.sort((a, b) =>
						Number(a.devId) < Number(b.devId) ? -1 : Number(a.devId) > Number(b.devId) ? 1 :
							a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
					this.index = "#3a"
					this.page = 3;
				} else {
					if (this.count === 0) {
						this.showDialogInputChecked()
						this.index = "#2a"
						this.page = 2;
					} else {
						this.index = "#3a"
						this.page = 3;
					}
				}
				break
			case 4:
				this.count = 1;
				this.countPage6 = 0;
				this.countFinal = 0;
				this.inputConfig();

				this.verifyIfAtLeastOneIsChecked()
				if (<any>document.getElementsByClassName('ng-invalid').length) {
					this.index = "#3a"
					this.page = 3;
					this.showSimpleDialog(this.jsonWord.error.required)
					break;
				}
				for (let i = 0; i < this.devsToOutput.length; i++) {
					holdElemId.push(<any>document.getElementById(this.devsToOutput[i].devIeeeAddr))
					if (holdElemId[i] && holdElemId[i].checked) {//classList[holdElemId[i].classList.length - 1] === "mat-checkbox-checked") {
						holdChecked.push(this.devsToOutput[i])
					}
					this.checkedDevices2 = holdChecked
				}
				if (this.checkedDevices2 && this.checkedDevices2.length < 1) {
					this.countFinal = 0
				}
				if (this.checkP && this.withoutValueP) {
					this.checkedDevices2 = this.checkedDevices2 ? this.checkedDevices2.sort((a, b) =>
						//a.status == 'ONLINE' && b.status == "OFFLINE" ? 1 : a.status != 'ONLINE' && b.status != "OFFLINE" ? -1 :
						Number(a.devId) < Number(b.devId) ? -1 : Number(a.devId) > Number(b.devId) ? 1 :
							a.name < b.name ? -1 : a.name > b.name ? 1 :
								a.created < b.created ? -1 : a.created > b.created ? 1 : 0) : this.checkedDevices2;
					this.page = 4;
					this.index = "#4a";
				} else {
					if (!this.checkP) { this.showSimpleDialog(this.jsonWord.text.options) }//"Marque a opção dos dispositivos!") }
					this.page = 3;
					this.index = "#3a";
				}
				break
			case 5:
				let holdCheckedOut = []
				for (let i = 0; i < this.devsToOutput.length; i++) {
					holdElemId.push(<any>document.getElementById(this.devsToOutput[i].devIeeeAddr))
					holdCheckedName.push(<any>document.getElementsByName(this.devsToOutput[i].devIeeeAddr)[0])
					if (holdElemId[i] && holdElemId[i].checked) {//classList[holdElemId[i].classList.length - 1] === "mat-checkbox-checked") {
						holdCheckedOut.push(this.devsToOutput[i])
						this.countFinal = 1
						this.whiteLight[i] = this.devsToOutput[i].value3
					}
					if (holdCheckedName[i] && holdCheckedName[i].checked) {//classList[holdElemId[i].classList.length - 1] === "mat-checkbox-checked") {
						holdCheckedOut.push(this.devsToOutput[i])
						this.countFinal = 1
					}
				}
				this.checkedDevices2 = holdCheckedOut
				for (let i = 0; i < this.checkedDevices2.length; i++) {
					this.whiteLight[i] = this.checkedDevices2[i].value3
				}
				this.outputConfigEnd();
				let holdScn = [], holdCheckedSc = [];
				for (let i = 0; i < this.scnName.length; i++) {
					holdScn.push(<any>document.getElementById(this.scnName[i][0]._id))
					if (holdScn[i] && holdScn[i].checked) {
						holdCheckedSc.push(this.scnName[i][0])
						this.countPage6 = 1;
					}
				}
				this.checkedScenarios = holdCheckedSc;

				let holdRule = [], holdCheckedRule = [];
				for (let i = 0; i < this.rulesToOutput.length; i++) {
					holdRule.push(<any>document.getElementById(this.rulesToOutput[i][0]._id))
					if (holdRule[i] && holdRule[i].checked) {
						holdCheckedRule.push(this.rulesToOutput[i][0])
						this.countPage1 = 1;
					}
				}
				this.holdCheckedRule = holdCheckedRule;
				/*if (this.checkedDevices2.length < 1 && this.checkedScenarios.length < 1 && this.holdCheckedRule.length < 1) {
					this.countFinal = 0;
					this.countPage6 = 0;
					this.countPage1 = 0;
					console.log("pages == 0")
				}
				if (this.checkedDevices2.length < 1 && this.countPage1 == 0) {
					this.countFinal = 0
					console.log("countFinal == 0")
				}
				*/
				if (this.checkedDevices2.length < 1 && this.checkedScenarios.length < 1 && this.holdCheckedRule.length < 1) { //se nao tem nada marcado
					this.showSimpleDialog(this.jsonWord.text.noScenarioActuatorRegistered)
					this.index = "#4a"
					this.page = 4;
				} else {
					if (this.checkedDevices2.length > 0 && (this.holdCheckedRule.length > 0 || this.holdCheckedRule.length < 1)) { //se tem dispositivo e tem regra ou nao
						this.page = 5;
						this.index = "#5a"
					}

					if (this.checkedDevices2.length < 1 && this.holdCheckedRule.length > 0) { //ou tem regra e disp ou so tem regra
						this.page += 1
						this.page = 6;
						this.index = "#6a"
					}
					if (this.checkedScenarios.length > 0 && this.checkedDevices2.length < 1 && this.holdCheckedRule.length < 1) {
						this.page = 7;
						this.index = "#7a"
						page = 7
						this.goToPage(page)
					}
				}
				break;
			case 6:
				this.holdCheckedRule = this.holdCheckedRule
				if (this.holdCheckedRule.length > 0) { //se tem regra
					this.page = 6;
					this.index = "#6a"
					break;
				} else {                   //não tem regra
					this.page = 7;
					this.index = "#7a"
					page += 1
					this.goToPage(page)
					//break;
				}
				break;
			case 7:
				this.page = 7;
				this.index = "#7a";
				this.devConfigd = null;
				if (this.checkedDevices2.length > 0) {
					this.devConfigd = this.outputConfigEnd();
				}
				break;
			default:
				break
		}
	}

	previousPage() {
		if (this.page > 1)
			if (this.page == 7) {
				if (this.holdCheckedRule.length < 1) {
					if (this.checkedDevices2.length < 1) //se não tem disp, nem regra, ou seja, apenas cenário
						this.page = this.page - 3;
					else//se não tem regra, mas tem disp
						this.page = this.page - 2;
				} else {
					this.page = this.page - 1;//se tem regra
				}
			} else if (this.page == 6) {
				if (this.checkedDevices2.length > 0) //se tem disp
					this.page = this.page - 1;
				else//se não tem disp
					this.page = this.page - 2;
			} else {
				this.page = this.page - 1
			}
	}

	nextPage() {
		if (this.page < 7) {
			this.goToPage(this.page + 1)
		}
	}

	showSimpleDialog(message) {
		this.modalRef = this.modalService.show(AlertComponent);
		this.modalRef.content.buttonConfirm = { show: false };
		this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
		this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
		this.modalRef.content.type = 'warning'
		this.modalRef.content.title = this.jsonWord.alert.attention
		this.modalRef.content.message = message
		this.modalRef.content.isDelete = false;
		return this.modalRef.content.onClose
	}
	showDialogInputChecked() {
		this.modalRef = this.modalService.show(AlertComponent);
		this.modalRef.content.buttonConfirm = { show: false };
		this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.remain };
		this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.next };
		this.modalRef.content.type = 'warning'
		this.modalRef.content.title = this.jsonWord.alert.attention
		this.modalRef.content.message = this.jsonWord.text.noSelectedInputs + '!'
		this.modalRef.content.onClose.subscribe(result => {
			this.next = result;
			if (this.next) {
				this.index = "#4a"
				this.page = 4;
				this.count = 1;
			} else {
				this.index = "#2a"
				this.page = 2;
			}
		});
	}

	getDateValue() {
		//verifica se tem value e seta algum retorno e pega no goToPage
		let hoursS, hourE;
		hoursS = <HTMLCollection>document.getElementsByClassName("hourS");
		this.horaStarts = hoursS[0].value ? hoursS[0].value : "00:00:00";
		hourE = <HTMLCollection>document.getElementsByClassName("hourE");
		this.horasEnds = hourE[0].value ? hourE[0].value : "00:00:00";
	}
	weekdaysChosen(i) {
		this.daysTrueFalse[i] = !this.daysTrueFalse[i];
		this.verifyIfHasOneDay(i);
	}
	//cria um json com as configurações de agendamento (horas e minutos iniciais e finais)
	scheduleConfig() {
		if (this.horaStarts && this.horasEnds) {
			let data = moment(this.startDay)// + "T12:01:25.296Z"
			this.horaStarts.length >= 6 ? this.horaStarts : this.horaStarts += ":00";
			this.horasEnds.length >= 6 ? this.horasEnds : this.horasEnds += ":00";
			this.interval = {
				"startDay": data,
				//"endDay": "2020-07-31",
				"startHour": this.horaStarts,//String(convertedHourS),//,
				"endHour": this.horasEnds//String(convertedHourE)
			}
			this.horasOk = true;
			return true
		} else {
			return false
		}
	}
	//Create a json with input configurations
	createJsonInput(dispositivo, epId, tipo, valor, cId, condicao, i) {
		let cmd;
		if (valor == this.jsonWord.label.on) { cmd = "on"; valor = 1 } else if (valor == this.jsonWord.label.off) { cmd = "off"; valor = 0 } else { cmd = null; }
		this.inputR[i] =
			{
				"cmd": cmd,
				"name": dispositivo.title,
				"ieeeAddr": dispositivo.devIeeeAddr,
				"devId": dispositivo.devId,
				"epId": String(epId),
				"cId": cId, //cId
				"value": Number(parseFloat(valor).toFixed(1)),
				"type": tipo,
				"condition": condicao
			}
		this.allInput[i] = this.inputR;
		return this.allInput
	}

	//configurações de dispositivos de entrada
	inputConfig() {
		let count = 0, holdIn = [];
		if (this.checkedDevices) {
			for (let i = 0; i < this.checkedDevices.length; i++) {//trato dispositivos input
				let hold = [], cond, condP = ["nada", "nada"];
				let optionTemp = [], optionUmd = [], optionPot = [], optionLum = []
				if (this.checkedDevices[i].devId === '770') {//se for sensor HT ou sensor de liminosidade
					hold[0] = this.checkedDevices[i];
					if (this.isCheckedT[i]) { //se temperatura marcada, pego condiçao+valor
						if (!this.valueT[i]) {
							//	this.withoutValueT = 0;
							this.showSimpleDialog(this.jsonWord.error.required)
							return;
						} else {
							optionTemp[i] = this.optionT[i] ? this.jsonWord.label.greater : this.jsonWord.label.lower;
							condP[0] = this.jsonWord.label.ifTemp + optionTemp[i] + " " + this.jsonWord.label.than + " " + this.valueT[i] + ". "
							this.createJsonInput(this.checkedDevices[i], 8, "measuredValue", this.valueT[i], "msTemperatureMeasurement", (this.optionT[i] ? ">" : "<"), count);
							count++;
						} this.checkT = true;
					}
					if (this.isCheckedU[i]) {
						if (!this.valueU[i]) {
							this.showSimpleDialog(this.jsonWord.error.required)
							//this.withoutValueU = 0;
							return;
						} else {
							console.log(this.valueU[i])
							if (this.valueU[i] > 100) { this.valueU[i] = 100 }
							//console.log(this.optionU, this.valueU[i], document.getElementsByClassName('newValor')[i])
							this.umidityForm = this.fb.group({
								valueU: ['', [
									Validators.required,
									Validators.minLength(2),
									Validators.maxLength(4),
									Validators.pattern(/^([0-9]{1,2}){1}(\.[0-9]{1,2})?$/)
								]]
							})

							optionUmd[i] = this.optionU[i] ? this.jsonWord.label.greater : this.jsonWord.label.lower;
							condP[1] = " " + this.jsonWord.label.ifHum + optionUmd[i] + " " + this.jsonWord.label.than + " " + this.valueU[i] + ". "
							this.createJsonInput(this.checkedDevices[i], 8, "measuredValue", this.valueU[i], "msRelativeHumidity", (this.optionU[i] ? ">" : "<"), count);
							count++;
						} this.checkU = true;
					}
					if (!this.isCheckedU[i] && !this.isCheckedT[i]) {
						this.checkT = false;
						this.checkU = false;
					}
					cond = condP;
				}
				if (this.checkedDevices[i].devId === '262') {//luminosidade
					//TODO
					hold[0] = this.checkedDevices[i];
					if (this.isCheckedL[i]) { //se temperatura marcada, pego condiçao+valor
						if (!this.valueL[i]) {
							this.showSimpleDialog(this.jsonWord.error.required)
							return;
						} else {
							optionLum[i] = this.optionL[i] ? this.jsonWord.label.greater : this.jsonWord.label.lower;
							condP[0] = this.jsonWord.label.ifLum + optionLum[i] + " " + this.jsonWord.label.than + " " + this.valueL[i] + " ";
							this.createJsonInput(this.checkedDevices[i], 8, "measuredValue", this.valueL[i], "msIlluminanceMeasurement", (this.optionL[i] ? ">" : "<"), count);
							count++;
						} this.checkP = true;
					} else {
						this.checkP = false;
					}
					cond = condP;
				} else { this.checkP = true; }
				if (this.checkedDevices[i].devId === '81' || this.checkedDevices[i].devId === '256') {
					hold[0] = this.checkedDevices[i];
					if (this.isCheckedP[i]) { //se temperatura marcada, pego condiçao+valor
						if (!this.valueP[i]) {
							this.showSimpleDialog(this.jsonWord.error.required)
							return;
						} else {
							optionPot[i] = this.optionP[i] ? this.jsonWord.label.greater : this.jsonWord.label.lower;
							condP[0] = this.jsonWord.label.ifPot + optionPot[i] + " " + this.jsonWord.label.than + " " + this.valueP[i] + " ";
							this.createJsonInput(this.checkedDevices[i], 8, "instantaneousDemand", this.valueP[i], "seMetering", (this.optionP[i] ? ">" : "<"), count);
							count++;
						} this.checkP = true;
					} else {
						this.checkP = false;
					}
					cond = condP;
				} else { this.checkP = true; }
				if (this.checkedDevices[i].devId === '12' || this.checkedDevices[i].devId === '774') {
					hold[0] = this.checkedDevices[i];
					if (this.state[i]) {
						condP[0] = this.checkedDevices[i].devId === '12' ? this.jsonWord.label.open : this.jsonWord.label.withWater  //"Aberto";
						this.createJsonInput(this.checkedDevices[i], 8, "onOff", this.jsonWord.label.on, "genOnOff", "=", count)
						count++;
					}
					else {
						condP[0] = this.checkedDevices[i].devId === '12' ? this.jsonWord.label.closed : this.jsonWord.label.withoutWater //"Fechado"
						this.createJsonInput(this.checkedDevices[i], 8, "onOff", this.jsonWord.label.off, "genOnOff", "=", count)
						count++;
					}
					this.condSignal[i] = "="
					cond = condP;
				}
				if (this.checkedDevices[i].devId === '263') {
					hold[0] = this.checkedDevices[i];
					condP[0] = this.state[i] ? this.jsonWord.label.withPresence : this.jsonWord.label.withoutPresence
					this.createJsonInput(this.checkedDevices[i], 8, "occupancy", (this.state[i] ? this.jsonWord.label.on : this.jsonWord.label.off), "msOccupancySensing", "=", count)
					count++;
					this.condSignal[i] = "="
					cond = condP;
				}
				holdIn[i] = (hold);
				this.condition[i] = cond;
			}
			this.input = holdIn;
		}
	}

	//working - 09/10
	//create a json with output configurations
	createJsonOutput(dispositivo, i) {
		//this.outputR = []
		if (this.output[i][1] === "Desativa") { //If it's to turn any devices off
			this.outputR.push({
				name: dispositivo.title,
				ieeeAddr: dispositivo.devIeeeAddr,
				epId: String(dispositivo.devEpList[0]),
				devId: dispositivo.devId,
				cId: "genOnOff",
				cmd: "off",
				zclData: {}
			})
		} else { //if diferent of lamp and if it's turn devices on
			this.outputR.push({
				name: dispositivo.title,
				ieeeAddr: dispositivo.devIeeeAddr,
				epId: String(dispositivo.devEpList[0]),
				devId: dispositivo.devId,
				cId: "genOnOff",
				cmd: "on",
				zclData: {}
			})
			if (dispositivo.devId === "258" || dispositivo.devId === "528" || dispositivo.devId === "261") {//If colorful lamps and if it's turn them on
				this.outputR.push({
					name: dispositivo.title,
					ieeeAddr: dispositivo.devIeeeAddr,
					epId: String(dispositivo.devEpList[0]),
					devId: dispositivo.devId,
					cId: "lightingColorCtrl",
					cmd: "moveToHueAndSaturation",
					zclData: { hue: parseInt(this.output[i][3]), saturation: parseInt(this.output[i][4]), transtime: 0 }
				}, {
						name: dispositivo.title,
						ieeeAddr: dispositivo.devIeeeAddr,
						epId: String(dispositivo.devEpList[0]),
						devId: dispositivo.devId,
						cId: "genLevelCtrl",
						cmd: "moveToLevel",
						zclData: { level: parseInt(this.output[i][2]), transtime: 0 }
					});
			}
		}
	}

	goToRules() {
		this.router.navigate(['home/rules']);
	}

	setRuleOnOff(rule, i) {
		this.isActiveRule[i] = !this.isActiveRule[i]
	}
	outputConfigEnd() {
		let holdOut = []
		if (this.checkedDevices2) {
			for (let i = 0; i < this.checkedDevices2.length; i++) {//dispositivos output
				let hold = [];
				hold[0] = this.checkedDevices2[i];
				hold[1] = (this.checkedDevices2[i].isActive ? "Ativa" : "Desativa")
				hold[2] = (!this.lightLevel[i] ? this.checkedDevices2[i].value1 : this.lightLevel[i])
				/*this.whiteOrColorful[i] = (this.whiteLight[i] ? 0 : 1)
				hold[4] = (this.whiteOrColorful[i] === 0 ? "1" : "254")
				this.white[i] = (this.whiteOrColorful[i] === 0 ? "100" : "50")*/
				hold[4] = (this.whiteLight[i] ? "0" : "254")
				this.white[i] = (this.whiteLight[i] ? "100" : "50")
				hold[3] = this.colorText[i];
				this.colorful[i] = (this.colorText[i] / 254) * 360; //se cor foi clicada 
				this.levelToShow[i] = (hold[2] / 254) * 100
				this.levelToShow[i] = parseInt(this.levelToShow[i])
				holdOut[i] = (hold);
			}
		}
		this.output = holdOut;
		return this.output;
	}

	//Works
	verifyIfHasOneDay(j) {
		this.jsonDays = []
		for (let i = 0; i < this.daysTrueFalse.length; i++) {
			if (this.daysTrueFalse[i]) {
				this.jsonDays.push({ "day": i });
				this.daysChecked = i;
			}
		}
		this.count1 = 0;
		if (this.jsonDays.length < 1) {
			this.daysTrueFalse[j] = true;
			this.showSimpleDialog(this.jsonWord.text.atLeastOneDay)
			return
		}
		return this.daysChecked;
	}

	verifyIfAtLeastOneIsChecked() {
		let holdTU = [], count = [], hold = [], holdT = [], holdU = [], count2 = [];
		for (let i = 0; i < this.checkedDevices.length; i++) {
			if (this.checkedDevices[i].devId === "256" || this.checkedDevices[i].devId === "81") {
				if (this.isCheckedP[i]) {
					this.valueP[i] && this.valueP[i].length > 0 ? hold[i] = true : hold[i] = false
				}
			}
			if (this.checkedDevices[i].devId === "770") {
				if (this.isCheckedT[i] || this.isCheckedU[i]) {
					//verifica se há valor quando checked
					holdTU.push(true);
					if (this.isCheckedT[i]) {
						if (this.valueT[i] && this.valueT[i].length > 0) {
							holdT[i] = (true)
						} else holdT[i] = (false)
					} else { holdT[i] = true }
					if (this.isCheckedU[i]) {
						if (this.valueU[i] && this.valueU[i].length > 0) {
							holdU[i] = (true)
						} else holdU[i] = (false)
					} else { holdU[i] = true }
					if (holdU[i] && holdT[i]) { hold[i] = (true); } else hold[i] = (false)
				}
				else {
					holdTU.push(false);
				}

			}
			count = holdTU.filter(res => res == false)
			count2 = hold.filter(res => res == false)
			this.withoutValueP = count2[0] == false ? false : true
			this.checkP = count[0] == false ? false : true
		}
	}

	setIcon() {
		this.modalRef = this.modalService.show(IconsScenarioModalComponent);
		this.modalRef.content.imgsScenario = this.imgsScenario
		this.modalRef.content.onClose.subscribe(res => {
			this.iconScenario = res
			this.iconScenarioWhite = (res.length < 7 ? res.slice(0, 6) + '_white.svg' : res.slice(0, 7) + '_white.svg')
		})
	}

	formatRules() {
		let idsRules = []
		for (let i = 0; i < this.holdCheckedRule.length; i++) {
			idsRules.push({
				"ruleId": this.holdCheckedRule[i]._id,
				"isActive": this.isActiveRule[i]
			})
		}
		return idsRules
	}

	configsRule() {
		let idsScenarios = [], idsRules = []; this.outputR = [];
		let inputFinal = this.allInput[0];
		console.log(inputFinal)
		for (let i = 0; i < this.output.length; i++) {
			this.createJsonOutput(this.output[i][0], i)
		}
		if (this.checkedScenarios) {
			for (let i = 0; i < this.checkedScenarios.length; i++) {
				idsScenarios.push({ "scenarioId": this.checkedScenarios[i]._id });
			}
		}
		if (this.holdCheckedRule) idsRules = this.formatRules()

		this.schedule = { interval: this.interval, weekdays: this.jsonDays }
		//if (this.nome) {
		//let message
		return {
			'name': this.nameRule,
			'inputs': inputFinal ? inputFinal : [],
			'outputs': this.outputR,
			'isActive': true,
			'schedule': this.schedule,
			'sceneOutputs': [],//idsScenarios,
			"ruleOutputs": idsRules,
			'icon': this.iconScenario
		}
	}
	createOpositeRuleModal() {
		let message = this.configsRule()
		this.showSimpleDialog("Deseja criar uma contra regra?").subscribe(res => {
			let smartPulse = this.outputR
			let indexPulse = smartPulse.findIndex(dev => dev.devId == '265') //Se houver SmartPulse, retirar da regra
			if (indexPulse > -1) {
				smartPulse.splice(indexPulse, 1)
			}
			//se tiver regra ou se tiver disp saida sem SPulse
			if ((smartPulse.length > 0 || message.ruleOutputs.length > 0)) {
				if (res) {
					this.createOpositeRule(message).subscribe(res => {
						if (res) {
							//TODO testar os fors antes de descomentar
							this.onSaveRule(res)
						}
					})
				} else {
					//this.onSaveRule(message)
				}
			} else {
				console.log(res)
				this.modalRef = this.modalService.show(AlertComponent);
				this.modalRef.content.buttonConfirm = { show: false };
				this.modalRef.content.buttonCancel = { show: false };
				this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
				this.modalRef.content.type = 'warning'
				this.modalRef.content.title = this.jsonWord.alert.attention
				this.modalRef.content.message = ("Não pode criar regra oposta")
				this.modalRef.content.onClose.subscribe(res => {
					//if (res) 
				})
			}
		})
		this.onSaveRule(message)
	}

	/**
	 * @function onSaveRule() get final values of rules and create a json, sending by mqtt to create a new rule
	 */
	onSaveRule(message) {
		if (this.nome) {
			this.saveClicked = 1;
			this.openLoadingModal(this.jsonWord.loading.message)
			this.rulesService.createRule(message).subscribe(res => {
				if (res) {
					this.modalRef.content.success = true
					this.modalRef.content.waiting = false
					this.modalRef.content.message = this.jsonWord.toast.createRuleSuccess
					setTimeout(() => {
						this.modalRef.hide()
						this.router.navigate(['home/rules']);
					}, 2000);
				} else {
					this.modalRef.content.success = true
					this.modalRef.content.waiting = false
					this.modalRef.content.message = this.jsonWord.toast.createConflictedRuleSuccess
					setTimeout(() => {
						this.modalRef.hide()
						this.router.navigate(['home/rules']);
					}, 2000);
				}
			}, err => {
				let message = ''
				switch (err.status) {
					case 0:
						message = this.jsonWord.error.noConnection//'Formulário de convidar usuário inválido.'
						//this.noInternetAlert()
						break
					case 400:
						message = this.jsonWord.error.invalidFormat//'Formulário de convidar usuário inválido.'
						break
					case 401:
						message = this.jsonWord.error.notAuthorized//'Você não tem permissão para convidar usuários.'
						break
					/*	case 403:
							message = this.jsonWord.error.userDisabled//'Usuário está desativado.'
							break*/
					case 404:
						message = this.jsonWord.error.ruleNotFound//'Usuário não encontrado ou número serial de gateway não registrado.'
						break
					case 408:
						message = this.jsonWord.error.timeoutServer;//'Tempo limite de conexão atingido.'
						break
					/*	case 409:
							message = this.jsonWord.error.userAlreadyInGtw//'Usuário já associado ao gateway.'
							break*/
					case 412:
						message = this.jsonWord.error.limitUserExceeded//'Usuário já associado ao gateway.'
						break
					case 500:
						message = this.jsonWord.toast.gatewayCommunicationError//'Não foi possível enviar convite.'
						break
					default:
						message = this.jsonWord.toast.createRuleError

				}
				this.modalRef.content.success = false
				this.modalRef.content.waiting = false
				this.modalRef.content.message = message
				setTimeout(() => {
					this.modalRef.hide()
					this.router.navigate(['home/rules']);
				}, 2000)
			})
		}

	}

	createOpositeRule(ruleConfig) {
		console.log(ruleConfig)
		let weekdaysSort = [], daysTrueFalse = [], arrayDevsRule = [], arrayDevConfig = []
		let devs = []
		devs = ruleConfig.outputs
		for (let day of ruleConfig.schedule.weekdays) {
			weekdaysSort.push(day.day)

		}
		let weekdaysBD = weekdaysSort.sort();
		for (let i = 0; i < weekdaysBD.length; i++) {
			daysTrueFalse[weekdaysBD[i]] = true;
		}
		for (let i = 0; i < devs.length; i++) {
			if ((devs[i].devId == 261 || devs[i].devId == 258) && devs[i].cmd == 'off') {
				arrayDevsRule.push(devs[i])
				arrayDevConfig.push(['on', 127, 0, 100])
				i = i + 2
			} else {
				arrayDevsRule.push(devs[i])
				arrayDevConfig.push('off')
			}
		}
		let startDay = ruleConfig.schedule.interval.startDay
		let startHour = ruleConfig.schedule.interval.startHour
		let endHour = ruleConfig.schedule.interval.endHour
		this.modalRefRuleOpt = this.modalService.show(RuleOpstCardModalComponent);
		this.modalRefRuleOpt.content.buttonConfirm = { show: false };
		this.modalRefRuleOpt.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
		this.modalRefRuleOpt.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
		this.modalRefRuleOpt.content.name = ruleConfig.name + " ("+ this.jsonWord.label.opposite + ")"
		this.modalRefRuleOpt.content.inputs = ruleConfig.inputs
		this.modalRefRuleOpt.content.nameOriginal = ruleConfig.name
		this.modalRefRuleOpt.content.outputs = arrayDevsRule
		this.modalRefRuleOpt.content.configs = arrayDevConfig
		this.modalRefRuleOpt.content.ruleOutputs = ruleConfig.ruleOutputs
		this.modalRefRuleOpt.content.startDay = startDay
		this.modalRefRuleOpt.content.startHour = startHour
		this.modalRefRuleOpt.content.weekdays = [this.jsonWord.label.sundayInitial, this.jsonWord.label.mondayInitial, this.jsonWord.label.tuesdayInitial, this.jsonWord.label.wednesdayInitial,
		this.jsonWord.label.thursdayInitial, this.jsonWord.label.fridayInitial, this.jsonWord.label.saturdayInitial]
		this.modalRefRuleOpt.content.endHour = endHour
		this.modalRefRuleOpt.content.nameRule = this.holdCheckedRule
		this.modalRefRuleOpt.content.daysTrueFalse = daysTrueFalse;
		this.modalRefRuleOpt.content.icon = ruleConfig.icon
		this.modalRefRuleOpt.content.ruleJson = ruleConfig
		this.modalRefRuleOpt.content.allRules = this.allRules
		return this.modalRefRuleOpt.content.onClose
	}
	changeCheck(device, checkDoor) {
		device[1] = checkDoor.checked
	}
	changeCheckScn(scn, checkScn) {
		scn[1] = checkScn.checked
	}
	changeCheckRule(rule, checkRule) {
		rule[1] = checkRule.checked
	}
	rotateCard(i) {
		this.flipCard[i] = !this.flipCard[i];
	}
	onSmartlightLevelChange(levelV, i) {
		var level;
		this.lightLevel[i] = levelV;
		return this.lightLevel;
	}
	onSmartlightColorChange(colorV, i) {
		this.colorText[i] = colorV;
		return this.colorText;
	}
	sendOnOff(check, i) {
		check.isActive = !check.isActive;
	}

	getColor(i, value2) {
		this.colorText[i] = this.colorText[i] ? this.colorText[i] : value2;
		return this.colorText[i]
	}
	getLevel(i, value1) {
		this.lightLevel[i] = this.lightLevel[i] ? this.lightLevel[i] : value1;
		return this.lightLevel[i];
	}
	whiteClicked(i) {
		this.whiteLight[i] = !this.whiteLight[i];
	}
	/**
	 * @param checkP @param checkU  @param checkT verify if power, temperatire and/or humidity are checked
	 */
	checkTemperature(checkT, i) {
		this.isCheckedT[i] = checkT.checked;
		return this.isCheckedT[i];
	}
	checkHumidity(checkU, i) {
		this.isCheckedU[i] = checkU.checked;
		return this.isCheckedU[i];
	}

	checkPower(checkP, i) {
		this.isCheckedP[i] = checkP.checked;
		return this.isCheckedP[i];
	}

	getOptionValue(value, i) {
		return value;
	}
	setLetterBGColor(i) {
		if (this.daysTrueFalse[i]) {
			return this.backgroundColor = "rgba(146, 202, 83, 0.8)";
		} else {
			return this.backgroundColor = "rgba(255,255,255,0.2)";
		}
	}
	setLetterColor(i) {
		if (this.daysTrueFalse[i]) {
			return this.color = "whitesmoke";
		} else {
			return this.color = "rgba(255,255,255,0.5)";
		}
	}
	goBack(): void {
		this.location.back();
	}
	openLoadingModal(message) {
		this.modalRef = this.modalService.show(
			LoadingModalComponent,
			{
				keyboard: false,
				backdrop: 'static',
				class: 'waiting-modal modal-sm'
			})
	}

	setArrow(i) {
		this.arrowTF[i] = !this.arrowTF[i]
		switch (i) {
			case (0):
				document.getElementById('arrowSP').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (1):
				document.getElementById('arrowS').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (2):
				document.getElementById('arrowRGB').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (3):
				document.getElementById('arrowSPP').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (4):
				document.getElementById('arrowLed').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (5):
				document.getElementById('arrowScn').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (6):
				document.getElementById('arrowTP').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (7):
				document.getElementById('arrowOC').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (8):
				document.getElementById('arrowOcc').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (9):
				document.getElementById('arrowSP2').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (10):
				document.getElementById('arrowL').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (11):
				document.getElementById('arrowTurn').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (12):
				document.getElementById('arrowW').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (13):
				document.getElementById('arrowSwitch').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (14):
				document.getElementById('arrowPulse').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (15):
				document.getElementById('arrowRule').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
		}
	}
}

import { TranslateJsonService } from './../../_services/translate-json.service';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { LoginService } from '../login.service';
import { LoadingModalComponent } from '../../_directives/loading-modal/loading-modal.component';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {

  public forgotForm: FormGroup
  public modalRef: BsModalRef
  public email: FormControl
  public loading = false
  public cooldown = false
  language;
  jsonWord;
  public today = new Date()

  keys = Object.keys

  constructor(
    private loginService: LoginService,
    private modalService: BsModalService,
    private translateJson:TranslateJsonService,
    private renderer: Renderer2
  ) {
    this.language = localStorage.getItem('language') ? localStorage.getItem('language') : 'pt'
    this.jsonWord = this.translateJson.changeLanguage(this.language) 
    renderer.setStyle(
      document.getElementsByTagName('body')[0],
      'background-image',
      'url(/assets/img/bg/3.jpg)'
      //'url(/assets/img/bg/xmas.png)'
    )
    this.email = new FormControl(null, [Validators.required, Validators.email])
    this.forgotForm = new FormGroup({
      email: this.email
    })
  }

  ngOnInit() {
  }

  submit(form) {
    this.loading = true
    this.openLoadingModal(this.jsonWord.loading.message);//'Solicitando recuperação de senha...')
    this.loginService.forgot(form).pipe(finalize(() => this.loading = false))
      .subscribe(
        res => {
          this.cooldown = true
          setTimeout(()=>{ this.cooldown = false },5000)
          this.modalRef.content.message = this.jsonWord.alert.recoverPasswordSuccess
          //"Solicitação realizada com sucesso! Um e-mail foi enviado para o endereço especificado com instruções para alterar sua senha."
          this.modalRef.content.waiting = false
          this.modalRef.content.success = true
        },
        err => {
          console.log(err)
          let message = ''
          switch (err.status) {
            case 0:
              message = this.jsonWord.error.noConnection;//'Campos de email ou senha inválidos.'
              //  this.noInternetAlert()
              break
            case 400:
              message = this.jsonWord.error.invalidFormat//'Formulário de recuperação de senha inválido.'
              break
            case 401:
              message = this.jsonWord.error.notAuthorized//'Você não tem permissão para solicitar recuperação de senha.'
              break
            case 403:
              message = this.jsonWord.error.userDisabled//'Usuário está desativado.'
              break
            case 404:
              message = this.jsonWord.error.userNotFound//'Usuário não encontrado ou número serial de gateway não registrado.'
              break
            case 408:
              message = this.jsonWord.error.timeoutServer//'Usuário não encontrado ou número serial de gateway não registrado.'
              break
            case 500:
              message = this.jsonWord.toast.errorToRecoverPassword//'Não foi possível solicitar recuperação de senha.'
              break
            default:
              message = this.jsonWord.error.failOperation
          }
          this.modalRef.content.message = this.jsonWord.error.error + " " + message
          this.modalRef.content.waiting = false
          this.modalRef.content.success = false
          setTimeout(() => {
              this.modalRef.hide()
          }, 2000);
          //this.openErrorDialog(err.message)
        }
      )
  }
  openLoadingModal(message) {
    this.modalRef = this.modalService.show(
      LoadingModalComponent,
      {
        keyboard: false,
        backdrop: 'static',
        class: 'waiting-modal modal-sm'
      })
    this.modalRef.content.message = message;
  }

}

import { DashboardComponent } from './dashboard/dashboard.component';
import { PrivacyComponent } from './privacy/privacy-component';
import { GatewayComponent } from './gateway/gateway.component';
import { EditUserComponent } from './user/edit-user/edit-user.component';
import { InviteUserComponent } from './user/invite-user/invite-user.component';
import { RuleEditComponent } from './rule/rule-edit/rule-edit.component';
import { RuleConfigComponent } from './rule/rule-config/rule-config.component';
import { SceneConfigComponent } from './scene/scene-config/scene-config.component';
import { EnvironmentConfigComponent } from './environment/environment-config/environment-config.component';
import { devicesRoutes } from './device/devices-routing';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//import { ModuleWithProviders } from '@angular/core/src/metadata/ng_module';

import { HomeComponent } from './home/home.component';
import { RuleComponent } from './rule/rule.component';
import { SettingsComponent } from './settings/settings.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_guards/auth.guard';
import { PanelComponent } from './panel/panel.component';
import { UserComponent } from './user/user.component';
import { SceneComponent } from './scene/scene.component';
import { LogComponent } from './log/log.component';
import { EnvironmentComponent } from './environment/environment.component';
import { SceneEditComponent } from './scene/scene-edit/scene-edit.component';
import { RegisterComponent } from './login/register/register.component';
import { ForgotComponent } from './login/forgot/forgot.component';
import { RecoveryComponent } from './login/recovery/recovery.component';
import { ConfirmationComponent } from './login/confirmation/confirmation.component';
import { ProfileComponent } from './profile/profile.component';
import { ModuleWithProviders } from '@angular/compiler/src/core';

//const teste = () => import('./panel/panel.module').then(m => m.PanelModule)

export const appRoutes: Routes = [
  {
    path:'privacy',
    component:PrivacyComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register/:_token',
    component: RegisterComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'forgot',
    component: ForgotComponent,
  },
  {
    path: 'recovery/:_token',
    component: RecoveryComponent,
  },
  {
    path: 'confirmUser/:_token',
    component: ConfirmationComponent,
  },
  {
    path: 'confirmInvite/:_token',
    component: ConfirmationComponent,
  },
  {
    path: '',
    redirectTo: 'home/panel',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'panel',
        component: PanelComponent,
        canActivate: [AuthGuard]
      },{
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard]
      },{
        path: 'users',
        component: UserComponent,
        canActivate: [AuthGuard]
      }, {
        path: 'users/edit',
        component: EditUserComponent,
        canActivate: [AuthGuard]
      }, {
        path: 'users/edit/:_id',
        component: EditUserComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'devices',
       // component: DeviceNewComponent,
        component: PanelComponent,
        canActivate: [AuthGuard]
      }/*, {
        path: 'devices/config/:_id',
        component: DeviceEditComponent,
        canActivate: [AuthGuard]
      }*/,
      {
        path: 'gateways',
        component: GatewayComponent,
        canActivate: [AuthGuard]
      },/* {
        path: 'gateways/config',
        component: GatewayConfigComponent,
        canActivate: [AuthGuard]
      }, {
        path: 'gateways/edit/:_id',
        component: GatewayEditComponent,
        canActivate: [AuthGuard]
      }*/
      {
        path: 'environments',
        component: EnvironmentComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'environments/config',
        component: EnvironmentConfigComponent,
        canActivate: [AuthGuard]
      }, {
        path: 'environments/config/:_id',
        component: EnvironmentConfigComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'scenarios',
        component: SceneComponent,
        canActivate: [AuthGuard]
      }, {
        path: 'scenarios/config',
        component: SceneConfigComponent,
        canActivate: [AuthGuard]
      }, {
        path: 'scenarios/edit/:_id',
        component: SceneEditComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'rules',
        component: RuleComponent,
        canActivate: [AuthGuard]
      }, {
        path: 'rules/config',
        component: RuleConfigComponent,
        canActivate: [AuthGuard]
      }, {
        path: 'rules/edit/:_id',
        component: RuleEditComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'logs',
        component: LogComponent,
        canActivate: [AuthGuard]
      }
    ]
  }
];

export const AppRoutingModule = RouterModule.forRoot(appRoutes);

import { EditUserModule } from './edit-user/edit-user.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '../_directives/directives.module';
import { UserComponent } from './user.component';
import { InviteUserModule } from './invite-user/invite-user.module';


@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    ReactiveFormsModule,
    InviteUserModule,
    EditUserModule,
    RouterModule,
    FormsModule
  ],
  declarations: [UserComponent],
  exports: [UserComponent]
})
export class UserModule { }

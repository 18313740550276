import { CreateGroupAlertComponent } from './../_directives/create-group-alert/create-group-alert.component';
import { Card } from './../models/card.model';
import { AlertComponent } from './../_directives/alert.component';
import { TranslateJsonService } from './../_services/translate-json.service';
import { ScenarioService } from './../_services/scenario.service';
import {
  Component, OnInit, ChangeDetectorRef, Injectable, ElementRef, SimpleChanges,
  SimpleChange, EventEmitter, Output, Input, HostListener, Renderer2, ViewChild, ɵConsole
} from '@angular/core';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/takeUntil';
import { finalize, filter, timeoutWith, takeUntil, count } from 'rxjs/operators';
import * as config from '../config';
import * as _ from 'lodash';
import { Location } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { v4 as uuid } from 'uuid';

import { DeviceService } from './../_services/device.service';
import { EnvironmentService } from './../_services/environment.service';
import { Device } from './../models/device.model';
import { User } from './../models/user.model';
import { HomeComponent } from '../home/home.component';
import { GatewayService } from '../_services/gateway.service';
import { Subject, Observable, of } from 'rxjs';
import { Gateway } from '../models/gateway.model';
import { UserService } from '../_services/user.service';
import { LoadingModalComponent } from '../_directives/loading-modal/loading-modal.component';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDropListGroup, CdkDropListContainer, transferArrayItem, CdkDrag, CdkDragMove } from '@angular/cdk/drag-drop';
//import  * as moment  from 'ngx-bootstrap/chronos/test/chain';

import * as moment from 'moment';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { IfStmt } from '@angular/compiler';
import { GroupService } from 'app/_services/group.service';
import { addUnitAlias } from 'ngx-bootstrap/chronos/units/aliases';
import { Group } from 'app/models/grouping.model';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { SamDragAndDropGridComponent } from '@sam-1994/ngx-drag-and-drop-grid';


const PERMITJOIN_TIMEOUT = 5000
const SMARTLOCK_TIMEOUT = 16000
const COMMAND_TIMEOUT = 5000
const utf8Decoder = new TextDecoder("utf-8")

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css']
})
export class PanelComponent implements OnInit {

  @ViewChild(CdkDropListGroup, { static: false }) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList, { static: false }) dropList: CdkDropList;
  @ViewChild('listComponent', { static: true }) listComponent: SamDragAndDropGridComponent;
  public list = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  public target: CdkDropList;
  public targetIndex: number = 0;
  public source: CdkDropList;
  public sourceIndex: number = 0;
  public dragIndex: number;
  public activeContainer;
  public arraySameType = []

  private ngUnsubscribe = new Subject();
  verticalOffset;
  public devicesFound: Array<Device>;
  public devicesToShow = []//: Array<Device> = [];
  public deviceToUp
  public devicesObserver = new Observable<any>();
  @Output() mouseWheelUp = new EventEmitter();
  @Output() mouseWheelDown = new EventEmitter();
  @HostListener('mousewheel', ['$event']) onmouseup(event: any) {
    this.onScroll(event);
  }
  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    this.onScroll(event);
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    this.onScroll(event);
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    this.onScroll(event);
  }
  
  collumn
  inFilter = false
  //moment: MomentFn
  public modalRef: BsModalRef;
  public groupModalRef: BsModalRef;
  dragDropActive = false
  public loadingModalRef: BsModalRef;
  private observersSubscription: Subscription;
  public message: any;
  public environments: any;
  public devicesType: Array<any>;// = ['Abert/Fecham', 'Fita de Led', 'Interruptor', 'Lâmpada WRGB', 'Luminosidade',
  // 'Presença', 'Sirene', 'SmartLock', 'SmartPlug', 'SmartPlugPlus', 'Temperatura']
  public deviceID = []
  public currentEnvironment: any; //Object
  public currentDeviceType: any;
  public dropItem: any;
  public loading: Boolean = false;
  public loadingScn: Boolean = false;
  public blur: Boolean = false;
  public users: any;
  public userId: any;
  uuid = require('uuid/v4');
  holdDevsToSort = []
  public bindableDevices: Array<any>
  public showPermitJoinTimes
  public scenarios: any;
  user: User = new User();
  currentUserToken;
  currentUser
  currentGateway;
  devices;
  getDevTeste = []
  teste2: Array<Device> = []
  jsonType = []
  public isClicked: Boolean = false;
  searchText: string = "";
  currentUserType;
  language;
  jsonWord;
  pathNameDev;
  eventDone=0
  typeChecked: Array<any> = [false, false, false, false, false, false, false, false, false, false, false, false]
  envChecked: Array<any> = [false, false, false, false, false, false, false, false, false, false]
  envsSelected = []
  testDrop = false
  public devicesTypeChosen = [];
  public devicesTypeNotChosen = [];
  public deviceIdNotChosen = [];
  public isAtBottom: Boolean = false;
  public isAtTop: Boolean = true;
  public isAtLeft: Boolean = false;
  public isAtRight: Boolean = true;
  public devicesExist = [];
  btnClicked = false
  doorIsActive
  count = 0
  isFirefox
  gatewayFull = []
  groups
  groupsToShow = []//: Array<Group> = []
  public groupsAndDevies
  groupId = []
  sortedDevices = []
  emailUser
  deviceToGroup = []
  private touchTimeout: any;

  /* @Input() busca: string;
   @Output() buscaChange: EventEmitter<string> = new EventEmitter<string>();
   private termosDaBusca: Subject<any> = new Subject<any>();*/

  constructor(private el: ElementRef,
    private _mqttService: MqttService,
    private _deviceService: DeviceService,
    private _environmentService: EnvironmentService,
    private userService: UserService,
    private router: Router,
    private _scenarioService: ScenarioService,
    private cd: ChangeDetectorRef,
    public homeComponent: HomeComponent,
    private gatewayService: GatewayService,
    private translateJson: TranslateJsonService,
    private readonly location: Location,
    private toastr: ToastrService,
    private groupService: GroupService,
    private renderer: Renderer2,
    private modalService: BsModalService, private viewportRuler: ViewportRuler) {

    //this.el.nativeElement.addEventListener('mouseup', this.onUp.bind(this), { passive: true });
    this.el.nativeElement.addEventListener('mousedown', this.onDown.bind(this), { passive: true });
    //this.el.nativeElement.addEventListener('touchmove', this.onMove.bind(this), { passive: true });
  }

  ngOnInit() {
    this.dragDropActive = false
    this.pathNameDev = window.location.pathname
    this.envChecked[10] = true
    //setTimeout(() => {
    // }, 1000);
    //console.log("isExplorer", /^((?!chrome|android).)*isIE10/i.test(navigator.userAgent))
    this.isFirefox = /^((?!chrome|android).)*firefox/i.test(navigator.userAgent);
    this.language = localStorage.getItem('language')
    localStorage.setItem('currentEnvironment', null)
    this.jsonWord = this.translateJson.changeLanguage(this.language)
    this.emailUser = JSON.parse(localStorage.getItem('currentUser')).email
    this.devicesType = [this.jsonWord.label.siren, this.jsonWord.label.switch, this.jsonWord.label.closeOpen, this.jsonWord.label.smartlock,
    this.jsonWord.label.smartPlug, this.jsonWord.label.lampRGB, this.jsonWord.label.ledF, this.jsonWord.label.luminosity,
    this.jsonWord.label.occupancy, this.jsonWord.label.smartSwitch, this.jsonWord.label.smartPulse, this.jsonWord.label.tempHumi, this.jsonWord.label.smartPlugPlus, this.jsonWord.label.turn]
    this.userId = localStorage.getItem('currentUserId');
    this.sortedDevices = JSON.parse(localStorage.getItem("sortedDevices"))
    this.deviceID = ['2', '6', '12', '10', '81', '258', '261', '262', '263', '264', '265', '770', '771', '772'];
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')).email
    //this.currentDeviceType = localStorage.getItem('currentDeviceType')
    // Observe gateway change


    this.location.replaceState('/');
    this.gatewayService.gatewayObservable
      .takeUntil(this.ngUnsubscribe)
      .map(
        gateway => {
          if (gateway) {
            // console.log("entrei", gateway)
            this.gatewayFull = []
            let holdDevs = [], holdIds = []
            this.groups = gateway.groups
            this.sortedDevices = gateway.sortedDevices
            this.groupsToShow = (this.groups)
            holdDevs = gateway.devices
            let holdId = []
            //this.groupId = this.groups.devices
            for (let i = 0; i < this.groups.length; i++) {
              for (let j = 0; j < this.groups[i].devices.length; j++) {
                this.groupId.push(this.groups[i].devices[j])
              }
            }
            //this.createIdList()
            //console.log(this.sortedDevices)

            // console.log(this.groupId, this.sortedDevices)
            /*  for (let i = 0; i < this.groups.length; i++) {
                this.groupId = this.gatewayFull.concat(this.groups[i].devices)
                console.log(this.groupId)
              }*/

            if (this.currentGateway != gateway.id) {
              //  console.log(gateway)
              this.currentGateway = gateway.id
              /* this.groups = gateway.groups
               this.sortedDevices = gateway.sortedDevices
               this.groupsToShow = (this.groups)
               for (let i = 0; i < this.groups.length; i++) {
                 this.groupId = this.gatewayFull.concat(this.groups[i].devices)
                 console.log(this.groupId)
               }*/
              this.loading = true
              //this.showPermitJoinTimes = false
              if (this.homeComponent.currentGateway && this.homeComponent.currentGateway.permitJoin.time > 0) {
                this.showPermitJoinTimes = false
                setTimeout(() => {
                  this.showPermitJoinTimes = true
                }, 500);
              } else {
                this.showPermitJoinTimes = false
              }

              this.loadingScn = true
              this.updateAll(gateway)
            } else {
              /*this.groups = gateway.groups
              this.sortedDevices = gateway.sortedDevices
              this.groupsToShow = (this.groups)
              for (let i = 0; i < this.groups.length; i++) {
                this.gatewayFull = this.gatewayFull.concat(this.groups[i].devices)
              }*/
              this.updateAll(gateway)
            }
          }
        }
      ).subscribe(_ => { })
    this._deviceService.device
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        device => {
          /* let groups, ieeeaddrGroup=[]
           groups = this.gatewayFull.groups
           for (let i = 0; i < groups.length; i++) {
             ieeeaddrGroup = ieeeaddrGroup.concat(groups[i].devices)
           }/*var intersection = array1.filter(function(e) {
             return array2.indexOf(e) > -1;
           });*/

          /*var index = this.envsSelected.indexOf(environment._id);
          if (index > -1) {
            this.envsSelected.splice(index, 1);
          }*/


          //splice em device qdo igual ao this.gatewayFull
          /*var index = this.envsSelected.indexOf(environment._id);
          if (index > -1) {
            this.envsSelected.splice(index, 1);
          }*/
          this.devices = device
          //console.log(device)
          this.filterDevicesByEnvironmentAndType()

        })

    this._environmentService.environment
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        environment => {
          if (JSON.stringify(environment) != JSON.stringify(this.currentEnvironment)) {
            this.currentEnvironment = environment ? environment : null

            // this.currentDeviceType = null
            //this.filterDevicesByEnvironment()

            //this.filterDevicesByEnvironment()
            //this.filterDevicesByType()
            this.filterDevicesByEnvironmentAndType()
          }
        })
    this.currentUserToken = localStorage.getItem('currentUserToken');
    if (!this.currentUserToken) {
      this.router.navigate(['/login']);
    }

  }
  private onDown(e: Event) {

  }
  mouseenter(device,event) {
    console.log('inicio dessa caralha', event, device)
    this.deviceToUp = device
    let devId = device._id 
    console.log('evento tipo',event.type)
    this.touchTimeout = setTimeout(() => {
     
        if(event.type == 'mousedown'){ 
          this.eventDone++
      if(this.eventDone<2){
        this.onUp(event) ; 
      }
     /*  $( "app-card ").mouseover(res=> {
        console.log('eh um app card', res,this.deviceToUp.devId)
        setTimeout(() => {
          if(event && this.deviceToUp.devId == res['devId']){
            console.log("evento", event, "device up", this.deviceToUp.devId, "res", res['devId'])
          }
        }, 1000);})*/
     
      event.stopPropagation();
    }
    if(event.type == 'mouseup'){
      this.eventDone++
     
      if(this.eventDone<2){
        this.onUp(event) ; 
      }
      event.stopPropagation();
    }
    }, 500);
    // if(event.type =='mouseover'){
     
      //}
  /*  $( "app-card ").mouseover(res=> {
      console.log('eh um app card', res,this.deviceToUp.devId)
      setTimeout(() => {
        if(event && this.deviceToUp.devId == res['devId']){
          console.log("evento", event, "device up", this.deviceToUp.devId, "res", res['devId'])
        }
      }, 1000);
     
      $( "app-card ").mousedown(eventmu=>{
        console.log('eh um app card1');
        this.eventDone++
        if(this.eventDone<2){
          this.onUp(eventmu) ; 
        }
      eventmu.stopPropagation();
    })
      $( "app-card ").mouseup(eventmu=>{
        console.log('eh um app card2');
        this.eventDone++
        if(this.eventDone<2){
          this.onUp(eventmu) ; 
        }
      eventmu.stopPropagation();
    })
    })*/

  }

  /* mouseenterup(groupsAndDevies){
     console.log(groupsAndDevies,"teste")
   }
   mouseenter(device){
     let deviceNext
     console.log("Estou aqui",device)
     let devId = device._id
     $( "app-card ").mouseover(res=> {
       
       console.log(true,res)
       let index = this.groupsAndDevies.findIndex(dev=>dev._id== device._id)
         if(index){
           deviceNext = this.groupsAndDevies[index]
           console.log(deviceNext)
           //puxar o criaçao de grupo
         }
 
       })
        (mousedown)="mouseenter(groupsAndDevies)" 
                     (mouseup)="mouseenterup(groupsAndDevies)"
       
   }*/

  onUp(e) {
    let deviceNext
    console.log("inicio em cima", this.deviceToUp)
    if (this.groupsAndDevies.length && this.deviceToUp) {
      const indexBefore = this.groupsAndDevies.findIndex(dev => dev._id == this.deviceToUp._id)
      // console.log("Estou aqui",device)
      // let devId = device._id
      setTimeout(() => {
        const indexLater = this.groupsAndDevies.findIndex(dev => dev._id == this.deviceToUp._id)
       console.log(indexBefore, indexLater)

        if (indexLater > -1) {
          if (indexBefore < indexLater) {
            deviceNext = this.groupsAndDevies[indexLater - 1]
            this.createGroup(this.groupsAndDevies, indexLater, indexLater - 1)
          } else {
            deviceNext = this.groupsAndDevies[indexLater + 1]
            console.log("estou aqui, indexDepois eh maior q -1", indexLater, indexBefore, deviceNext)
            this.createGroup(this.groupsAndDevies, indexLater, indexLater + 1)
          }
         // this.createGroup(this.groupsAndDevies, indexLater, indexLater - 1)

          //puxar o criaçao de grupo
        }
      }, 500);
    }
    e.stopPropagation();
  }
  updateAll(gateway: Gateway) {
    this.devicesFound = null

    this.listDevices(gateway)

    if (this.observersSubscription) this.observersSubscription.unsubscribe()

    // Setup observer for all events of all devices
    const allDevicesTopic = config.gtwTopic + this.currentGateway + '/device/#';

    this.devicesObserver = this._mqttService.observe(allDevicesTopic)
      .map((IMQTTMessage: IMqttMessage) => {
        let message = utf8Decoder.decode(IMQTTMessage.payload)
        return message ? JSON.parse(message) : String(IMQTTMessage)
      })

    // Observe joining devices
    this.observersSubscription =
      this.homeComponent.observersObservable.concatMap(
        res => {
          if (res) {
            this.setReportListener(gateway)
            return this.homeComponent.deviceObserver
              .takeUntil(this.ngUnsubscribe)
              .map(message => {
                // console.log(message)
                switch (message['operation']) {
                  case 'add':
                    this.addDevice(message['data'].ieeeAddr);
                    break
                  /*case 'delete':
                    this.deleteDevice(message['data'].ieeeAddr);
                    break*/
                  default:
                    break
                }
              }).merge(
                this.homeComponent.gatewayErrorObserver.filter(msg => msg['op'] == 'command')
                  .map(message => {
                    if (this.loadingModalRef)
                      this.loadingModalRef.hide()
                    this.homeComponent.gatewayErrorToast({
                      status: 204,
                      message: 'Falha ao enviar comando ao dispositivo: ' + message
                    })
                  })
              )
          } else {
            return of()
          }
        }
      ).subscribe()

    this._deviceService.listDevices().concatMap(res=>{

      return this._scenarioService.listScenarios()
    })
    .takeUntil(this.ngUnsubscribe).concatMap(scenarios=>{ 
      this.loadingScn = false
      this.scenarios = scenarios//.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
      return this._environmentService.listEnvironments()
    })
    .takeUntil(this.ngUnsubscribe).map((environments:any)=>{
      if (environments) this.environments = environments ? environments.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0) : environments;
      this.setEnvironment(null, 10)
    })

     /* .map((devices: any) => {
        this.devices = devices;
      }).merge(this._environmentService.listEnvironments().map((environments: any) => {
        if (environments) this.environments = environments ? environments.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0) : environments;
        this.setEnvironment(null, 10)
      })).merge(this._scenarioService.listScenarios().map(
        scenarios => {
          this.loadingScn = false
          this.scenarios = scenarios//.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
        })*/
      .subscribe(() => { })
  }


  runScenario(scenario: any) {
    const topic = config.gtwTopic + this.currentGateway + '/scenario/' + scenario._id + '/start';
    const message = {
      messageId: this.uuid(),
      user: this.currentUser,
      timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    };
    this.unsafePublish(topic, JSON.stringify(message));
  }

  attScnAndRls() {
    this._scenarioService.listScenarios().subscribe(
      scenarios => {
        this.scenarios = scenarios;
        this.cd.markForCheck()
      })
  }

  setEnvironment(environment, i) {
    if (i == 10) {
      this.envChecked = [false, false, false, false, false, false, false, false, false, false]
      this.envChecked[12] = false
      this.envsSelected = []
      let hold = []
      console.log(this.sortedDevices,this.groupsAndDevies)
      hold = this.sortedDevices && this.sortedDevices.length > 0 && this.groupsAndDevies != undefined ? 
      this.groupsAndDevies : this.groupsAndDevies.sort((a, b) =>
      a.favoriteDevice && !b.favoriteDevice ? -1 : 
      b.favoriteDevice && !a.favoriteDevice ? 1 : a._id[1]!='x' && b._id[1]=='x' ? 1:
      a._id[1]=='x' && b._id[1]!='x' ? 1 :
        //a.status == 'ONLINE' && b.status == "OFFLINE" ? 1 : a.status != 'ONLINE' && b.status != "OFFLINE" ? -1 :
        Number(a.devId) < Number(b.devId) ? -1 : Number(a.devId) > Number(b.devId) ? 1 :
          a.name < b.name ? -1 : a.name > b.name ? 1 :
            a.created < b.created ? -1 : a.created > b.created ? 1 : 0);
      //    console.log(this.groupsAndDevies)
      this.groupsAndDevies = hold

    } else if (i == 11) {
      this.envChecked = [false, false, false, false, false, false, false, false, false, false]
      this.envChecked[12] = false
      this.envsSelected = []
      let hold = []
      hold = this.devicesFound.sort((a, b) =>a.favoriteDevice && !b.favoriteDevice ? -1 : 
      b.favoriteDevice && !a.favoriteDevice ? 1 : a._id[1]!='x' && b._id[1]=='x' ? 1:
      a._id[1]=='x' && b._id[1]!='x' ? 1 :
        //a.status == 'ONLINE' && b.status == "OFFLINE" ? 1 : a.status != 'ONLINE' && b.status != "OFFLINE" ? -1 :
        Number(a.devId) < Number(b.devId) ? -1 : Number(a.devId) > Number(b.devId) ? 1 :
          a.name < b.name ? -1 : a.name > b.name ? 1 :
            a.created < b.created ? -1 : a.created > b.created ? 1 : 0);
      this.groupsAndDevies = hold
    } else if (i == 12) {
      this.envChecked = [false, false, false, false, false, false, false, false, false, false]
      this.envChecked[i] = !this.envChecked[i]
      this.envsSelected = []
      let hold = []
      hold = this.groupsAndDevies.sort((a, b) => (a.type < b.type && a.environment.name < b.environment.name) ? 1 : 
      (a.type > b.type && a.environment.name > b.environment.name) ? -1 : 0)
      this.groupsAndDevies = []
      this.groupsAndDevies = hold
    } else {
      this.envChecked = [false, false, false, false, false, false, false, false, false, false]
      this.envChecked[i] = !this.envChecked[i]
      if (this.envChecked[i]) this.envsSelected.push(environment._id)
      else {
        var index = this.envsSelected.indexOf(environment._id);
        if (index > -1) {
          this.envsSelected.splice(index, 1);
        }
      }
    }
    this._environmentService.setCurrentEnvironment(environment)
    this.filterDevicesByEnvironmentAndType()

  }

  dragDrop() {
    this.inFilter = false
    let width
    this.dragDropActive = !this.dragDropActive


    if (this.dragDropActive) {
      width = $(".container").width()
      let collumn = Math.trunc(width / 150)

      this.collumn = this.groupsAndDevies.length < 10 ? 7 : collumn
    }
  }
  /*isSelected(type, i) {
    this.typeChecked[i] = this.devicesType.includes(type)
  }*/
  /*ngOnChanges(changes: SimpleChanges): void {
		let busca: SimpleChange = changes['busca'];
		this.search(busca.currentValue)
		console.log(changes);
  }*/

  showBarSearch() {
    if (!this.blur) {
      this.isClicked = !this.isClicked
      this.blur = false
    } else {
      this.isClicked = false;
      this.blur = false
    }
    setTimeout(() => {
      let searchFocus = <HTMLElement>document.getElementById('searchBar');
      if (searchFocus) searchFocus.focus();
    });
  }

  onBlurSearch() {
    this.isClicked = false;
    this.blur = true
  }

  listDevices(gateway) {
    if (!this.currentGateway) {
      return
    }

    if (!this.devicesFound) {
      let hold = [], devSort = []
      this._deviceService.listDevices(gateway)
        .pipe(finalize(() => {
        }))
        .takeUntil(this.ngUnsubscribe)
        .subscribe(
          (deviceList: Array<any>) => {
            this.holdDevsToSort = []
            let a = 0, devs = deviceList, holdIds = []
            this.deviceToGroup = this.devicesExist = deviceList           
            this.groupsAndDevies = this.groupsToShow.concat(devs);
            this.groupsAndDevies
           // console.log("aqui", this.groupsAndDevies, this.groupsToShow, this.groupId)
            if (this.groupId && this.groupsAndDevies && this.groupsToShow.length > 0) {
              for (let i = 0; i < this.groupId.length; i++) {
                let index = this.groupsAndDevies.findIndex(dev => dev._id == this.groupId[i])
                //console.log(index)
                if (index > -1) {
                  this.groupsAndDevies.splice(index, 1);
                 // console.log(this.groupsAndDevies)
                }
              }
            }
           // this.groupsAndDevies
            if (this.sortedDevices.length == 0) {
              this.holdDevsToSort = this.groupsAndDevies.sort((a, b) =>a.favoriteDevice && !b.favoriteDevice ? -1 : 
             b.favoriteDevice && !a.favoriteDevice ? 1 : a._id[1]!='x' && b._id[1]=='x' ? 1:
             a._id[1]=='x' && b._id[1]!='x' ? 1 :
                //a.status == 'ONLINE' && b.status == "OFFLINE" ? 1 : a.status != 'ONLINE' && b.status != "OFFLINE" ? -1 :
                Number(a.devId) < Number(b.devId) ? -1 : Number(a.devId) > Number(b.devId) ? 1 :
                  a.name < b.name ? -1 : a.name > b.name ? 1 :
                    a.created < b.created ? -1 : a.created > b.created ? 1 : 0);
              //  this()
            } else {
              let a = this.sortedDevices.length
              for (let i = 0; i < this.groupsAndDevies.length; i++) {
                let index = this.sortedDevices.findIndex(sortdev => sortdev == this.groupsAndDevies[i]._id)
                if (index > -1) {
                  this.holdDevsToSort[index] = (this.groupsAndDevies[i]) //pego o index do sorted device e armazeno na mesma posição no array hold
                 // console.log("tem sorteddevices", index, this.holdDevsToSort)
                } else {
                  this.holdDevsToSort[a] = (this.groupsAndDevies[i]) //guardo o que nao tem no sorted e tem nos devs no final do array
               //   console.log("nao tem sorteddevices ", index, this.holdDevsToSort)
                  holdIds.push(index)
                  a++
                }
                //   this.attList(this.holdDevsToSort)
              }
              this.holdDevsToSort = this.holdDevsToSort.filter(item => item != undefined)//remove as posições que ficaram undefined
          
            } 
            this.groupsAndDevies = this.holdDevsToSort
           
            let devsToFilter = this.holdDevsToSort
            this.devicesFound = this.holdDevsToSort//.sort((a)=>a.favoriteDevice ? 1 : 0)
            this.groupsAndDevies = this.holdDevsToSort//.concat(this.groupsToShow);
            //console.log("devices e grupos", this.groupsAndDevies)
            this.getDevIdExists()
            this.filterDevicesByEnvironmentAndType()
            this.loading = false
          },
          err => {
            this.homeComponent.openErrorAlert(err)
          }
        );
    } else
      this.filterDevicesByEnvironmentAndType()
  }


  attListDevsAndGroups(deviceList){
    let a = 0, devs = deviceList, holdIds = []; this.groupsAndDevies=[],this.holdDevsToSort =[]
    this.deviceToGroup = this.devicesExist = deviceList
    this.groupsAndDevies = this.groupsToShow.concat(devs);
    if (this.groupId && this.groupsAndDevies && this.groupsToShow.length > 0) {
      for (let i = 0; i < this.groupId.length; i++) {
        let index = this.groupsAndDevies.findIndex(dev => dev._id == this.groupId[i])
        if (index > -1) {
          this.groupsAndDevies.splice(index, 1);
        }
      }
    }
    if (this.sortedDevices.length == 0) {
      this.holdDevsToSort = this.groupsAndDevies.sort((a, b) =>a.favoriteDevice && !b.favoriteDevice ? -1 : 
     b.favoriteDevice && !a.favoriteDevice ? 1 : a._id[1]!='x' && b._id[1]=='x' ? 1:
     a._id[1]=='x' && b._id[1]!='x' ? 1 :
        //a.status == 'ONLINE' && b.status == "OFFLINE" ? 1 : a.status != 'ONLINE' && b.status != "OFFLINE" ? -1 :
        Number(a.devId) < Number(b.devId) ? -1 : Number(a.devId) > Number(b.devId) ? 1 :
          a.name < b.name ? -1 : a.name > b.name ? 1 :
            a.created < b.created ? -1 : a.created > b.created ? 1 : 0);
      //  this()
    } else {
      //console.log("estou aqui com sorted")
      let a = this.sortedDevices.length
      for (let i = 0; i < this.groupsAndDevies.length; i++) {
        let index = this.sortedDevices.findIndex(sortdev => sortdev == this.groupsAndDevies[i]._id)
        if (index > -1) {
          this.holdDevsToSort[index] = (this.groupsAndDevies[i]) //pego o index do sorted device e armazeno na mesma posição no array hold
        } else {
          this.holdDevsToSort[a] = (this.groupsAndDevies[i]) //guardo o que nao tem no sorted e tem nos devs no final do array
          holdIds.push(index)
          a++
        }
      }
      this.holdDevsToSort = this.holdDevsToSort.filter(item => item != undefined)//remove as posições que ficaram undefined
    } 
    this.groupsAndDevies=[]
    this.groupsAndDevies = this.holdDevsToSort

   // let devsToFilter = this.holdDevsToSort
    return this.devicesFound = this.holdDevsToSort//.sort((a)=>a.favoriteDevice ? 1 : 0)
  
  //  this.groupsAndDevies = this.holdDevsToSort
  }

  attList(groupsAndDevies) {
    let idList = []
    for (let i = 0; i < groupsAndDevies.length; i++) {
      idList.push(groupsAndDevies[i]._id)
    }
    let sortedDevices = [{
      "op": "replace",
      "path": "/registeredUsers/sortedDevices/",
      "value": {
        "idList": idList
      }
    }]
    this.sortedDevices = idList
    this.groupsAndDevies = groupsAndDevies
    //console.log(this.sortedDevices)
    this.dragDropActive = false
    this.userService.invite((sortedDevices), this.emailUser).subscribe(res => { /* console.log("atualizou a lista", res) */})
  }

  setReportListener(gateway: Gateway) {
    this.homeComponent.periodicStatusObserver
      .takeUntil(this.ngUnsubscribe)
      .subscribe(payload => {
        let incomingDevices = payload['data'].devices.map(dev => new Device(dev)).sort(this._deviceService.devicesOrder) as Array<Device>
       // console.log("Periodic status: ", this.devicesFound)
        incomingDevices.forEach(incomingDevice => {
          if (this.devicesFound) {
            var showingDeviceIndex = this.devicesFound.findIndex(dev => dev.ieeeAddr == incomingDevice.ieeeAddr)
            var showingDevice = this.devicesFound[showingDeviceIndex]
            if (showingDevice) {
              let updatedDevice = new Device(showingDevice)
              let hasChanged = false
           //   console.log("showing device: ", showingDevice, incomingDevice)
              Object.keys(incomingDevice).forEach(prop => {
                if (incomingDevice[prop] && showingDevice[prop]) {
                  if ((incomingDevice[prop] as Object).toString() != (showingDevice[prop] as Object).toString()) {
                    updatedDevice[prop] = incomingDevice[prop]
                    hasChanged = true
                  }
                }

              });
              if (hasChanged) {
                this.devicesFound[showingDeviceIndex] = updatedDevice
             //   console.log("uodateDevice: ", updatedDevice)
             //this.updateAll(gateway)
             this.groupsAndDevies =[]
             this.groupsAndDevies =  this.attListDevsAndGroups(this.devicesFound)
             console.log("aqui depois do att list",this.groupsAndDevies)
                this.filterDevicesByEnvironmentAndType()
              }

            }
          }
        });
      })
  }
  getDevIdExists() { //pega apenas os dispositivos com ids existentes
    this.deviceID = []
    this.devicesType = []
   // console.log(this.groupsAndDevies)
    var devsTypes = [this.jsonWord.label.siren, this.jsonWord.label.switch, this.jsonWord.label.closeOpen, this.jsonWord.label.smartlock,
    this.jsonWord.label.smartPlug, this.jsonWord.label.lampRGB, this.jsonWord.label.ledF, this.jsonWord.label.luminosity,
    this.jsonWord.label.occupancy, this.jsonWord.label.smartSwitch, this.jsonWord.label.smartPulse, this.jsonWord.label.tempHumi,
    this.jsonWord.label.smartPlugPlus, this.jsonWord.label.turn, this.jsonWord.label.waterSensor]

    var devIds = ['2', '6', '12', '10', '81', '258', '261', '262', '263', '264', '265', '770', '771', '772', '774'];
    for (let i = 0; i < this.groupsAndDevies.length; i++) {
      var index = devIds.indexOf(this.groupsAndDevies[i].devId);
      if (index > -1) {
        this.devicesType.push(devsTypes[index])
        this.deviceID.push(devIds[index])
        devIds.splice(index, 1);
        devsTypes.splice(index, 1);
      }
      // 
    }
    this.devicesTypeNotChosen = devsTypes;
    this.deviceIdNotChosen = devIds;
  }

  getDevIdExistsEnv() {
    this.deviceID = []
    this.devicesType = []
    var devsTypes = [this.jsonWord.label.siren, this.jsonWord.label.switch, this.jsonWord.label.closeOpen, this.jsonWord.label.smartlock,
    this.jsonWord.label.smartPlug, this.jsonWord.label.lampRGB, this.jsonWord.label.ledF, this.jsonWord.label.luminosity,
    this.jsonWord.label.occupancy, this.jsonWord.label.smartSwitch, this.jsonWord.label.smartPulse, this.jsonWord.label.tempHumi,
    this.jsonWord.label.smartPlugPlus, this.jsonWord.label.turn, this.jsonWord.label.waterSensor]

    var devIds = ['2', '6', '12', '10', '81', '258', '261', '262', '263', '264', '265', '770', '771', '772', '774'];
    for (let i = 0; i < this.devicesExist.length; i++) {
      var index = devIds.indexOf(this.devicesExist[i].devId);
      if (index > -1) {
        this.devicesType.push(devsTypes[index])
        this.deviceID.push(devIds[index])
        devIds.splice(index, 1);
        devsTypes.splice(index, 1);
      }
      // 
    }
    this.devicesTypeNotChosen = devsTypes;
    this.deviceIdNotChosen = devIds;
  }
  getDeviceType(devType, i) {
    //this.jsonType = []
    this.inFilter = true
//console.log(devType)
    let hold = [], holdDevType = [];
    if (devType) {
      this.typeChecked[i] = !this.typeChecked[i]//this.devicesType.includes(devType)
      // for(let j=0; j< this.typeChecked.length;j++){
      if (this.typeChecked[i]) {
        this.jsonType.push(this.deviceID[i])
        this.currentDeviceType = this.jsonType
        this.devicesTypeChosen.push(devType)
       // console.log(this.typeChecked, devType, i, this.devicesTypeChosen, this.currentDeviceType)
        localStorage.setItem('currentDeviceType', JSON.stringify(this.jsonType))
      }
      else this.getOffDeviceType(devType, i)
      // }

      /* var index = this.devicesType.indexOf(devType);
       if (index > -1) {
         this.devicesType.splice(index, 1);
       }
       var indexId = this.deviceID.indexOf(this.deviceID[i]);
       if (indexId > -1) {
         this.deviceID.splice(indexId, 1);
       }*/

      this.filterDevicesByEnvironmentAndType()
    } else { //null
      this.jsonType = []
      this.typeChecked = [false, false, false, false, false, false, false, false, false, false, false, false]
      this.currentDeviceType = []//this.jsonType
      // this.getDevIdExistsEnv()
      localStorage.setItem('currentDeviceType', JSON.stringify(this.jsonType))
      this.filterDevicesByEnvironmentAndType()
    }
  }

  getOffDeviceType(devTypeC, i) {
    let indexNewId, indexId, indexNew
    var newTypes = [this.jsonWord.label.siren, this.jsonWord.label.switch, this.jsonWord.label.closeOpen, this.jsonWord.label.smartlock,
    this.jsonWord.label.smartPlug, this.jsonWord.label.lampRGB, this.jsonWord.label.ledF, this.jsonWord.label.luminosity,
    this.jsonWord.label.occupancy, this.jsonWord.label.smartSwitch, this.jsonWord.label.smartPulse, this.jsonWord.label.tempHumi,
    this.jsonWord.label.smartPlugPlus, this.jsonWord.label.turn, this.jsonWord.label.waterSensor]
    var newId = ['2', '6', '12', '10', '81', '258', '261', '262', '263', '264', '265', '770', '771', '772', '774'];
    var index = this.devicesTypeChosen.indexOf(devTypeC);
    if (index > -1) {
      this.devicesTypeChosen.splice(index, 1);
      this.currentDeviceType.splice(index, 1)
    }
    /*for (let i = 0; i < this.currentDeviceType.length; i++) {
      indexNewId = newId.indexOf(this.currentDeviceType[i])
      indexId = this.currentDeviceType.indexOf(this.currentDeviceType[i]);
      console.log(index)
    if (indexId >=0 ) {
      this.currentDeviceType.splice(indexId, 1);
      console.log(this.currentDeviceType)
    }
    }*/
    indexNew = newTypes.indexOf(devTypeC)
    this.filterDevicesByEnvironmentAndType()
  }
  filterDevicesByEnvironmentAndType() {
    let teste: Array<Device> = []
    this.teste2 = []
   // console.log(this.devicesFound)
    if (this.devicesFound) {
      //   debugger
      if (this.currentDeviceType && this.currentDeviceType.length > 0) {
        for (let i = 0; i < this.currentDeviceType.length; i++) {
         // console.log("começo", i, this.deviceToGroup, this.devicesFound)
          teste = (this.devicesExist.filter(dev => !this.currentDeviceType || this.currentDeviceType.length < 0 || dev.devId == this.currentDeviceType[i]))
          if (teste.length > 0) {
            for (let t of teste) {
              this.teste2.push(t)
            }
          //  console.log("começo", this.teste2, teste, this.currentDeviceType, i)
            this.deviceToGroup = this.teste2.filter(dev => dev.type &&
              (!this.currentEnvironment || dev.environment['_id'] == this.currentEnvironment._id || (this.currentEnvironment == "nowhere" && dev.envName == '')))
            if (this.envChecked[12]) {
              let hold = this.devicesExist.sort((a, b) => a.environment.name < b.environment.name ? -1 : a.environment.name > b.environment.name ? 1 : 0)
              this.groupsAndDevies = []
              this.groupsAndDevies = hold
            } else {
              this.groupsAndDevies = []
              let hold = this.deviceToGroup.sort((a, b) =>
                //a.status == 'ONLINE' && b.status == "OFFLINE" ? 1 : a.status != 'ONLINE' && b.status != "OFFLINE" ? -1 :
                Number(a.devId) < Number(b.devId) ? -1 : Number(a.devId) > Number(b.devId) ? 1 :
                  a.name < b.name ? -1 : a.name > b.name ? 1 :
                    a.created < b.created ? -1 : a.created > b.created ? 1 : 0);
              this.groupsAndDevies = hold
            }
          } else {

          }
        }
        this.bindableDevices = this.deviceToGroup.filter(dev => dev.devId === '261' || dev.devId === '258' || dev.devId === '528')
      } else {
    //    console.log("1", this.groupsAndDevies, this.deviceToGroup, this.devicesFound)
        this.devicesTypeChosen = []
        this.groupsAndDevies = []
        this.getDevIdExistsEnv()
        this.groupsAndDevies = this.devicesFound.filter(dev => dev.type &&
          (!this.currentEnvironment || dev.environment['_id'] == this.currentEnvironment._id || this.currentEnvironment == "nowhere" && dev.envName == ''))
        //  this.groupsAndDevies.concat(this.groupsToShow)
      //  console.log("2", this.groupsAndDevies, this.envChecked)
        if (this.envChecked[12]) {
          let hold = this.deviceToGroup.sort((a, b) => a.environment.name < b.environment.name ? -1 : a.environment.name > b.environment.name ? 1 : 0)
          this.groupsAndDevies = []
          this.groupsAndDevies = hold
        } else {
         // console.log("3", this.groupsAndDevies, this.envChecked)
          let hold = this.deviceToGroup.sort((a, b) =>
            //a.status == 'ONLINE' && b.status == "OFFLINE" ? 1 : a.status != 'ONLINE' && b.status != "OFFLINE" ? -1 :
            Number(a.devId) < Number(b.devId) ? -1 : Number(a.devId) > Number(b.devId) ? 1 :
              a.name < b.name ? -1 : a.name > b.name ? 1 :
                a.created < b.created ? -1 : a.created > b.created ? 1 : 0);
          let holdDevsToSort = []
          let varCheck = this.envChecked.find(env => env == true)
          //  console.log(varCheck)
          if (!varCheck) {
            if (this.sortedDevices.length > 0) {
              holdDevsToSort = []
             // console.log(this.devicesFound, this.groupsAndDevies)
              this.groupsAndDevies = this.devicesFound
              //console.log("teste",hold, this.groupsToShow, this.groupsAndDevies,this.sortedDevices)
              // this.groupsAndDevies = hold.concat(this.groupsToShow)
              for (let i = 0; i < this.sortedDevices.length; i++) {
                let index = (this.groupsAndDevies.findIndex(sortdev => sortdev._id == this.sortedDevices[i]))
                //    console.log(this.groupsAndDevies)
                if (index > -1)
                  holdDevsToSort.push(this.groupsAndDevies[index])
                if (this.groupsToShow.length < 1 && index < 0) {
                  //    console.log("to aqui",this.groupsAndDevies,i)
                  holdDevsToSort.push(this.groupsAndDevies[i])
                }
                this.deviceToGroup = holdDevsToSort
                /*    else{
                      holdDevsToSort.push(this.groupsAndDevies[i])
                    }*/
              }
              this.deviceToGroup = this.devicesExist
            } else {
              this.groupsAndDevies = this.devicesFound//.concat(this.groupsToShow)
            }
          }
        }
      }

    }
    //console.log(this.groupsAndDevies, this.sortedDevices, this.envChecked)
  }


  addDevice(ieeeAddr) {
    if (!this.currentGateway) return

    let hold = Array.from(this.devicesFound), devSort = []

    this._deviceService.listDeviceById(ieeeAddr)
      .pipe(finalize(() => this.loading = false))
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        device => {
        //  console.log(device)
          let index = this.devicesFound.findIndex(dev => dev.ieeeAddr == device.ieeeAddr)
          if (index != -1) {
            this.devicesFound.splice(index, 1, device)
            this.attList(this.devicesFound)
          }
          else {
            hold.push(device)
            // console.log(hold)
            devSort = hold.sort((a, b) =>
              //a.status == 'ONLINE' && b.status == "OFFLINE" ? 1 : a.status != 'ONLINE' && b.status != "OFFLINE" ? -1 :
              Number(a.devId) < Number(b.devId) ? -1 : Number(a.devId) > Number(b.devId) ? 1 :
                a.name < b.name ? -1 : a.name > b.name ? 1 :
                  a.created < b.created ? -1 : a.created > b.created ? 1 : 0);
            index = devSort.indexOf(device)
            this.devicesFound.splice(index, 0, device)
            this.attList(this.devicesFound)
          }
          this.filterDevicesByEnvironmentAndType()
          this.bindableDevices = this.devicesFound.filter(dev => dev.devId === '261' || dev.devId === '258' || dev.devId === '528')
        },
        err => {
          console.log('Erro: ', err);
        }
      );
  }

  deviceUpdater(event) {
    switch (event.op) {
      case 'edit':
        this.updateDevice(event.device._id)
        break
      case 'delete':
        this.removeDevice(event.device.ieeeAddr)
        break
      default:
        break
    }
  }

  updateDevice(id) {
    this._deviceService.listDeviceById(id).subscribe(
      updatedDevice => {
        let outdatedIndex = this.devicesFound.findIndex(dev => dev._id == id)
        if (this.devicesFound[outdatedIndex].invertOnOff) {
          this.devicesFound[outdatedIndex].isActive = !(this.devicesFound[outdatedIndex].isActive)
        }
        this.devicesFound.splice(outdatedIndex, 1, new Device(updatedDevice))
        /* this.devicesFound = this.devicesFound.sort((a, b) =>
            Number(a.devId) < Number(b.devId) ? -1 : Number(a.devId) > Number(b.devId) ? 1 :
              a.name < b.name ? -1 : a.name > b.name ? 1 :
                a.created < b.created ? -1 : a.created > b.created ? 1 : 0)
          */
        this.attListDevsAndGroups(this.devicesFound)
        this.groupsAndDevies = this.attListDevsAndGroups(this.devicesFound)//this.devicesFound
        this.filterDevicesByEnvironmentAndType()
      }
    )
  }

  onScroll(event: Event) {
    let scrollRight = <any>document.getElementById("scrollRight")
    //this.isFirefox = /^((?!chrome|android).)*firefox/i.test(navigator.userAgent);

    //console.log(event)
    let width, height;
    $("#devCont").each(function () {
      height = $(this).height()
    });
    if (!this.isFirefox) {
      let el = event.srcElement as Element
      this.isAtTop = el['scrollTop'] < 3 || event['deltaY'] < 52 ? true : false
      this.isAtBottom = el['deltaY'] > 50 || height + 1 > el.scrollHeight - el.scrollTop ? true : false
    } else {
      let height;
      $("#devCont").each(function () {
        height = $(this).height()
      });
      this.isAtTop = event.target['scrollTop'] < 3 || event.target['deltaY'] < 52 ? true : false
      this.isAtBottom = event.target['deltaY'] > 50 || height + 1 > event.target['scrollHeight'] - event.target['scrollTop'] ? true : false
    }
  }
  onScrollScn(event: Event) {
    let el = event.srcElement as Element
    let width
    //this.isFirefox = /^((?!chrome|android).)*firefox/i.test(navigator.userAgent);
    $("#scn-content").each(function () {
      width = $(this).width()
    });
    if (!this.isFirefox) {
      this.isAtLeft = el.scrollLeft > 1 || event['deltaY'] > -52 ? true : false
      this.isAtRight = event['deltaY'] < 50 || (el.scrollWidth - 2 > width + el.scrollLeft) ? true : false
    } else {
      this.isAtLeft = event['scrollLeft'] > 1 || event['deltaY'] > -52 ? true : false
      this.isAtRight = event['deltaY'] < 50 || (event["scrollWidth"] - 2 > width + event['scrollLeft']) ? true : false
    }
  }
  scrollScn(event) {
    // console.log(event.type)
    if (!this.isFirefox) {
      document.addEventListener('wheel', (e) => {
        let eventWheel = e['path'], ev = null
        ev = eventWheel.filter(ev => ev.className == "fixar-rodape")
        if (ev.length > 0) {
          document.getElementById('holder').scrollLeft = document.getElementById('holder').scrollLeft + e.deltaY;
        }
      })
    } else {
      document.addEventListener('wheel', (e) => {
        let eventWheel = e['explicitOriginalTarget'].offsetParent.offsetParent.className//, ev = null
        /*  ev = eventWheel.filter(ev => ev.className == "fixar-rodape")*/
        if (eventWheel == "fixar-rodape" || e.target['offsetParent'].className == "fixar-rodape") {
          document.getElementById('holder').scrollLeft = document.getElementById('holder').scrollLeft + e.deltaY;
        }
      })
    }
  }

  removeDevice(ieeeAddr) {
    if (!this.currentGateway) return
    let indexToRemove = this.devicesFound.findIndex(dev => dev.ieeeAddr == ieeeAddr)
    this.devicesFound.splice(indexToRemove, 1)
    this.attList(this.devicesFound)
    this.filterDevicesByEnvironmentAndType()
    this.attListDevsAndGroups(this.devicesFound)
  }

  public unsafePublish(topic: string, message: string): void {
    this._mqttService.unsafePublish(topic, message, { qos: 1, retain: false });
  }

  permitJoin(time) {
    //MQTT addDevices
    const topic = config.gtwTopic + this.currentGateway + "/addDevice";
    const message = {
      'messageId': this.uuid(),
      'timestamp': (Date.now()),
      'time': time
    };
    this.homeComponent.isJoining = true
    this.showPermitJoinTimes = false
    // 
    this.homeComponent.gatewaySuccessObserver
      .pipe(
        finalize(() => {
          if (this.homeComponent.isJoining && !this.homeComponent.currentGateway.permitJoin.time) {
            this.homeComponent.isJoining = false
            this.showPermitJoinTimes = false
          }
        }),
        timeoutWith(PERMITJOIN_TIMEOUT, ErrorObservable.throw(
          new Response(null, {
            status: 408, statusText: 'Tempo limite de resposta atingido'
          })
        ))
      ).take(1)
      .subscribe(res => {
        this.showPermitJoinTimes = false
      }

        ,
        err => {
          this.homeComponent.gatewayErrorToast(err);
          this.showPermitJoinTimes = false
        }
      )
    this.unsafePublish(topic, JSON.stringify(message));


  }

  showTimes(time?: number) {
    // this.gatewayService.getMaxLimitEntity(this.currentGateway, 'device').subscribe(res => {
    // console.log('dispositivos MAXIMO', res)

    if (!this.homeComponent.isJoining) {
      this.showPermitJoinTimes = true //
    } else {
      this.showPermitJoinTimes = false //
    }
    /*  } else {
        this.modalRef = this.modalService.show(AlertComponent);
        this.modalRef.content.buttonCancel = { show: false };
        this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
        this.modalRef.content.title = this.jsonWord.alert.attention
        this.modalRef.content.type = 'warning'
        this.modalRef.content.message = this.jsonWord.toast.devicesLimitReached
        //}
      }
    })*/
  }

  setShowPermitJoinTimes(event) {
  }


  onClickOutside(action) {
    // console.log("CLICK OUTSIDE", action, "COLLECTION", document.getElementsByClassName('button-time'))
    if (this.homeComponent.isJoining) {
      let timeButtons = document.getElementsByClassName('button-time') as HTMLCollectionOf<Element>
      Array.from(timeButtons).forEach(el => {
        this.renderer.removeClass(el, 'select-time')
        this.renderer.removeClass(el, 'show-time')
        this.renderer.addClass(el, 'hide-time')
      })
    } else {
      if (this.isFirefox) {
        if (action.originalTarget.className != "btn-permitjoin" && !this.homeComponent.isJoining) {
          //console.log("teste")
          this.showPermitJoinTimes = false
          let timeButtons = document.getElementsByClassName('button-time') as HTMLCollectionOf<Element>
          Array.from(timeButtons).forEach(el => {
            this.renderer.addClass(el, 'hide-time')
            this.renderer.addClass(el, 'select-time')
            this.renderer.removeClass(el, 'show-time')
          })
        } else {
          this.showPermitJoinTimes = true
        }
      } else {
        if (action.path[1].id != "main-button" && !this.homeComponent.isJoining) {
          this.showPermitJoinTimes = false
          let timeButtons = document.getElementsByClassName('button-time') as HTMLCollectionOf<Element>
          Array.from(timeButtons).forEach(el => {
            this.renderer.addClass(el, 'hide-time')
            this.renderer.addClass(el, 'select-time')
            this.renderer.removeClass(el, 'show-time')
          })
        } else {
          this.showPermitJoinTimes = true
        }
      }
    }
  }
  addFakeCard() {
    this.devicesFound.splice(this.devicesFound.length - 2, 0, new Device())
  }
  openLoadingModal(message: String) {
    this.loadingModalRef = this.modalService.show(
      LoadingModalComponent, {
        class: 'waiting-modal modal-sm',
        keyboard: false,
        backdrop: 'static'
      }
    )
    this.loadingModalRef.content.message = message;
  }

  dragMoved(e: CdkDragMove) {
    console.log(e)
  }

  //sortedDevices
  createIdList() {
    let idList = []
    for (let i = 0; i < this.groupsAndDevies.length; i++) {
      idList.push(this.groupsAndDevies[i]._id)
    }
    //console.log("aqui", idList, this.groupsAndDevies)
    let sortedDevices = [{
      "op": "replace",
      "path": "/registeredUsers/sortedDevices/",
      "value": {
        "idList": idList
      }
    }]
    this.modalRef = this.modalService.show(LoadingModalComponent);
    this.userService.invite((sortedDevices), this.emailUser).subscribe(res => {
      //this.modalRef = this.modalService.show(LoadingModalComponent);
      this.modalRef.content.success = false
      this.modalRef.content.waiting = true
      this.modalRef.content.message = "Aguarde"//this.jsonWord.toast.successScenario
      if (res) {
        // console.log("array salvo", res)
        let holdDevsToSort = []
        this.sortedDevices = res['data'].sortedDevices
        this.dragDropActive = false
        for (let i = 0; i < this.sortedDevices.length; i++) {
          let index = (this.groupsAndDevies.findIndex(sortdev => sortdev._id == this.sortedDevices[i]))
          holdDevsToSort.push(this.groupsAndDevies[index])
        }
        localStorage.setItem("sortedDevices", JSON.stringify(this.sortedDevices))
        //console.log(holdDevsToSort, this.sortedDevices)
        this.groupsAndDevies = holdDevsToSort
        // this.openLoadingModal(this.jsonWord.loading.message)
        this.gatewayService.getGatewayById(this.currentGateway).subscribe(res => {
          let result = new Gateway(res['data'])
          this.gatewayService.setGateway(result)
        })
        this.modalRef.content.success = true
        this.modalRef.content.waiting = false
        this.modalRef.content.message = "Sucesso"//this.jsonWord.toast.successScenario
        setTimeout(() => {
          this.modalRef.content.success = true
          this.modalRef.content.waiting = false
          this.modalRef.content.message = "Sucesso"//this.jsonWord.toast.successScenario
          this.modalRef.hide()
        }, 2000)
      }

    }, err => {
      console.log(err)
      setTimeout(() => {
        this.modalRef.content.success = false
        this.modalRef.content.waiting = false
        this.modalRef.content.message = "Falhou"//this.jsonWord.toast.successScenario
        this.modalRef.hide()
      }, 2000)
    })
  }


  removeIdList() {
   // console.log("teste")
    let sortedDevices = [{
      "op": "replace",
      "path": "/registeredUsers/sortedDevices/",
      "value": {
        "idList": []
      }
    }]

    this.userService.invite((sortedDevices), this.emailUser).subscribe(res => {
      // console.log("resposta", res)
      if (res) {
        let cards = []
        this.dragDropActive = false
        this.sortedDevices=[]
        this.gatewayService.getGatewayById(this.currentGateway).map(res => {let result
          result = new Gateway(res['data']).devices
          for(let i=0;i<result.length;i++){
            cards.push(new Device(result[i]))
          }
          //result = new Card(result)
      ///    this.gatewayService.setGateway(result)
        this.groupsAndDevies = this.attListDevsAndGroups(cards)
          
         // console.log(this.groupsAndDevies)
          // this.groupsAndDevies
          //this.ngOnInit()
        }).subscribe(_=>{})
      }
    })
  }
  createGroup(devices, sourceIndex, targetIndex) {
    //let tIndex = 0, sIndex = 0
    this.eventDone=0
    if (!this.inFilter) {
      let dev1 = this.groupsAndDevies[targetIndex], dev2 = this.groupsAndDevies[sourceIndex]
      if (devices[sourceIndex]._id[1] == 'x' && devices[targetIndex]._id[1] == 'x' && (sourceIndex != targetIndex)) { //sao 2 cards
        if (this.groupsAndDevies[targetIndex].type == "Router" && this.groupsAndDevies[sourceIndex].type == "Router") {
          if (((this.groupsAndDevies[targetIndex].devId == this.groupsAndDevies[sourceIndex].devId)) ||//se for router e iguais
            (this.groupsAndDevies[targetIndex].devId == '261' && this.groupsAndDevies[sourceIndex].devId == '258') ||
            (this.groupsAndDevies[targetIndex].devId == '258' && this.groupsAndDevies[sourceIndex].devId == '261') ||
            (this.groupsAndDevies[sourceIndex].devId == '81' && (this.groupsAndDevies[targetIndex].devId == '771' ||
              this.groupsAndDevies[targetIndex].devId == '265')) || (this.groupsAndDevies[sourceIndex].devId == '771' &&
                (this.groupsAndDevies[targetIndex].devId == '81' || this.groupsAndDevies[targetIndex].devId == '265')) ||
            (this.groupsAndDevies[sourceIndex].devId == '265' && (this.groupsAndDevies[targetIndex].devId == '81' ||
              this.groupsAndDevies[targetIndex].devId == '771'))) {


            //this.groupsAndDevies[indexTarget] = sindex
            // this.groupsAndDevies[indexSource] = tindex
            // console.log("grupos e disp2",this.groupsAndDevies)
            this.groupModalRef = this.modalService.show(CreateGroupAlertComponent);
            //console.log(this.groupModalRef)
            this.groupModalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
            this.groupModalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
            this.groupModalRef.content.edit = false
            this.groupModalRef.content.dev1 = dev2//this.groupsAndDevies[this.sourceIndex]
            this.groupModalRef.content.dev2 = dev1//this.groupsAndDevies[tIndex]
            this.groupModalRef.content.onClose.subscribe(res => {
              if (res) {
                let nameExist = this.groups.find(name => name.name == res.name)
                // console.log("o nome ja esxiste",nameExist)
                if (!nameExist) {
                  let group = {
                    name: res.name,//"Grupo (" + this.groupsToShow.length + ')',
                    devId: dev1.devId,//this.groupsAndDevies[tIndex].devId,
                    devices: [
                      dev1.ieeeAddr,//this.groupsAndDevies[tIndex].ieeeAddr,
                      dev2.ieeeAddr//this.groupsAndDevies[sIndex].ieeeAddr]
                    ]
                  }
                  //console.log("group", group)
                  //this.arraySameType[lengthArray] = (this.devicesToShow[this.targetIndex], this.devicesToShow[this.sourceIndex])
                  this.groupService.createGroup(group).subscribe(res => {
                    //  this.gatewayService.().subscribe(res=>console.log("all groups",res))
                    //pega o ieeeAddr
                    if (res) {
                      var index = this.groupsAndDevies.findIndex(dev => dev._id == dev1._id);
                      if (index > -1) {
                        this.groupsAndDevies.splice(index, 1);
                        index = this.groupsAndDevies.findIndex(dev => dev._id == dev2._id);
                        if (index > -1) {
                          this.groupsAndDevies.splice(index, 1);
                        }
                      } 
                      this.groupsAndDevies = this.groupsAndDevies.concat(res['data'])
                      this.attList(this.groupsAndDevies)
                      this.gatewayService.getGatewayById(this.currentGateway).subscribe(res => {
                        let result = new Gateway(res['data'])
                        this.gatewayService.setGateway(result)
                      })
                    }
                  }, err => {
                    console.log(err)
                  })
                } else {
                  this.showSimpleDialog(this.jsonWord.toast.groupAlreadyRegistered)
                }
              } else {
                //console.log(this.groupsAndDevies, this.sourceIndex, this.targetIndex,sourceIndex, targetIndex)
                // moveItemInArray(this.groupsAndDevies, sourceIndex, tIndex);
                //  this.createIdList()
              }
            })
          
        }else {// if (this.groupsAndDevies[this.targetIndex].devId != this.groupsAndDevies[this.sourceIndex].devId) {
            //let idList=[]
            //  moveItemInArray(this.groupsAndDevies, sourceIndex, targetIndex);
            // console.log("idlist",idList)
          }
        }
      
      }
      if (devices[targetIndex]._id[1] != 'x') {
         console.log(dev1, dev2)
        if ((dev1.devId == '261' && dev2.devId == '258') || (dev1.devId == '258' && dev2.devId == '261') ||
          (dev1.devId == dev2.devId)) {
          //let dev1 = this.groupsToShow[tIndex], dev2 = this.groupsAndDevies[sIndex]
          /*let devices = []
          devices = dev1.devices
          console.log(devices, dev1.devices)
          let devsAll = devices.push(dev2.ieeeAddr)*/
          let id = dev1._id
          let group = [{
            op: "add",
            path: "/device/",
            value: {
              device: String(dev2.ieeeAddr)
            }
          }]
          this.groupModalRef = this.modalService.show(CreateGroupAlertComponent);
          this.groupModalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
          this.groupModalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
          this.groupModalRef.content.edit = true
          this.groupModalRef.content.name = devices[targetIndex].name
          // this.groupModalRef.content.dev1 = dev2//this.groupsAndDevies[this.sourceIndex]
          this.groupModalRef.content.dev2 = dev1//this.groupsAndDevies[tIndex]
          this.groupModalRef.content.onClose.subscribe(res => {
            if (res) {
              this.groupService.updateGroup(id, group).subscribe(res => {
                if (res) {
                  // for(let i=0;i<this.groupsAndDevies.length;i++){
                  var index = this.groupsAndDevies.findIndex(dev => dev._id == dev2._id);
                  if (index > -1) {
                    this.groupsAndDevies.splice(index, 1);
                  }
                  this.createIdList()
                  this.gatewayService.getGatewayById(this.currentGateway).subscribe(res => {
                    let result = new Gateway(res['data'])
                    this.gatewayService.setGateway(result)
                  })
                }
              }, err => {
                console.log(err)
              })
            }
          })
        }
        if ((dev2.devId == '81' && (dev1.devId == '771' || dev1.devId == '265')) ||
          (dev2.devId == '771' && (dev1.devId == '81' || dev1.devId == '265')) ||
          (dev2.devId == '265' && (dev1.devId == '81' || dev1.devId == '771'))) {
          // let dev1 = this.groupsToShow[tIndex], dev2 = this.groupsAndDevies[this.sourceIndex]
          let devices = []
          devices = dev1.devices
          let devsAll = devices.push(dev2.ieeeAddr)
          let id = dev1._id
          let group = [{
            op: "add",
            path: "/device/",
            value: {
              device: String(dev2.ieeeAddr)
            }
          }]
          this.groupModalRef = this.modalService.show(CreateGroupAlertComponent);
          this.groupModalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
          this.groupModalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
          this.groupModalRef.content.edit = true
          this.groupModalRef.content.name = devices[targetIndex].name
          // this.groupModalRef.content.dev1 = dev2//this.groupsAndDevies[this.sourceIndex]
          this.groupModalRef.content.dev2 = dev1//this.groupsAndDevies[tIndex]
          this.groupModalRef.content.onClose.subscribe(res => {
            if (res) {
              this.groupService.updateGroup(id, group).subscribe(res => {
                if (res) {
                  var index = this.groupsAndDevies.findIndex(dev => dev._id == dev2._id);
                  if (index > -1) {
                    this.groupsAndDevies.splice(index, 1);
                  }
                  this.createIdList()
                  this.gatewayService.getGatewayById(this.currentGateway).subscribe(res => {
                    let result = new Gateway(res['data'])
                    this.gatewayService.setGateway(result)
                  })
                }
              }, err => {
                console.log(err)
              })
            }
          })
        }
      }
    }
  }



  showSimpleDialog(message) {
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.buttonCancel = { show: false, text: this.jsonWord.button.cancel };
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
    this.modalRef.content.type = 'warning'
    this.modalRef.content.title = this.jsonWord.alert.attention
    this.modalRef.content.message = message
    this.modalRef.content.onClose.subscribe(result => { })
  }

  groupModal() {
    this.modalRef = this.modalService.show(AlertComponent)
    this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
    this.modalRef.content.title = this.jsonWord.alert.attention
    this.modalRef.content.type = 'warning'
    this.modalRef.content.message = "Deseja remover a ordenação?"//this.jsonWord.toast.devicesLimitReached

    this.modalRef.content.onClose.subscribe(res => {
      if (res) {
        this.removeIdList()
      }
    })
  }

  /* drop(event: CdkDragDrop<any>) {
     moveItemInArray(this.devicesToShow, event.previousIndex, event.currentIndex);
   }*/

  ngOnDestroy() {
    //this.homeComponent.isJoining = false
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

}
/** Determines whether an event is a touch event. */
function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return event.type.startsWith('touch');
}

function __isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number) {
  /**
    * Returns −1 if the point is before the range, 0 if the point is
    * in the range, and 1 if the point is after the range.
    */
  const { top, bottom, left, right } = dropList.element.nativeElement.getBoundingClientRect();
  return y >= top && y <= bottom && x >= left && x <= right;
}
function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node);
};

import { Device } from "app/models/device.model";
import { TranslateJsonService } from "./../../_services/translate-json.service";
import { Component, OnInit, HostListener, Input } from "@angular/core";
import { Subject } from "rxjs/Subject";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UserService } from "../../_services/user.service";

@Component({
  moduleId: module.id,
  selector: "app-create-group-alert",
  templateUrl: "./create-group-alert.component.html",
  styleUrls: ["./create-group-alert.component.css"]
})
export class CreateGroupAlertComponent implements OnInit {
  public groupName: FormGroup;
  public onClose: Subject<any>;
  language;
  jsonWord;
  type;
  public dev1;
  public dev2;
  public showCloseButton: boolean = true;
  public devices: Array<Device>;
  private profileForm;
  title;
  edit;
  name;
  toEditName = false;
  @HostListener("window:keyup", ["$event"])
  enterKeyUpEvent(e) {}

  constructor(
    private modalService: BsModalService,
    private thisModalRef: BsModalRef,
    private loadingModalRef: BsModalRef,
    public translateJson: TranslateJsonService,
    private userService: UserService,
    private fb: FormBuilder
  ) {
    this.groupName = fb.group({
      name: [
        "",
        [Validators.required, Validators.minLength(3), Validators.maxLength(30)]
      ]
    });
  }

  public ngOnInit(): void {
    this.onClose = new Subject();
    this.language = localStorage.getItem("language");
    this.jsonWord = this.translateJson.changeLanguage(this.language);
    this.dev1 = "batata";
    this.dev2 = "batata";
  }

  public submit(groupName: any): void {
    console.log(groupName);
    var nome = groupName
      ? groupName
      : this.groupName.setValue({ name: this.name });

    this.onConfirm(nome);
    //  Object.assign(this.profileForm, groupName)
    // var data = this.profileForm
    //data.buildingNumber = Number(data.buildingNumber)
  }

  toEdit(name) {
    this.groupName.value.name = this.name = name;
    this.toEditName = !this.toEditName;
  }
  getName(name) {
    this.name = name;
    this.groupName.setValue({ name: this.name });
    console.log("name", name, "this.name", this.name);
    this.toEditName = !this.toEditName;
  }
  fieldHasErrors(field) {
    let control = this.groupName.get(field);
    if (
      control &&
      control.errors &&
      Object.keys(control.errors).length &&
      control.invalid &&
      control.touched &&
      control.dirty
    )
      return "has-errors";
    return "";
  }

  public onConfirm(groupName): void {
    this.onClose.next(groupName);
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    }
  }

  public onCancel(): void {
    this.onClose.next(false);
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    }
  }
}

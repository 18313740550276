import { UserService } from './../_services/user.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginService } from '../login/login.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, loginService: LoginService,
        public jwtHelper: JwtHelperService, private userService: UserService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (localStorage.getItem('currentUser')) {
            // logged in so return true
            let token = localStorage.getItem('currentUserToken')
            let exp = this.jwtHelper.decodeToken(token)
            exp = (exp.exp) / 86400000
            //exp=0.5
            if (exp < 1) { //se a pessoa deu check, armazena o valor no localstorage
                let body = {
                    "refreshToken": localStorage.getItem('refreshToken'),
                    "deviceId": "."
                }
                this.userService.refreshToken(body).subscribe(res => {
                    localStorage.setItem('refreshToken', (res['data'].refreshToken));
                }, err => {
                    let message = ""
                    switch (err.status) {
                        case 400:
                            message = "Formato inválido!"//'Formulário de convidar usuário inválido.'
                            break
                        case 401:
                            message = "Token inválido"//'Formulário de convidar usuário inválido.'
                            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                            break
                        case 408:
                            message = "Verifique a conexão"//'Formulário de convidar usuário inválido.'
                            break
                    }
                    
                })
            }
            return true;

        } else {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            // window.location.reload();
            return false;
        }
    }
}

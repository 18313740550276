import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { environment } from './../../../environments/environment.prod';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs/Subscription';
import { DeviceService } from './../../_services/device.service';
import { LoadingModalComponent } from './../loading-modal/loading-modal.component';
import { AlertComponent } from './../../_directives/alert.component';
import { DevicesModalComponent } from './../../_directives/devices-modal/devices-modal.component';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { timeout, catchError, timeoutWith, finalize } from 'rxjs/operators';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { EnvironmentService } from './../../_services/environment.service';
import { TranslateJsonService } from './../../_services/translate-json.service';
import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import * as config from '../../config';
import { v4 as uuid } from 'uuid';
import * as moment from 'moment';
import { ResponseService } from '../../_services/response.service';

const SMARTLOCK_TIMEOUT = 16000
const COMMAND_TIMEOUT = 5000
const BIND_TIMEOUT = 10000
const CONFIG_TIMEOUT = 10000
const DELETE_TIMEOUT = 15000
const TIMEOUT = 10000
const utf8Decoder = new TextDecoder("utf-8")


@Component({
  moduleId: module.id,
  selector: 'app-environment-card-modal',
  templateUrl: 'environment-card-modal.component.html',
  styleUrls: ['./environment-card-modal.component.css'],

})

export class EnvironmentCardModalComponent implements OnInit {
  private ngUnsubscribe = new Subject();
  public modalRef: BsModalRef;

  public title: any;
  @Input() environment: any
  public message: any;
  public devName: any;
  public devSerial: any;
  public language;
  public jsonWord;
  public envList = [];
  public globalClass: string
  public envListId = [];
  public whiteLight;
  public correlId
  vectorTypes = [null, null, null, null, null, null, null, null, null, null, null]
  arrowTF = [false, false, false, false, false, false, false, false, false, false, false];

  public buttonEdit: any = {
    show: false,
    text: 'EDITAR'
  };

  public buttonSave: any = {
    show: false,
    text: 'SALVAR'
  };
  public buttonCancel: any = {
    show: false,
    value: null,
    text: 'CANCELAR'
  };

  public inputText1: any = {
    show: false,
    value: null,
    type: 'text',
    placeholder: 'Digite o valor'
  };
  public inputText2: any = {
    show: false,
    value: null,
    type: 'text',
    placeholder: 'Digite o valor'
  };
  public nameEnv: any
  public editCreate: any = {
    show: false,
    value: null,
  };
  public card: any = {
    show: false,
    value: null,
  };

  public deviceTopicSync;
  public devsSyncObserver;
  public devicesFound;
  public currentUserType;
  public currentUserId;
  public currentUser;
  public currentGateway: any;
  public isAtBottom: boolean = false
  public acceptTerm: boolean = false
  public showCloseButton: boolean = true
  public onClose: Subject<any>;
  public nenhum = "Nenhum"
  public fusoChosen// = "Escolha um fuso horário";
  uuid = require('uuid/v4');
  public mqttSuccess: Subscription;
  public mqttError: Subscription;
  public deletingDevice;
  public gatewaySuccessObserver
  public gatewayErrorObserver
  toFixedNumber = Math.round
  public devListLamp = []
  public devListLed = []
  public devListSP = []
  public devListSPP = []
  public devListS = []
  public devListTH = []
  public devListAF = []
  public devListLum = []
  public devListInt = []
  public devListSL = []
  public devListP = []
  devAvEnv = [];
  inEnv = [];
  public environmentToOperation
  public loadingModalRef: BsModalRef;
  @HostListener('window:keyup', ['$event'])
  enterKeyUpEvent(e) {
    e.stopPropagation()
    if (e.keyCode == 13 && this.buttonSave.show) {
      this.onSave()
    }
    if (e.keyCode == 13 && this.buttonEdit.show) {
      this.onEdit()
    }
  }

  constructor(
    private _bsModalRef: BsModalRef,
    public translateJson: TranslateJsonService,
    private _environmentService: EnvironmentService,
    private modalService: BsModalService,
    private _deviceService: DeviceService,
    private responseService: ResponseService,
    private router: Router,
    private toastr: ToastrService,
    private _mqttService: MqttService, ) {
    // this.fusoChosen = this.jsonWord.label.choseTimezone
  }

  public ngOnInit(): void {
    this.language = localStorage.getItem('language')

    this.jsonWord = this.translateJson.changeLanguage(this.language)
    this.currentGateway = localStorage.getItem('currentGateway')
    // this.deviceTopic = `${config.gtwTopic}${this.currentGateway}/device/${this.card.devIeeeAddr}/`

    this.currentUserType = JSON.parse(localStorage.getItem('currentRole'));
    // this.currentGateway = this.deviceNewComponent.currentGateway
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')).email
    this.currentUserId = (localStorage.getItem('currentUserId'))
    this.deviceTopicSync = `ecomfort/iot/v1/sync/gateway/${this.currentGateway}/user/#`
    this.devsSyncObserver = this._mqttService.observe(this.deviceTopicSync)
      .map((IMQTTMessage: IMqttMessage) => {return JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload))})

    this.gatewaySuccessObserver = this._mqttService.observe(config.gtwTopic + this.currentGateway + '/success')
      .map((IMQTTMessage: IMqttMessage) => {
        let plainText = utf8Decoder.decode(IMQTTMessage.payload)
        return plainText ? JSON.parse(plainText) : null
      })

    this.gatewayErrorObserver = this._mqttService.observe(config.gtwTopic + this.currentGateway + '/error')
      .map((IMQTTMessage: IMqttMessage) => {
        let plainText = utf8Decoder.decode(IMQTTMessage.payload)
        return plainText ? JSON.parse(plainText) : null
      })

    this.listDevices(this.currentGateway)
    this.onClose = new Subject();
  }

  public onSave(): void {
    let holdElemId = [], holdChecked = [], name, packEnv, envs, ok = true;
    envs = this.card.value
    let messageNoName = this.jsonWord.error.requiredName;//"Coloque um nome!!";
    let messageAlreadyExist = this.jsonWord.toast.environmentAlreadyRegistered;
    name = this.inputText2.value ? this.inputText2.value : this.showSimpleDialog(messageNoName)
    envs.forEach(environment => {
      if (environment.name === name) {
        this.showSimpleDialog(messageAlreadyExist)
        ok = false;
      }
    });
    if (this.devicesFound.length > 0) {
      for (let i = 0; i < this.devicesFound.length; i++) {
        holdElemId.push(<any>document.getElementById(this.devicesFound[i].ieeeAddr))
        if (holdElemId[i] && holdElemId[i].checked) {//classList[holdElemId[i].classList.length - 1] === "mat-checkbox-checked") {
          holdChecked.push(this.devicesFound[i].ieeeAddr)
        }
        packEnv = {
          devices: holdChecked,
          name: name
        }
      }
    } else {
      packEnv = {
        "name": name,
        "devices": []
      }
    }

    if (this.inputText2.value && ok) {
      this.openLoadingModal(this.jsonWord.loading.message)
      /*      this.responseService.responseStatusEnvironment(this.environmentToOperation, 'add', '/status')
      
              .pipe(finalize(() => {
                console.log("FINALIZE")
                this._bsModalRef.content.waiting = false
                setTimeout(() => {
                  this._bsModalRef.hide()
                }, 5000);
              })).take(2).subscribe(res => {
                console.log(res)
                this._bsModalRef.content.success = true
                this._bsModalRef.content.message = this.jsonWord.toast.addEnvironmentSucess
              },
                err => {
                  console.log(err)
                  this._bsModalRef.content.success = false
                  this._bsModalRef.content.message = this.jsonWord.toast.errorAddEnvironment
                })*/
      this._environmentService.createEnvironment((packEnv)).subscribe(res => {
        // this.environmentToOperation = res.data;
        // this.responseService.responseStatusEnvironment(this.environmentToOperation, 'add', '/status', res)
        if (res) {
          this.loadingModalRef.content.success = true
          this.loadingModalRef.content.waiting = false
          this.loadingModalRef.content.message = this.jsonWord.toast.editEnvironmentSucess
          setTimeout(() => {
            this.loadingModalRef.hide()
            this._bsModalRef.hide()
          }, 2000);
        }
      }, err => {
        let message = ''
      switch (err.status) {
        case 0:
          message = this.jsonWord.error.noConnection//'Formulário de convidar usuário inválido.'
          //this.noInternetAlert()
          break
        case 400:
          message = this.jsonWord.error.invalidFormat//'Formulário de convidar usuário inválido.'
          break
        case 401:
          message = this.jsonWord.error.notAuthorized//'Você não tem permissão para convidar usuários.'
          break
        /*	case 403:
            message = this.jsonWord.error.userDisabled//'Usuário está desativado.'
            break*/
        case 404:
          message = this.jsonWord.error.environmentNotFound//'Usuário não encontrado ou número serial de gateway não registrado.'
          break
        case 408:
          message = this.jsonWord.error.timeoutServer;//'Tempo limite de conexão atingido.'
          break
        /*	case 409:
            message = this.jsonWord.error.userAlreadyInGtw//'Usuário já associado ao gateway.'
            break
          case 412:
            message = this.jsonWord.error.limitUserExceeded//'Usuário já associado ao gateway.'
            break*/
        case 500:
          message = this.jsonWord.toast.gatewayCommunicationError//'Não foi possível enviar convite.'
          break
        default:
          message = this.jsonWord.toast.errorEditEnvironment

      }
        this.loadingModalRef.content.success = false
        this.loadingModalRef.content.waiting = false
        this.loadingModalRef.content.message = message//this.jsonWord.toast.errorEditEnvironment
        setTimeout(() => {
          this.loadingModalRef.hide()
          this._bsModalRef.hide()
        }, 2000)
      })
    }
  }

  public onEdit(): void {
    let holdElemId = [], holdChecked = [], name, packEnv, envs, ok = true;
    envs = this.card.value
    let env = this.nameEnv
    let message
    let messageNoName = this.jsonWord.error.requiredName;//"Coloque um nome!!";
    let messageAlreadyExist = this.jsonWord.toast.environmentAlreadyRegistered;
    if (this.inputText2.value) { name = this.inputText2.value, ok = true } else { this.showSimpleDialog(messageNoName); ok = false; }
    for (let i = 0; i < this.devicesFound.length; i++) {
      holdElemId.push(<any>document.getElementById(this.devicesFound[i].ieeeAddr))
      if (holdElemId[i] && holdElemId[i].checked) {//classList[holdElemId[i].classList.length - 1] === "mat-checkbox-checked") {
        holdChecked.push(this.devicesFound[i].ieeeAddr)
      }
    }
    if (this.nameEnv.name === this.inputText2.value) {
      message = [{
        op: "update",
        path: "/devices/",
        value: {
          devices: holdChecked ? holdChecked : []
        }
      }]
    } else {
      envs.forEach(environment => {
        if (environment.name === name && name != this.nameEnv.name) {
          this.showSimpleDialog(messageAlreadyExist)
          ok = false;
        }
      });
      message = [{
        op: "replace",
        path: "/name/",
        value: {
          name: name,
        }
      }, {
        op: "update",
        path: "/devices/",
        value: {
          devices: holdChecked ? holdChecked : []
        }
      }]
    }

    if (this.inputText2.value && ok) {
      this.openLoadingModal(this.jsonWord.loading.message)
      /* this.responseService.responseStatusEnvironment(this.nameEnv, 'edit', '/status')
       .pipe(finalize(() => {
         console.log("FINALIZE")
         this._bsModalRef.content.waiting = false
         setTimeout(() => {
           this._bsModalRef.hide()
         }, 5000);
       })).take(2).subscribe(res => {
         console.log(res)
         this._bsModalRef.content.success = true
         this._bsModalRef.content.message = this.jsonWord.toast.editEnvironmentSucess
       },
         err => {
           console.log(err)
           this._bsModalRef.content.success = false
           this._bsModalRef.content.message = this.jsonWord.toast.errorEditEnvironment
         })*/
      this._environmentService.updateEnvironment(message, this.nameEnv._id).subscribe(res => {
        console.log(res)

        //this.responseService.responseStatusEnvironment(this.nameEnv, 'edit', '/status',res)
        if (res) {
          this.loadingModalRef.content.success = true
          this.loadingModalRef.content.waiting = false
          this.loadingModalRef.content.message = this.jsonWord.toast.editEnvironmentSucess
          setTimeout(() => {
            this.closeAllModals()
          }, 2000);
        }
      }, err => {
        let message = ''
        switch (err.status) {
          case 0:
            message = this.jsonWord.error.noConnection//'Formulário de convidar usuário inválido.'
            //this.noInternetAlert()
            break
          case 400:
            message = this.jsonWord.error.invalidFormat//'Formulário de convidar usuário inválido.'
            break
          case 401:
            message = this.jsonWord.error.notAuthorized//'Você não tem permissão para convidar usuários.'
            break
          /*	case 403:
              message = this.jsonWord.error.userDisabled//'Usuário está desativado.'
              break*/
          case 404:
            message = this.jsonWord.error.environmentNotFound//'Usuário não encontrado ou número serial de gateway não registrado.'
            break
          case 408:
            message = this.jsonWord.error.timeoutServer;//'Tempo limite de conexão atingido.'
            break
          /*	case 409:
              message = this.jsonWord.error.userAlreadyInGtw//'Usuário já associado ao gateway.'
              break
            case 412:
              message = this.jsonWord.error.limitUserExceeded//'Usuário já associado ao gateway.'
              break*/
          case 500:
            message = this.jsonWord.toast.gatewayCommunicationError//'Não foi possível enviar convite.'
            break
          default:
            message = this.jsonWord.toast.errorEditEnvironment

        }
        this.loadingModalRef.content.success = false
        this.loadingModalRef.content.waiting = false
        this.loadingModalRef.content.message = message//this.jsonWord.toast.errorEditEnvironment
        setTimeout(() => {
          this.closeAllModals()
        }, 2000)
      })

    }
  }
  public onCancel(): void {
    // this.onClose.next(false);
    this._bsModalRef.hide();
  }

  listDevices(gateway) {
    if (!this.currentGateway) {
      return
    }
    if (!this.devicesFound) {
      let hold = [], devSort = []
      this._deviceService.listDevices(gateway)
        .pipe(finalize(() => {
        }))
        .subscribe(
          (deviceList) => {
            this.devicesFound = deviceList;
            for (let j = 0; j < (this.devicesFound.length); j++) {
              if (this.devicesFound[j].environment == "" || this.devicesFound[j].environment == null || !this.devicesFound[j].environment.name) {
                this.devAvEnv.push(this.devicesFound[j]);
                this.inEnv.push(false)
              }
              if (this.devicesFound[j].environment.name && this.inputText2.value && this.devicesFound[j].environment.name == this.inputText2.value) {
                this.devAvEnv.push(this.devicesFound[j]);
                this.inEnv.push(true)
              }
            }
            this.vectorTypes = [null, null, null, null, null, null, null, null, null, null, null]
            let count0 = [], count1 = [], count2 = [], count3 = [], count4 = [], count5 = [], count6 = [], count7 = [], count8 = [], count9 = [], count10 = [];

            for (let i = 0; i < this.devAvEnv.length; i++) {
              if (this.devAvEnv[i].devId == '770') count0.push([this.devAvEnv[i], this.inEnv[i]]);
              if (this.devAvEnv[i].devId == '12') count1.push([this.devAvEnv[i], this.inEnv[i]]);  //3
              if (this.devAvEnv[i].devId == '263') count2.push([this.devAvEnv[i], this.inEnv[i]]);
              if (this.devAvEnv[i].devId == '81' || this.devAvEnv[i].devId === '256') count3.push([this.devAvEnv[i], this.inEnv[i]]);
              if (this.devAvEnv[i].devId == '2') count4.push([this.devAvEnv[i], this.inEnv[i]]); //2
              if (this.devAvEnv[i].devId == '6') count5.push([this.devAvEnv[i], this.inEnv[i]]); //2
              if (this.devAvEnv[i].devId == '258' || this.devAvEnv[i].devId === '528') count6.push([this.devAvEnv[i], this.inEnv[i]]);
              if (this.devAvEnv[i].devId == '10') count7.push([this.devAvEnv[i], this.inEnv[i]]); //1
              if (this.devAvEnv[i].devId == '262') count8.push([this.devAvEnv[i], this.inEnv[i]]);
              if (this.devAvEnv[i].devId == '771') count9.push([this.devAvEnv[i], this.inEnv[i]]);
              if (this.devAvEnv[i].devId == '261') count10.push([this.devAvEnv[i], this.inEnv[i]]); //1
            }
            this.vectorTypes[0] = count0;
            this.vectorTypes[1] = count1;
            this.vectorTypes[2] = count2;
            this.vectorTypes[3] = count3;
            this.vectorTypes[4] = count4;
            this.vectorTypes[5] = count5;
            this.vectorTypes[6] = count6;
            this.vectorTypes[7] = count7;
            this.vectorTypes[8] = count8;
            this.vectorTypes[9] = count9;
            this.vectorTypes[10] = count10;
          }, err => {
            if (err.status == 0 || err.status == 408) {
              this.modalRef = this.modalService.show(AlertComponent);
              this.modalRef.content.type = 'remove'
              this.modalRef.content.title = /*"<i class='fa fa-lg fa-times text-danger'> </i> " +*/ this.jsonWord.error.conectionError
              this.modalRef.content.message = this.jsonWord.error.noConnection
              this.modalRef.content.isDelete = false
              this.modalRef.content.typeB = 'refresh'
              //"Tempo limite de conexão com o servidor atingido. Verifique o status do servidor recarregue a página novamente."
              this.modalRef.content.buttonConfirm = { show: true, text: /*"<i class='fa fa-refresh'></i>" + '  ' + */this.jsonWord.label.reload }
              this.modalRef.content.onClose.subscribe(
                res => {
                  if (res)
                    window.location.reload()
                }
              )
            }
          }
        );
    }
  }
  showSimpleDialog(message) {
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonCancel = { show: false };
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
    this.modalRef.content.imagem = { show: false };
    this.modalRef.content.type = 'warning'
    this.modalRef.content.title = this.jsonWord.alert.attention
    this.modalRef.content.message = message
    this.modalRef.content.onClose.subscribe(result => { })
  }
  setArrow(i) {
    this.arrowTF[i] = !this.arrowTF[i]
    switch (i) {
      case (0):
        if (this.arrowTF[i])
          document.getElementById('arrowS').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
        else
          document.getElementById('arrowS').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
      case (1):
        if (this.arrowTF[i])
          document.getElementById('arrowInt').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
        else
          document.getElementById('arrowInt').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
      case (2):
        if (this.arrowTF[i])
          document.getElementById('arrowSL').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
        else
          document.getElementById('arrowSL').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
      case (3):
        if (this.arrowTF[i])
          document.getElementById('arrowAF').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
        else
          document.getElementById('arrowAF').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
      case (4):
        if (this.arrowTF[i])
          document.getElementById('arrowSP').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
        else
          document.getElementById('arrowSP').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
      case (5):
        if (this.arrowTF[i])
          document.getElementById('arrowLamp').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
        else
          document.getElementById('arrowLamp').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
      case (6):
        if (this.arrowTF[i])
          document.getElementById('arrowLed').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
        else
          document.getElementById('arrowLed').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
      case (7):
        if (this.arrowTF[i])
          document.getElementById('arrowLum').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
        else
          document.getElementById('arrowLum').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
      case (8):
        if (this.arrowTF[i])
          document.getElementById('arrowP').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
        else
          document.getElementById('arrowP').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
      case (9):
        if (this.arrowTF[i])
          document.getElementById('arrowTH').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
        else
          document.getElementById('arrowTH').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
      case (10):
        if (this.arrowTF[i])
          document.getElementById('arrowSPP').className = 'fa fa-chevron-up rotate-counterclockwise fa-sm';
        else
          document.getElementById('arrowSPP').className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
    }
  }

  openLoadingModal(message: String) {
    this.loadingModalRef = this.modalService.show(
      LoadingModalComponent, {
        class: 'waiting-modal modal-sm',
        keyboard: false,
        backdrop: 'static'
      }
    )
    this.loadingModalRef.content.message = message;
  }
  gatewayErrorToast(err) {
    if (err.status == 408) {
      this.toastr.error('O gateway demorou muito pra responder. Verifique o status do gateway e tente novamente mais tarde.')
    }
  }
  private closeAllModals() {
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    }
  }
  public unsafePublish(topic: string, message: string) {
    this.correlId = JSON.parse(message).messageId
    this._mqttService.unsafePublish(topic, message, { qos: 1, retain: false })
  }
}

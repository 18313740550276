import { ColorhueScreenComponent } from './../../_directives/colorhue-screen/colorhue-screen.component';
import { EnvironmentService } from './../../_services/environment.service';
import { DeviceCardModalComponent } from './../../_directives/device-card-modal/device-card-modal.component';
import { ResponseService } from './../../_services/response.service';
import { TranslateJsonService } from './../../_services/translate-json.service';
import { UserService } from './../../_services/user.service';
import { DeviceService } from './../../_services/device.service';
import { Component, OnInit, Input, Output, OnDestroy, EventEmitter, ChangeDetectorRef, SimpleChanges, SimpleChange } from '@angular/core';
import { Card } from './../../models/card.model';
import { Device } from './../../models/device.model';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import * as config from '../../config';
import { AlertComponent } from '../../_directives/alert.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { v4 as uuid } from 'uuid';
import * as moment from 'moment';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { DevicesModalComponent } from '../../_directives/devices-modal/devices-modal.component';
import { PanelComponent } from '../panel.component';
import { LoadingModalComponent } from '../../_directives/loading-modal/loading-modal.component';
import { Subscription, Subject, of, NEVER, Observable, empty } from 'rxjs';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/catch';
import { timeout, catchError, finalize, timeoutWith, onErrorResumeNext } from 'rxjs/operators';
import { HomeComponent } from '../../home/home.component';
import { SmartlockModalComponent } from '../../_directives/smartlock-modal/smartlock-modal.component';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';

const SMARTLOCK_TIMEOUT = 16000
const COMMAND_TIMEOUT = 5000
const BIND_TIMEOUT = 10000
const CONFIG_TIMEOUT = 10000
const utf8Decoder = new TextDecoder("utf-8")

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: [
    './card.component.css',
    './csshake.css'
  ]
})

export class CardComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  public modalRef: BsModalRef;
  public loadingModalRef: BsModalRef;
  private subscription: Subscription;
  private deviceTopic;
  private deviceObserver
  private deviceStatusObserver
  public message: any;
  public loading = false;
  public dev: Device
  isFavorite:Boolean=false
  @Input() dragDropActive
  @Input() set deviceSetter(dev: Device) {
    this.dev = dev;
    this.card = new Card(dev)
  };
  @Input() pathNameDev
  @Input() isLast: boolean;
  @Input() devsToBind: Array<any>
  @Output() devFeedback = new EventEmitter();

  card: Card;
  backgroundColor = '#d5d5d5';
  textColor = '#ffffff';
  flipCard = true;
  cardFlipTimer: any;
  whiteLight;
  buzzer = true;
  led = true;
  autoLock = true;
  batteryLevelIcon;
  lockState;
  doorState;
  currentGateway;
  uuid = require('uuid/v4');
  devsCheckedBind;
  deviceTopicSync;
  devsSyncObserver
  currentUserToken;
  userId;
  public correlId: string
  public waiting: Boolean = true;
  public success: Boolean //= true;
  public currentUser: any;
  public buttomBlocked: Boolean = true;
  currentUserId
  currentUserType

  btnClicked = false
  doorIsActive
  count = 0

  toFixedNumber = Math.round
  @Input() busca: string;
  @Output() buscaChange: EventEmitter<string> = new EventEmitter<string>();
  private termosDaBusca: Subject<any> = new Subject<any>();
  public language;
  public jsonWord;
  constructor(
    public panelComponent: PanelComponent,
    public homeComponent: HomeComponent,
    private _mqttService: MqttService,
    private modalService: BsModalService,
    private responseService: ResponseService,
    private userService: UserService,
    private router: Router, private route: ActivatedRoute,
    private devicesService: DeviceService,
    private translateJson: TranslateJsonService,
    private _environmentService: EnvironmentService,
    private cd: ChangeDetectorRef, private _deviceService: DeviceService) {
  }

  ngOnInit() {
    this.language = localStorage.getItem('language')
    this.jsonWord = this.translateJson.changeLanguage(this.language)
    this.card = new Card(this.dev);
    /* if((this.card.isActive && this.card.invertOnOff) || (!this.card.isActive && this.card.invertOnOff)){
       this.card.isActive = !this.card.isActive
     }*/
     console.log(this.card);
    this.card.isActive = (this.card.isActive && !this.card.invertOnOff) || (!this.card.isActive && this.card.invertOnOff)
    this.isFavorite = this.card.favoriteDevice
    //verifica o browse
    var isSafari = /^((?!chrome|android).)*firefox/i.test(navigator.userAgent);
    this.getBatteryLevel(this.card.batteryLevel)
    this.currentUserType = this.homeComponent.currentUserType;
    this.currentGateway = this.panelComponent.currentGateway
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')).email
    this.currentUserId = (localStorage.getItem('currentUserId'))
    this.deviceTopic = `${config.gtwTopic}${this.currentGateway}/device/${this.card.devIeeeAddr}/`
    this.deviceTopicSync = `ecomfort/iot/v1/sync/gateway/${this.currentGateway}/user/#`

    this.deviceObserver = this._mqttService.observe(this.deviceTopic)
      .map((IMQTTMessage: IMqttMessage) => {
        //console.log("decode ", new TextDecoder("utf-8").decode(IMQTTMessage.payload),
        //"sem decode",IMQTTMessage.payload)
        return JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload))
      })

    this.devsSyncObserver = this._mqttService.observe(this.deviceTopicSync)
      .map((IMQTTMessage: IMqttMessage) => {
    let plainText = (utf8Decoder.decode(IMQTTMessage.payload))
   // console.log("ERRO NO TOPICO", plainText)
    return plainText ? JSON.parse(plainText) : null
    //    return JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload))
       // console.log(new TextDecoder("utf-8").decode(IMQTTMessage.payload))
      })


    this.deviceStatusObserver = this._mqttService.observe(`${this.deviceTopic}status`)
      .map((IMQTTMessage: IMqttMessage) => {
        return JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload))})

    this.currentUserToken == localStorage.getItem('currentUserToken');

    this.waiting = false

    // General listener to all events from unknown source
    this.panelComponent.devicesObserver
      .filter(payload => payload.data && payload.data.ieeeAddr == this.card.devIeeeAddr)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(payload => {
        this.message = payload
        let op = payload.operation
        if (op === 'attReport') {
          this.messageDispatcher(this.message.data);
        }
        //Operações de delete e add ficam a cargo do pai (panel)
        else if (op === 'delete') {
          this.devFeedback.emit({ op, device: this.dev });
        }
        else if (op === 'edit') {
          this.devFeedback.emit({ op, device: this.dev });
        }
      });

    this.devsSyncObserver
      .takeUntil(this.ngUnsubscribe)
      .subscribe(payload => {
        this.message = payload
        let op = payload
        console.log(payload)
        if (payload && payload.blockedDevices && payload.blockedDevices == this.card.devIeeeAddr) {
          console.log("aqui",payload)
          this.card.blocked = true
        }
        if (payload && payload.unblockedDevices && payload.unblockedDevices == this.card.devIeeeAddr) {
          this.card.blocked = false
        }
      })
    this.doorState = this.card.value1;
    this.whiteLight = this.card.value3;
  }


  removeDevice() {
    this.devFeedback.emit({ operation: 'delete', ieeeAddr: this.dev.ieeeAddr });
  }

  messageDispatcher(message) {
    switch (message.cId) {
      case 'genOnOff':
        if (message.type === 'onOff') {
          let status: boolean;
          status = message.value ? true : false;
          this.card.isActive = !this.card.invertOnOff ? status : !status;
          this.waiting = false
          this.success = true
        }
        break;
      case 'genPowerCfg':
        if (message.type === 'batteryPercentageRemaining') {
          let value: boolean;
          value = message.value;
          this.card.batteryLevel = String(value);//this.getBatteryPercentage(value);
          this.getBatteryLevel(value)
        }
        break;
      case 'msTemperatureMeasurement':
        if (message.type === 'measuredValue' && this.card.devId == '770') {
          let value;
          value = Number(message.value) / 100;
          this.card.value1 = String(value.toFixed(1));
        }
        break;
      case 'msRelativeHumidity':
        if (message.type === 'measuredValue' && this.card.devId == '770') {
          let value;
          value = Number(message.value) / 100;
          this.card.value2 = String(value.toFixed(1));
        }
        break;
      case "msIlluminanceMeasurement":
        if (message.type === 'measuredValue' && this.card.devId == '262') {
          //if (!this.card.isActive) {
          //  this.card.value1 = '0.0';
          //  } else {
          const value = (message.value);
          this.card.value1 = value;
          //}
        }
        break;
      case 'seMetering':
        if (message.type === 'instantaneousDemand') {
          if (!this.card.isActive) {
            this.card.value1 = '0.0';
          } else {
            const value = (message.value / 1000);
            this.card.value1 = value.toFixed(1);
          }
        }
        break;
      case 'haElectricalMeasurement':
        if (message.type['apparentPower']) {
          if (!this.card.isActive) {
            this.card.value1 = '0.0';
          } else {
            const value = (message.type.apparentPower / 10);
            this.card.value1 = value.toFixed(1);
          }
        }
        if (message.type['rmsVoltage']) {
          if (!this.card.isActive) {
            this.card.value2 = '0.0';
          } else {
            const value = (message.type.rmsVoltage / 10);
            this.card.value2 = value.toFixed(1);
          }
        }
        if (message.type['rmsCurrent']) {
          if (!this.card.isActive) {
            this.card.value3 = '0.0';
          } else {
            const value = (message.type.rmsCurrent / 10);
            this.card.value3 = value.toFixed(1);
          }
        }
        if (message.type['instantaneousPower']) {
          if (!this.card.isActive) {
            this.card.value4 = '0.0';
          } else {
            const value = (message.type.instantaneousPower / 10);
            this.card.value4 = value.toFixed(1);
          }
        }
        break;
      case 'genLevelCtrl':
        if (message.type === 'currentLevel') {
          let value;
          value = message.value;
          this.card.value1 = value.toFixed(1);
        }
        break;
      case 'lightingColorCtrl':
        if (message.type === 'currentHue') {
          let value;
          value = message.value;
          this.card.value2 = value.toFixed(1);
        } else if (message.type === 'currentSaturation') {
          let value;
          value = message.value;
          if (value > 1) {
            this.whiteLight = false;
            this.card.value3 = false
          } else if (value <= 1) {
            this.whiteLight = true;
            this.card.value3 = true
          }
        }
        break;
      case 'msOccupancySensing':
        if (message.type === 'occupancy') {
          let status: boolean;
          status = message.value ? true : false;
          this.card.isActive = status;
        }
        break;
      case 'closuresDoorLock':
        if (message.type === 'doorState') {
          this.doorState = message.value;
          if (this.doorState === 0) {
            this.card.shake = true;
          }
          if (this.doorState === 1) {
            this.card.shake = false;
          }
        }
        if (message.type === 'lockState') {
          this.lockState = message.value;
          if (this.lockState === 2) {
            this.card.isActive = false;
          }
          if (this.lockState === 1) {
            this.card.isActive = true;
          }
        }
        break;
    }
  }
  /*clickedSL() {
    this.btnClicked = !this.btnClicked
  }
  openCloseSmarLock(card) {
    let message
    console.log(this.btnClicked)
    let deviceTopic = `${config.gtwTopic}${this.currentGateway}/device/${card.devIeeeAddr}/`
    const topic = `${deviceTopic}command`;
    this.doorIsActive = this.count == 0 ? card.isActive : this.doorIsActive
    if (this.btnClicked) {
      if (this.doorIsActive) {
        console.log(card, deviceTopic, this.btnClicked)
        message = {
          messageId: uuid(),
          timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          epId: card.devEpList[0],
          user: this.currentUser,
          cId: 'closuresDoorLock',
          cmd: 'unlockDoor',
          zclData: { pincodevalue: '00000000' }
        };
        this.unsafePublish(topic, JSON.stringify(message));
      } else {
        console.log(card, deviceTopic, this.btnClicked)
        message = {
          messageId: uuid(),
          timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          epId: card.devEpList[0],
          user: this.currentUser,
          cId: 'closuresDoorLock',
          cmd: 'lockDoor',
          zclData: { pincodevalue: '00000000' }
        };
        this.unsafePublish(topic, JSON.stringify(message));
      }
    }
  }
  openCloseObserver(card) {
    this.homeComponent.gatewayErrorObserver
      .subscribe(message => {
        console.log("TESTE ERRO:", message)
        this.count++;
        if (this.btnClicked) {
          if (this.count < 4) {
            this.openCloseSmarLock(card)
          } else {
            alert("Saiu da rede")
          }
        }
      })
    this.panelComponent.devicesObserver
      .filter(payload => payload.data && payload.data.ieeeAddr == card.devIeeeAddr)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(payload => {
        let message = payload
        let op = payload.operation
        if (op === 'attReport') {
          console.log("MENSAGEM", message)
          if (message.data.type == "lockState" && message.data.value == 1 && this.btnClicked) {
            this.doorIsActive = true
            this.count = 1
            this.openCloseSmarLock(card)
          } else if (message.data.type == "lockState" && message.data.value == 2 && this.btnClicked) {
            this.doorIsActive = false
            console.log("TESTE", this.doorIsActive)
            this.openCloseSmarLock(card)
            this.count = 1
          }

        }
      })
  }*/
  sendMQTTOnOffCommand(card) {
    if (!card.blocked && !this.dragDropActive) {
      this.waiting = true
      if ((card.devType === 'Router') || (card.devId === '10') || (card.devId === '6')) {
        let message;
        if ((card.devId === '81') || (card.devId === '258') || (card.devId === '771') || (card.devId === '6') ||
          (card.devId === '264') || (card.devId === '265') || (card.devId === '772')) {
          message = {
            messageId: uuid(),
            user: this.currentUser,
            timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            epId: card.devEpList[0],
            //userId:'teste',//JSON.parse(localStorage.getItem('currentUserId'))._id,
            cId: 'genOnOff',
            cmd: 'toggle',
            zclData: {}
          };
        }
        if (!this.card.invertOnOff) {
          if (card.isActive) {
            message = {
              messageId: uuid(),
              timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
              epId: card.devEpList[0],
              user: this.currentUser,
              cId: (card.devId === '10') ? 'closuresDoorLock' : 'genOnOff',
              cmd: (card.devId === '10') ? 'unlockDoor' : 'off',
              zclData: (card.devId === '10') ? { pincodevalue: '00000000' } : {}
            };
          } else if (!card.isActive) {
            message = {
              messageId: uuid(),
              timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
              epId: card.devEpList[0],
              user: this.currentUser,
              cId: (card.devId === '10') ? 'closuresDoorLock' : 'genOnOff',
              cmd: (card.devId === '10') ? 'lockDoor' : 'on',
              zclData: (card.devId === '10') ? { pincodevalue: '00000000' } : {}
            };
          }
        } else {
          if (card.isActive) {
            message = {
              messageId: uuid(),
              timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
              epId: card.devEpList[0],
              user: this.currentUser,
              cId: 'genOnOff',
              cmd: 'on',
              zclData: {}
            };
          } else if (!card.isActive) {
            message = {
              messageId: uuid(),
              timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
              epId: card.devEpList[0],
              user: this.currentUser,
              cId: 'genOnOff',
              cmd: 'off',
              zclData: {}
            };
          }
        }
        const topic = `${this.deviceTopic}command`;
        this.unsafePublish(topic, JSON.stringify(message))

        //caso haja erro
        this.subscription =
          this.homeComponent.gatewayErrorObserver
            .filter(msg => this.correlId == msg['correlId'])
            .map(message => {
              this.success = false
              return message
            })
            .merge(
              this.homeComponent.gatewaySuccessObserver
                .filter(msg => this.correlId == msg['correlId'])
                .map(message => {
                  this.success = true
                  this.buttomBlocked = true;
                  return message
                })
            )
            .take(1)
            .pipe(finalize(() => {
              this.waiting = false
            }), timeoutWith(this.card.devId == '10' ? SMARTLOCK_TIMEOUT : COMMAND_TIMEOUT, ErrorObservable.throw(
              new Response(null, {
                status: 408, statusText: 'Tempo limite de resposta atingido'
              })
            )))
            .subscribe(_ => {
              this.correlId = null
            },
              err => {
                this.homeComponent.gatewayErrorToast(err)
              }
            )
      }
    }
  }

  public unsafePublish(topic: string, message: string) {
    this.correlId = JSON.parse(message).messageId
    this._mqttService.unsafePublish(topic, message, { qos: 1, retain: false })
  }

  getBatteryLevel(voltage) {
    let level;
    if (voltage <= 25) {
      this.batteryLevelIcon = 'fa-battery-quarter';
      // level = '5';
    }
    if ((voltage > 25) && (voltage < 50)) {
      this.batteryLevelIcon = 'fa-battery-half';
      //  level = '25';
    }
    if ((voltage >= 50) && (voltage < 75)) {
      this.batteryLevelIcon = 'fa-battery-three-quarters';
      // level = '50';
    }
    if ((voltage >= 75) && (voltage <= 100)) {
      this.batteryLevelIcon = 'fa-battery-full';
      //  level = '75';
    }
    return this.batteryLevelIcon;
  }

  getBatteryPercentage(value) {
    return value + ' %';
  }

  rotateCard() {
    this.flipCard = !this.flipCard;
    if (this.flipCard) {
      clearTimeout(this.cardFlipTimer);
    }
    if (!this.flipCard) {
      this.cardFlipTimer = setTimeout(() => {
        this.rotateCard();
      }, 60000);
    }
  }

  getDimmerSliderValue() {
    return this.card.value1
    //return this.card.attributes['genLevelCtrl#currentLevel'];
  }

  getColorSliderValue() {
    return this.card.value2//this.card.attributes['lightingColorCtrl#currentHue'];
  }

  onSmartlightWhiteColorChecked(card) {
    const topic = `${this.deviceTopic}command`;
    this.whiteLight = !this.whiteLight;
    let command: any;
    if (this.whiteLight) {
      command = {
        messageId: uuid(),
        timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        ieeeAddr: card.devIeeeAddr,
        epId: card.devEpList[0],
        user: this.currentUser,
        cId: 'lightingColorCtrl',
        cmd: 'moveToSaturation',
        zclData: { saturation: 0, transtime: 0 }
      };
    } else if (!this.whiteLight) {
      command = {
        messageId: uuid(),
        timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        ieeeAddr: card.devIeeeAddr,
        user: this.currentUser,
        epId: card.devEpList[0],
        cId: 'lightingColorCtrl',
        cmd: 'moveToSaturation',
        zclData: { saturation: 254, transtime: 0 }
      };
    }
    if(!card.blocked) this.unsafePublish(topic, JSON.stringify(command));
  }

  onSmartlightColorChange(card, value) {
    this.whiteLight = false;
    const command = {
      messageId: uuid(),
      timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      ieeeAddr: card.devIeeeAddr,
      epId: card.devEpList[0],
      user: this.currentUser,
      cId: 'lightingColorCtrl',
      cmd: 'moveToHueAndSaturation',
      zclData: { hue: value, saturation: 254, transtime: 0 }
    };
    const topic = `${this.deviceTopic}command`;
    if(!card.blocked) this.unsafePublish(topic, JSON.stringify(command));
  }

  onSmartlightLevelChange(card, value) {
    const command = {
      messageId: uuid(),
      timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      ieeeAddr: card.devIeeeAddr,
      epId: card.devEpList[0],
      user: this.currentUser,
      cId: 'genLevelCtrl',
      cmd: 'moveToLevel',
      zclData: { level: value, transtime: 0 }
    };
    const topic = `${this.deviceTopic}command`;
    if(!card.blocked) this.unsafePublish(topic, JSON.stringify(command));
  }
  showArray() {
    var txt = "";
    for (let dev in this.devsToBind) {
      txt += this.devsToBind[dev].name + "," + " ";
    }
    document.getElementById("p1").innerHTML = txt;
  }
  colorScreenModal(card){
    //this.closeAllModals()
  //  setTimeout(() => {
    this.modalRef = this.modalService.show(ColorhueScreenComponent);
    this.modalRef.content.card = card
    this.modalRef.content.pixelData = this.hslToRgb(card.value1/255, (card.value2/255), 1)
    this.modalRef.content.value1 = card.value1
    this.modalRef.content.value2 = card.value2
    this.modalRef.content.onClose.subscribe(res=>{
      this.editDeviceModal(card)
    })
  //  }, 1000);
  //  this.modalRef.content.wheel = this.showWheel()
  }


  hslToRgb(h, s, l) {
    var r, g, b;

    if (s == 0) {
      r = g = b = l; // achromatic
    } else {
      var hue2rgb = function hue2rgb(p, q, t) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      }

      var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      var p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }
    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  }


  editDeviceModal(card) {
    //this.cardToBeEdited = card
    this.modalRef = this.modalService.show(DeviceCardModalComponent);
    this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
    this.modalRef.content.buttonConfirm = { show: true, text: this.jsonWord.button.save };
    this.modalRef.content.buttonBlock = { show: true, text: this.jsonWord.button.blockDevice };
    this.modalRef.content.buttonUnblock = { show: card.blocked ? true : false, text: this.jsonWord.button.unblockDevice };
    this.modalRef.content.buttonBind = { show: card.devId == '6' ? true : false, text: this.jsonWord.button.bind };
    this.modalRef.content.buttonCalibrate = { show: card.devId == '10' || card.devId == '772' ? true : false, text: this.jsonWord.button.calibrate };
    this.modalRef.content.buttonUnbind = { show: card.devId == '6' ? true : false, text: this.jsonWord.button.unbind };
    this.modalRef.content.whiteLight = card.value3;
    this.modalRef.content.buttonRemove = { show: true, text: this.jsonWord.button.removeDevice };
    this.modalRef.content.inputText1 = { show: true, type: "text", placeholder: card.devIeeeAddr, value: card.devIeeeAddr };
    this.modalRef.content.inputText2 = { show: true, type: "text", placeholder: this.jsonWord.label.name, value: card.title };
    this.modalRef.content.inputLevelLum = { show: true, type: "text" };
    this.modalRef.content.inputLevel = { show: true, type: "text" };
    this.modalRef.content.inputHue = { show: true, type: "text" };
    this.modalRef.content.input = { show: true, type: "text" };
    this.modalRef.content.offset = { show: true, type: "text", value: card.environment && card.environment.name ? card.environment.name : "Nenhum" };
    this.modalRef.content.card = card
    this.modalRef.content.isEdit=false
    this.modalRef.content.isFavorite = card.favoriteDevice
    this.modalRef.content.title = card.title//'Vincular Dispositivos';
  }

  editDevice(card) {
    this.editDeviceModal(card)
    this.modalRef.content.onClose
      .subscribe(
        res => {
          if (res) {
            let envId
            let cardRes = res._id

            if (this.modalRef.content.offset.value == "Nenhum") {
              envId = "nowhere"
              let data = {
                name: this.modalRef.content.inputText2.value,
                environment: { _id: envId },
                favoriteDevice: res.favoriteDevice,
                calibrated: true
              }
              /*  this._deviceService.updateDevice(card._id, data).subscribe(res => {
                  console.log(res)
                  this.modalRef.content.success = true
                  this.modalRef.content.message = this.jsonWord.toast.successRemoveDevice
                },
                  err => {
                    console.log(err)
                    this.modalRef.content.success = false
                    this.modalRef.content.message = this.jsonWord.toast.errorRemoveDevice
  
                  })*/
              this.openLoadingModal(this.jsonWord.loading.message)
              this._deviceService.updateDevice(card._id, data).subscribe(res => {
                if (res) {
                  this.loadingModalRef.content.success = true
                  this.loadingModalRef.content.waiting = false
                  this.loadingModalRef.content.message = this.jsonWord.toast.editDeviceSucess
                  setTimeout(() => {
                    this.loadingModalRef.hide()
                    this.modalRef.hide()
                  }, 2000);
                }
              }, err => {
                this.loadingModalRef.content.success = false
                this.loadingModalRef.content.waiting = false
                this.loadingModalRef.content.message = this.jsonWord.toast.errorEditDevice
                setTimeout(() => {
                  this.loadingModalRef.hide()
                  this.modalRef.hide()
                }, 2000)
              })
            } else {
              this._environmentService.listEnvironments().subscribe(res => {
                let env = res.filter(env => env.name == String(this.modalRef.content.offset.value))
                envId = env[0]._id
                let data = {
                  name: this.modalRef.content.inputText2.value,
                  environment: { _id: env[0]._id },
                  favoriteDevice: res.favoriteDevice,
                  calibrated: true
                }
                this.openLoadingModal(this.jsonWord.loading.message)
                this._deviceService.updateDevice(card._id, data).subscribe(res => {
                  if (res) {
                    this.loadingModalRef.content.success = true
                    this.loadingModalRef.content.waiting = false
                    this.loadingModalRef.content.message = this.jsonWord.toast.editDeviceSucess
                    setTimeout(() => {
                      //this.loadingModalRef.hide()
                      // this.modalRef.hide()
                      this.closeAllModals()
                    }, 2000);
                  }
                }, err => {
                  this.loadingModalRef.content.success = false
                  this.loadingModalRef.content.waiting = false
                  this.loadingModalRef.content.message = this.jsonWord.toast.errorEditDevice
                  setTimeout(() => {
                    //this.loadingModalRef.hide()
                    //this.modalRef.hide()
                    this.closeAllModals()
                  }, 2000)
                })
                /* res.forEach(env => {
                   console.log(env, this.modalRef.content.offset.value, env.name)
                   if (String(this.modalRef.content.offset.value) == env.name) {
                    
                     console.log(data)
                     //this.openLoadingModal(this.jsonWord.text.updatingDevice)//'Adicionando gateway...')
                     
                   }
                 })*/
              })
            }

          }
        })
  }

  openLoadingModal(message: String) {
    this.loadingModalRef = this.modalService.show(
      LoadingModalComponent, {
        class: 'waiting-modal modal-sm',
        keyboard: false,
        backdrop: 'static'
      }
    )
    this.loadingModalRef.content.message = message;
  }

  private closeAllModals() {
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

}
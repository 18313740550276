import { HttpClient } from '@angular/common/http';
import { GatewayService } from './gateway.service';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { timeoutWith } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { Injectable, OnInit } from '@angular/core';
import * as config from '../config';

const url = config.serverURL;//+ 'ecomfort/v1';
const urlConfig = config.serverURL + '/gateway/'
const TIMEOUT: number = 10000 // 10 seconds

@Injectable()

export class LogsService {
    constructor(
        private http: HttpClient,
        private gatewayService: GatewayService){}

        listLogs(type,page,limit) {
            
             return this.http.get(urlConfig + this.gatewayService.currentGateway.id + '/history/'+type+'/'+page+'/'+limit)
          }


      
}
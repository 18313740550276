import { DirectivesModule } from './../directives.module';
import { AppRoutingModule } from './../../app-routing.module';
import { CardGroupModule } from './card/card-group.module';
import { RouterModule } from '@angular/router';
import { ClickOutsideModule } from 'ng-click-outside';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GroupModalComponent } from './group-modal.component';



@NgModule({
  imports: [
    AppRoutingModule,
    CommonModule,
    DragDropModule,CardGroupModule,
    DirectivesModule,ClickOutsideModule,
    FormsModule,
    Ng2SearchPipeModule,

    ReactiveFormsModule,
    RouterModule
  ],
  declarations: [
    GroupModalComponent
  ],
  providers: [],
  exports: [GroupModalComponent] ,
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
] 
})
export class GroupModalModule { }
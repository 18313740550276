export class Device {
  _id: string;
  name: string;
  ieeeAddr: string;
  epList: Array<number>;
  nwkAddr: string;
  profId: string;
  devId: string;
  modelId: string;
  status: string;
  manufId: string;
  manufName: string;
  powerSource: string;
  type: string;
  attributes: any;
  environment: {
    _id: string;
    name: string;
  };
  blockedUsers:any;
  isActive: boolean;
  created: Date;
  lastUpdate: Date;
  associatedDevices: [any];
  blocked: boolean;
  envName:string;
  invertOnOff:boolean=false;
  batteryLevel: string;
  isBluetooth:boolean;
  favoriteDevice:boolean=false;

  constructor(newDevice?) {
    if (newDevice) {
      this._id = newDevice._device ? newDevice._device : newDevice.ieeeAddr;
      this.name = newDevice.name ? newDevice.name : null;
      this.ieeeAddr = newDevice.ieeeAddr ? newDevice.ieeeAddr : null;
      this.nwkAddr = newDevice.nwkAddr ? newDevice.nwkAddr : null;
      this.epList = newDevice.epList ? newDevice.epList : null;
      this.type = newDevice.type ? newDevice.type : null;
      this.devId = newDevice.devId ? newDevice.devId : null;
      this.attributes = newDevice.attributes;
      this.blockedUsers = newDevice.blockedUsers ? newDevice.blockedUsers : []
      this.environment = newDevice.environment ? newDevice.environment : {};
      this.invertOnOff = newDevice.invertOnOff ? newDevice.invertOnOff : false;
      this.favoriteDevice = newDevice.favoriteDevice
    //  if(this.ieeeAddr == '772')  this.isActive = !newDevice.invertOnOff ? newDevice.isActive : !newDevice.invertOnOff
      this.isActive = newDevice.isActive;
      this.isBluetooth = newDevice.isBluetooth ? newDevice.nwkAddr : false;
      this.status = newDevice.status ? newDevice.status.toLowerCase() : '';
      this.blocked = newDevice.blocked ? newDevice.blocked : false;
      this.associatedDevices = newDevice.associatedDevices ? newDevice.associatedDevices : null;
      this.created = newDevice.createdAt ? newDevice.createdAt : null
      this.envName = this.environment.name ? this.environment.name : "";
      if((newDevice.devId == '10' || newDevice.type == "EndDevice")){
        this.batteryLevel = newDevice.attributes && newDevice.attributes['genPowerCfg#batteryPercentageRemaining'] ?  String(newDevice.attributes['genPowerCfg#batteryPercentageRemaining']) : newDevice.attributes
      }

    }
  }

}

import { UserService } from "./../_services/user.service";
import { Component, OnInit, OnDestroy, Renderer2, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { LoginService } from "./login.service";
import "rxjs/add/operator/takeWhile";
import { AlertComponent } from "./../_directives/alert.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { TranslateJsonService } from "../_services/translate-json.service";

@Component({
  moduleId: module.id,
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit, OnDestroy {
  public modalRef: BsModalRef;
  private alive = true;
  public today = new Date();

  loginError = false;
  model: any = {};
  loading = false;
  returnUrl: string;
  language;
  jsonWord;
  selectedLanguage = "pt";
  languageSelected;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private modalService: BsModalService,
    private translateJson: TranslateJsonService,
    private renderer: Renderer2,
    private userService: UserService
  ) {
    //document.getElementsByClassName('random-bg')[0].style.backgroundImage = 'url(assets/img/bg/3.jpg/)';
    renderer.setStyle(
      document.getElementsByTagName("body")[0],
      "background-image",
      "url(/assets/img/bg/3.jpg)"
      //'url(/assets/img/bg/xmas.png)'
    );
  }

  ngOnInit() {
    /*if(/^((?!chrome|android).)*firefox/i.test(navigator.userAgent)){
      alert("eh explorer")
    }else alert("nao eh explorer")*/
    this.router.navigate(["/login"], { skipLocationChange: true });
    // reset login status
    this.loginService.logout();
    this.languageSelected
      ? localStorage.setItem("language", this.languageSelected)
      : localStorage.setItem("language", "pt");
    this.language = localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "pt";
    this.jsonWord = this.translateJson.changeLanguage(this.language);
  }

  openConfirmDialog(message) {
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.buttonOk = {
      show: true,
      text: this.jsonWord.button.ok
    }; //'OK' };
    this.modalRef.content.title = this.jsonWord.alert.loginError; //'Não foi possível efetuar o login';
    this.modalRef.content.type = "remove";
    this.modalRef.content.message = /*'<i class="fa fa-lg fa-times text-danger"> </i> ' +*/ message;
  }

  switchLanguage(selectedLanguage) {
    this.languageSelected = selectedLanguage;
    localStorage.setItem("language", selectedLanguage);
    this.ngOnInit();
  }

  login() {
    this.loginError = true;
    this.loading = true;

    const loginData = {
      email: this.model.email,
      password: this.model.password,
      session: {
        deviceId: ".",
        tokenId: ".",
        platform: "web",
        language: localStorage.getItem("language")
          ? localStorage.getItem("language")
          : "pt"
      }
    };
    this.loginService.login(loginData).subscribe(
      data => {
        if (data["message"]) {
          this.loginError = true;
          this.loading = false;
          this.openConfirmDialog(data["message"]);
        } else {
          this.loginError = false;
          //  console.log(userId,sessionData.language,response.data.token.token)
          let body = {
            language: data["language"] ? data["language"] : "pt",
            session: {
              tokenId: ".",
              deviceId: "."
            }
          };
          this.userService.updateSessionUser(data["userId"], body); //.subscribe(res => {console.log("RESPOSTA", res)})
          this.router.navigateByUrl("/home/panel");
        }
      },
      error => {
        console.log("erro", error);
        let message = "";
        switch (error.status) {
          case 0:
            message = this.jsonWord.error.timeoutServer; //'Campos de email ou senha inválidos.'
            break;
          case 400:
            message = this.jsonWord.error.invalidAuth; //'Campos de email ou senha inválidos.'
            break;
          case 401:
            message = this.jsonWord.error.incorrectEmailPassword; //'E-mail ou senha incorretos.'
            break;
          case 403:
            message = this.jsonWord.error.incorrectEmailPassword; //'E-mail ou senha incorretos.'
            break;
          case 408:
            message = this.jsonWord.error.timeoutServer; //'Tempo limite de conexão atingido.'
            break;
          case 429:
            message = this.jsonWord.toast.tooManyRequest; //'Tempo limite de conexão atingido.'
            break;
          case 500:
            message = this.jsonWord.alert.loginError; //'Erro ao efetuar login.'
            break;
          default:
            message = this.jsonWord.error.failOperation;
        }
        this.openConfirmDialog(message);
        this.loading = false;
      }
    );
  }

  createAccount() {
    this.router.navigate(["register"]);
  }

  forgotPassword() {
    this.router.navigate(["forgot"]);
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}

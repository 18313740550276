import { LoadingModalComponent } from './../_directives/loading-modal/loading-modal.component';
import { TranslateJsonService } from './../_services/translate-json.service';
import { HomeComponent } from './../home/home.component';
import { IMqttMessage } from 'ngx-mqtt';
import { AlertComponent } from './../_directives/alert.component';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import { UserService } from './../_services/user.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { User } from '../models/user.model';
import { Location } from '@angular/common';
import { GatewayService } from '../_services/gateway.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
  providers: [UserService]
})
export class UserComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  users;
  user;
  userData;
  public modalRef: BsModalRef;
  private loadingModalRef: BsModalRef;
  uuid = require('uuid/v4');
  public loading: Boolean = false;
  currentUser;
  public currentUserType = ''
  public language;
  public jsonWord;
  currentGateway
  sortedUsers
  constructor(
    private _usersService: UserService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private modalService: BsModalService,
    private readonly location: Location,
    public homeComponent: HomeComponent,
    private translateJson: TranslateJsonService,
    private gatewayService: GatewayService
  ) { }

  ngOnInit() {
    this.language = localStorage.getItem('language')
    this.location.replaceState('/')
    this.jsonWord = this.translateJson.changeLanguage(this.language)
    this.currentUser = localStorage.getItem('currentUserId')
    this.currentGateway = localStorage.getItem('currentGateway')
    this.currentUserType = this.homeComponent.currentUserType;//JSON.parse(localStorage.getItem('currentRole'))

    this.gatewayService.gatewayObservable
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        gateway => {
          if (gateway) {
            this.loading = true
            this.updateAll()
          }
        }
      )
  }

  deactivateUser(user){
    let status = user.status == 'ENABLED' ? 'DISABLED' : 'ENABLED';
    let body=[{
      "op": "replace",
      "path": "/registeredUsers/",
      "value": {
        "role": user.role,
        "status":user.status == 'ENABLED' ? 'DISABLED' : 'ENABLED'
      }
    }]
    this._usersService.invite(body, user.email).subscribe(res=>{
      
    user.status = status
      
    }, err => {
      if (err.status == 0 || err.status == 408) {
        this.modalRef = this.modalService.show(AlertComponent);
        this.modalRef.content.type = 'remove'
        this.modalRef.content.title = /*"<i class='fa fa-lg fa-times text-danger'> </i> " +*/ this.jsonWord.error.conectionError
        this.modalRef.content.message = this.jsonWord.error.noConnection
        this.modalRef.content.isDelete = false
        this.modalRef.content.typeB = 'refresh'
        //"Tempo limite de conexão com o servidor atingido. Verifique o status do servidor recarregue a página novamente."
        this.modalRef.content.buttonConfirm = { show: true, text: /*"<i class='fa fa-refresh'></i>" + '  ' + */this.jsonWord.label.reload }
        this.modalRef.content.onClose.subscribe(
          res => {
            if (res)
              window.location.reload()
          }
        )
        }
    })
  }

  openLoadingModal(message: String) {
    this.loadingModalRef = this.modalService.show(
      LoadingModalComponent, {
        class: 'waiting-modal modal-sm',
        keyboard: false,
        backdrop: 'static'
      }
    )
    this.loadingModalRef.content.message = message;
  }

  updateAll() {
    this.location.replaceState('/');
    this.loading = true
    let sort1, sort2
    this._usersService.listUsers()
      .subscribe((users) => {
        this.currentUser = localStorage.getItem('currentUserId');
        this.loading = false
        let hold;
        this.users = users.sort((a,b)=>(a._user.name < b._user.name ? -1 : a._user.name > b._user.name ? 1 : 0));
        for (let user of users) {
          if (this.currentUser == user._user._id) {
            this.currentUserType = user.role;
          }
        }
      },
        err => {
          console.log(this.jsonWord.error.dataError + ' ' + this.jsonWord.text.user, err);
          /*if (err.status === 401) {
            this.router.navigate(['/login']);
          }*/

          this.homeComponent.openErrorAlert(err)
        });
  }

  resendInvite(user) {
    //console.log("teste" , user)
    let email = {
      "email": user.email
    }
    this.gatewayService.resendInvite(email).subscribe(res => {
      this.openLoadingModal(this.jsonWord.loading.message)
      this.loadingModalRef.content.message = this.jsonWord.loading.sendingInvite
      if (res) {
        this.loadingModalRef.content.success = true
        setTimeout(() => {
          if (this.loadingModalRef.content.success)
            this.loadingModalRef.content.message = this.jsonWord.toast.resendInvitationWasSuccess
          this.loadingModalRef.content.waiting = false
          this.loadingModalRef.hide()
        }, 2000)

      } else {
        this.loadingModalRef.content.waiting = false
        this.loadingModalRef.content.success = false
        this.loadingModalRef.hide()
      }
    }, err => {
        if (err.status == 0 || err.status == 408) {
          this.modalRef = this.modalService.show(AlertComponent);
          this.modalRef.content.type = 'remove'
          this.modalRef.content.title = /*"<i class='fa fa-lg fa-times text-danger'> </i> " +*/ this.jsonWord.error.conectionError
          this.modalRef.content.message = this.jsonWord.error.noConnection
          this.modalRef.content.isDelete = false
          this.modalRef.content.typeB = 'refresh'
          //"Tempo limite de conexão com o servidor atingido. Verifique o status do servidor recarregue a página novamente."
          this.modalRef.content.buttonConfirm = { show: true, text: /*"<i class='fa fa-refresh'></i>" + '  ' + */this.jsonWord.label.reload }
          this.modalRef.content.onClose.subscribe(
            res => {
              if (res)
                window.location.reload()
            }
          )
          }
    })
  }

  goToPage(){
    this.gatewayService.getMaxLimitEntity(this.currentGateway, 'user').subscribe(res => {

      if (res.availableCreation) {
        this.router.navigate(['home/users/edit/'])
      } else {
        this.modalRef = this.modalService.show(AlertComponent);
        this.modalRef.content.buttonCancel = { show: false };
        this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
        this.modalRef.content.title = this.jsonWord.alert.attention
        this.modalRef.content.type = 'warning'
        this.modalRef.content.message = this.jsonWord.toast.limitUserExceeded
        //}*/
      }
    }, err => {
      if (err.status == 0 || err.status == 408) {
        this.modalRef = this.modalService.show(AlertComponent);
        this.modalRef.content.type = 'remove'
        this.modalRef.content.title = /*"<i class='fa fa-lg fa-times text-danger'> </i> " +*/ this.jsonWord.error.conectionError
        this.modalRef.content.message = this.jsonWord.error.noConnection
        this.modalRef.content.isDelete = false
        this.modalRef.content.typeB = 'refresh'
        //"Tempo limite de conexão com o servidor atingido. Verifique o status do servidor recarregue a página novamente."
        this.modalRef.content.buttonConfirm = { show: true, text: /*"<i class='fa fa-refresh'></i>" + '  ' + */this.jsonWord.label.reload }
        this.modalRef.content.onClose.subscribe(
          res => {
            if (res)
              window.location.reload()
          }
        )
        }
      })
  }

  deleteUser(user, table) {
    let imgUser = user._user.profileImage ? user._user.profileImage : '../assets/img/avatar.jpg'
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
    this.modalRef.content.imagem = { show: true, text: imgUser };
    // tslint:disable-next-line:max-line-length
    this.modalRef.content.title = user._user.name/*'\
    <br>\
    <span><img src='+ imgUser + ' width="30"/></span>&nbsp;&nbsp;\
    <span><b>' +  + '</b></span>\
    <br>\
    ';*/
    this.modalRef.content.message = this.jsonWord.alert.messageRemoveUserOfGateway + ' ' + user._user.name + '?'/*
    <p> '<b>';*/
    if (this.modalRef.content.buttonCancel) {

    }
    this.modalRef.content.onClose.subscribe(result => {
      if (result) {
        this._usersService.deleteUser(user._user._id).subscribe((message) => {
          this.openLoadingModal(this.jsonWord.loading.savingChanges)
          //this.loadingModalRef.content.message="aguarde"
          if (message) {
            this.loadingModalRef.content.success = true
            setTimeout(() => {
              if (this.loadingModalRef.content.success)
                this.loadingModalRef.content.message = this.jsonWord.success.disassociatedUser
              this.loadingModalRef.content.waiting = false
              this.loadingModalRef.hide()
              this.updateAll()
            }, 2000)

          }
        },
          err => {
            this.loadingModalRef.content.message = this.jsonWord.error.dataError + ' ' + this.jsonWord.text.user
            this.loadingModalRef.content.waiting = false
            this.loadingModalRef.content.success = false
            setTimeout(() => {
              if (!this.loadingModalRef.content.success)
                this.loadingModalRef.hide()
            }, 2000)
          });

      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }
  /*public unsafePublish(topic: string, message: string): void {
    this._mqttService.unsafePublish(topic, message, { qos: 1, retain: false });
  }*/

}

import { ScenarioService } from './../../_services/scenario.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './../../app-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SceneConfigComponent } from './scene-config.component';
import { DirectivesModule } from '../../_directives/directives.module';
import { CollapseComponent } from 'app/_directives/collapse-component/collapse.component';


@NgModule({
  imports: [
    AppRoutingModule,
    CommonModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    SceneConfigComponent
  ],
  providers: [ScenarioService],
  exports: [SceneConfigComponent]
})
export class SceneConfigModule { }

import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from './../login/login.service';
import { Subject } from 'rxjs/Subject';
import { Injectable } from '@angular/core';
//import { Http, Headers, RequestOptions, RequestMethod } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Gateway } from '../models/gateway.model';
import { timeout, finalize, timeoutWith } from 'rxjs/operators';
import * as config from '../config';

const url = config.serverURL;//+ 'ecomfort/v1';
const urlConfig = config.serverURL + '/gateway/' //+ localStorage.getItem('currentGateway')
const TIMEOUT: number = 10000 // 10 seconds

@Injectable()
export class GatewayService {
  returnUrl
  public currentGateway: Gateway = new Gateway()
  public myGateways: Array<Gateway> = new Array<Gateway>()
  public gatewayObservable = new BehaviorSubject<Gateway>(null)
  public subscription
  public currentUserId;
  private destroyed: Boolean = false
  constructor(
    private http: HttpClient,
    private router: Router,
    private _loginService: LoginService,
    private cookieService: CookieService,
    private route: ActivatedRoute,
  ) {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/login';
    /* this.gatewayObservable.subscribe(
       (gateway: Gateway) => {
         if (gateway) {
           console.log(gateway)
           this.currentGateway = gateway
           localStorage.setItem('currentGateway', gateway.id)
           //localStorage.setItem('currentGatewayName', gateway.name)
           let userId = localStorage.getItem('currentUserId')
           if (cookieService.get(userId)) {
             var sessionData = JSON.parse(cookieService.get(userId))
             sessionData.currentGateway = gateway.id
           }
           cookieService.set(userId, JSON.stringify(sessionData))
         }
       }
     )*/
    this.makeSubscription()

  }

  public makeSubscription() {
    this.subscription = this.gatewayObservable.pipe(finalize(() => { console.log("Finalize"); }))
      .subscribe((gateway: Gateway) => {
        if (gateway) {
          this.currentGateway = gateway
          const currentUser = JSON.parse(localStorage.getItem('currentUser'));
          localStorage.setItem('currentGateway', gateway.id)
          //localStorage.setItem('currentGatewayName', gateway.name)
          let userId = localStorage.getItem('currentUserId')
          // console.log("ANTES DO COOKIE", gateway, userId, this.cookieService.get(userId))
          var sessionData = this.cookieService.get(userId) ? JSON.parse(this.cookieService.get(userId)) : [];
          if (sessionData && (sessionData['currentGateway'] || sessionData['currentEnvironment'] || sessionData['language'])){
            sessionData['currentGateway'] = gateway.id
            sessionData['language'] = currentUser.language
          } else{
         // this.cookieService.set(userId, JSON.stringify(sessionData))
         this.cookieService.set(userId, JSON.stringify({ currentGateway: gateway.id, currentEnvironment: '', language: currentUser.linguagem ? currentUser.linguagem : 'pt' }))
          }
        }
      })
  }
  public getGateway(): Gateway {

    return this.currentGateway
  }

  public setGateway(gateway: Gateway) {
    // if ((gateway && this.currentGateway.id != gateway.id) || !gateway)
    //localStorage.setItem('currentGateway',gateway.id)
    this.gatewayObservable.next(gateway)
    this.makeSubscription()
  }
  getGatewayById(id) {
    return this.http.get((url + '/gateway/' + id));
  }


  listGatewayById(gatewayId:string){
    return this.http.get((url + '/gateway/' + gatewayId));
  }

  updateGateway(gateway, id) {
    return this.http.put((url + '/gateway/' + id), gateway);
  }

  updateListSorted(body,email){
      let gateway = localStorage.getItem("currentGateway")
      return this.http.patch(urlConfig + gateway, body, { params: { email } })
          .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
              new Response(null, {
                  status: 408, statusText: 'Tempo limite de resposta atingido'
              })
          )))
  }

  resendMqtt(gateway, body) {
    return this.http.post((url + '/gateway/' + gateway + '/resendMqtt'), body);
  }
  addGateway(data) {
    return this.http.post(url + '/gateway', data)
      .map(res => res['data']);
  }

  resetGateway(data, id?: string) {
    let options = {
      body: data
    }
    return this.http.request('DELETE', url + '/gateway/' + (this.currentGateway.id), options)//(id ? id : this.currentGateway.id), options);
  }

  resendInvite(email) {
    return this.http.post((url + '/gateway/' + (this.currentGateway.id) + '/resendInvite'), email)
  }

  deleteGateway(id, userId) {
    return this.http.request('DELETE', url + '/gateway/' + (id) + '/user/' + (userId));
  }

  getMaxLimitEntity(id, entity) {
    return this.http.request('GET', url + '/gateway/' + (id) + '/settings/limit/' + entity).map(res => res['data']);
  }

}
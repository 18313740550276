import { TranslateJsonService } from './../../_services/translate-json.service';
import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { PanelComponent } from '../../panel/panel.component';
import { v4 as uuid } from 'uuid';
import * as config from '../../config';
import { ResponseService } from '../../_services/response.service';

@Component({
  selector: 'app-smartlock-modal',
  templateUrl: './smartlock-modal.component.html',
  styleUrls: ['./smartlock-modal.component.css']
})
export class SmartlockModalComponent implements OnInit {

  public title: any;
  public message: any;
  public card: any;
  private selectedDevices: Array<any> = [];
  public isListening: boolean;
  public option//: any = '0';
  @Input() value;
  public led
  public buzzer
  public uuid = require('uuid/v4');
  public buttonCancel: any = {
    show: false,
    text: 'CANCELAR'
  };
  public buttonOk: any = {
    show: false,
    text: 'OK'
  };
  deviceTopic
  currentGateway
  device
  devicesList
  language;
  jsonWord;
  /*public buttonCancel: any = {
    show: true,
    text: 'Cancelar'
  };
  public buttonOk: any = {
    show: true,
    text: 'Configurar'
  };*/
  public onClose: Subject<any>;
  public waiting: Boolean = true;
  public success: Boolean //= true;
  public loadingModalRef: BsModalRef;

  constructor(private _bsModalRef: BsModalRef,private responseService:ResponseService,
    //public panelComponent: PanelComponent,
    private _mqttService: MqttService,public translateJson : TranslateJsonService) {
  }

  public ngOnInit(): void {
    this.language = localStorage.getItem('language')
    this.jsonWord = this.translateJson.changeLanguage(this.language);
    this.devicesList = this.card
    this.card = this.card
    //this.buzzer=this.card.value2 == "2" ? true : false
    //this.led=this.card.value3 == "2" ? true : false 
    //this.device = this.card
    this.device = this.devicesList
    //this.filteredDevicesList = this.devicesList.filter(device => device.devId == 25 || device.devId == 6)
    this.onClose = new Subject();
    this.currentGateway = localStorage.getItem("currentGateway")//this.panelComponent.currentGateway
  }

  setLed(led, card) {
    this.led = led.checked;
  }

  setBuzzer(buzzer, card) {
    this.buzzer = buzzer.checked;
  }

  getValueAutoLock(autoLock,card) {
    /*let values = <any>document.getElementsByName('autoLock')
    for (let value of values) {
      if (value.checked) {
        this.option = value.value;
      }
    }*/
    this.option = autoLock.checked
  }

  buildConfigPayload() {
    let getBuzzerLedStatus = <any>document.getElementsByClassName('checkbox')
    let message = {
      "messageId": this.uuid(),
      "timestamp": (Date.now()),
      // "devId": this.card.devId,
      "led": this.led ? "1" : "0", //ligado
      "buzzer": this.buzzer ? "1" : "0", //desligado
      "autolock": (this.option) ? "60" : "0"
    }
    return message;
  }

  @HostListener('window:keyup', ['$event'])
  keyUpEvent(e) {
    e.stopPropagation()
    if (e.keyCode == 13) {
      this.onConfirm()
    }
  }

  public onConfirm(): void {
    this.deviceTopic = `${config.gtwTopic}${this.currentGateway}/device/${this.card.devIeeeAddr}/write`
    this.onClose.next(this._mqttService.unsafePublish(this.deviceTopic, JSON.stringify(this.buildConfigPayload())));
   
    this.responseService.responseStatusSettingsSL(this.card.devIeeeAddr, 'write', '/status')
    this._bsModalRef.hide();
  }

  public onCancel(): void {
   // this.onClose.next(null);
    this._bsModalRef.hide();
  }

  onUnbindAll(): void {

  }
}

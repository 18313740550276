import { RuleService } from './../../_services/rule.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './../../app-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RuleConfigComponent } from './rule-config.component';
import { DirectivesModule } from '../../_directives/directives.module';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap';
import { DateTimeFormatPipe } from './pipe-date';

@NgModule({
  imports: [
    AppRoutingModule,
    CommonModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule, 
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    DatepickerModule.forRoot() 
  ],
  declarations: [
    RuleConfigComponent,DateTimeFormatPipe
  ],
  providers: [RuleService],
  exports: [RuleConfigComponent,DateTimeFormatPipe]
})
export class RuleConfigModule { }

import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { LoadingModalComponent } from './../_directives/loading-modal/loading-modal.component';
import { TranslateJsonService } from './translate-json.service';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { timeoutWith, finalize } from 'rxjs/operators';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import * as config from '../config';
import { SceneComponent } from '../scene/scene.component';

const url = config.serverURL;//+ 'ecomfort/v1';
const urlConfig = config.serverURL + '/gateway/'
const TIMEOUT: number = 5000 // 5 seconds
const TIMEOUTSL: number = 15000 // 5 seconds
const TIMEOUTDEV: number = 15000 //15seg

@Injectable()

export class ResponseService implements OnInit {
  private loadingModalRef: BsModalRef
  private ngUnsubscribe = new Subject();
  private currentGateway;
  private jsonWord;
  private language;
  constructor(
    private _mqttService: MqttService,
    private translateJson: TranslateJsonService,
    private modalService: BsModalService,
    private router: Router,
  ) {
    this.currentGateway = localStorage.getItem('currentGateway')
    this.language = localStorage.getItem('language')
    this.jsonWord = this.translateJson.changeLanguage(this.language)
  };

  ngOnInit() { }
  responseStatusDevice(object, operation, topic, response) {
    let objeto = object ? object._id : null
    let teste = config.gtwTopic + this.currentGateway + '/device/#'// + objeto + topic //: config.gtwTopic + this.currentGateway + '/device/#'
    let messageResponse = null
    this.openLoadingModal(this.jsonWord.loading.message)
    return this._mqttService.observe(teste)
      .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
      .filter(message => message['operation'] == operation && message['data'].ieeeAddr == objeto)
      .map(message => {
        messageResponse = 'sucesso'
        return message
      })
      .merge(
        this._mqttService.observe(config.gtwTopic + this.currentGateway + '/error')
          .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
          .filter(message => message['operation'] == operation && message['data'].ieeeAddr != objeto)
          .map(message => {
            messageResponse = 'error'
            return message
          })
      )
      .pipe(finalize(() => {
        if (messageResponse == 'sucesso' || response) {
          this.loadingModalRef.content.success = true
          this.loadingModalRef.content.waiting = false
          switch (operation) {
            case 'edit':
              this.loadingModalRef.content.message = this.jsonWord.toast.editDeviceSucess
              break;
            case 'delete':
              this.loadingModalRef.content.message = this.jsonWord.toast.successRemoveDevice
          }
        } else {
          this.loadingModalRef.content.success = false
          this.loadingModalRef.content.waiting = false
          switch (operation) {
            case 'edit':
              this.loadingModalRef.content.message = this.jsonWord.toast.errorEditDevice
              break;
            case 'delete':
              this.loadingModalRef.content.message = this.jsonWord.toast.errorRemoveDevice
              break;
          }
        }
        if (this.loadingModalRef) {
          this.loadingModalRef.content.waiting = false
        }
        setTimeout(() => {
          this.loadingModalRef.hide()
          this.closeAllModals()
        }, 2000)
      }), timeoutWith(12000, ErrorObservable.throw(
        new Response(null, {
          status: 408, statusText: this.jsonWord.error.timeoutServer/*'Não foi possível completar a operação: o dispositivo demorou muito para responder.<br>\
                Verifique se o dispositivo está ligado e corretamente pareado com o gateway'*/
        })
      ))).take(1)
  }

  responseStatusEnvironment(object, operation, topic, response) {
    let objeto = object ? object._id : null
    let teste = objeto ? config.gtwTopic + this.currentGateway + '/environment/' + objeto + topic : config.gtwTopic + this.currentGateway + '/environment/#'
    let messageResponse
    this.openLoadingModal(this.jsonWord.loading.message)

    this._mqttService.observe(teste)
      .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
      .filter(message => message['operation'] == operation)
      .map(message => {
        messageResponse = 'sucesso'
        return message
      })
      .merge(
        this._mqttService.observe(config.gtwTopic + this.currentGateway + '/error')
          .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
          .filter(message => message['operation'] == operation)
          .map(message => {
            messageResponse = 'error'
            return message
          })
      )
      .pipe(finalize(() => {
        //  console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&", response)
        if (messageResponse == 'sucesso' || response) {
          this.loadingModalRef.content.success = true
          this.loadingModalRef.content.waiting = false
          switch (operation) {
            case 'add':
              this.loadingModalRef.content.message = this.jsonWord.toast.addEnvironmentSucess
              break;
            case 'edit':
              this.loadingModalRef.content.message = this.jsonWord.toast.editEnvironmentSucess
              break;
            case 'delete':
              this.loadingModalRef.content.message = this.jsonWord.toast.successToRemoveEnvironment
              break;
          }
        } else {
          this.loadingModalRef.content.success = false
          this.loadingModalRef.content.waiting = false
          switch (operation) {
            case 'add':
              this.loadingModalRef.content.message = this.jsonWord.toast.errorAddEnvironment
              break;
            case 'edit':
              this.loadingModalRef.content.message = this.jsonWord.toast.errorEditEnvironment
              break;
            case 'delete':
              this.loadingModalRef.content.message = this.jsonWord.toast.errorRemoveEnvironment
              break;
          }
        }
        if (this.loadingModalRef) {
          this.loadingModalRef.content.waiting = false
        }
        setTimeout(() => {
          this.loadingModalRef.hide()
          this.closeAllModals()
        }, 2000)
      }), timeoutWith(TIMEOUT, ErrorObservable.throw(
        new Response(null, {
          status: 408, statusText: this.jsonWord.error.timeoutServer/*'Não foi possível completar a operação: o dispositivo demorou muito para responder.<br>\
                Verifique se o dispositivo está ligado e corretamente pareado com o gateway'*/
        })
      ))).take(1).subscribe(_ => {
      },
        err => {
          this.loadingModalRef.content.message = err.message ? err.message : err.statusText
          setTimeout(() => {
            this.loadingModalRef.hide()
          }, 8000)
        })
  }

  responseStatusScenario(object, operation, topic) {
    let objeto = object ? object._id : null
    let teste = objeto ? config.gtwTopic + this.currentGateway + '/scenario/' + objeto + topic : config.gtwTopic + this.currentGateway + '/scenario/#'
    let messageResponse;
    this.openLoadingModal(this.jsonWord.loading.message)
    //  this._mqttService.observe(teste)
    //  .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload))).subscribe(res => console.log(res))
    return this._mqttService.observe(teste)
      .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload))) //if imqttmessage?
      .filter(message => message['operation'] == operation)
      .map(message => {
        messageResponse = 'sucesso'
        return message
      })
      .merge(
        this._mqttService.observe(config.gtwTopic + this.currentGateway + '/error')
          .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
          .filter(message => message['operation'] == operation)
          .map(message => {
            messageResponse = 'erro'
            return message
          })
      )
      .pipe(finalize(() => {
        if (messageResponse == 'sucesso') {
          this.loadingModalRef.content.success = true
          this.loadingModalRef.content.waiting = false
          switch (operation) {
            case 'add':
              this.loadingModalRef.content.message = this.jsonWord.toast.successScenario
              break;
            case 'edit':
              this.loadingModalRef.content.message = this.jsonWord.toast.successEditScenario
              break;
            case 'delete':
              this.loadingModalRef.content.message = this.jsonWord.toast.successRemoveScenario
              break;
          }
        } else {
          this.loadingModalRef.content.success = false
          this.loadingModalRef.content.waiting = false
          switch (operation) {
            case 'add':
              this.loadingModalRef.content.message = this.jsonWord.toast.errorScenario
              break;
            case 'edit':
              this.loadingModalRef.content.message = this.jsonWord.toast.errorEditScenario
              break;
            case 'delete':
              this.loadingModalRef.content.message = this.jsonWord.toast.errorToRemoveScenario
              break;
          }
        }
        if (this.loadingModalRef) {
          this.loadingModalRef.content.waiting = false
        }
        setTimeout(() => {
          if (this.loadingModalRef) {
            this.loadingModalRef.hide()
            this.closeAllModals()
          }
          this.router.navigate(['home/scenarios']);
        }, 2000)
      }), timeoutWith(TIMEOUT, ErrorObservable.throw(
        new Response(null, {
          status: 408, statusText: this.jsonWord.error.timeoutServer/*'Não foi possível completar a operação: o dispositivo demorou muito para responder.\
                Verifique se o dispositivo está ligado e corretamente pareado com o gateway'*/
        })
      ))).take(1)/*.subscribe(_ => {
      },
        err => {
          console.log(err)
          this.loadingModalRef.content.message = err.message ? err.message : err.statusText
          setTimeout(() => {
            this.loadingModalRef.hide()
          }, 8000)
        })*/
  }

  responseStatusRule(object, operation, topic) {
    let objeto = object ? object : null
    let teste = objeto ? config.gtwTopic + this.currentGateway + '/rule/' + objeto + topic : config.gtwTopic + this.currentGateway + '/rule/#'
    let messageResponse = null
    this.openLoadingModal(this.jsonWord.loading.message)

    this._mqttService.observe(teste)
      .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload))).subscribe(res => {})

    return this._mqttService.observe(teste)
      .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
      .filter(message => message['operation'] == operation)
      .map(message => {
        messageResponse = 'sucesso'
        return message
      })
      .merge(
        this._mqttService.observe(config.gtwTopic + this.currentGateway + '/error')
          .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
          .filter(message => message['operation'] == operation)
          .map(message => {
            messageResponse = 'erro'
            return message
          })
      )
      .pipe(finalize(() => {
        if (messageResponse == 'sucesso') {
          this.loadingModalRef.content.success = true
          this.loadingModalRef.content.waiting = false
          switch (operation) {
            case 'add':
              this.loadingModalRef.content.message = this.jsonWord.toast.createRuleSuccess
              break;
            case 'edit':
              this.loadingModalRef.content.message = this.jsonWord.toast.editRuleSuccess
              break;
            case 'delete':
              this.loadingModalRef.content.message = this.jsonWord.toast.deleteRuleSuccess
              break;
          }
        } else {
          this.loadingModalRef.content.success = false
          this.loadingModalRef.content.waiting = false
          switch (operation) {
            case 'add':
              this.loadingModalRef.content.message = this.jsonWord.toast.createRuleError
              break;
            case 'edit':
              this.loadingModalRef.content.message = this.jsonWord.toast.editRuleError
              break;
            case 'delete':
              this.loadingModalRef.content.message = this.jsonWord.toast.errorToRemoveRule
              break;
          }
        }
        if (this.loadingModalRef) {
          this.loadingModalRef.content.waiting = false
        }
        setTimeout(() => {
          this.loadingModalRef.hide()
          this.closeAllModals()
          this.router.navigate(['home/rules']);
        }, 2000)
      }), timeoutWith(TIMEOUT, ErrorObservable.throw(
        new Response(null, {
          status: 408, statusText: this.jsonWord.error.timeoutServer/*'Não foi possível completar a operação: o dispositivo demorou muito para responder.\
                Verifique se o dispositivo está ligado e corretamente pareado com o gateway'*/
        })
      )))
      .take(1)/*.subscribe(_ => {
      },
        err => {
          console.log(err)
          this.loadingModalRef.content.message = err.message ? err.message : err.statusText
          setTimeout(() => {
            this.loadingModalRef.hide()
          }, 8000)
        })*/
  }

  openLoadingModal(message: String) {
    this.loadingModalRef = this.modalService.show(
      LoadingModalComponent, {
        class: 'waiting-modal modal-sm',
        keyboard: false,
        backdrop: 'static'
      }
    )
    this.loadingModalRef.content.message = message;
  }

  responseStatusSettingsSL(object, operation, topic) {
    let teste = config.gtwTopic + this.currentGateway + '/device/' + object + topic
    this.openLoadingModal(this.jsonWord.loading.message)
    this._mqttService.observe(teste)
      .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
      .map(message => {
        this.loadingModalRef.content.success = true
            this.loadingModalRef.content.message = this.jsonWord.label.settingSmartLock
        setTimeout(() => {
          if (this.loadingModalRef.content.success) {
            this.loadingModalRef.content.waiting = false
            this.loadingModalRef.hide()
          }
        }, 2000)
        return message
      })
      .merge(
        this._mqttService.observe(config.gtwTopic + this.currentGateway + '/error')
          .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
          .map(message => {
            this.loadingModalRef.content.success = false
                this.loadingModalRef.content.message = this.jsonWord.label.settingFailSmartLock
            setTimeout(() => {
              if (!this.loadingModalRef.content.success) {
                this.loadingModalRef.content.waiting = false
                this.loadingModalRef.hide()
              }
            }, 2000)
            return message
          })
      )
      .pipe(finalize(() => {
        this.loadingModalRef.content.waiting = false
        setTimeout(() => {
          if (this.loadingModalRef)
            this.loadingModalRef.hide()
        }, 2000)
      }), timeoutWith(TIMEOUTSL, ErrorObservable.throw(
        new Response(null, {
          status: 408, statusText: this.jsonWord.error.timeoutServer/*'Não foi possível completar a operação: o dispositivo demorou muito para responder.\
                Verifique se o dispositivo está ligado e corretamente pareado com o gateway'*/
        })
      )))
      .take(1)
      .subscribe(_ => {
      },
       /* err => {
          console.log(err)
          this.loadingModalRef.content.message = err.message ? err.message : err.statusText
          setTimeout(() => {
            this.loadingModalRef.content.waiting = false
            this.loadingModalRef.content.success = false
            this.loadingModalRef.hide()
          }, 8000)
        }*/)
  }

  
  private closeAllModals() {
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    }
  }

  ngOnDestroy() {
    this.loadingModalRef.hide()
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }
}

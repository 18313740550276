import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ClickOutsideModule } from 'ng-click-outside';
import { CookieService } from 'ngx-cookie-service';
import {
  IMqttServiceOptions, MqttModule, MqttService
} from 'ngx-mqtt';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import {
  ToastrModule
} from 'ngx-toastr';
import { AppBootstrapModule } from './app-bootstrap.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';
import { LoginModule } from './login/login.module';
import { LoginService } from './login/login.service';
import { UserModule } from './user/user.module';
import { Safe } from './_directives/alert.component';
import { DirectivesModule } from './_directives/directives.module';
import { SanitizeHtmlPipe } from './_directives/sanitizeHtmlPipe';
import { AuthGuard } from './_guards/auth.guard';
//import { HttpClientModule } from '@angular/http';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { AlertService } from './_services/alert.service';
import { DeviceService } from './_services/device.service';
import { LogsService } from './_services/logs.services';
import { ResponseService } from './_services/response.service';
import { RuleService } from './_services/rule.service';
import { ScenarioService } from './_services/scenario.service';
import { TranslateJsonService } from './_services/translate-json.service';

;


//import { EventListener } from '@angular/core/src/debug/debug_node';

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  protocol: "ws",
  hostname: 'mqtt.ecomfort.com.br', //window.location.
  port: 9001,
  path: '/ws',
  username: 'ecomfort-web',
  password: 'ecomfort*2018',
  cryptoKey: 'pixel',
  cryptoMethod: 'AES'
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
export function mqttServiceFactory() {
  return new MqttService(MQTT_SERVICE_OPTIONS);
}
/*export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}*/

@NgModule({
  declarations: [
    AppComponent,
    SanitizeHtmlPipe,Safe,
      ],
  imports: [
    AppRoutingModule,
    AppBootstrapModule,
    BrowserModule,
    DirectivesModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
   // HttpClientModule,
    HttpClientModule,
    HomeModule,
    LoginModule,
    UserModule,
    PerfectScrollbarModule,
    ClickOutsideModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS, undefined),//MQTT_SERVICE_OPTIONS),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      autoDismiss: true,
      timeOut: 8000,
      maxOpened:4,
      easing: 'ease-out',
      positionClass: 'toast-bottom-center',
      preventDuplicates:true,
      toastClass: 'toast custom-toast toast-error',
      closeButton: true
    })
  ],
  providers: [
    AuthGuard,
    AlertService,
    LoginService,
    DeviceService,
    ScenarioService,
    RuleService,
    ResponseService,
    LogsService,
    SanitizeHtmlPipe,Safe,
    TranslateJsonService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent],
  exports: [
    DirectivesModule,
    SanitizeHtmlPipe,Safe,
  ]
})
export class AppModule { }

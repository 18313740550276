import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './register/register.component';
import { AppRoutingModule } from '../app-routing.module';
import { LoginComponent } from './login.component';
import { LoginService } from './login.service';
import { ForgotComponent } from './forgot/forgot.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { DirectivesModule } from '../_directives/directives.module';

import { ImageCropperModule } from 'ngx-image-cropper';
@NgModule({
  imports: [
    AppRoutingModule,
    CommonModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,ImageCropperModule
  ],
  declarations: [
    RegisterComponent,
    LoginComponent,
    ForgotComponent,
    RecoveryComponent,
    ConfirmationComponent,
  ],
  exports: [
  ],
  providers: [
    LoginService
  ],
  bootstrap: []
})
export class LoginModule { }

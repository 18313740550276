import { GatewayService } from "./../_services/gateway.service";
import { Subject } from "rxjs/Subject";
import {
  Component,
  OnInit,
  ViewChild,
  SimpleChanges,
  ElementRef,
  ContentChild,
  Input
} from "@angular/core";
import { MqttService, IMqttMessage } from "ngx-mqtt";
import { timer } from "rxjs";
import { EnvironmentService } from "./../_services/environment.service";
import { DeviceService } from "./../_services/device.service";
import * as config from "../config";
import * as _ from "lodash";

import { BaseChartDirective, Color, Label } from "ng2-charts";
import { ChartDataSets, ChartOptions } from "chart.js";
//import { ChartDataSets, ChartOptions } from 'chart.js';
//import { Color, BaseChartDirective } from 'ng2-charts';
import * as hammer from "hammerjs";
import * as Chart from "chart.js";
import { Location } from "@angular/common";
import * as pluginZoom from "chartjs-plugin-zoom";
import { TranslateJsonService } from "app/_services/translate-json.service";
const utf8Decoder = new TextDecoder("utf-8");

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"]
})
export class DashboardComponent implements OnInit {
  smartP = [];
  temp = [];
  umid = [];
  sp = [];
  data = [];
  tempUmd = [];
  category = [];
  dataSource: Object;
  type: string;
  width: string;
  height: string;
  currentGateway;
  loading = true;
  dataset = [];
  public language;
  public jsonWord;
  private yRange: number = 0;
  private yRangeSubject: Subject<number>;

  constructor(
    public deviceService: DeviceService,
    private translateJson: TranslateJsonService,
    private readonly location: Location,
    private gatewayService: GatewayService,
    private _mqttService: MqttService
  ) {}

  ngOnInit() {
    this.location.replaceState("/");
    this.language = localStorage.getItem("language");
    this.jsonWord = this.translateJson.changeLanguage(this.language);
    this.currentGateway = localStorage.getItem("currentGateway");
    this.gatewayService.gatewayObservable
      .concatMap(gateway => {
        if (gateway) {
          this.currentGateway = gateway.id;
          //  this.loading = false
        }
        return this.deviceService.listDevices(this.currentGateway);
      }) /*.map((devices) => {
            this.dataset = []
            // this.chart.datasets=[]
            this.lineChartColors = []
            this.lineChartData = []
            this.smartP = []
            this.tempUmd = []
            this.category = []
            console.log("&&&&&&&&&&&7", devices)
            for (let device of devices) {
                switch (device.devId) {
                    case '81':
                        var letters = '0123456789ABCDEF';
                        var color = '#';
                        for (var i = 0; i < 6; i++) {
                            color += letters[Math.floor(Math.random() * 16)];
                        }
                        this.dataset.push({
                            "data": []
                        })
                        this.lineChartData.push({ data: [], label: device.name })
                        this.lineChartColors.push({
                            backgroundColor: color + '33',
                            borderColor: color,
                            pointBackgroundColor: color,
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: '#fff',
                            pointHoverBorderColor: color + 'cc' //cc
                        })
                        console.log("dataset", this.lineChartData, this.lineChartColors, "chart", this.chart);
                        this.smartP.push(device)
                    case '770':
                        var letters = '0123456789ABCDEF';
                        var color = '#';
                        for (var i = 0; i < 6; i++) {
                            color += letters[Math.floor(Math.random() * 16)];
                        }
                        this.dataset.push({
                            "data": []
                        })
                        this.lineChartData.push({ data: [], label: device.name })
                        this.lineChartColors.push({
                            backgroundColor: color + '33',
                            borderColor: color,
                            pointBackgroundColor: color,
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: '#fff',
                            pointHoverBorderColor: color + 'cc' //cc
                        })
                        console.log("dataset", this.lineChartData, this.lineChartColors, "chart", this.chart);
                        this.tempUmd.push(device)
                }

            }
            this.loading = false
            console.log(this.dataset)
        })*/
      .subscribe(_ => {});

    /*   const allDevicesTopic = config.gtwTopic + this.currentGateway + '/device/#';

        let devicesObserver = this._mqttService.observe(allDevicesTopic)
            .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
            .subscribe(payload => {
                let message = payload
                let op = payload.operation
                if (op === 'attReport') {
                   // this.messageDispatcher(message.data);
                }
            })

    }

   /* messageDispatcher(message) {
        console.log("EVENTOS: ", message)
        switch (message.cId) {
            case "msTemperatureMeasurement":
                if (message.type === 'measuredValue') {
                    let data = new Date().getHours() + ":" + new Date().getMinutes()
                    console.log(data)
                    let value;
                    value = Number(message.value) / 100;
                    this.temp.push({ ieeeAddr: message.ieeeAddr, value: value, label: data })
                    console.log("value>>>>>>>>>>>>>>>>>>>>>>>> ", this.tempUmd, value);
                    let temp = this.tempUmd.findIndex(temp => temp.ieeeAddr == message.ieeeAddr)
                    console.log(data, temp, this.tempUmd, "CHART", this.chart)
                    for (let i = 0; i < this.tempUmd.length; i++) {
                        if (temp == i) {
                            console.log(this.dataset)
                            this.dataset[i].data.push(value)
                            this.chart.datasets[i].data.push(value)
                            this.category.push(new Date().getHours() + ":" + new Date().getMinutes())
                        } else {
                            console.log(this.dataset)
                            let length = this.dataset[i] ? this.dataset[i].data.length : 0
                            let data = length > 0 ? this.dataset[i].data[length - 1] : 0
                            this.dataset[i].data.push(data)
                            this.chart.datasets[i].data.push(data)
                        }
                        /*  if(this.chart)
                          this.chart.chart.update()
                          else console.log("CHART AQUI",this.chart)
                    }
                    this.lineChartLabels = this.category
                    console.log(this.data)

                    //this.card.value1 = String(value.toFixed(1));
                }
                break;
        /*    case 'msRelativeHumidity':
                if (message.type === 'measuredValue') {
                    let data = new Date().getHours() + ":" + new Date().getMinutes()
                    console.log(data)
                    let value;
                    value = Number(message.value) / 100;
                    this.umid.push({ ieeeAddr: message.ieeeAddr, value: value, label: data })
                    console.log("value>>>>>>>>>>>>>>>>>>>>>>>> ", this.tempUmd, value);
                    let umid = this.tempUmd.findIndex(umid => umid.ieeeAddr == message.ieeeAddr)
                    console.log(data, umid, this.tempUmd, "CHART", this.chart)
                    for (let i = 0; i < this.smartP.length; i++) {
                        if (umid == i) {
                            this.dataset[i].data.push(value)
                            this.chart.datasets[i].data.push(value)
                            this.category.push(new Date().getHours() + ":" + new Date().getMinutes())
                        } else {
                            console.log(this.dataset)
                            let length = this.dataset[i] ? this.dataset[i].data.length : 0
                            let data = length > 0 ? this.dataset[i].data[length - 1] : 0
                            this.dataset[i].data.push(data)
                            this.chart.datasets[i].data.push(data)
                        }
                        /*  if(this.chart)
                          this.chart.chart.update()
                          else console.log("CHART AQUI",this.chart)
                    }
                    this.lineChartLabels = this.category
                    console.log(this.sp, this.data)

                    //   this.card.value2 = String(value.toFixed(1));
                }
                break;
            case "msIlluminanceMeasurement": {
            }
                break;
            case 'seMetering':
                if (message.type === 'instantaneousDemand') {
                    const value = (message.value / 1000);
                    console.log("value>>>>>>>>>>>>>>>>>>>>>>>> ", this.smartP, value);
                    let data = new Date().getHours() + ":" + new Date().getMinutes()
                    let sp = this.smartP.findIndex(sp => sp.ieeeAddr == message.ieeeAddr)
                    console.log(data, sp, this.dataset, "CHART", this.chart)
                    for (let i = 0; i < this.smartP.length; i++) {
                        if (sp == i) {
                            this.dataset[i].data.push(value)
                            this.chart.datasets[i].data.push(value)
                            this.category.push(new Date().getHours() + ":" + new Date().getMinutes())
                        } else {
                            console.log(this.dataset)
                            let length = this.dataset[i] ? this.dataset[i].data.length : 0
                            let data = length > 0 ? this.dataset[i].data[length - 1] : 0
                            this.dataset[i].data.push(data)
                            this.chart.datasets[i].data.push(data)
                        }
                        /*  if(this.chart)
                          this.chart.chart.update()
                          else console.log("CHART AQUI",this.chart)
                    }
                    this.lineChartLabels = this.category
                    console.log(this.sp, this.data)
                }
                break;
        }
    }

    resetZoom($event) {
        //console.log('this.resetZoom')
        this.chart.chart['resetZoom']();
        //this.chart.update()
        return false
    }*/
    // events
    /* public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
        console.log(event, active);
    }

    public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
        console.log(event, active);
    }
    */
  }
}

import { TranslateJsonService } from './../../_services/translate-json.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.css']
})
export class LoadingModalComponent implements OnInit {

  @HostListener('window:keyup', ['$event'])
  enterKeyUpEvent(e) {
    e.stopPropagation()
    if (e.keyCode == 27){
      this.modalRef.hide()
    }
  }

  public message: String //= 'Processando operação...'
  public waiting: Boolean = true
  public success: Boolean = false
  public onClose: Subject<any>;
  language;
  jsonWord;

  constructor(
    public modalRef: BsModalRef,
    public translateJson : TranslateJsonService
  ) {
    this.onClose = new Subject();
  }

  ngOnInit() {
   // $('modal-container').removeClass("fade");
    this.language = localStorage.getItem('language')
    this.jsonWord = this.translateJson.changeLanguage(this.language)
    //this.message = this.jsonWord.label.processingOperation
  }

  ngOnDestroy() {
    this.onClose.next()
    this.onClose.complete()
  /*  if (this._isShown) {
      this._isShown = false;
      this.hideModal();
    }*/
   // $(".modal-backdrop").remove();
  }
}

import { LoadingModalComponent } from './../_directives/loading-modal/loading-modal.component';
import { LogsService } from './../_services/logs.services';
import { Device } from './../models/device.model';
import { Subject } from 'rxjs/Subject';
import { EnvironmentCardModalComponent } from './../_directives/environment-card-modal/environment-card-modal.component';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { HomeComponent } from './../home/home.component';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { EnvironmentService } from './../_services/environment.service';

import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AlertComponent } from '../_directives/alert.component';
import 'rxjs/add/operator/takeWhile';
import * as config from './../config';
import * as moment from 'moment';
import { GatewayService } from '../_services/gateway.service';
import { finalize, timeoutWith, concatMap } from 'rxjs/operators';
import { TranslateJsonService } from '../_services/translate-json.service';
import { ResponseService } from '../_services/response.service';
import { DeviceService } from '../_services/device.service';

const utf8Decoder = new TextDecoder("utf-8")
const TIMEOUT = 15000
@Component({
  selector: 'app-environment',
  templateUrl: './environment.component.html',
  styleUrls: ['./environment.component.css']
})
export class EnvironmentComponent implements OnInit, OnDestroy {
  environments: any = [];
  private alive = true;
  public modalRef: BsModalRef;
  public loadingModalRef: BsModalRef;
  uuid = require('uuid/v4');
  envL = 0;
  message;
  currentGateway;
  loading = false
  currentUserType;
  public language;
  public jsonWord;
  devsEnvName = [];
  public deletingEnvironment;
  private ngUnsubscribe = new Subject();
  envMax;

  constructor(
    private _environmentService: EnvironmentService,
    private router: Router,
    private devicesService: DeviceService,
    private modalService: BsModalService,
    private _mqttService: MqttService,
    private readonly location: Location,
    private translateJson: TranslateJsonService,
    private gatewayService: GatewayService,
    public homeComponent: HomeComponent,
    private responseService: ResponseService
  ) { }

  ngOnInit() {
    this.language = localStorage.getItem('language')
    this.jsonWord = this.translateJson.changeLanguage(this.language)
    this.location.replaceState('/');
    this.currentUserType = this.homeComponent.currentUserType;



    this.gatewayService.gatewayObservable.concatMap(gateway => {
      if (gateway) {
        this.currentGateway = gateway.id
        this.loading = true
        return this._environmentService.listEnvironments()
      }
    })
      .concatMap(
        environments => {
          this.loading = false
          this.environments = environments.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
          this.envL = this.environments.length;
          return this.devicesService.listDevices()
        }).pipe(finalize(() => {
        })).subscribe(devs => {
          let devsEnv = [];
          for (let i = 0; i < this.environments.length; i++) {
            this.devsEnvName[i] = devs.filter(dev => dev.environment._id == this.environments[i]._id)
          }
        }, err => {

          this.homeComponent.openErrorAlert(err)
        })

    let resposta = `${config.gtwTopic}${this.currentGateway}/environment/#`;
    let teste = null
    this._mqttService.observe(resposta)
      .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
      .takeUntil(this.ngUnsubscribe).subscribe(res => {
        teste = res
        if (res.operation == 'add' || res.operation == 'edit' || res.operation == 'delete') {
          this.updateAll()
        } else {
        }
      }, err => {
        if (teste && (teste['operation'] == 'add' || teste['operation'] == 'edit' || teste['operation'] == 'delete')) {
          this.updateAll()
        }
      })
  }

  updateAll() {
    this._environmentService.listEnvironments()
      .pipe(finalize(() => this.loading = false))
      .concatMap(
        environments => {
          this.loading = false
          this.environments = environments;
          this.envL = this.environments.length;
          return this.devicesService.listDevices()
        }).pipe(finalize(() => {
        })).subscribe(devs => {
          let devsEnv = [];
          for (let i = 0; i < this.environments.length; i++) {
            this.devsEnvName[i] = devs.filter(dev => dev.environment._id == this.environments[i]._id)
          }
        },
          err => {
            console.log(this.jsonWord.error.dataError + ' ambiente: ', err);//'Erro ao ler dados de ambientes: ', err);
          });
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  saveEnvironmentModal() {
    this.gatewayService.getMaxLimitEntity(this.currentGateway, 'environment').subscribe(res => {
      this.envMax = res.availableCreation
      if (this.envMax) {
        this.modalRef = this.modalService.show(EnvironmentCardModalComponent);
        this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
        this.modalRef.content.buttonSave = { show: true, text: this.jsonWord.button.save };
        this.modalRef.content.inputText1 = { show: true, type: "text" };
        this.modalRef.content.editCreate = { show: true, value: 1 };
        this.modalRef.content.inputText2 = { show: true, type: "text", placeholder: this.jsonWord.placeholder.environmentName };
        this.modalRef.content.card = { show: true, value: this.environments };
        this.modalRef.content.isDelete = false;
        this.modalRef.content.title = this.jsonWord.page.addEnvironment//'Vincular Dispositivos';
        this.modalRef.content.onClose.subscribe(res => {
          this.updateAll()
          let environment = this.modalRef.content.buttonSave
          if (environment) {
            const message = {
              name: res.name,
              devices: res.devices
            }
          }
        })
      } else {
        this.modalRef = this.modalService.show(AlertComponent);
        this.modalRef.content.buttonConfirm = { show: false };
        this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
        this.modalRef.content.title = this.jsonWord.alert.attention
        this.modalRef.content.type = 'warning'
        this.modalRef.content.message = this.jsonWord.toast.limitEnvironmentHit
      }
    }, err => {
      if (err.status == 0 || err.status == 408) {
        this.modalRef = this.modalService.show(AlertComponent);
        this.modalRef.content.type = 'remove'
        this.modalRef.content.title = /*"<i class='fa fa-lg fa-times text-danger'> </i> " +*/ this.jsonWord.error.conectionError
        this.modalRef.content.message = this.jsonWord.error.noConnection
        this.modalRef.content.isDelete = false
        this.modalRef.content.typeB = 'refresh'
        //"Tempo limite de conexão com o servidor atingido. Verifique o status do servidor recarregue a página novamente."
        this.modalRef.content.buttonConfirm = { show: true, text: /*"<i class='fa fa-refresh'></i>" + '  ' + */this.jsonWord.label.reload }
        this.modalRef.content.onClose.subscribe(
          res => {
            if (res)
              window.location.reload()
          }
        )
      }
    })
  }
  /**
 * @author Nathalia
 * Catch by checkbox property className "mat-checkbox-check" that shows this checkbox is checked and got the checkbox name by .outerText.
 * Then i catch by mdinput property className "newEnv"and .value gives me its value. 
 * So, through the for, i got the checkboxes names and add in devices array.  
 */
  saveEnv(environment, name): void {
    let holdElemId = [], holdChecked = []
  }

  editEnvironmentModal(environment) {
    this.modalRef = this.modalService.show(EnvironmentCardModalComponent);
    this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
    this.modalRef.content.buttonEdit = { show: true, text: this.jsonWord.button.save };
    this.modalRef.content.inputText1 = { show: true, type: "text", value: environment.devices };
    this.modalRef.content.inputText2 = { show: true, type: "text", value: environment.name };
    this.modalRef.content.nameEnv = environment;
    this.modalRef.content.isDelete = false;
    this.modalRef.content.editCreate = { show: true, value: 2 };
    this.modalRef.content.card = { show: true, value: this.environments };
    this.modalRef.content.title = this.jsonWord.page.editEnvironment//'Vincular Dispositivos';
    this.modalRef.content.onClose.subscribe(res => {
      let environment = this.modalRef.content.buttonEdit
      if (environment) {
        this.updateAll()
      }
    }, err => {
      if (err.status == 0 || err.status == 408) {
        this.modalRef = this.modalService.show(AlertComponent);
        this.modalRef.content.type = 'remove'
        this.modalRef.content.title = /*"<i class='fa fa-lg fa-times text-danger'> </i> " +*/ this.jsonWord.error.conectionError
        this.modalRef.content.message = this.jsonWord.error.noConnection
        this.modalRef.content.isDelete = false
        this.modalRef.content.typeB = 'refresh'
        //"Tempo limite de conexão com o servidor atingido. Verifique o status do servidor recarregue a página novamente."
        this.modalRef.content.buttonConfirm = { show: true, text: /*"<i class='fa fa-refresh'></i>" + '  ' + */this.jsonWord.label.reload }
        this.modalRef.content.onClose.subscribe(
          res => {
            if (res)
              window.location.reload()
          }
        )
      }
    })
  }

  deleteEnvironment(environment, table) {
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
    this.modalRef.content.object = environment
    this.modalRef.content.isDelete = true;
    this.modalRef.content.title = this.jsonWord.alert.titleRemoveEnviroment
    this.modalRef.content.message = this.jsonWord.alert.messageRemoveEnvironment /*Deseja deletar o ambiente*/ + " " + environment.name + '?'
    this.modalRef.content.onClose.subscribe(result => {
      this.deletingEnvironment = environment
      if (result) {
        this.openLoadingModal(this.jsonWord.loading.message)
        this._environmentService.deleteEnvironmentByIeeeAddr(this.deletingEnvironment._id).subscribe(res => {
          if (res) {
            this.loadingModalRef.content.success = true
              this.loadingModalRef.content.message = this.jsonWord.toast.successToRemoveEnvironment
              this.loadingModalRef.content.waiting = false
              let indexToRemove = this.environments.findIndex(env => env._id == this.deletingEnvironment._id)
              this.environments.splice(indexToRemove, 1)
            setTimeout(() => {
              this.loadingModalRef.hide()
              this.modalRef.hide()
            }, 2000);
          }
        }, err => {
          let message = ''
          switch (err.status) {
            case 0:
              message = this.jsonWord.error.noConnection//'Formulário de convidar usuário inválido.'
              //this.noInternetAlert()
              break
            case 400:
              message = this.jsonWord.error.invalidFormat//'Formulário de convidar usuário inválido.'
              break
            case 401:
              message = this.jsonWord.error.notAuthorized//'Você não tem permissão para convidar usuários.'
              break
            /*	case 403:
                message = this.jsonWord.error.userDisabled//'Usuário está desativado.'
                break*/
            case 404:
              message = this.jsonWord.error.environmentNotFound//'Usuário não encontrado ou número serial de gateway não registrado.'
              break
            case 408:
              message = this.jsonWord.error.timeoutServer;//'Tempo limite de conexão atingido.'
              break
            /*	case 409:
                message = this.jsonWord.error.userAlreadyInGtw//'Usuário já associado ao gateway.'
                break
              case 412:
                message = this.jsonWord.error.limitUserExceeded//'Usuário já associado ao gateway.'
                break*/
            case 500:
              message = this.jsonWord.toast.gatewayCommunicationError//'Não foi possível enviar convite.'
              break
            default:
              message = this.jsonWord.toast.errorRemoveEnvironment

          }
          this.loadingModalRef.content.success = false
          this.loadingModalRef.content.waiting = false
          this.loadingModalRef.content.message = message//this.jsonWord.toast.errorRemoveEnvironment
          setTimeout(() => {
            this.loadingModalRef.hide()
            this.modalRef.hide()
          }, 2000)
        })
      }
    });
  }

  public unsafePublish(topic: string, message: string): void {
    this._mqttService.unsafePublish(topic, message, { qos: 1, retain: false });
  }

  openLoadingModal(message: String) {
    this.loadingModalRef = this.modalService.show(
      LoadingModalComponent, {
        class: 'waiting-modal modal-sm',
        keyboard: false,
        backdrop: 'static'
      }
    )
    this.loadingModalRef.content.message = message;
  }

  goToRouter() {
    this.router.navigate(['config/']);
  }
}

import { AuthGuard } from './../_guards/auth.guard';
import { TranslateJsonService } from './../_services/translate-json.service';
import { UserService } from './../_services/user.service';
import { Rule } from './../models/rule.model';
import { Component, OnInit, ChangeDetectorRef, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { GatewayService } from './../_services/gateway.service';
import { Gateway } from './../models/gateway.model';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from './../login/login.service';
import { ScenarioService } from './../_services/scenario.service';
import { RuleService } from './../_services/rule.service';
import { AlertComponent } from '../_directives/alert.component';
import { LoadingModalComponent } from '../_directives/loading-modal/loading-modal.component';

import { User } from './../models/user.model';
import { Device } from '../models/device.model';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import * as config from '../config';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/concatMap';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/mergeAll';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/throw';
import { Location } from '@angular/common';
//import { moment } from 'ngx-bootstrap/chronos/test/chain';
import { PanelComponent } from '../panel/panel.component';
import { pipe, merge, throwError, Subject, timer, of, TimeoutError, BehaviorSubject } from 'rxjs/';
import { Observable } from 'rxjs/Observable';
import { mergeMap, concatMap, mergeAll, finalize, timeInterval, timeoutWith } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

const GATEWAY_TIMEOUT = 3000
const PERMITJOIN_TIMEOUT = 5000
const utf8Decoder = new TextDecoder("utf-8")

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})

export class HomeComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  currentUser;
  currentUserToken;
  public currentGateway: Gateway;
  user: User = new User();
  scenarios: any;
  rules: any;
  returnUrl: String;
  allGateways: Array<Gateway>;
  listGateway;
  loading: boolean = false
  isJoining: Boolean = false;
  permitJoinTimer: number
  userId;
  gtwChosen: string;
  modalRef: BsModalRef
  arrowDD = false
  // MQTT Observers
  deviceObserver = new Observable();
  gatewaySuccessObserver = new Observable();
  gatewayUserSuccessObserver = new Observable();
  gatewayPermitjoinObserver = new Observable();
  gatewayErrorObserver = new Observable();
  periodicStatusObserver = new Observable();
  // Observable to inform that the observables has been set
  observersObservable = new BehaviorSubject<boolean>(false)

  users
  currentUserType
  public isShown: Boolean = false
  counter: NodeJS.Timer
  uuid = require('uuid/v4');
  onlyGateways:Array<Gateway>;
  language;
  jsonWord;
  currentGtwName
  gatewaysUser
  holdGateways
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _ruleService: RuleService,
    private userService: UserService,
    public _gatewayService: GatewayService,
    private _scenarioService: ScenarioService,
    private _loginService: LoginService,
    private _mqttService: MqttService,
    private cd: ChangeDetectorRef,
    private readonly location: Location,
    private eRef: ElementRef,
    private modalService: BsModalService,
    private translateJson: TranslateJsonService,
    private renderer: Renderer2,
    private translate: TranslateService,
    private toastr: ToastrService,
    private cookieService: CookieService,
  ) {
    let user = JSON.parse(localStorage.getItem('currentUser')).language
    localStorage.setItem('language', user)
    var images = ['assets/img/bg/Teste1.jpg' /*'assets/img/bg/1.jpg', 'assets/img/bg/2.jpg', 'assets/img/bg/3.jpg', 'assets/img/bg/4.jpg',
      'assets/img/bg/5.jpg', 'assets/img/bg/6.jpg', 'assets/img/bg/7.jpg', 'assets/img/bg/8.jpg',
  'assets/img/bg/9.jpg', 'assets/img/bg/10.jpg'*/]; //get "login" string
    this.renderer.setStyle(
      document.getElementsByTagName('body')[0],
      'background-image',
      'url(' + images[Math.floor(Math.random() * images.length)] + ')'
    );
  }

  devicesFound: Array<Device> = [];
  section = 'panel';

  ngOnInit() {
    this.onlyGateways=[]
    //this.location.replaceState('/');
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/login';
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.currentUserToken = localStorage.getItem('currentUserToken');
    this.currentUserType = JSON.parse(localStorage.getItem('currentRole'))
    this.userId = localStorage.getItem('currentUserId');
    //console.log("linguagem! ",JSON.parse(this.cookieService.get(this.userId))['language'])
    this.language = this.cookieService.get(this.userId) ? JSON.parse(this.cookieService.get(this.userId))['language'] : 'pt'
    this.jsonWord = this.translateJson.changeLanguage(this.language);
    this.gtwChosen = this.jsonWord.label.selectGateway//"Selecione um gateway"

    this.getUserData()
    //  this.currentUser = JSON.parse(localStorage.getItem('currentGateway'));
    if (!this.currentUserToken) {
      this.router.navigate(['/login']);
    }
    //Endpoint : /user/id/gateway
    
    this.userService.listUserInGatewayById(this.userId)
      .pipe(finalize(() => {
        // if (userSub.closed)
        this.loading = false
      }))
      .takeUntil(this.ngUnsubscribe)
      .concatMap(gateways => {
        this.allGateways = gateways.filter(gtw => gtw.status == "ENABLED" && gtw.type == "GATEWAY");

        if (!(this.allGateways && this.allGateways.length)) {
          this._gatewayService.setGateway(null)
          return of()
        }

        this.userService.listUserById(this.userId).subscribe(res => {
          let gtws = res._gateways
          this.gatewaysUser = gtws.filter(gtw => gtw.status == 'DISABLED')
        })
        let selectedGateway = this.allGateways.find(gtw => gtw.id == localStorage.getItem('currentGateway'))
        this.currentGateway = this._gatewayService.currentGateway
        this.currentGtwName = this._gatewayService.currentGateway.name
        if (!(this.currentGateway && selectedGateway) || this.currentGateway != selectedGateway) {
          if (!selectedGateway) {
            selectedGateway = this.allGateways.find(g => g.id === this.currentGateway.id)

          }
          if (!selectedGateway) {
            selectedGateway = this.allGateways[0]
          }
          this._gatewayService.setGateway(selectedGateway)
        }

        //subscribe to all permitjoin events
        this.allGateways.forEach(gateway => {
          this._mqttService.observe(config.gtwTopic + gateway.id + '/success')
            .map((IMQTTMessage: IMqttMessage) => {
              let plainText = utf8Decoder.decode(IMQTTMessage.payload)
              return plainText ? JSON.parse(plainText) : null
            })
            .filter(message => message['operation'] == "join")
            .subscribe(message => {
              if (this.currentGateway.id == gateway.id)
                this.isJoining = true
              this.startPermitJoinTimer(gateway, message['data'].time)

            })
        })
        return this._gatewayService.gatewayObservable.switchMap(
          gateway => {
            if (gateway) {
              if (this.allGateways) {
                this.currentGateway = this.allGateways[this.allGateways.findIndex(gtw => gtw.id == gateway.id)]
              }
              else return of()

              this.gtwChosen = gateway.name
              this.currentUserType = gateway.role
              this.loading = false
              
              this.isJoining = this.currentGateway.permitJoin.counter && this.currentGateway.permitJoin.time ? true : false

              this.deviceObserver = this._mqttService.observe(config.gtwTopic + gateway.id + '/device')
                .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))

              this.gatewayPermitjoinObserver = this._mqttService.observe(config.gtwTopic + gateway.id + '/addDevice')
                .map((IMQTTMessage: IMqttMessage) => {
                  let plainText = utf8Decoder.decode(IMQTTMessage.payload)
                  return plainText ? JSON.parse(plainText) : null
                })
              this.gatewayUserSuccessObserver = this._mqttService.observe(config.gtwTopic + gateway.id + '/user/status')
                .map((IMQTTMessage: IMqttMessage) => {
                  let plainText = utf8Decoder.decode(IMQTTMessage.payload)
                //  console.log("USER STATUS: ", plainText)
                  return plainText ? JSON.parse(plainText) : null
                })

              this.gatewaySuccessObserver = this._mqttService.observe(config.gtwTopic + gateway.id + '/success')
                .map((IMQTTMessage: IMqttMessage) => {
                  let plainText = utf8Decoder.decode(IMQTTMessage.payload)
                 // console.log("SUCCESS: ", plainText)
                  return plainText ? JSON.parse(plainText) : null
                })

              this.gatewayErrorObserver = this._mqttService.observe(config.gtwTopic + gateway.id + '/error')
                .map((IMQTTMessage: IMqttMessage) => {
                  let plainText = (utf8Decoder.decode(IMQTTMessage.payload))
                 // console.log("ERRO NO TOPICO", plainText)
                  return plainText ? (plainText) : null
                })

              // Periodic status observer
              this.periodicStatusObserver = this._mqttService.observe(config.gtwTopic + gateway.id + '/status')
                .map((IMQTTMessage: IMqttMessage) => {
                  let plainText = utf8Decoder.decode(IMQTTMessage.payload)
                  // private closeAllModals() {
                  console.log("Periodic status: ", plainText)
                 /* if (this.modalService.getModalsCount() > 0) {
                    console.log("tem modais")
                    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
                      this.modalService.hide(i);
                      this.openLoadingModal(this.jsonWord.loading.message)
                    }
                  } else {
                    if (this.modalRef && this.modalRef.content) {
                      this.modalRef.content.message = this.jsonWord.toast.success
                      this.modalRef.content.success = true
                      this.modalRef.content.waiting = false
                      this.modalRef.hide();
                    }
                  }*/

                  //}
                  return plainText ? JSON.parse(plainText) : null
                })

              this.observersObservable.next(true)
              return this.userService.listUsers()
                .concatMap((users) => {
                  //this.currentUser = localStorage.getItem('currentUserId');
                  let hold;
                  this.users = users;
                  for (let user of users) {
                    if (this.userId == user._user._id) {
                      this.currentUserType = user.role;
                      localStorage.setItem('currentRole', JSON.stringify(user.role));
                    }
                  }
                  if (!this.currentUserType) this.router.navigate(['/login']);
                  return this._scenarioService.listScenarios()
                })
                // return this._scenarioService.listScenarios()
                .map(
                  scenarios => {
                    this.scenarios = scenarios;
                    // return  this._ruleService.listRules()
                  })
            } else return of()
          }
        )
      })
      .takeUntil(this.ngUnsubscribe)
      .take(2)
      .subscribe(null,
        err => {
          this.openErrorAlert(err)
        })
  }

  getGateway() {
    this.userService.listUserInGatewayById(this.userId)
      .subscribe(gateways => {
        this.allGateways = gateways.filter(gtw => gtw.status == "ENABLED" && gtw.type == "GATEWAY");
        if (!(this.allGateways && this.allGateways.length)) {
          this._gatewayService.setGateway(null)
          return of()
        }
        let selectedGateway = this.allGateways.find(gtw => gtw.id == localStorage.getItem('currentGateway'))
        this.currentGateway = this._gatewayService.currentGateway
        this.currentGateway.name = this._gatewayService.currentGateway.name
      })
  }

  showBar() {
    this.isShown = !this.isShown;
    return this.isShown;
  }

  getUserData() {
    let userSub = this.userService.getUserByID(this.userId)
      .pipe(finalize(() => {
        this.loading = false
      }
      ))
      .subscribe((user: any) => {
        this.currentUser.userName = user.name
        this.currentUser.profileImage = user.profileImage
        this.currentUser.email = user.email
        this.currentUser._id = user._id

        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
      },
        err => {
          console.log(err)
        })
  }

  attScnAndRls() {
    this._scenarioService.listScenarios().subscribe(
      scenarios => {
        this.scenarios = scenarios;
        this.cd.markForCheck()
      })
    this._ruleService.listRules().subscribe(
      rules => {
        this.rules = rules;
      })
  }

  toggleRule(rule) {
    if (rule.isActive) {
      rule.isActive = false
    } else {
      rule.isActive = true
    }
    //rule.isActive = !rule.isActive
    const message = rule.isActive //? false : true;
    this._ruleService.updateIsActiveRule({ isActive: message }, rule._id).subscribe(res => {
      console.log(res)
    })
    // this.unsafePublish(topic, JSON.stringify(message));
  }

  tempButtonClick(type) {
    let payload: any;

    switch (type) {
      case 'addDevice':
        payload = {
          time: '90',
          user: this.currentUser,
        };
        break;
      case 'reset':
        payload = {
          mode: '0',
          user: this.currentUser,
        };
        break;
    }

    this.unsafePublish(config.gtwTopic + this.currentUser.gatewayId + '/' + type, JSON.stringify(payload));
  }

  public unsafePublish(topic: string, message: string): void {
    this._mqttService.unsafePublish(topic, message, { qos: 1, retain: false });
  }

  logout() {
    this._loginService.logout();
    this.router.navigate([this.returnUrl]);
  }

  startPermitJoinTimer(gateway: Gateway, time: number) {
    let gatewayRef = this.allGateways[this.allGateways.findIndex(g => g.id == gateway.id)]
    clearInterval(gatewayRef.permitJoin.counter)
    if (this.currentGateway.id == gatewayRef.id) {
      this.isJoining = true
      gatewayRef.permitJoin.time = time
      gatewayRef.permitJoin.counter = setInterval(() => {
        gatewayRef.permitJoin.time -= 1
        if(this.currentGateway.id == gatewayRef.id){
          this.currentGateway.permitJoin.time = gatewayRef.permitJoin.time
        this.currentGateway.permitJoin.counter = gatewayRef.permitJoin.counter
        this.isJoining = true
        }
        if (gatewayRef.permitJoin.time <= 0) {
          if (this.currentGateway.id == gatewayRef.id)
            this.isJoining = false
          clearInterval(gatewayRef.permitJoin.counter)
          gatewayRef.permitJoin.time = null
        }
      }, 1000)
    }
  }

  closeRightMenu() {
    let button: Element = document.getElementsByClassName('opener-right-menu').item(0);
    let menu = document.getElementById('right-menu')
  }

  openInputAlert() {
    this.modalRef = this.modalService.show(AlertComponent, { class: "modal-md" })
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.inputText1 = { show: true, type: "text", placeholder: this.jsonWord.placeholder.name }
    this.modalRef.content.inputText2 = { show: true, type: "text", placeholder: this.jsonWord.label.serialNumber }
    this.modalRef.content.offset = { show: true, type: "button", placeholder: this.jsonWord.label.choseTimezone }
    this.modalRef.content.isDst = { show: true, type: "checkbox", placeholder: this.jsonWord.placeholder.daylightSavingTime }
    this.modalRef.content.title = this.jsonWord.page.gatewayAdd//"Adicionar Gateway"
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok }
    this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel }
  }

  openLoadingModal(message) {
    this.modalRef = this.modalService.show(
      LoadingModalComponent,
      {
        keyboard: false,
        backdrop: 'static',
        class: 'waiting-modal modal-sm'
      })
    this.modalRef.content.message = message;
  }

  openErrorAlert(err) {
    this.modalRef = this.modalService.show(
      AlertComponent,
      {
        keyboard: false,
        backdrop: 'static',
      })
    if (err.status == 408 || err.status == 0) {
      this.modalRef.content.type = 'remove'
      this.modalRef.content.title = /*"<i class='fa fa-lg fa-times text-danger'> </i> " +*/ this.jsonWord.error.conectionError
      this.modalRef.content.message = this.jsonWord.error.noConnection
      this.modalRef.content.isDelete = false
      this.modalRef.content.typeB = 'refresh'
      //"Tempo limite de conexão com o servidor atingido. Verifique o status do servidor recarregue a página novamente."
      this.modalRef.content.buttonConfirm = { show: true, text: /*"<i class='fa fa-refresh'></i>" + '  ' + */this.jsonWord.label.reload }
      this.modalRef.content.onClose.subscribe(
        res => {
          if (res)
            window.location.reload()
        }
      )
    } else if (err.status == 401) {
      this.modalRef.content.type = 'remove'
      this.modalRef.content.title = /*"<i class='fa fa-lg fa-times text-danger'> </i> " + */this.jsonWord.error.conectionError
      this.modalRef.content.message = this.jsonWord.error.notAuthorized
      this.modalRef.content.isDelete = false
      this.modalRef.content.typeB = 'refresh'
      //"Tempo limite de conexão com o servidor atingido. Verifique o status do servidor recarregue a página novamente."
      this.modalRef.content.buttonConfirm = { show: true, text: /*"<i class='fa fa-refresh'></i>" + '  ' + */this.jsonWord.button.ok }
      this.modalRef.content.onClose.subscribe(
        res => {
          if (res)
            this.router.navigateByUrl('/login')
        }
      )
    } else {
      // this.currentGateway
      this.modalRef.content.type = 'remove'
      this.modalRef.content.title = /*"<i class='fa fa-lg fa-times text-danger'> </i>" + */this.jsonWord.error.conectionError
      this.modalRef.content.message = this.jsonWord.error.noConnection
      this.modalRef.content.isDelete = false
      this.modalRef.content.typeB = 'refresh'
      //"Tempo limite de conexão com o servidor atingido. Verifique o status do servidor recarregue a página novamente."
      this.modalRef.content.buttonConfirm = { show: true, text: /*"<i class='fa fa-refresh'></i>" + '  ' +*/ this.jsonWord.button.ok }
      this.modalRef.content.onClose.subscribe(
        res => {
          if (res)
            this.router.navigateByUrl('/login')
        }
      )
    }
  }

  setGatewayChosen(gateway) {
    let gatewayId = gateway.id
    this.scenarios = null
    this.rules = null
    if (gatewayId != this.currentGateway.id) {
      let selectedGateway = this.allGateways.find(gtw => gtw.id == gatewayId)
      if (selectedGateway) {
        this.currentGateway.name = gateway.name

        localStorage.setItem('currentGateway', selectedGateway.id)

        localStorage.setItem('currentRole', JSON.stringify(selectedGateway.role))
        this.currentUserType = JSON.parse(localStorage.getItem('currentRole'))
        // localStorage.setItem('currentGateway', JSON.stringify(selectedGateway.id)
        //  this.attScnAndRls()
        this._gatewayService.setGateway(selectedGateway)
      }
    }
    ///setTimeout(() => { this.growDiv() }, 500)
  }

  addGateway() {
    this.openInputAlert()
    this.modalRef.content.onClose
      .concatMap(
        res => {
          if (res) {
            let data = {
              name: this.modalRef.content.inputText1.value,
              serialNumber: this.modalRef.content.inputText2.value,
              offset: this.modalRef.content.offset.value,
              isDst: this.modalRef.content.isDst.value ? this.modalRef.content.isDst.value : false,
              isOnline: true
            }
            this.openLoadingModal(this.jsonWord.toast.addingGateway + ' ' + this.jsonWord.toast.gateway)//'Adicionando gateway...')
            return this._gatewayService.addGateway(data)
          }
          else
            return ErrorObservable.create('Reset canceled by user.')
        })
      .map(
        res => {
          this.modalRef.content.message = this.jsonWord.success.gatewayRegistration//"Gateway adicionado com sucesso."
          this.modalRef.content.success = true
          this.modalRef.content.waiting = false
          return res
        }
      )
      .takeUntil(this.ngUnsubscribe)
      .subscribe(res => {
        let timer = setTimeout(() => {
          this._gatewayService.gatewayObservable.next(res)
          this.modalRef.hide()
          this.ngOnInit()
        }, 1500)
      },
        err => {
          console.log(err)
          let message = ''
          switch (err.status) {
            case 0:
              message = this.jsonWord.error.noConnection;//'Campos de email ou senha inválidos.'
              //  this.noInternetAlert()
              break
            case 400:
              message = this.jsonWord.error.invalidFormat//'Formulário de criação de gateway inválido.'
              break
            case 401:
              message = this.jsonWord.error.notAuthorized//'Você não tem permissão para adicionar um gateway'
              break
            case 404:
              message = this.jsonWord.error.gatewayNotFound//'Usuário não encontrado ou número serial de gateway não registrado.'
              break
              case 408:
                message = this.jsonWord.error.timeoutServer//'Usuário não encontrado ou número serial de gateway não registrado.'
                break
            case 409:
              message = this.jsonWord.toast.serialGtwAlreadyExists//'Já existe um gateway registrado com esse serial.'
              break
            case 412:
              message = this.jsonWord.toast.limitUserGatewayExceeded//'Usuário não pode se vincular a mais gateways.'
              break
            case 500:
              message = this.jsonWord.toast.gatewayCommunicationError//'Não foi possível adicionar gateway.'
              break
            default:
              message = this.jsonWord.error.failOperation
          }
          this.modalRef.content.message = this.jsonWord.error.error + ": " + message
          this.modalRef.content.success = false
          this.modalRef.content.waiting = false
          //this.openErrorDialog(err.message)
        })
  }

  goToProfile() {
    this.router.navigateByUrl('/home/profile')
  }

  gatewayErrorToast(err) {
    if (err.status == 408 || err.status == 0) {
      this.toastr.error('O gateway demorou muito pra responder. Verifique o status do gateway e tente novamente mais tarde.')
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  // Gateway Menu
  isSelected(gateway) {
    //  this.attScnAndRls()
    return this.currentGateway.id == gateway.id
  }

  growDiv(action?: string) {
    var growDiv = document.getElementById('grow');
    var ungrowDiv = document.getElementById('ungrow');
    var arrowDiv = document.getElementById('arrow')
    if (growDiv.clientHeight) {
      growDiv.style.height = '0';
      ungrowDiv.style.height = "32px";
      arrowDiv.className = 'fa fa-chevron-down rotate-counterclockwise fa-sm dropdown-arrow'
    } else if (action != "collapse") {
      ungrowDiv.style.height = '0';
      var wrapper = document.querySelector('.measuringWrapper');
      growDiv.style.height = wrapper.clientHeight + "px";
      arrowDiv.className = 'fa fa-chevron-up rotate-clockwise fa-sm'
    }
  }

  growDivSmall() {
    var growDivSmall = document.getElementById('growSmall');
    var arrowDivSmall = document.getElementById('arrowSmall')
    if (growDivSmall.clientHeight) {
      growDivSmall.style.height = '0';
      arrowDivSmall.className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow'
    } else {
      var wrapper = document.querySelector('.measuringWrapperSmall');
      growDivSmall.style.height = wrapper.clientHeight + "px";
      arrowDivSmall.className = 'fa fa-chevron-up rotate-counterclockwise fa-sm'
    }
  }

  growDivMenu(action?: string) {
    var growDiv = document.getElementById('growMenu');
    var ungrowDiv = document.getElementById('ungrowMenu');
    var arrowDiv = document.getElementById('arrowMenu')
    if (growDiv.clientHeight) {
      growDiv.style.height = '0';
      ungrowDiv.style.height = "32px";
      arrowDiv.className = 'fa fa-chevron-down rotate-counterclockwise fa-sm dropdown-arrow'
    } else if (action != "collapse") {
      ungrowDiv.style.height = '0';
      var wrapper = document.querySelector('.measuringWrapperMenu');
      growDiv.style.height = wrapper.clientHeight + "px";
      arrowDiv.className = 'fa fa-chevron-up rotate-clockwise fa-sm'
    }
  }

  growDivSmallMenu() {
    var growDivSmall = document.getElementById('growMenu');
    var arrowDivSmall = document.getElementById('arrowMenu')
    if (growDivSmall.clientHeight) {
      growDivSmall.style.height = '0';
      arrowDivSmall.className = 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow'
    } else {
      var wrapper = document.querySelector('.measuringWrapperMenu');
      growDivSmall.style.height = wrapper.clientHeight + "px";
      arrowDivSmall.className = 'fa fa-chevron-up rotate-counterclockwise fa-sm'
    }
  }
  
  gotoPlan() : void {
    window.location.href= config.serverURL == "https://dev.api.ecomfort.com.br/cap/api/v1" ? `https://dev.planos.ecomfort.com.br#${this.currentUserToken}` : `https://planos.ecomfort.com.br#${this.currentUserToken}`;
  }
  arrowUpDown() {
    //this.arrowDD = !this.arrowDD
    if (!document.getElementById('dropdown-main').classList[3]) {
      this.arrowDD = true
      this.getGateway()
    } else this.arrowDD = false
    /* let arrow = document.getElementById('arrowDD');
     if (this.arrowDD) {
       this.getGateway()
       arrow.className = 'fa fa-chevron-down rotate-clockwise fa-sm'
     } else {
       arrow.className = 'fa fa-chevron-up rotate-counterclockwise fa-sm dropdown-arrow'
     }*/
  }
}
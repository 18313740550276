import { DeviceCardModalComponent } from './../../device-card-modal/device-card-modal.component';
import { DeviceService } from './../../../_services/device.service';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { TranslateJsonService } from './../../../_services/translate-json.service';
import { Component, OnInit, HostListener, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, of, Observable } from 'rxjs';
import * as config from '../../../config';
const utf8Decoder = new TextDecoder("utf-8")
import { v4 as uuid } from 'uuid';
import * as moment from 'moment';

@Component({
  selector: 'app-colorhue-canvas',
  templateUrl: './colorhue-canvas.component.html',
  styleUrls: ['./colorhue-canvas.component.css']
})
export class ColorhueCanvasComponent implements OnInit {
  @Input() card
  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('imgHue', { static: true }) imgHue: ElementRef
  @HostListener('mousedown', ['$event']) onMouseWheelChrome(event: any) {
    if(event.path[0].id == 'canvas')
      this.echoColor(event);
  }
  ctx;
  pixelData = [245, 245, 245, 255];
  selectedColorArray = [false, false, false, false, false, false, false, false];
  indexColor = 0;
  randomColor = [255, 255, 255, 255];
  colorPackage
  position
  currentUser
  public currentGateway: any;
  @Output() changeColor = new EventEmitter();
  public modalRef: BsModalRef;
  private ngUnsubscribe = new Subject();
  language
  jsonWord
  value1
  value2
  sat

  constructor(
    private modalService: BsModalService,
    private _deviceService: DeviceService,
    public translateJson: TranslateJsonService,
    private _mqttService: MqttService, ) {
  }
  ngOnInit() {
    this.language = localStorage.getItem('language')
    this.jsonWord = this.translateJson.changeLanguage(this.language)
    this.showWheel()
    // this.hslToRgb(this.card.value1 / 254, this.card.value2 / 254, 1)
    this.currentGateway = localStorage.getItem('currentGateway')
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')).email;
    const allDevicesTopic = config.gtwTopic + this.currentGateway + '/device/#';
    this.getAnswerColor({ hue: this.value1, sat: this.value2 })

    this._mqttService.observe(allDevicesTopic)
      .map((IMQTTMessage: IMqttMessage) => {
        let message = utf8Decoder.decode(IMQTTMessage.payload)
        return message ? JSON.parse(message) : String(IMQTTMessage)
      })
      .filter(payload => payload.data && payload.data.ieeeAddr == this.card.devIeeeAddr)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(payload => {
        var message = payload
        let op = payload.operation
        if (op === 'attReport') {
          this.messageDispatcher(message.data);
        }
      });

  }

  //Color
  onCancel(card) {
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    }
setTimeout(() => {
      this.modalRef = this.modalService.show(DeviceCardModalComponent);
      this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
      this.modalRef.content.buttonConfirm = { show: true, text: this.jsonWord.button.save };
      this.modalRef.content.buttonBlock = { show: true, text: this.jsonWord.button.blockDevice };
      this.modalRef.content.buttonUnblock = { show: card.blocked ? true : false, text: this.jsonWord.button.unblockDevice };
      this.modalRef.content.buttonBind = { show: card.devId == '6' ? true : false, text: this.jsonWord.button.bind };
      this.modalRef.content.buttonCalibrate = { show: card.devId == '10' || card.devId == '772' ? true : false, text: this.jsonWord.button.calibrate };
      this.modalRef.content.buttonUnbind = { show: card.devId == '6' ? true : false, text: this.jsonWord.button.unbind };
      this.modalRef.content.whiteLight = card.value3;
      this.modalRef.content.buttonRemove = { show: true, text: this.jsonWord.button.removeDevice };
      this.modalRef.content.inputText1 = { show: true, type: "text", placeholder: card.devIeeeAddr, value: card.devIeeeAddr };
      this.modalRef.content.inputText2 = { show: true, type: "text", placeholder: this.jsonWord.label.name, value: card.title };
      this.modalRef.content.inputLevelLum = { show: true, type: "text" };
      this.modalRef.content.inputLevel = { show: true, type: "text" };
      this.modalRef.content.inputHue = { show: true, type: "text" };
      this.modalRef.content.input = { show: true, type: "text" };
      this.modalRef.content.offset = { show: true, type: "text", value: card.environment && card.environment.name ? card.environment.name : "Nenhum" };
      this.modalRef.content.card = card
      this.modalRef.content.title = card.title//'Vincular Dispositivos'
    }, 1000);
    
    
  }
  showWheel() {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    var img = $('#imgHue')[0];
    img.addEventListener('load', e => {
      this.ctx.drawImage(img, 0, 0, this.canvas.nativeElement.width-1, this.canvas.nativeElement.height-1);
    });
    // var graphics = canvas.getContext("2d");
    this.ctx.drawImage(img, 0, 0, this.canvas.nativeElement.width-1, this.canvas.nativeElement.height-1);
    /*  var CX = this.canvas.nativeElement.width / 2,
        CY = this.canvas.nativeElement.height / 2,
        sx = CX,
        sy = CY;
      let sat
      for (var i = 0; i < 360; i += 0.1) {
       // for (var j = 100; j >= 50; j -= 1) {
          var rad = i * (2 * Math.PI) / 360;
          sat = (i+50)
          this.ctx.strokeStyle = "hsla(" + i + ",  100%," + sat + "%, 1.0)";
          this.ctx.beginPath();
          this.ctx.moveTo(CX, CY);
          this.ctx.lineTo(CX + sx * Math.cos(rad), CY + sy * Math.sin(rad));
          this.ctx.stroke();
        }
    //  }
      // console.log(this.ctx,this.ctx.stroke(), this.canvas)
      /*  var img = new Image();
      img.src = '/assets/img/dashboard/ui/hue.png';
      var context = this.canvas.nativeElement.getContext('2d');
      context.drawImage(img, 0, 0);
      //
      console.log(img)
         return img*/
  }

 /* createHue(){
    var CX = this.canvas.nativeElement.width / 2,
        CY = this.canvas.nativeElement.height / 2,
        sx = CX,
        sy = CY;
      let sat
      for (var i = 0; i < 360; i += 0.1) {
       // for (var j = 100; j >= 50; j -= 1) {
          var rad = i * (2 * Math.PI) / 360;
          sat = (i+50)
          this.ctx.strokeStyle = "hsla(" + i + ",  100%," + sat + "%, 1.0)";
          this.ctx.beginPath();
          this.ctx.moveTo(CX, CY);
          this.ctx.lineTo(CX + sx * Math.cos(rad), CY + sy * Math.sin(rad));
          this.ctx.stroke();
        }
    //  }
      // console.log(this.ctx,this.ctx.stroke(), this.canvas)
        var img = new Image();
      img.src = '/assets/img/dashboard/ui/hue.png';
      var context = this.canvas.nativeElement.getContext('2d');
      context.drawImage(img, 0, 0);
      //
      console.log(img)
         return img
  }*/
  echoColor(e) {
    //$('#canvas').click(event => {
    var a = this.canvas.nativeElement.getContext("2d").getImageData(e.offsetX, e.offsetY, 1, 1)
    if (!(a.data[0] == a.data[1] && a.data[2] == a.data[1] && a.data[0] == a.data[2]
      && a.data[0] <= 200 && a.data[1] <= 200 && a.data[2] <= 200)) {
    this.renderMouseCircle(e.offsetX, e.offsetY)
      //  if (this.indexColor < 1) {
      this.pixelData[0] = a.data[0];
      this.pixelData[1] = a.data[1];
      this.pixelData[2] = a.data[2];
      this.pixelData[3] = a.data[3];
      this.colorPackage = {
        index: this.indexColor,
        color: this.pixelData
      }
      // }
      //}
      this.changeColorFunc({ "color": this.colorPackage })
      //se for menor q 4 maior q 0 ele pega a cor dos botos RGB, depois salva as configurações para setar a lampada

      // $('#output').html('R: ' + this.pixelData[0] + '<br>G: ' + this.pixelData[1] + '<br>B: ' + this.pixelData[2] + '<br>A: ' + this.pixelData[3]);
    }//);
  }
  renderMouseCircle(x, y) {
    var canvas = this.canvas;

    var ctx = this.canvas.nativeElement.getContext('2d');
    if (this.position) {
      ctx.clearRect(0, 0, 300, 300)
    } this.showWheel()
    var ctx = this.canvas.nativeElement.getContext('2d');
    ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
    ctx.fillStyle = 'transparent';
    ctx.lineWidth = 2; //lineWidth = '3';
    ctx.beginPath();
    ctx.arc(x, y, 10, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    this.position = {
      x: x,
      y: y
    }
  };

  selectedColor(j) {
    var fixedColor = [];
    this.selectedColorArray[j] = !this.selectedColorArray[j]
    for (var i = 0; i < 7; i++) {
      if (i != j) this.selectedColorArray[i] = false
    }
    if (j > 0 && j < 5) {
      this.pixelData[0] = fixedColor[0] = j == 1 ? 255 : 0
      this.pixelData[1] = fixedColor[1] = j == 2 ? 255 : 0
      this.pixelData[2] = fixedColor[2] = j == 3 ? 255 : 0
      if (j == 4) {
        this.pixelData[0] = fixedColor[0] = 255
        this.pixelData[1] = fixedColor[1] = 255
        this.pixelData[2] = fixedColor[2] = 0
      }

      if (j == 2) var h = 85
      if (j == 4) h = 40
      if (j == 3) h = 170
      if (j == 1) h = 0
      this.colorPackage = {
        index: this.indexColor,
        color: fixedColor
      }

    this.onSmartlightColorChange(this.card, h, 255)
    }
   // this.changeColorFunc({ "color": this.colorPackage })
    this.indexColor = j;
    this.getHue({ "color": this.colorPackage })
  }

  getHue(event){
    var r, g, b;
    r = event.color.color[0]
    g = event.color.color[1]
    b = event.color.color[2]
    r = r / 255, g = g / 255, b = b / 255;

    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, v = max;

    var d = max - min;
    s = max == 0 ? 0 : d / max;

    if (max == min) {
      h = 0; // achromatic
    } else {
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }

      h *= 60;
    }
    h = h/360*255
    this.onSmartlightColorChange(this.card, h, 254)
  }

  changeColorFunc(event) {
//    console.log("evento", event, event.color.color)
    var r, g, b;
    r = event.color.color[0]
    g = event.color.color[1]
    b = event.color.color[2]
    r = r / 255, g = g / 255, b = b / 255;

    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, v = max;

    var d = max - min;
    s = max == 0 ? 0 : d / max;

    if (max == min) {
      h = 0; // achromatic
    } else {
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }

      h *= 60;
    }
    var hip = ((this.position.y - 136) * (this.position.y - 136)) + ((this.position.x - 136) * (this.position.x - 136))
    hip = Math.sqrt(hip)
    // s = hip/(136 * -1 * (Math.tan(h *Math.PI / 180)));

    var tang = Math.tan(h * Math.PI / 180)
    //   s = (this.position.x-136)/(136 * -1 * (Math.cos(h * Math.PI / 180)));
    h = Math.floor((h / 360) * 255)
    s = Math.floor((hip / 1.36) * 2.55)/ 308 * 255
    s = s.toFixed(0)
    this.sat = s
    // var yoffset = 136 + (136 * -1 * (Math.sin(hue * Math.PI / 180))) * sat;
    //var xoffset = 136 + (136 * -1 * (Math.cos(hue * Math.PI / 180))) * sat;
    this.onSmartlightColorChange(this.card, h, s)
    // Make r, g, and b fractions of 1

    // Find greatest and smallest channel values
    /* let cmin = Math.min(r,g,b),
         cmax = Math.max(r,g,b),
         delta = cmax - cmin,
         h = 0,
         s = 0,
         l = 0;
   
     // Calculate hue
     // No difference
     if (delta == 0)
       h = 0;
     // Red is max
     else if (cmax == r)
       h = ((g - b) / delta) % 6;
     // Green is max
     else if (cmax == g)
       h = (b - r) / delta + 2;
     // Blue is max
     else
       h = (r - g) / delta + 4;
   
     h = Math.round(h * 60);
   
     // Make negative hues positive behind 360°
     if (h < 0)
         h += 360;
   
     // Calculate lightness
     l = (cmax + cmin) / 2;
   
     // Calculate saturation
     s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
   
     // Multiply l and s by 100
     s = +(s * 100).toFixed(1);
     l = +(l * 100).toFixed(1);
     console.log(h,s,l)
       return [ h, s, l ];*/
  }

  getAnswerColor(valor) {
    this.sat = valor.sat
    var sat = valor.sat / 254;
    var hue = (valor.hue / 254) * 360;
    var yoffset = 145 + ((-138 * (Math.sin(hue * Math.PI / 180))) * sat);
    var xoffset = 145 + ((-140 * (Math.cos(hue * Math.PI / 180))) * sat);

    this.renderMouseCircle(xoffset, yoffset)
  }

  messageDispatcher(message) {
    var hue, sat;
    switch (message.cId) {
      case 'genLevelCtrl':
        if (message.type === 'currentLevel') {
          let value = message.value;
          //this.card.value1 = value.toFixed(1);
          /*if(this.card.devId== '6'){
            this.inputLevel.value = value ? value.toFixed(1) : '127'
          }else this.card.value1 = value.toFixed(1);*/
        }
        //this.colorhueScreenComponent.getAnswerColor('hue',hue)
        break;
      case 'lightingColorCtrl':
        if (message.type === 'currentHue') {
          let value;
          value = message.value;
          hue = value
          this.card.value2 = value.toFixed(1);
          this.getAnswerColor({ hue: hue, sat: this.sat })
          //this.getAnswerColor({ hue: hue, sat: this.card.value2 })
        } else if (message.type === 'currentSaturation') {
          let value;

          this.sat = sat = value = message.value;
          this.getAnswerColor({ hue: this.card.value2, sat: sat })
        }
        // this.colorhueScreenComponent.getAnswerColor("sat",sat)
        break;
    }
  }

  /* hslToRgb(h, s, l) {
     console.log(h,s,l)
     var r, g, b;
 
     if (s == 0) {
       console.log("aqui")
       r = g = b = l; // achromatic
     } else {
       var hue2rgb = function hue2rgb(p, q, t) {
         if (t < 0) t += 1;
         if (t > 1) t -= 1;
         if (t < 1 / 6) return p + (q - p) * 6 * t;
         if (t < 1 / 2) return q;
         if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
         return p;
       }
 
       var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
       var p = 2 * l - q;
       r = hue2rgb(p, q, h + 1 / 3);
       g = hue2rgb(p, q, h);
       b = hue2rgb(p, q, h - 1 / 3);
     }
     console.log([Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)])
     return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
   }
 */

  onSmartlightColorChange(card, h, s) {
    const command = {
      messageId: uuid(),
      timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      ieeeAddr: card.devIeeeAddr,
      epId: card.devEpList[0],
      user: this.currentUser,
      cId: 'lightingColorCtrl',
      cmd: 'moveToHueAndSaturation',
      zclData: { hue: h, saturation: s, transtime: 0 }
    };
    const topic = `${config.gtwTopic}${this.currentGateway}/device/${card.devIeeeAddr}/command`;
    if(!card.blocked) this.unsafePublish(topic, JSON.stringify(command));
  }
  public unsafePublish(topic: string, message: string) {
    var correlId = JSON.parse(message).messageId
    this._mqttService.unsafePublish(topic, message, { qos: 1, retain: false })
  }
  ngOnDestroy() {

  }
}

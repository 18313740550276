import { Subscription } from 'rxjs/Subscription';
import { Card } from './../../models/card.model';
import { Device } from './../../models/device.model';
import { DeviceCardModalComponent } from './../device-card-modal/device-card-modal.component';
import { DeviceService } from './../../_services/device.service';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { TranslateJsonService } from './../../_services/translate-json.service';
import { Component, OnInit, HostListener, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, of, Observable } from 'rxjs';
import * as config from '../../config';
const utf8Decoder = new TextDecoder("utf-8")
import { v4 as uuid } from 'uuid';
import * as moment from 'moment';

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.css']
})
export class CollapseComponent implements OnInit {
  @Input() vectorTypes=[]
  @Input() card
  @HostListener('mousedown', ['$event']) onMouseWheelChrome(event: any) {
   
  }
  arrowTF=[]
  currentUser
  public currentGateway: any;
  @Output() changeColor = new EventEmitter();
  public modalRef: BsModalRef;
  private ngUnsubscribe = new Subject();
  language
  jsonWord
  sat
  inputToCheck=[]
  devToClose:Subscription
  devName
	inScn = [];
  devices
  devNameArray=[]

  constructor(
    private modalService: BsModalService,
    private _deviceService: DeviceService,
    public translateJson: TranslateJsonService,
    private _mqttService: MqttService, private devicesService:DeviceService) {
     
  }
  ngOnInit() {
    this.language = localStorage.getItem('language')
    this.jsonWord = this.translateJson.changeLanguage(this.language)
    this.currentGateway = localStorage.getItem('currentGateway')
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')).email;
    const allDevicesTopic = config.gtwTopic + this.currentGateway + '/device/#';

    this._mqttService.observe(allDevicesTopic)
      .map((IMQTTMessage: IMqttMessage) => {
        let message = utf8Decoder.decode(IMQTTMessage.payload)
        return message ? JSON.parse(message) : String(IMQTTMessage)
      })
      .filter(payload => payload.data && payload.data.ieeeAddr == this.card.devIeeeAddr)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(payload => {
        var message = payload
        let op = payload.operation
        if (op === 'attReport') {
          this.messageDispatcher(message.data);
        }
      }); 
      this.devToClose = this.devicesService.listDevices().subscribe(res => {
        this.devName = res//.devices;
        let resp: Device, a = 0
        let teste = [];
        for (resp of res) {//.devices) {
          this.card = new Card(resp);
          if (this.card.devId != '770' && this.card.devId != '12' && this.card.devId != '263' && this.card.devId != '262' &&
            this.card.devId != '10' && this.card.devId != '6') {
            this.card.isActive = (this.card.isActive && !this.card.invertOnOff) || (!this.card.isActive && this.card.invertOnOff)
            this.inputToCheck.push(this.card)
            }
          }
     // this.checkDevsType(this.inputToCheck) 
      });
  }

  //PRECISO DISSO
	/*checkDevsType(inputToCheck) {
    console.log(inputToCheck)
		this.vectorTypes = [null, null, null, null, null, null, null]
		let count0 = [], count1 = [], count2 = [], count3 = [], count4 = [], count5 = [], count6 = [], count7 = [];

		for (let i = 0; i < inputToCheck.length; i++) {
			if (this.inputToCheck[i].devId == '81' || this.inputToCheck[i].devId === '256') { count0.push([inputToCheck[i], this.inScn[i]]); }
			if (this.inputToCheck[i].devId == '2') { count1.push([inputToCheck[i], this.inScn[i]]); }//2
			if (this.inputToCheck[i].devId == '258' || this.inputToCheck[i].devId === '528') { count2.push([inputToCheck[i], this.inScn[i]]); }
			if (this.inputToCheck[i].devId == '771') { count3.push([inputToCheck[i], this.inScn[i]]); }
			if (this.inputToCheck[i].devId == '261') { count4.push([inputToCheck[i], this.inScn[i]]); }//
			if (this.inputToCheck[i].devId == '772') { count5.push([inputToCheck[i], this.inScn[i]]); }//1
			if (this.inputToCheck[i].devId == '265') { count6.push([inputToCheck[i], this.inScn[i]]); }//1
			if (this.inputToCheck[i].devId == '264') { count6.push([inputToCheck[i], this.inScn[i]]); }//1
		}
		this.vectorTypes[0] = count0;
		this.vectorTypes[1] = count1;
		this.vectorTypes[2] = count2;
		this.vectorTypes[3] = count3;
		this.vectorTypes[4] = count4;
		this.vectorTypes[5] = count5;
		this.vectorTypes[6] = count6;
    this.vectorTypes[7] = count7;
    console.log(this.vectorTypes)
	}*/

  //Color
  onCancel(card) {
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    }
setTimeout(() => {
      this.modalRef = this.modalService.show(DeviceCardModalComponent);
      this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
      this.modalRef.content.buttonConfirm = { show: true, text: this.jsonWord.button.save };
      this.modalRef.content.buttonBlock = { show: true, text: this.jsonWord.button.blockDevice };
      this.modalRef.content.buttonUnblock = { show: card.blocked ? true : false, text: this.jsonWord.button.unblockDevice };
      this.modalRef.content.buttonBind = { show: card.devId == '6' ? true : false, text: this.jsonWord.button.bind };
      this.modalRef.content.buttonCalibrate = { show: card.devId == '10' || card.devId == '772' ? true : false, text: this.jsonWord.button.calibrate };
      this.modalRef.content.buttonUnbind = { show: card.devId == '6' ? true : false, text: this.jsonWord.button.unbind };
      this.modalRef.content.whiteLight = card.value3;
      this.modalRef.content.buttonRemove = { show: true, text: this.jsonWord.button.removeDevice };
      this.modalRef.content.inputText1 = { show: true, type: "text", placeholder: card.devIeeeAddr, value: card.devIeeeAddr };
      this.modalRef.content.inputText2 = { show: true, type: "text", placeholder: this.jsonWord.label.name, value: card.title };
      this.modalRef.content.inputLevelLum = { show: true, type: "text" };
      this.modalRef.content.inputLevel = { show: true, type: "text" };
      this.modalRef.content.inputHue = { show: true, type: "text" };
      this.modalRef.content.input = { show: true, type: "text" };
      this.modalRef.content.offset = { show: true, type: "text", value: card.environment && card.environment.name ? card.environment.name : "Nenhum" };
      this.modalRef.content.card = card
      this.modalRef.content.title = card.title//'Vincular Dispositivos'
    }, 1000);
  }

	changeCheck(device, checkDoor,i) {
    device[1] = checkDoor.checked
    this.inScn[i] = checkDoor.checked
  }
  
	setArrow(i) {
		this.arrowTF[i] = !this.arrowTF[i]
		switch (i) {
			case (0):
				document.getElementById('arrowSP').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (1):
				document.getElementById('arrowS').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (2):
				document.getElementById('arrowRGB').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (3):
				document.getElementById('arrowSPP').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (4):
				document.getElementById('arrowLed').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (5):
				document.getElementById('arrowTurn').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (6):
				document.getElementById('arrowSPul').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (7):
				document.getElementById('arrowSSw').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (8):
				document.getElementById('arrowRule').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
		}
	}


  
  messageDispatcher(message) {
    var hue, sat;
    switch (message.cId) {
      case 'genLevelCtrl':
        if (message.type === 'currentLevel') {
          let value = message.value;
          //this.card.value1 = value.toFixed(1);
          /*if(this.card.devId== '6'){
            this.inputLevel.value = value ? value.toFixed(1) : '127'
          }else this.card.value1 = value.toFixed(1);*/
        }
        //this.colorhueScreenComponent.getAnswerColor('hue',hue)
        break;
      case 'lightingColorCtrl':
        if (message.type === 'currentHue') {
          let value;
          value = message.value;
          hue = value
          this.card.value2 = value.toFixed(1);
          //this.getAnswerColor({ hue: hue, sat: this.card.value2 })
        } else if (message.type === 'currentSaturation') {
          let value;

          this.sat = sat = value = message.value;
        }
        // this.colorhueScreenComponent.getAnswerColor("sat",sat)
        break;
    }
  }
  public unsafePublish(topic: string, message: string) {
    var correlId = JSON.parse(message).messageId
    this._mqttService.unsafePublish(topic, message, { qos: 1, retain: false })
  }
  ngOnDestroy() {
		this.devToClose.unsubscribe();
	//	this.scenesAll.unsubscribe();
  }
}

import { ScenarioService } from './../../_services/scenario.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './../../app-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SceneEditComponent } from './scene-edit.component';
import { DirectivesModule } from '../../_directives/directives.module';


@NgModule({
  imports: [
    AppRoutingModule,
    CommonModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    SceneEditComponent
  ],
  providers: [ScenarioService],
  exports: [SceneEditComponent]
})
export class SceneEditModule { }

import { TranslateJsonService } from "./../../_services/translate-json.service";
import { GatewayService } from "./../../_services/gateway.service";
import { DeviceService } from "./../../_services/device.service";
import { Subject } from "rxjs/Subject";
import {
  Component,
  OnInit,
  ViewChild,
  SimpleChanges,
  ElementRef,
  ContentChild,
  Input
} from "@angular/core";
import { MqttService, IMqttMessage } from "ngx-mqtt";
import { timer } from "rxjs";
import * as config from "../../config";
import * as _ from "lodash";

import { BaseChartDirective, Color, Label } from "ng2-charts";
import { ChartDataSets, ChartOptions } from "chart.js";
//import { ChartDataSets, ChartOptions } from 'chart.js';
//import { Color, BaseChartDirective } from 'ng2-charts';
import * as hammer from "hammerjs";
import * as Chart from "chart.js";
import { Location } from "@angular/common";
import * as pluginZoom from "chartjs-plugin-zoom";
const utf8Decoder = new TextDecoder("utf-8");

@Component({
  selector: "app-lumchart",
  templateUrl: "./lumchart.component.html",
  styleUrls: ["./lumchart.component.css"]
})
export class LumchartComponent implements OnInit {
  smartL = [];
  data = [];
  category = [];
  currentGateway;
  loading = true;
  dataset = [];
  public language;
  public jsonWord;
  private yRange: number = 0;
  private yRangeSubject: Subject<number>;
  /**
   *
   *  Chart graphic
   */
  public lineChartData: ChartDataSets[] = [{ data: [], label: "Series A" }];
  public lineChartLabels = [];

  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
  public lineChartOptions: ChartOptions & { annotation: any } = {
    responsive: true,
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          id: "y-axis-0",
          position: "left"
        }
      ]
    },
    annotation: {},
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: "xy"
        },
        zoom: {
          enabled: true,
          mode: "xy",
          speed: 0.1
        }
      }
    }
  };
  public lineChartColors: Color[] = [
    {
      // grey
      backgroundColor: "rgba(148,159,177,0.2)",
      borderColor: "rgba(148,159,177,1)",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(148,159,177,0.8)"
    }
  ];

  public lineChartLegend = true;
  public lineChartType = "line";
  public lineChartPlugins = [pluginZoom];

  constructor(
    public deviceService: DeviceService,
    private translateJson: TranslateJsonService,
    private readonly location: Location,
    private gatewayService: GatewayService,
    private _mqttService: MqttService
  ) {}

  ngOnInit() {
    this.location.replaceState("/");
    this.language = localStorage.getItem("language");
    this.jsonWord = this.translateJson.changeLanguage(this.language);
    this.currentGateway = localStorage.getItem("currentGateway");
    this.gatewayService.gatewayObservable
      .concatMap(gateway => {
        if (gateway) {
          this.currentGateway = gateway.id;
          //  this.loading = false
        }
        return this.deviceService.listDevices(this.currentGateway);
      })
      .map(devices => {
        this.dataset = [];
        this.lineChartColors = [];
        this.lineChartData = [];
        this.smartL = [];
        this.category = [];
        for (let device of devices) {
          switch (device.devId) {
            case "262":
              var letters = "0123456789ABCDEF";
              var color = "#";
              for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
              }
              this.dataset.push({
                data: []
              });
              this.lineChartData.push({ data: [], label: device.name });
              this.lineChartColors.push({
                backgroundColor: color + "33",
                borderColor: color,
                pointBackgroundColor: color,
                pointBorderColor: "#fff",
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: color + "cc" //cc
              });
              this.smartL.push(device);
          }
        }
        this.loading = false;
      })
      .subscribe(_ => {});

    const allDevicesTopic = config.gtwTopic + this.currentGateway + "/device/#";

    let devicesObserver = this._mqttService
      .observe(allDevicesTopic)
      .map((IMQTTMessage: IMqttMessage) =>
        JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload))
      )
      .subscribe(payload => {
        let message = payload;
        let op = payload.operation;
        if (op === "attReport") {
          this.messageDispatcher(message.data);
        }
      });
  }

  messageDispatcher(message) {
    switch (message.cId) {
      case "msIlluminanceMeasurement":
        if (message.type === "measuredValue") {
          const value = message.value;
          let lum = this.smartL.findIndex(
            lum => lum.ieeeAddr == message.ieeeAddr
          );
          for (let i = 0; i < this.smartL.length; i++) {
            if (lum == i) {
              let data = new Date().getHours() + ":" + new Date().getMinutes();
              this.dataset[i].data.push(value);
              this.chart.datasets[i].data.push(value);
              this.category.push(data);
            } else {
              let length = this.dataset[i] ? this.dataset[i].data.length : 0;
              let data = length > 0 ? this.dataset[i].data[length - 1] : 0;
              this.dataset[i].data.push(data);
              this.chart.datasets[i].data.push(data);
            }
          }
          this.lineChartLabels = this.category;
        }
        break;
    }
  }

  resetZoom($event) {
    this.chart.chart["resetZoom"]();
    return false;
  }
  // events
  public chartClicked({
    event,
    active
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {
    // console.log(event, active);
  }

  public chartHovered({
    event,
    active
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {
    // console.log(event, active);
  }
}

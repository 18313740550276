import { IconsScenarioModalComponent } from './../../_directives/icons-scenario-modal/icons-scenario-modal.component';
import { LoadingModalComponent } from './../../_directives/loading-modal/loading-modal.component';
import { ResponseService } from './../../_services/response.service';
import { Subject } from 'rxjs/Subject';
import { TranslateJsonService } from './../../_services/translate-json.service';
import { AlertComponent } from './../../_directives/alert.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService, BsDatepickerConfig, defineLocale } from 'ngx-bootstrap';
import { Card } from './../../models/card.model';
import { enGbLocale, ptBrLocale, esLocale } from 'ngx-bootstrap/locale';
import { Device } from './../../models/device.model';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { ScenarioService } from './../../_services/scenario.service';
import { RulesModel } from './../../models/rules.model';
import { RuleService } from './../../_services/rule.service';
import * as config from '../../config';
import { TimepickerModule, BsLocaleService } from 'ngx-bootstrap';
import { TimepickerConfig, TimepickerStore, TimepickerActions } from './../../../../node_modules/ngx-bootstrap/timepicker';

import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  ContentChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validator,
  Validators
} from '@angular/forms';
import { Location } from '@angular/common';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { DeviceService } from '../../_services/device.service';
import * as moment from 'moment';
import { timeoutWith, finalize, concatMap } from 'rxjs/operators';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { HomeComponent } from '../../home/home.component';

const utf8Decoder = new TextDecoder("utf-8")
const TIMEOUT = 10000

@Component({
  selector: 'vr-rule-edit',
  templateUrl: './rule-edit.component.html',
  styleUrls: ['./rule-edit.component.css'],
  providers: [RuleService],
})
export class RuleEditComponent implements OnInit, OnDestroy {
  selectedIndex = 0;
  lastIndex = 6;
  flipCard = []
  rules: RulesModel;
  backgroundColor = "whitesmoke";
  color = "black"
  devices;
  checkedDevices;
  checkedScenarios;
  id: number;
  checkedDevices2;
  colorText = [];
  lightLevel = [];
  levelToShow = [];
  input = [];
  output = [["nada", "Desativada", "50", "1"]];
  isCheckedT = []//boolean = false;
  isCheckedU = [] //boolean = false;
  isCheckedP = []//boolean = false;
  isCheckedL = []//boolean = false;
  valueT = [];
  valueL = [];
  valueU = [];
  valueP = [];
  optionT = [];
  optionL = [];
  optionU = [];
  optionP = [];
  state = [];
  condition = [];
  onOff = [];
  scenChecked;
  scnName = [];
  schedule;
  @Input() option = "sim";
  @Input() horaStarts = "00:00:01";
  @Input() horasEnds = "23:59:59";
  @Input() startDay;
  @ContentChild('tabGroup', { static: false }) tabGroup !: ElementRef
  dateNow;
  weekdays;
  vectorTypes = [null, null, null, null, null];
  vectorTypesIn = [[], [], [], [], []];
  interval;
  outputR = [];
  inputR;
  allInput = [];
  condSignal = [];
  daysTrueFalse = [false, false, false, false, false, false, false];
  jsonDays = [];
  //horasOk = true;
  count1 = 0;
  count2 = 0;
  daysChecked = 0;
  weekdaysBD;
  white = [];
  colorful = [];
  withoutValueT = [];
  withoutValueP = true;
  withoutValueU = [];
  checkP = true;
  checkU = true;
  checkT = true;
  editCheck = [];
  devsToInput = [];
  devsToOutput = [];
  outputStart = [];
  getOnlyOnOffOfDevs = []; //para pegar apenas um valor da lampada
  nome;
  allRules;
  nameRule;
  devConfigd
  vetorOutput //= ["LampadaRGB", "Smartplug", "Sirene", "Smartplug Plus", "Fita de Led"];
  vetorInput //= ["Temperatura e umidade", "Abertura/Fechamento", "Presença", "Smartplug", "Luminosidade", "Smartplug Plus"];
  saveClicked = 0;
  page = 1;
  index = "";
  inRule = [];
  outRule = [];
  card;
  cardInRule = []; //input
  cardOutRule = []; //output
  whiteLight = [];
  isDevActive = [];
  valueInput = [];
  currentGateway;
  allScn;
  public modalRef: BsModalRef;
  next;
  count;
  datePickerConfig: Partial<BsDatepickerConfig>;
  startDayFinal;
  countFinal1 = 0;
  countFinal2 = 0;
  holdCheckedRule = []
  language;
  public loadingModalRef: BsModalRef;
  private ngUnsubscribe = new Subject();
  public arrowTF = [false, false, false, false, false, false, false, false, false, false, false, false, false];
  inputToShow = [];
  jsonWord;
  rulesToOutput = []
  countPage6 = 0;
  countPage1 = 0
  isActiveRule = []
	imgsScenario=[]
  iconScenario='icon_1'
  inputRule
	iconScenarioWhite = 'icon_1_white.svg'
  constructor(
    private location: Location,
    private devicesService: DeviceService,
    private route: ActivatedRoute,
    private localeService: BsLocaleService,
    private translateJson: TranslateJsonService,
    private rulesService: RuleService,
    private router: Router,
    private modalService: BsModalService,
    private scenariosS: ScenarioService, ) {

    this.daysTrueFalse = [false, false, false, false, false, false, false];
    this.interval = [];// new IntervalModel();
    this.outputR = []//new OutputModel();
    this.inputR = []//new InputModel();
    this.schedule //new RulesModel();

    /* enGbLocale.invalidDate = 'Custom label';
     defineLocale('custom locale', enGbLocale);
     this.localeService.use('custom locale');*/
    if (localStorage.getItem('language') == 'pt') {
      ptBrLocale.invalidDate = 'Custom label';
      defineLocale('custom locale', ptBrLocale);
      this.localeService.use('custom locale');
    }
    if (localStorage.getItem('language') == 'es') {
      esLocale.invalidDate = 'Custom label';
      defineLocale('custom locale', esLocale);
      this.localeService.use('custom locale');
    }
    if (localStorage.getItem('language') == 'en') {
      enGbLocale.invalidDate = 'Custom label';
      defineLocale('custom locale', enGbLocale);
      this.localeService.use('custom locale');
    }
  }

  ngOnInit(): void {
		for (let i = 1; i <= 33; i++) {
			var img = 'icon_' + i + '_white.svg'
			this.imgsScenario.push(img)

		}
    this.iconScenario='icon_1'
		this.iconScenarioWhite = 'icon_1_white.svg'
    this.currentGateway = (localStorage.getItem('currentGateway'));
    this.language = localStorage.getItem('language')
    this.location.replaceState('/')
    this.jsonWord = this.translateJson.changeLanguage(this.language)
    this.weekdays = [this.jsonWord.label.sundayInitial, this.jsonWord.label.mondayInitial, this.jsonWord.label.tuesdayInitial, this.jsonWord.label.wednesdayInitial,
    this.jsonWord.label.thursdayInitial, this.jsonWord.label.fridayInitial, this.jsonWord.label.saturdayInitial];
    this.vetorOutput = [this.jsonWord.label.lampRGB, this.jsonWord.label.smartPlug, this.jsonWord.label.siren, this.jsonWord.label.smartPlugPlus, this.jsonWord.label.ledF];
    this.vetorInput = [this.jsonWord.label.tempHum, this.jsonWord.label.openClose, this.jsonWord.label.occupancy, this.jsonWord.label.smartPlug,
    this.jsonWord.label.luminosity, this.jsonWord.label.smartPlugPlus, this.jsonWord.label.waterSensor];
    this.condition = ["teste", "teste"]
    this.colorText = [];
    this.lightLevel = [];
    this.rulesService.listRules().subscribe(res => {
      this.allRules = res;
    })
    this.devices = this.devicesService.listDevices();

    /*  this.rulesService.listRules().subscribe(res => {
       this.allRules = res;
       for (let i = 0; i < res.length; i++) {
         if(res[i].id != this.id){
            this.rulesToOutput.push([res[i], false])
         this.isActiveRule.push(res[i].isActive)
         console.log(this.rulesToOutput)
         }
       }
     })*/

    this.devices.concatMap(res => {
      this.getAllDevices(res)
      return this.route.params
    }).concatMap((params) => {
      this.id = params['_id'];
      return this.rulesService.listRuleById(params._id)
    })
      .concatMap(res => {
        this.nameRule = res.name;
        this.iconScenario = res.icon
        if (res.icon) {
					if (res.icon.length > 8) {
						var icon = (res.icon.length < 11 ? res.icon.slice(0, 6) : res.icon.slice(0, 7))
						this.iconScenarioWhite = icon + '_white.svg';
					} else {
						var icon = (res.icon.length < 6 ? res.icon.slice(0, 6) : res.icon.slice(0, 7))
						this.iconScenarioWhite = icon + '_white.svg';
					}
				} else {
					this.iconScenarioWhite = 'icon_1_white.svg'
				}
        this.weekdaysBD = res.schedule.weekdays.sort();
        for (let i = 0; i < this.weekdaysBD.length; i++) {
          this.daysTrueFalse[this.weekdaysBD[i].day] = true;
        }
        //seta horas //todo setar dia
        this.interval = res.schedule.interval;
        this.startDay = moment(Date.now()).format('YYYY-MM-DD');
        let newDate = new Date(this.interval.startDay); //this.interval.startDay);new Date()
        // var userTimezoneOffset = ;
        //	console.log("Pipe",new Date(temp))
        //	var date = new Date(temp.userTimezoneOffset))
        this.startDay = newDate.getTime() + (2 * (newDate.getTimezoneOffset() * 60000)) //this.interval.startDay//moment(this.interval.startDay).format('YYYY-MM-DD')//newDate//moment(this.interval.startDay).format('YYYY-MM-DD');
        this.datePickerConfig = Object.assign(
          {},
          {
            containerClass: 'theme-green',
            showWeekNumbers: false,
            rangeInputFormat: 'DD/MM/YYYY',
            minDate: newDate   //this.interval.startDay//new Date(),
          })
        // this.startDay = 
        this.horaStarts = this.interval.startHour;
        this.horasEnds = this.interval.endHour;
        this.scheduleConfig();
        this.outputStart = res.outputs;
        //seta input
        this.inputR = res.inputs;
        // check which is in the rules and check
        for (let i = 0; i < this.devsToInput.length; i++) {
          for (let j = 0; j < this.inputR.length; j++) {
            if (this.devsToInput[i].ieeeAddr === this.inputR[j].ieeeAddr) {
              if (this.inputR[j].devId === '770') {
                this.inRule[i] = true
                if (this.inputR.length != (j + 1) && this.inputR[j].ieeeAddr === this.inputR[j + 1].ieeeAddr) {
                  j++;
                }
              } else {
                this.inRule[i] = true
              }
            }
          }
          this.cardInRule.push(this.devsToInput[i])
        }
          this.inputRule = res.ruleOutputs
        this.checkDevsTypeIn(this.cardInRule) //verify types
        //verifica devs de saida q ja estao na regra ou nao
        // this.verifyStateStartOutput()
        for (let i = 0; i < this.devsToOutput.length; i++) {
          for (let j = 0; j < this.outputStart.length; j++) {
            if (this.devsToOutput[i].devIeeeAddr === this.outputStart[j].ieeeAddr) {
              this.outRule[i] = (true)
            }
          }
          this.cardOutRule.push(this.devsToOutput[i])
        }
        this.checkDevsType(this.cardOutRule)
        this.allScn = res.sceneOutputs
        this.schedule = res.schedule;
        return this.scenariosS.listScenarios();
      }).concatMap(res => {
        let hold = res
        for (let i = 0; i < hold.length; i++) {
          this.scnName[i] = ([hold[i], false]);
          if (this.allScn) {
            for (let j = 0; j < this.allScn.length; j++) {
              if (hold[i]._id == this.allScn[j].scenarioId) {
                this.scnName[i] = ([hold[i], true]);
              }
            }
          }
        }
        return this.rulesService.listRules();
      }).pipe(finalize(() => { })).map(res => {
        this.allRules = res;
        /*for (let i = 0; i < res.length; i++) {
          if (res[i].id != this.id) {
            this.rulesToOutput.push([res[i], false])
            this.isActiveRule.push(res[i].isActive)
          }
        } */
        for (let i = 0; i < res.length; i++) {
          //this.isActiveRule[i] = res[i].ruleOutputs.isActive
            
            //for(let j=0;j< this.inputRule.length;j++){
            //this.rulesToOutput[i] = ([res[i], false])
            if(res[i]._id != this.id){
              let index = this.inputRule.findIndex(inputR => inputR.ruleId == res[i]._id)
              if(index>-1) this.rulesToOutput.push([res[i], true]); else this.rulesToOutput.push([res[i], false])
            //  this.rulesToOutput.push([res[i], true])
            }
            //	}
  
          }
      }).subscribe(_ => { }, err => {
        if (err.status == 0 || err.status == 408 || err.status == 404) {
          this.modalRef = this.modalService.show(AlertComponent);
          this.modalRef.content.type = 'remove'
          this.modalRef.content.title = /*"<i class='fa fa-lg fa-times text-danger'> </i> " +*/ this.jsonWord.error.conectionError
          this.modalRef.content.message = this.jsonWord.error.noConnection
          this.modalRef.content.isDelete = false
          this.modalRef.content.typeB = 'refresh'
          //"Tempo limite de conexão com o servidor atingido. Verifique o status do servidor recarregue a página novamente."
          this.modalRef.content.buttonConfirm = { show: true, text: /*"<i class='fa fa-refresh'></i>" + '  ' + */this.jsonWord.label.reload }
          this.modalRef.content.onClose.subscribe(
            res => {
              if (res)
                window.location.reload()
            }
          )
        }
      })
  }
  getAllDevices(res) {
    let resp: Device;
    /*  let device14: Device = new Device({
        name: "Sensor de agua",
        _id: "123123141",
        ieeeAddr: "123123141",
        devId: "264",
        status: "online",
        epList: [6],
        type: "route",
        isActive: true
      })
      let deviceP: Device = new Device({
        name: "INTERRUPTOR",
        _id: "123123141",
        ieeeAddr: "123123141",
        devId: "265",
        epList: [6],
        status: "online",
        type: "route",
        isActive: true
      })
      let deviceSS = new Device({
        name: "SS",
        _id: "123123141",
        epList: [6],
        ieeeAddr: "123123141",
        devId: "772",
        status: "online",
        type: "route",
        isActive: true
      })
      let deviceL = new Device({
        name: "Luminosidade",
        _id: "123123141",
        epList: [6],
        ieeeAddr: "123123141",
        devId: "262",
        status: "online",
        type: "route",
        isActive: true
      })
      let deviceA = new Device({
        name: "Sensor de agua",
        _id: "123123141ABD",
        epList: [6],
        ieeeAddr: "123123141ABD",
        devId: "774",
        status: "online",
        type: "route",
        isActive: true
      })
      res.push(deviceSS)
      res.push(deviceL)
      res.push(deviceA)
      res.push(device14)
      res.push(deviceP)*/
    for (resp of res) {//.devices) {
      this.card = new Card(resp);
      if ((this.card.devId != '2') && (this.card.devId != '6') &&
        (this.card.devId != '258') && (this.card.devId != '528') &&
        (this.card.devId != '261') && (this.card.devId != '771') &&
        (this.card.devId != '264') && (this.card.devId != '265') && (this.card.devId != '772')) {
        this.devsToInput.push(resp);
        this.editCheck.push(false)
        this.inRule.push(false)
        this.optionU.push(false)
        this.optionT.push(false)
        this.optionP.push(false)
        this.optionL.push(false)
        this.isCheckedT.push(true);
        this.isCheckedU.push(true);
        this.isCheckedP.push(true);
        this.isCheckedL.push(true);
      }
      if ((this.card.devId == '2') ||
        (this.card.devId == '258') || (this.card.devId == '528') || (this.card.devId == '264') ||
        (this.card.devId == '261') || (this.card.devId == '256') || (this.card.devId == '265') ||
        (this.card.devId == '81') || (this.card.devId == '771') || (this.card.devId == '772')) {
        this.devsToOutput.push(this.card);
        this.outRule.push(false)
        this.flipCard.push(true)
      }
    }
  }
  //preciso
  checkDevsType(devsToOutput) {
    this.vectorTypes = [null, null, null, null, null];
    let count0 = [], count1 = [], count2 = [], count3 = [], count4 = [], count5 = [], count6 = [], count7 = [];
    for (let i = 0; i < devsToOutput.length; i++) {
      if (this.cardOutRule[i].devId == '81' || this.cardOutRule[i].devId === '256') count0.push([devsToOutput[i], this.outRule[i]]);
      if (this.cardOutRule[i].devId == '2') count1.push([devsToOutput[i], this.outRule[i]]); //2
      if (this.cardOutRule[i].devId == '258' || this.cardOutRule[i].devId === '528') { count2.push([devsToOutput[i], this.outRule[i]]); this.whiteLight[i] = this.whiteLight[i] ? this.whiteLight[i] : false; }
      if (this.cardOutRule[i].devId == '771') count3.push([devsToOutput[i], this.outRule[i]]);
      if (this.cardOutRule[i].devId == '261') {
        count4.push([devsToOutput[i], this.outRule[i]]);
        this.whiteLight[i] = this.whiteLight[i] ? this.whiteLight[i] : false;
      }//1
      if (this.devsToOutput[i].devId == '772') count5.push([devsToOutput[i], this.outRule[i]]);
      if (this.devsToOutput[i].devId == '264') count6.push([devsToOutput[i], this.outRule[i]]); //smartswitch
      if (this.devsToOutput[i].devId == '265') count7.push([devsToOutput[i], this.outRule[i]]); //smartpulse
    }
    this.vectorTypes[0] = count0;
    this.vectorTypes[1] = count1;
    this.vectorTypes[2] = count2;
    this.vectorTypes[3] = count3;
    this.vectorTypes[4] = count4;
    this.vectorTypes[5] = count5;
    this.vectorTypes[6] = count6;
    this.vectorTypes[7] = count7;
  }
  //preciso
  checkDevsTypeIn(inputToCheck) {
    let count5 = [], count6 = [], count7 = [], count8 = [], count9 = [], count10 = [];
    for (let i = 0; i < inputToCheck.length; i++) {
      if (this.cardInRule[i].devId == '770') { count5.push([inputToCheck[i], this.inRule[i]]); this.optionU[i] = this.optionU[i] ? this.optionU[i] : false; this.optionT[i] = this.optionT[i] ? this.optionT[i] : false; }
      if (this.cardInRule[i].devId == '12') count6.push([inputToCheck[i], this.inRule[i]]);  //3
      if (this.cardInRule[i].devId == '774') count10.push([inputToCheck[i], this.inRule[i]]);  //3
      if (this.cardInRule[i].devId == '263') count7.push([inputToCheck[i], this.inRule[i]]);
      if (this.cardInRule[i].devId == '81' || this.cardInRule[i].devId === '256') { count8.push([inputToCheck[i], this.inRule[i]]); this.optionP[i] = this.optionP[i] ? this.optionP[i] : false; }
      if (this.cardInRule[i].devId == '262') count9.push([inputToCheck[i], this.inRule[i]]);

    }
    this.vectorTypesIn[0] = count5;
    this.vectorTypesIn[1] = count6;
    this.vectorTypesIn[2] = count7;
    this.vectorTypesIn[3] = count8;
    this.vectorTypesIn[4] = count9;
    this.vectorTypesIn[5] = count10;
  }
  //precisarei
  verifyStateStartOutput(j, n, outputStart) {
    let hue, level, a = 0; this.getOnlyOnOffOfDevs = [];
    //let i = 0;
    let arrayIn = this.outputStart.filter(dev => dev.ieeeAddr == outputStart.devIeeeAddr)
    if (arrayIn[0].cId === "genOnOff") {
      if (arrayIn[0].cmd === "on") {
        this.isDevActive[j] = true;
        this.onOff[j] = true;
        if (arrayIn[0].devId === '261' || arrayIn[0].devId === '258' || arrayIn[0].devId === '528') {
     //     n = n * 3;
          hue = this.outputStart[n + 1].zclData;
          level = this.outputStart[n + 2].zclData;
          this.colorText[j] = hue.hue;
          this.lightLevel[j] = level.level

          if (hue.saturation == '0') {
            this.whiteLight[j] = true;
          } else if (hue.saturation == '254') {
            this.whiteLight[j] = false;
          }
        }
      } else {
        this.isDevActive[j] = false;
        this.onOff[j] = false;

      }
    }
    //this.outputStart = this.getOnlyOnOffOfDevs;
  }
  getInputsCheckedAndValue() {
    for (let j = 0; j < this.checkedDevices.length; j++) {
      for (let i = 0; i < this.inputR.length; i++) {
        if (this.checkedDevices[j].ieeeAddr === this.inputR[i].ieeeAddr) {
          if (this.inputR[i].cId === "msTemperatureMeasurement") {
            this.optionT[j] = true;
            this.isCheckedT[j] = true;
            this.valueT[j] = this.inputR[i].value;
            if (this.inputR[i].condition === ">") {
              this.optionT[j] = true;
            } else {
              this.optionT[j] = false;
            }
          }
          if (this.inputR[i].cId === "msRelativeHumidity") {
            this.optionU[j] = true;
            this.isCheckedU[j] = true;
            this.valueU[j] = this.inputR[i].value;
            if (this.inputR[i].condition === ">") {
              this.optionU[j] = true;
            } else {
              this.optionU[j] = false;
            }
          }
          if (this.inputR[i].cId === "seMetering") {
            this.isCheckedP[j] = true;
            this.optionP[j] = true;
            this.valueP[j] = this.inputR[i].value;
            if (this.inputR[i].condition === ">") {
              this.optionP[j] = true;
            } else {
              this.optionP[j] = false;
            }
          }
          if (this.inputR[i].cId === "msIlluminanceMeasurement") {
            this.isCheckedL[j] = true;
            this.optionL[j] = true;
            this.valueL[j] = this.inputR[i].value;
            if (this.inputR[i].condition === ">") {
              this.optionL[j] = true;
            } else {
              this.optionL[j] = false;
            }
          }
          if (this.inputR[i].cId === "msOccupancySensing") {
            if (this.inputR[i].value == 1) {
              this.state[j] = true;
            } else {
              this.state[j] = false;
            }
          }
          if (this.inputR[i].cId === "genOnOff") {
            if (this.inputR[i].value == 1) {
              this.state[j] = true;
            } else {
              this.state[j] = false;
            }
          }
        }
      }
    }
  }
  ngOnDestroy(): void { }

  datepickerValueChange(event) {
    this.startDay = moment(this.startDay).format('YYYY-MM-DD')// ? event : moment(event).format('YYYY-MM-DD');
  }

  weekdaysChosen(i) {
    this.daysTrueFalse[i] = !this.daysTrueFalse[i];
    this.verifyIfHasOneDay(i);
  }
  //cria um json com as configurações de agendamento (horas e minutos iniciais e finais)
  scheduleConfig() {
    this.startDayFinal = moment(this.startDay).format('DD-MM-YYYY');
    if (this.horaStarts && this.horasEnds) {
      this.horaStarts.length >= 6 ? this.horaStarts : this.horaStarts += ":00";
      this.horasEnds.length >= 6 ? this.horasEnds : this.horasEnds += ":00";
      let data = moment(this.startDay)//this.startDay //+ "T12:01:25.296Z"
      this.interval = {
        "startDay": data,
        "startHour": this.horaStarts,
        "endHour": this.horasEnds
      }
      return true
    } else {
      return false
    }
  }
  //Create a json with input configurations
  createJsonInput(dispositivo, epId, tipo, valor, cId, condicao, i) {
    let cmd;
    if (valor == this.jsonWord.label.on) { cmd = "on"; valor = 1 } else if (valor == this.jsonWord.label.off) { cmd = "off"; valor = 0 } else { cmd = null; }
    this.valueInput[i] =
      {
        "cmd": cmd,
        "name": dispositivo.name,
        "ieeeAddr": dispositivo.ieeeAddr,
        "devId": dispositivo.devId,
        "epId": String(epId),
        "cId": cId, //cId
        "value": Number(parseFloat(valor).toFixed(1)),
        "type": tipo,
        "condition": condicao
      }
    this.allInput = this.valueInput;
  }

  //configurações de dispositivos de entrada
  inputConfig() {
    let count = 0, holdIn = [];
    if (this.checkedDevices) {
      for (let i = 0; i < this.checkedDevices.length; i++) {//trato dispositivos input
        let hold = [], cond, condP = ["nada", "nada"];
        let optionTemp = [], optionUmd = [], optionPot = [], optionLum = []
        if (this.checkedDevices[i].devId === '770') {//se for sensor HT ou sensor de liminosidade
          hold[0] = this.checkedDevices[i];
          if (this.isCheckedT[i]) { //se temperatura marcada, pego condiçao+valor
            this.valueT[i] = document.getElementById('tempId' + this.checkedDevices[i].ieeeAddr)['value']
            if (!this.valueT[i]) {
              // this.showSimpleDialog(this.jsonWord.error.required)//"Insira um valor no campo!")
              return;
            } else {
              optionTemp[i] = this.optionT[i] ? this.jsonWord.label.greater : this.jsonWord.label.lower;
              condP[0] = this.jsonWord.label.ifTemp + optionTemp[i] + " " + this.jsonWord.label.than + " " + this.valueT[i] + ". "
              this.createJsonInput(this.checkedDevices[i], 8, "measuredValue", this.valueT[i], "msTemperatureMeasurement", (this.optionT[i] ? ">" : "<"), count);
              count++;
            } this.checkT = true;
          }
          if (this.isCheckedU[i]) {
            this.valueU[i] = document.getElementById('umidId' + this.checkedDevices[i].ieeeAddr)['value']
            if (!this.valueU[i]) {
              //this.showSimpleDialog(this.jsonWord.error.required + 'umidade')//"Insira um valor no campo!")
              return;
            } else {
              optionUmd[i] = this.optionU[i] ? this.jsonWord.label.greater : this.jsonWord.label.lower;
              condP[1] = " " + this.jsonWord.label.ifHum + optionUmd[i] + " " + this.jsonWord.label.than + " " + this.valueU[i] + ". "
              this.createJsonInput(this.checkedDevices[i], 8, "measuredValue", this.valueU[i], "msRelativeHumidity", (this.optionU[i] ? ">" : "<"), count);
              count++;
            } this.checkU = true;
          }
          if (!this.isCheckedU[i] && !this.isCheckedT[i]) {
            this.checkT = false;
            this.checkU = false;
          }
          cond = condP;
        }
        if (this.checkedDevices[i].devId === '262') {//luminosidade
          //TODO
          hold[0] = this.checkedDevices[i];
          if (this.isCheckedL[i]) { //se temperatura marcada, pego condiçao+valor
            if (!this.valueL[i]) {
              // this.showSimpleDialog(this.jsonWord.error.required)
              return;
            } else {
              optionLum[i] = this.optionL[i] ? this.jsonWord.label.greater : this.jsonWord.label.lower;
              condP[0] = this.jsonWord.label.ifLum + optionLum[i] + " " + this.jsonWord.label.than + " " + this.valueL[i] + " ";
              this.createJsonInput(this.checkedDevices[i], 8, "measuredValue", this.valueL[i], "msIlluminanceMeasurement", (this.optionL[i] ? ">" : "<"), count);
              count++;
            } this.checkP = true;
          } else {
            this.checkP = false;
          }
          cond = condP;
        } else { this.checkP = true; }
        if (this.checkedDevices[i].devId === '81' || this.checkedDevices[i].devId === '256') {
          hold[0] = this.isCheckedP[i];
          if (this.isCheckedP[i]) { //se temperatura marcada, pego condiçao+valor
            this.valueP[i] = document.getElementById('potId' + this.checkedDevices[i].ieeeAddr)['value']
            if (!this.valueP[i]) {
              //   this.showSimpleDialog(this.jsonWord.error.required)//"Insira um valor no campo!")
              return;
            } else {
              optionPot[i] = this.optionP[i] ? this.jsonWord.label.greater : this.jsonWord.label.lower;
              condP[0] = this.jsonWord.label.ifPot + optionPot[i] + " " + this.jsonWord.label.than + " " + this.valueP[i] + " ";
              this.createJsonInput(this.checkedDevices[i], 8, "instantaneousDemand", this.valueP[i], "seMetering", (this.optionP[i] ? ">" : "<"), count);
              count++;
            } this.checkP = true;
          } else {
            this.checkP = false;
          }
          cond = condP;
        } else { this.checkP = true; }
        if (this.checkedDevices[i].devId === '12' || this.checkedDevices[i].devId === '774') {
          hold[0] = this.checkedDevices[i];
          if (this.state[i]) {
            condP[0] = this.checkedDevices[i].devId === '12' ? this.jsonWord.label.open : this.jsonWord.label.withWater  //"Aberto";
            this.createJsonInput(this.checkedDevices[i], 8, "onOff", this.jsonWord.label.on, "genOnOff", "=", count)
            count++;
          }
          else {
            condP[0] = this.checkedDevices[i].devId === '12' ? this.jsonWord.label.closed : this.jsonWord.label.withoutWater //"Fechado"
            this.createJsonInput(this.checkedDevices[i], 8, "onOff", this.jsonWord.label.off, "genOnOff", "=", count)
            count++;
          }
          this.condSignal[i] = "="
          cond = condP;
        }
        if (this.checkedDevices[i].devId === '263') {
          hold[0] = this.checkedDevices[i];
          if (this.state[i]) {
            condP[0] = this.jsonWord.label.withPresence//"Presença"
            this.createJsonInput(this.checkedDevices[i], 8, "occupancy", this.jsonWord.label.on, "msOccupancySensing", "=", count)
            count++;
          }
          else {
            condP[0] = this.jsonWord.label.withoutPresence//"Sem presença"
            this.createJsonInput(this.checkedDevices[i], 8, "occupancy", this.jsonWord.label.off, "msOccupancySensing", "=", count)
            count++;
          }
          this.condSignal[i] = "="
          cond = condP;
        }
        holdIn[i] = (hold);
        this.condition[i] = cond;
      }
      this.input = holdIn;
    }
  }
  //create a json with output configurations
  createJsonOutput(dispositivo, i) {
    this.getValueFromDevicesToBeEdited()
    if (this.output[i][0] != 'nada' && this.output[i][1] === "Desativada") { //If it's to turn any devices off
      this.outputR.push({
        name: dispositivo.title,
        ieeeAddr: dispositivo.devIeeeAddr,
        epId: String(dispositivo.devEpList[0]),
        devId: dispositivo.devId,
        cId: "genOnOff",
        cmd: "off",
        zclData: {}
      })
    } else { //if diferent of lamp and if it's turn devices on
      this.outputR.push({
        name: dispositivo.title,
        ieeeAddr: dispositivo.devIeeeAddr,
        epId: String(dispositivo.devEpList[0]),
        devId: dispositivo.devId,
        cId: "genOnOff",
        cmd: "on",
        zclData: {}
      })
      if (dispositivo.devId === "258" || dispositivo.devId === "528" || dispositivo.devId === "261") {//If colorful lamps and if it's turn them on
        this.outputR.push({
          name: dispositivo.title,
          ieeeAddr: dispositivo.devIeeeAddr,
          epId: String(dispositivo.devEpList[0]),
          devId: dispositivo.devId,
          cId: "lightingColorCtrl",
          cmd: "moveToHueAndSaturation",
          zclData: { hue: parseInt(this.output[i][3]), saturation: parseInt(this.output[i][4]), transtime: 0 }
        }, {
            name: dispositivo.title,
            ieeeAddr: dispositivo.devIeeeAddr,
            epId: String(dispositivo.devEpList[0]),
            devId: dispositivo.devId,
            cId: "genLevelCtrl",
            cmd: "moveToLevel",
            zclData: { level: parseInt(this.output[i][2]), transtime: 0 }
          });
      }
    }
  }

  outputConfigEnd() {
    this.getValueFromDevicesToBeEdited()
    let holdOut = [];
    for (let i = 0; i < this.checkedDevices2.length; i++) {//dispositivos output
      let hold = [];
      hold[0] = this.checkedDevices2[i];
      hold[1] = this.isDevActive[i] ? "Ativada" : "Desativada"
      hold[2] = (!this.lightLevel[i] ? "127" : this.lightLevel[i]);
      hold[4] = (this.whiteLight[i] ? "0" : "254")
      this.white[i] = (this.whiteLight[i] ? "100" : "50")
      //  this.levelToShow[i] = (hold[2] / 254) * 100
      // this.levelToShow[i] = parseInt(this.levelToShow[i])
      hold[3] = this.colorText[i]
      this.colorful[i] = (this.colorText[i] / 254) * 360;
      this.levelToShow[i] = (hold[2] / 254) * 100
      this.levelToShow[i] = parseInt(this.levelToShow[i])
      holdOut[i] = (hold);
    }
    this.output = holdOut;
    return this.output;
  }

  //Works
  verifyIfHasOneDay(j) {
    this.jsonDays = []
    for (let i = 0; i < this.daysTrueFalse.length; i++) {
      if (this.daysTrueFalse[i]) {
        this.jsonDays.push({ "day": i });
        this.daysChecked = i;
      }
    }
    this.count1 = 0;
    if (this.jsonDays.length < 1) {
      this.daysTrueFalse[j] = true;
      this.showSimpleDialog(this.jsonWord.text.atLeastOneDay)//"Escolha ao menos um dia!!")
      return
    }
    return this.daysChecked;
  }

  getValueFromDevicesToBeEdited() {
    for (let i = 0; i < this.onOff.length; i++) {
      this.onOff[i] = (this.isDevActive[i] ? true : false)
    }
  }
  verifyCheckedScenario() {
    let holdScn = [], a = 0;
    for (let i = 0; i < this.scenChecked.length; i++) {
      for (let j = 0; j < this.scnName.length; j++) {//matCheck1[i].classList[matCheck1[i].classList.length - 1] === "mat-checkbox-checked"
        if (this.scnName[j].name && ((this.scnName[j].name).trim() === (this.scenChecked[i].outerText).trim())) {//if ieeeAddr?
          holdScn[a] = (this.scnName[j]);
          a++;
        }
      }
      this.checkedScenarios = holdScn;
    }
  }
  goToRules() {
    this.router.navigate(['home/rules']);
  }

  rotateCard(i) {
    this.flipCard[i] = !this.flipCard[i];
  }

  goToPage(page: number) {
    let holdElemId = [], holdChecked = [], holdCheckedName = [], holdCheckedSc = [];
    switch (page) {
      case 1:
        if (this.count1 == 0) {
          this.index = "#1a"
          this.page = 1;
          this.count1++;
        }
        break
      case 2:
        if (!this.nameRule) {
          this.showSimpleDialog(this.jsonWord.error.requiredName)
          this.nome = false;
          return;
        } else {
          this.nome = true;
        }
        //Verify if scenario name alredy exists, and an alert will be sent
        if (this.allRules) {
          for (let item of this.allRules) {
            if (item.name === this.nameRule && item._id != this.id) {
              this.nome = false;
              this.showSimpleDialog(this.jsonWord.toast.ruleAlreadyRegistered)
              return;
            } else {
              this.nome = true;
            }
          }
        }
        this.count = 0;
        this.verifyIfHasOneDay(0)
        if (this.scheduleConfig() && this.nome) {
          this.index = "#2a"
          this.page = 2;
        } else {
          this.showSimpleDialog(this.jsonWord.toast.invalidValueHour)//"Coloque um horário válido!")
          this.index = "#1a"
          this.page = 1;
        }
        break
      case 3:
        for (let i = 0; i < this.cardInRule.length; i++) {
          holdElemId.push(<any>document.getElementById(this.cardInRule[i].ieeeAddr))
          if (holdElemId[i] && holdElemId[i].checked) {//classList[holdElemId[i].classList.length - 1] === "mat-checkbox-checked") {
            holdChecked.push(this.cardInRule[i])
          }
        }
        this.checkedDevices = holdChecked
        this.getInputsCheckedAndValue()
        this.scheduleConfig();
        if (this.checkedDevices.length < 1) {
          this.count = 0
        }
        if (this.checkedDevices && this.checkedDevices.length >= 1) {
          this.checkedDevices = this.checkedDevices.sort((a, b) =>
            Number(a.devId) < Number(b.devId) ? -1 : Number(a.devId) > Number(b.devId) ? 1 :
              a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
          this.index = "#3a"
          this.page = 3;
        } else {
          if (this.count === 0) {
            this.showDialogInputChecked()
            this.index = "#2a"
            this.page = 2;
          } else {
            this.index = "#3a"
            this.page = 3;
          }
        }
        break
      case 4:
        this.countFinal1 = 0;
        this.countPage6 = 0;
        this.count = 1;
        this.inputConfig();
        this.verifyIfAtLeastOneIsChecked();
        if (<any>document.getElementsByClassName('ng-invalid').length) {
          this.index = "#3a"
          this.page = 3;
          this.showSimpleDialog(this.jsonWord.error.required)
          break;
        }
        for (let i = 0; i < this.cardOutRule.length; i++) {
          holdElemId.push(<any>document.getElementById(this.cardOutRule[i].devIeeeAddr))
          holdCheckedName.push(<any>document.getElementsByName(this.cardOutRule[i].devIeeeAddr))
          if (holdElemId[i] && holdElemId[i].checked) {//classList[holdElemId[i].classList.length - 1] === "mat-checkbox-checked") {
            holdChecked.push(this.cardOutRule[i])
          }
          if (holdCheckedName[i] && holdCheckedName[i].checked) {//classList[holdElemId[i].classList.length - 1] === "mat-checkbox-checked") {
            holdChecked.push(this.cardOutRule[i])
          }
        }

        this.checkedDevices2 = holdChecked
        if (this.checkedDevices2 && this.checkedDevices2.length < 1) {
          this.countFinal1 = 0
        }
        if (this.checkP && this.withoutValueP) { //falta verigicar se temp e um tbm sao validos
          this.checkedDevices2 ? this.checkedDevices2.sort((a, b) =>
            //a.status == 'ONLINE' && b.status == "OFFLINE" ? 1 : a.status != 'ONLINE' && b.status != "OFFLINE" ? -1 :
            Number(a.devId) < Number(b.devId) ? -1 : Number(a.devId) > Number(b.devId) ? 1 :
              a.name < b.name ? -1 : a.name > b.name ? 1 :
                a.created < b.created ? -1 : a.created > b.created ? 1 : 0) : this.checkedDevices2;
          this.page = 4;
          this.index = "#4a";
        } else {
          if (!this.checkP) { this.showSimpleDialog(this.jsonWord.label.selectDeviceRule) }//"Marque a opção dos dispositivos!") }
          this.page = 3;
          this.index = "#3a";
        }
        break
      case 5:
        this.flipCard = []
        let isInInput = [], arrayInput = [], n = 0;
        this.devConfigd = null;
        for (let i = 0; i < this.cardOutRule.length; i++) {
          this.flipCard[i] = true
          holdElemId.push(<any>document.getElementById(this.cardOutRule[i].devIeeeAddr))
          holdCheckedName.push(<any>document.getElementsByName(this.cardOutRule[i].devIeeeAddr)[0])
          if (holdElemId[i] && holdElemId[i].checked) {//classList[holdElemId[i].classList.length - 1] === "mat-checkbox-checked") {
            holdChecked.push(this.cardOutRule[i])
            this.countFinal1 = 1;
          }
          if (holdCheckedName[i] && holdCheckedName[i].checked) {//classList[holdElemId[i].classList.length - 1] === "mat-checkbox-checked") {
            holdChecked.push(this.cardOutRule[i])
            this.countFinal1 = 1;
          }
        }
        if (holdChecked.length > 0) {
          this.onOff = [], this.isDevActive = []
          for (let j = 0; j < this.outputStart.length; j++) {
            if (this.outputStart[j].cId == "genOnOff") {
              isInInput.push(holdChecked.filter(dev => dev._id == this.outputStart[j].ieeeAddr)[0])
            }
          }
          arrayInput = isInInput.filter(item=>item)
          let lampOn = 0
          for (let j = 0; j < arrayInput.length; j++) {
            var index = (holdChecked.findIndex(input=>input.ieeeAddr == arrayInput[j]._id));
            if (index < 0) {
              this.isDevActive[j] = holdChecked[j].isActive ? true : false
              this.onOff[j] = holdChecked[j].isActive ? true : false
              this.colorText[j] = holdChecked[j].value2;
              this.lightLevel[j] = holdChecked[j].value1;
              this.whiteLight[j] = holdChecked[j].value3
            } else {
             // if(arrayInput[index].cmd == 'on') 
              this.verifyStateStartOutput(j, index, arrayInput[index])
              // n++
            }
          }
        }
        this.checkedDevices2 = holdChecked
    /*   let holdScenario = []
        //Working!! caso seja configuraçao de novo cenario, chama  a funçao
        for (let i = 0; i < this.scnName.length; i++) {
          holdScenario.push(<any>document.getElementById(this.scnName[i][0]._id))
          if (holdScenario[i] && holdScenario[i].checked) {//classList[holdElemId[i].classList.length - 1] === "mat-checkbox-checked") {
            holdCheckedSc.push(this.scnName[i][0])
            this.countPage6 = 1;
          }
        }
        this.checkedScenarios = holdCheckedSc;*/
        let holdRule = [], holdCheckedRule = [];
        for (let i = 0; i < this.rulesToOutput.length; i++) {
          holdRule.push(<any>document.getElementById(this.rulesToOutput[i][0]._id))
          if (holdRule[i] && holdRule[i].checked) {
            holdCheckedRule.push(this.rulesToOutput[i][0])
						let index = this.inputRule.findIndex(inputR => inputR.ruleId == this.rulesToOutput[i][0]._id)
						if(index >=0) this.isActiveRule[i] = this.inputRule[index].isActive
						else this.isActiveRule[i] = this.rulesToOutput[i][0].isActive
            this.countPage1 = 1;
          }
        }
        this.holdCheckedRule = holdCheckedRule;

        
        if (this.checkedDevices2.length < 1 && this.holdCheckedRule.length < 1) {
          this.countFinal1 = 0;
          this.countPage6 = 0;
          this.countPage1 = 0;
        }
        if (this.checkedDevices2.length < 1 && this.countPage1 == 0) {
          this.countFinal1 = 0
        }
        if(this.checkedDevices2.length < 1 && this.holdCheckedRule.length < 1){
          this.page = 7;
          this.index = "#7a"
          page = 7
          this.goToPage(page)
        }
        if (this.countFinal1 == 0 && this.countPage6 == 0 && this.countPage1 == 0) {
          this.showSimpleDialog(this.jsonWord.text.noScenarioActuatorRegistered)
          this.index = "#4a"
          this.page = 4;
        } else {
          if ((this.checkedDevices2.length > 0 && this.holdCheckedRule.length > 0) || (this.checkedDevices2.length > 0 && this.holdCheckedRule.length < 1)) { //se tem dispositivo
            this.page = 5;
            this.index = "#5a"
          }
          
          if (this.checkedDevices2.length < 1 && this.holdCheckedRule.length > 0) { //ou tem regra e disp ou so tem regra
            this.page += 1
            this.page = 6;
            this.index = "#6a"
          }
        }
        break;
      case 6:
        this.holdCheckedRule = this.holdCheckedRule
        if (this.holdCheckedRule.length > 0) { //se tem regra
          this.page = 6;
          this.index = "#6a"
          break;
        } else {                   //não tem regra
          this.page = 7;
          this.index = "#7a"
          page += 1
          this.goToPage(page)
          //break;
        }
        break;
      case 7:
        this.page = 7;
        this.index = "#7a";
        this.devConfigd = null;
        if (this.countFinal1 == 1) {
          this.devConfigd = this.outputConfigEnd();
        }
        break
      default:
        break
    }
  }
  setRuleOnOff(rule, i) {
    this.isActiveRule[i] = !this.isActiveRule[i]
  }
  verifyIfAtLeastOneIsChecked() {
    let holdTU = [], count = [], hold = [], holdT = [], holdU = [], count2 = [];
    for (let i = 0; i < this.checkedDevices.length; i++) {
      if (this.checkedDevices[i].devId === "256" || this.checkedDevices[i].devId === "81") {
        if (this.isCheckedP[i]) {
          this.valueP[i] && this.valueP[i].length > 0 ? hold[i] = true : hold[i] = false
          /*  console.log(this.valueP)
            holdTU.push(true);
            //verifica se há valor quando checked
            this.valueP[i] ? hold.push(true) : hold.push(false);
          } else {
            holdTU.push(false);
          }*/
        }
      }
      if (this.checkedDevices[i].devId === "770") {
        if (this.isCheckedT[i] || this.isCheckedU[i]) {
          //verifica se há valor quando checked
          holdTU.push(true);
          if (this.isCheckedT[i]) {
            if (this.valueT[i] && this.valueT[i].length > 0) {
              holdT[i] = (true)
            } else holdT[i] = (false)
          } else { holdT[i] = true }
          if (this.isCheckedU[i]) {
            if (this.valueU[i] && this.valueU[i].length > 0) {
              holdU[i] = (true)
            } else holdU[i] = (false)
          } else { holdU[i] = true }
          if (holdU[i] && holdT[i]) { hold[i] = (true); } else hold[i] = (false)
        }
        else {
          holdTU.push(false);
        }

        /* for (let i = 0; i < holdTU.length; i++) {
           if (!holdTU[i]) {
             count++;
             console.log("count - hold,",count)
             this.checkP = true;
             break;
           }
           else this.checkP = false;
         }
          for (let i = 0; i < hold.length; i++) {
           console.log("hold", hold)
           if (hold[i]) {
             count2++;
             console.log("count2 - hold,",count2)
             this.withoutValueP = false;
             break;
           }
           else this.withoutValueP = true;
         }
       
        if (count <= 0) {
           this.checkP = true;
         } else { this.checkP = false; }
     
        console.log("COUNT DO CARALHO", count2)
         if (count2 > 0) {
           this.withoutValueP = true;
         } else { this.withoutValueP = false; }*/
      }
      count = holdTU.filter(res => res == false)
      count2 = hold.filter(res => res == false)
      this.withoutValueP = count2[0] == false ? false : true
      this.checkP = count[0] == false ? false : true
    }
  }
	setIcon() {
		this.modalRef = this.modalService.show(IconsScenarioModalComponent);
		this.modalRef.content.imgsScenario = this.imgsScenario
		this.modalRef.content.onClose.subscribe(res => {
			this.iconScenario = res
			this.iconScenarioWhite = (res.length < 7 ? res.slice(0, 6) + '_white.svg' : res.slice(0, 7) + '_white.svg')
		})
	}
  formatRules() {
    let idsRules = [];
		for (let i = 0; i < this.holdCheckedRule.length; i++) {
			idsRules.push({
				"ruleId": this.holdCheckedRule[i]._id,
				"isActive": this.isActiveRule[i]
			})
		}
    return idsRules
	}
  /**
   * @function onSaveRule() get final values of rules and create a json, sending by mqtt to create a new rule
   */
  onSaveRule() {
    if (this.nome) {
      this.saveClicked = 1;
      let idsScenarios = [],idsRules = [];
      let inputFinal = this.allInput;
      for (let i = 0; i < this.output.length; i++) {
        if (this.output[i][0] != 'nada')
          this.createJsonOutput(this.output[i][0], i)
      }
      if (this.checkedScenarios) {
        for (let i = 0; i < this.checkedScenarios.length; i++) {
          idsScenarios.push({ "scenarioId": this.checkedScenarios[i]._id });
        }
      }
      if (this.holdCheckedRule) idsRules=this.formatRules()

      this.schedule = { interval: this.interval, weekdays: this.jsonDays }
      if (this.nome) {
        this.saveClicked = 1;
        let message;
        message = {
          'name': this.nameRule,
          'inputs': inputFinal ? inputFinal : [],
          'outputs': this.outputR,
					'isActive': true,
          'schedule': this.schedule,
          'sceneOutputs': [],//idsScenarios,
          'ruleOutputs': idsRules,
          "icon": this.iconScenario,
        }
        this.openLoadingModal(this.jsonWord.loading.message)
        this.rulesService.updateRule(this.id, JSON.stringify(message)).subscribe(res => {
          if (res) {
            this.loadingModalRef.content.success = true
            this.loadingModalRef.content.waiting = false
            this.loadingModalRef.content.message = this.jsonWord.toast.editRuleSuccess
            setTimeout(() => {
              this.loadingModalRef.hide()
              this.router.navigate(['home/rules']);
            }, 2000);
          } else {
            this.loadingModalRef.content.success = true
            this.loadingModalRef.content.waiting = false
            this.loadingModalRef.content.message = this.jsonWord.toast.editConflictedRuleSuccess
            setTimeout(() => {
              this.loadingModalRef.hide()
              this.router.navigate(['home/rules']);
            }, 2000);
          }
        }, err => {
          let message = ''
          switch (err.status) {
            case 0:
              message = this.jsonWord.error.noConnection//'Formulário de convidar usuário inválido.'
              //this.noInternetAlert()
              break
            case 400:
              message = this.jsonWord.error.invalidFormat//'Formulário de convidar usuário inválido.'
              break
            case 401:
              message = this.jsonWord.error.notAuthorized//'Você não tem permissão para convidar usuários.'
              break
						/*	case 403:
								message = this.jsonWord.error.userDisabled//'Usuário está desativado.'
								break*/
            case 404:
              message = this.jsonWord.error.ruleNotFound//'Usuário não encontrado ou número serial de gateway não registrado.'
              break
            case 408:
              message = this.jsonWord.error.timeoutServer;//'Tempo limite de conexão atingido.'
              break
						/*	case 409:
								message = this.jsonWord.error.userAlreadyInGtw//'Usuário já associado ao gateway.'
								break
							case 412:
								message = this.jsonWord.error.limitUserExceeded//'Usuário já associado ao gateway.'
								break*/
            case 500:
              message = this.jsonWord.toast.gatewayCommunicationError//'Não foi possível enviar convite.'
              break
            default:
              message = this.jsonWord.toast.editRuleError

          }
          this.loadingModalRef.content.success = false
          this.loadingModalRef.content.waiting = false
          this.loadingModalRef.content.message = message//this.jsonWord.toast.editRuleError
          setTimeout(() => {
            this.loadingModalRef.hide()
            this.router.navigate(['home/rules']);
          }, 2000)
        })
      }
    }
  }

  openLoadingModal(message: String) {
    this.loadingModalRef = this.modalService.show(
      LoadingModalComponent, {
        class: 'waiting-modal modal-sm',
        keyboard: false,
        backdrop: 'static'
      }
    )
    this.loadingModalRef.content.message = message;
  }
  showSimpleDialog(message) {
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.buttonCancel = { show: false, text: this.jsonWord.button.cancel };
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
    this.modalRef.content.type = 'warning'
    this.modalRef.content.title = this.jsonWord.alert.attention
    this.modalRef.content.message = message
    this.modalRef.content.onClose.subscribe(result => { console.log(result) })
  }

  showDialogInputChecked() {
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.remain };
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.next };
    this.modalRef.content.type = 'warning'
    this.modalRef.content.title = this.jsonWord.alert.attention
    this.modalRef.content.message = this.jsonWord.text.noSelectedInputs
    this.modalRef.content.onClose.subscribe(result => {
      this.next = result;
      if (this.next) {
        this.index = "#4a"
        this.page = 4;
        this.count = 1;
      } else {
        this.index = "#2a"
        this.page = 2;
      }
    });
  }

  changeCheck(device, checkDoor) {
    device[1] = checkDoor.checked
  }

  changeCheckScn(scn, checkScn) {
    scn[1] = checkScn.checked
  }
  sendOnOff(card, i) {
    this.isDevActive[i] = !this.isDevActive[i];
  }
  changeCheckRule(rule, checkRule) {
    rule[1] = checkRule.checked
  }

  onSmartlightLevelChange(levelV, i) {
    this.lightLevel[i] = levelV;
    return this.lightLevel;
  }
  onSmartlightColorChange(colorV, i) {
    this.colorText[i] = colorV;
    return this.colorText;
  }

  getColor(i, value2) {
    this.colorText[i] = this.colorText[i] ? this.colorText[i] : value2;
    return this.colorText[i]
  }
  getLevel(i, value1) {
    this.lightLevel[i] = this.lightLevel[i] ? this.lightLevel[i] : value1;
    return this.lightLevel[i];
  }
  whiteClicked(i) {
    this.whiteLight[i] = !this.whiteLight[i];
  }

  getValueOnOff(check, i) {
    this.editCheck[i] = check.checked;
    this.onOff[i] = this.editCheck[i]
  }

  /**
   * @param checkP @param checkU  @param checkT verify if power, temperatire and/or humidity are checked
   */
  checkTemperature(checkT, i) {
    this.isCheckedT[i] = checkT.checked;
    return this.isCheckedT[i];
  }

  checkHumidity(checkU, i) {
    this.isCheckedU[i] = checkU.checked;
    return this.isCheckedU[i];
  }

  checkPower(checkP, i) {
    this.isCheckedP[i] = checkP.checked;
    return this.isCheckedP[i];
  }

  getOptionValue(value, i) {
    return value[i];
  }
  setLetterBGColor(i) {
    if (this.daysTrueFalse[i]) {
      return this.backgroundColor = "rgba(146, 202, 83, 0.8)";
    } else {
      return this.backgroundColor = "rgba(255,255,255,0.2)";
    }
  }
  setLetterColor(i) {
    if (this.daysTrueFalse[i]) {
      return this.color = "whitesmoke";
    } else {
      return this.color = "rgba(255,255,255,0.5)";
    }
  }

  previousPage() {
    if (this.page > 1){
      if(this.page==7){
        if(this.holdCheckedRule.length < 1){
            if(this.checkedDevices2.length < 1) //se não tem disp, nem regra, ou seja, apenas cenário
              this.page = this.page - 3;
            else//se não tem regra, mas tem disp
              this.page = this.page - 2;
        }else{
          this.page = this.page - 1;//se tem regra
        }
      } else if(this.page==6){
        if(this.checkedDevices2.length > 0) //se tem disp
              this.page = this.page - 1;
            else//se não tem disp
              this.page = this.page - 2;
      }else{
        this.page = this.page-1
      }
    }
  }

  nextPage() {
    if (this.page < 7) {
      this.goToPage(this.page + 1)
    }
  }

  setArrow(i) {
    this.arrowTF[i] = !this.arrowTF[i]
    switch (i) {
      case (0):
        document.getElementById('arrowSP').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
      case (1):
        document.getElementById('arrowS').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
      case (2):
        document.getElementById('arrowRGB').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
      case (3):
        document.getElementById('arrowSPP').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
      case (4):
        document.getElementById('arrowLed').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
      case (5):
        document.getElementById('arrowScn').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
      case (6):
        document.getElementById('arrowTP').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
      case (7):
        document.getElementById('arrowOC').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
      case (8):
        document.getElementById('arrowOcc').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
      case (9):
        document.getElementById('arrowSP2').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
      case (10):
        document.getElementById('arrowL').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
      case (11):
        document.getElementById('arrowW').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
      case (12):
        document.getElementById('arrowTurn').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
      case (13):
        document.getElementById('arrowSwitch').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
      case (14):
        document.getElementById('arrowPulse').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
        break;
    }
  }
}
import { UserService } from './../_services/user.service';
import { TranslateJsonService } from './../_services/translate-json.service';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import * as config from '../config'
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { GatewayService } from '../_services/gateway.service';
import { Subscription, Observable, from, Subject } from 'rxjs';
import { AlertComponent } from '../_directives/alert.component';
import { v4 as uuid } from 'uuid';
import * as moment from 'moment';
import { LoginService } from '../login/login.service';
import { LoadingModalComponent } from '../_directives/loading-modal/loading-modal.component';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { finalize } from 'rxjs/operators';
import { HomeComponent } from '../home/home.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { EULA } from './term.js';
/*import * as dataPt from './../pt.json';
//import * as dataSp from './../en.json';
import * as dataEn from './../en.json';*/

const utf8Decoder = new TextDecoder("utf-8")
/*const jsonPt = dataPt.default
const jsonEn = dataEn.default*/

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
  // providers:[TranslateJsonService]
})
export class SettingsComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  currentGateway: any
  modalRef: BsModalRef
  authModalRef: BsModalRef
  language: String
  selectedLanguage: String
  subscription: Subscription
  loading = false
  gateway;
  currentUserType;
  jsonWord
  name
  uuid = require('uuid/v4');
  //currentGateway;
  //loading = false
  public version = { version: '0.0.0.2' }
  //translateJson:TranslateJsonService

  constructor(
    private readonly location: Location,
    private modalService: BsModalService,
    private _mqttService: MqttService,
    private translateJson: TranslateJsonService,
    private gatewayService: GatewayService,
    private loginService: LoginService,
    public homeComponent: HomeComponent,
    //private settingService:SettingService,
    private router: Router,
    private userService: UserService,
    //public translate: TranslateService,
    private cookieService: CookieService
  ) {
    this.language = this.selectedLanguage = localStorage.getItem('language')// = this.translate.currentLang //localStorage.getItem('language')
    if (this.language)
      this.jsonWord = this.translateJson.changeLanguage(this.language)
  }

  ngOnInit() {
    this.gateway = this.gatewayService.getGateway()
    this.location.replaceState('/')
    this.currentUserType = this.homeComponent.currentUserType;
    /*this.translate.getTranslation('APP_NAME').subscribe(res=>{
      console.log(res)
    })*/
    /*this.translate.get('APP_NAME').subscribe(res => {
      console.log(res)
    })*/
    this.gatewayService.gatewayObservable.subscribe(
      gateway => {
        if (gateway) {
          this.currentGateway = gateway.id
          this.loading = true
          setTimeout(() => {

            this.loading = false
          }, 2000);
          //this.updateAll()
        }
      }
    )
    this.gatewayService.gatewayObservable
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        gateway => {
          if (gateway) {
            this.name = gateway.name
            if (!this.currentGateway || this.currentGateway.id != gateway.id) {
              this.currentGateway = gateway
              this.loading = true
              //this.updateAll()
            }
          }
        }
      )
  }

  switchLanguage(languageEvent) {
    /* let language = (languageEvent)
     console.log(languageEvent)
     //this.translate.use(language);
     localStorage.setItem('language', String(language));
     this.language = localStorage.getItem('language');
     this.jsonWord = this.translateJson.changeLanguage(this.language);
     console.log(this.jsonWord)
     //console.log(this.translateJson.changeLanguage(language))
     var userId = localStorage.getItem('currentUserId')
 
    // var sessionData = this.cookieService.get(userId);
  //   console.log(sessionData['language'])
     // console.log("ANTES DO COOKIE", gateway, userId, this.cookieService.get(userId))
      var sessionData = JSON.parse(this.cookieService.get(userId))
      console.log(sessionData,sessionData['language'])
      //var lang = sessionData.map(res=> res == 'language')
      //console.log(lang)
      if (sessionData) {
        sessionData['language'] = this.language
        console.log(sessionData)
      this.cookieService.set(userId, JSON.stringify(sessionData))
      } 
    
    /* try {
       var sessionData = JSON.parse(this.cookieService.get(userId));
     } catch(err) {}
     sessionData['language'] = language*/
    // this.cookieService.set(userId, JSON.stringify(sessionData))
    let language = String(languageEvent)
    // this.translate.use(language);
    localStorage.setItem('language', language)
    this.jsonWord = this.translateJson.changeLanguage(language)
    var userId = localStorage.getItem('currentUserId')
    try {
      var sessionData = JSON.parse(this.cookieService.get(userId));
    } catch (err) { }
    sessionData.language = language
    this.cookieService.set(userId, JSON.stringify(sessionData))
    let body = {
      "language": language ? language : "pt",
      "session": {
        "tokenId": localStorage.getItem('currentUserToken'),
        "deviceId": "."
      }
    }
    var gateway = localStorage.getItem('currentGateway')
    this.userService.updateSessionUser(userId, body).subscribe(res => {
      //if(this.cookieService.get())
      localStorage.setItem("language", res['data'].language)
      this.userService.getUserByID(userId).subscribe(res=>{console.log("user", res)})
      this.homeComponent.ngOnInit()
    },err=>{
      console.log("erro ",err)
    })
  }

  factoryReset() {
    //ecomfort/iot/v1/s2g/gateway/hzUaSRJbpBKTMPAq/reset
    //const topic = config.gtwTopic + this.gateway.id + "/reset"
    /*  this.openConfirmDialog()
        .concatMap(res => {
          if (res) {
            return this.openInputDialog()
          } else
            return ErrorObservable.create('Reset canceled by user.')
        }).concatMap(res => {
          if (res != false) {
            console.log(res)
            this.openLoadingModal(this.jsonWord.loading.processingRequire)//'Processando requisição...')
            return this.gatewayService.resetGateway({ password: res })
          } else
            return ErrorObservable.create('Reset canceled by user.')
        }).map(res => {
          console.log(res)
          res.subscribe(res=>{
            console.log(res)
            if(res['data'] == "Entity removed"){
              this.modalRef.content.message = this.jsonWord.success.gatewayReset//'Gateway restaurado com sucesso.'
              this.modalRef.content.success = true
              this.modalRef.content.waiting = false
            }else{
              this.modalRef.content.message = "deu ruim"//this.jsonWord.success.gatewayReset//'Gateway restaurado com sucesso.'
              this.modalRef.content.success = false
              this.modalRef.content.waiting = false
            }
          })
          
        })*/
        this.openConfirmDialog()
        .concatMap(res => {
          if (res) {
            return this.openInputDialog()
          } else
            return ErrorObservable.create('Reset canceled by user.')
        }).concatMap(res => {
          if (res != false) {
            this.openLoadingModal(this.jsonWord.loading.processingRequire)//'Processando requisição...')
            return this.gatewayService.resetGateway({ password: res })
          } else
            return ErrorObservable.create('Reset canceled by user.')
        }).map(res => {
          this.modalRef.content.message = this.jsonWord.success.gatewayReset//'Gateway restaurado com sucesso.'
          this.modalRef.content.success = true
          this.modalRef.content.waiting = false
        })
        .takeUntil(this.ngUnsubscribe)
        .subscribe(res => {
          let timer = setTimeout(() => {
            this.router.navigateByUrl('/home/panel')
            this.homeComponent.ngOnInit()
            this.modalRef.hide()
          }, 2000)
        }, err => {
          let message = ''
          switch (err.status) {
            case 0:
            message = this.jsonWord.error.noConnection;//'Campos de email ou senha inválidos.'
              //this.noInternetAlert()
            break
            case 400:
              message = this.jsonWord.error.gatewayInvalidRedef//'Formulário para redefinição do gateway inválido.'
              break
            case 401:
              message = this.jsonWord.error.notAuthorized//'Não autorizado.'
              break
            case 403:
              message = this.jsonWord.error.userNotAccept//'Usuário não aceitou o convite enviado no e-mail registrado.'
              break
            case 404:
              message = this.jsonWord.error.gatewayNotFound//'Gateway não encontrado.'
              break
              case 408:
                message = this.jsonWord.error.timeoutServer//'Gateway não encontrado.'
                break
            case 500:
              message = this.jsonWord.toast.gatewayCommunicationError//'Não foi possível realizar a autenticação.'
              break
            default:
              message = this.jsonWord.error.failOperation
          }
          this.modalRef.content.message = this.jsonWord.error.error + " " + message
          this.modalRef.content.success = false
          this.modalRef.content.waiting = false
          setTimeout(() => {
            this.modalRef.hide()
  
          }, 3000);
          //this.openErrorDialog(err.message)
        }
        )
  }


  termsConditions() {
    this.openTermDialog(this.jsonWord.alert.messageTermsAndConditions)
  }

  openTermDialog(message) {
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
    this.modalRef.content.buttonCancel = { show: false, text: this.jsonWord.button.cancel };
    this.modalRef.content.checkbox = { show: false };
    this.modalRef.content.title = this.jsonWord.text.termsConditions//'Termos e Condições';
    this.modalRef.content.message = message;
    // this.modalRef.content.onClose.subscribe(result => {
    //     console.log('results', result);
    // });
  }

  openLoadingModal(message) {
    this.modalRef = this.modalService.show(
      LoadingModalComponent,
      {
        keyboard: false,
        backdrop: 'static',
        class: 'waiting-modal modal-sm'
      })
    this.modalRef.content.message = message;
  }

  public unsafePublish(topic: string, message: string): void {
    this._mqttService.unsafePublish(topic, message, { qos: 1, retain: false });
  }

  openInputDialog(): Observable<any> {
    let modalRef = this.modalService.show(AlertComponent, { class: "modal-sm" })
    modalRef.content.buttonConfirm = { show: false };
    modalRef.content.inputText1 = { show: true, placeholder: this.jsonWord.label.password, type: 'password' }
    modalRef.content.message = this.jsonWord.placeholder.password//"Digite sua senha:"
    modalRef.content.title = this.jsonWord.alert.resetGateway//"Adicionar Gateway"
    modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok }
    modalRef.content.role = 'ADMIN'
    modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel }
    return modalRef.content.onClose.map(res => { console.log(res); if (res) return modalRef.content.inputText1.value; else return false })
  }

  openConfirmDialog(): Observable<any> {

    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.redefine };
    this.modalRef.content.type = 'warning'
    this.modalRef.content.title = this.jsonWord.alert.attention/*'<b><i class="fa fa-warning" style="color: yellow"> </i> Atenção</b>';*/
    this.modalRef.content.message = this.jsonWord.alert.doYouReally + ' ' + this.currentGateway.name + '? ' + this.jsonWord.alert.restoreGatewayConfirmation/*'Essa operação irá remover TODOS os dados associados ao gateway, como dispositivos, ' +
      'ambientes, cenários, regras e usuários (incluindo o usuário administrador).<br>' +
      'Tem certeza que deseja restaurar o Gateway <b>' + this.currentGateway.name + '</b> para as configurações de fábrica?';*/
    return this.modalRef.content.onClose;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

}

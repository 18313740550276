import { Pipe, Injectable, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({name: "dateTimeFormatFilter"})
	@Injectable()
	export class DateTimeFormatPipe implements PipeTransform {
		transform(value: any): any {
			if (value) {
					if (value.toString().indexOf(' ') === -1) {
							value = value + ' 00:00:00';
					}
					const temp = new Date(value.toString().replace(' ', 'T'));
					var userTimezoneOffset = 2*(temp.getTimezoneOffset() * 60000);
					var date = new Date(temp.getTime()+(userTimezoneOffset))
					return date//new Date(date.getTime() - userTimezoneOffset);
					//return new Date(temp);
			} else {
					return null;
			}
	}
	}
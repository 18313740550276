import { ResponseService } from './../_services/response.service';
import { RuleCardModalComponent } from './../_directives/rule-card-modal/rule-card-modal.component';
import { TranslateJsonService } from './../_services/translate-json.service';
import { AlertComponent } from './../_directives/alert.component';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Component, OnInit, EventEmitter, Output, HostListener, ɵConsole } from '@angular/core';
import { RuleService } from '../_services/rule.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap';
import * as config from './../config';
import * as moment from 'moment';
import { GatewayService } from '../_services/gateway.service';
import { HomeComponent } from '../home/home.component';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import {Observable, Subject} from 'rxjs/Rx';
import { finalize, timeoutWith, concatMap } from 'rxjs/operators';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';

const utf8Decoder = new TextDecoder("utf-8")
const TIMEOUT = 10000

@Component({
  selector: 'app-rule',
  templateUrl: './rule.component.html',
  styleUrls: ['./rule.component.css']
})

export class RuleComponent implements OnInit {
  @Output() mouseWheelUp = new EventEmitter();
  @Output() mouseWheelDown = new EventEmitter();
  /*@HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    this.onScroll(event);
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    this.onScroll(event);
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    this.onScroll(event);
  }
*/
private ngUnsubscribe = new Subject();
private ngUnsubscribe2 = new Subject();
  rules: any = [];
  public modalRef: BsModalRef;
  uuid = require('uuid/v4');
  currentGateway;
  message;
  ruleL;
  currentUserType
  public language;
  public jsonWord;
  public loading: Boolean
  arrayDevsRule = []
  arrayDevConfig = []
  public loadingModalRef: BsModalRef;
  public isAtBottom: Boolean = false;
  public isAtTop: Boolean = true;
  daysTrueFalse
  isFirefox
  imgsArrayDark=[]
  constructor(
    private _ruleService: RuleService,
    private _mqttService: MqttService,
    private modalService: BsModalService,
    private router: Router,
    private responseService: ResponseService,
    private translateJson: TranslateJsonService,
    private readonly location: Location,
    private gatewayService: GatewayService,
    public homeComponent: HomeComponent
  ) { }

  ngOnInit() {
    this.location.replaceState('/');
    this.language = localStorage.getItem('language')
    this.jsonWord = this.translateJson.changeLanguage(this.language)
    this.currentGateway = localStorage.getItem('currentGateway')
    this.currentUserType = this.homeComponent.currentUserType;
    this.daysTrueFalse = [false, false, false, false, false, false, false];
    this.isFirefox = /^((?!chrome|android).)*firefox/i.test(navigator.userAgent);
    this.gatewayService.gatewayObservable.subscribe(
      gateway => {
        if (gateway) {
          this.currentGateway = gateway.id
          this.loading = true
          this.updateAll()
        }
      }
    )
    /* let resposta = `${config.gtwTopic}${this.currentGateway}/rule/#`;
         this._mqttService.observe(resposta)
           .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload))).subscribe(res => {
             console.log(res)
             if(res.operation == 'add' || res.operation == 'edit' || res.operation == 'delete'){
               console.log('ATUALIZANDO')
               this.updateAll()
             }
           })*/
    let resposta = `${config.gtwTopic}${this.currentGateway}/rule/#`;
    let teste = null
   /* let sync = `${config.gtwTopicSync}${this.currentGateway}/rule/#`
    console.log(sync)
    this._mqttService.observe(sync)
      .map((IMQTTMessage:IMqttMessage) => {
        console.log(IMQTTMessage)
        return JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload))})
      .takeUntil(this.ngUnsubscribe).subscribe(res => {
        console.log(res)
        if (res && res.code == "ERROR_SYNC") {
          for (let rule of this.rules) {
            if (rule._id == res.entityId) {
              rule.sync.status = 'FAIL' //
            }
          }
        } else {
          console.log(res)
          if (res.op == 'EDIT') {
            this._ruleService.listRuleById(res.entityId).subscribe(
              updatedRule => {
                let outdatedIndex = this.rules.findIndex(rule => rule._id == res.entityId)
                //let outdatedDevice = this.devicesFound[outdatedIndex]
                this.rules.splice(outdatedIndex, 1, updatedRule)
              })
          }
          if (res.op == 'DELETE') {
            console.log(res.op)
            let indexToRemove = this.rules.findIndex(rule => rule._id == res.entityId)
            this.rules.splice(indexToRemove, 1)
          }
          /**
           *  "sync": "SUCCESS",
              "entityId": "5cee9cec1583994940cabc2f",
              "op": "EDIT"

           
          this._ruleService.listRuleById(res.entityId).subscribe(
            updatedRule => {
              let outdatedIndex = this.rules.findIndex(rule => rule._id == res.entityId)
              //let outdatedDevice = this.devicesFound[outdatedIndex]
              this.rules.splice(outdatedIndex, 1, updatedRule)
            })
            /*let indexToRemove = this.rules.findIndex(rule => rule._id == res.entityId)
            this.rules.splice(indexToRemove, 1)
        }
      })*/
    let sync = `${config.gtwTopicSync}${this.currentGateway}/rule/#`
    this._mqttService.observe(sync)
      /* .map((IMQTTMessage: IMqttMessage) => {
       try {
          return JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload))
        } catch (error) {
          console.log("ERROR ", new TextDecoder("utf-8").decode(IMQTTMessage.payload), IMQTTMessage.payload)

        }
      })
      .takeUntil(this.ngUnsubscribe).subscribe(res => {*/
      
      .map((IMQTTMessage: IMqttMessage) => {
        return JSON.parse(JSON.stringify(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
      }) 
      .takeUntil(this.ngUnsubscribe2).subscribe(res => {
        //if(res){
          let resposta= JSON.parse(res)
        if (resposta.code == "ERROR_SYNC") {
          for (let rule of this.rules) {
            if (rule._id == resposta.entityId) {
              rule.sync.status = 'FAIL'
            }
          }
        } else {
          if (res.op == 'EDIT') {
            this._ruleService.listRuleById(res.entityId).subscribe(
              updatedRule => {
                let outdatedIndex = this.rules.findIndex(rule => rule._id == res.entityId)
                //let outdatedDevice = this.devicesFound[outdatedIndex]
                this.rules.splice(outdatedIndex, 1, updatedRule)
              })
          }
          if (res.op == 'DELETE' && sync == 'SUCCESS') {
            let indexToRemove = this.rules.findIndex(rule => rule._id == res.entityId)
            this.rules.splice(indexToRemove, 1)
          }
        }
        //  }
       
      })

    this._mqttService.observe(resposta)
      .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
      .takeUntil(this.ngUnsubscribe).subscribe(res => {
        teste = res
        if (res.data) {
           if (res['operation'] == 'add') {
             let hold = Array.from(this.rules)
 
             this._ruleService.listRuleById(res['data'].ruleId)
               .pipe(finalize(() => this.loading = false))
               .takeUntil(this.ngUnsubscribe)
               .subscribe(
                 rule => {
                   hold.push(rule)
                   let index = hold.indexOf(rule)
                   this.rules.splice(index, 0, rule)
                 })
           }
          if (res['operation'] == 'edit') {
            this._ruleService.listRuleById(res['data'].ruleId).subscribe(
              updatedRule => {
                let outdatedIndex = this.rules.findIndex(rule => rule._id == res['data'].ruleId)
                //let outdatedDevice = this.devicesFound[outdatedIndex]
                this.rules.splice(outdatedIndex, 1, updatedRule)
              })
          }
          if (res['operation'] == 'delete') {
            let indexToRemove = this.rules.findIndex(rule => rule._id == res['data'].ruleId)
            this.rules.splice(indexToRemove, 1)
          }
          //  this.updateAll()
        } else {
        }
      }, err => {
        if (teste && (teste['operation'] == 'add' || teste['operation'] == 'edit' || teste['operation'] == 'delete')) {
          // this.updateAll()
        }
      })

  }

  updateAll() {
    this._ruleService.listRules()
      .pipe(finalize(() => { console.log('finalizei'); this.loading = false }))
      .subscribe((rules) => {
        this.rules = rules//(rules.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
        this.ruleL = rules.length
     /*   for(let i=0;i<this.ruleL;i++){
          var hasIcon = this.rules[i].icon && this.rules[i].icon.length ? this.rules[i].icon : "icon_1"
          var icon = (hasIcon.length<11 ? hasIcon.slice(0, 6)+'.svg' : hasIcon.slice(0, 7)+'.svg')
          console.log(icon)
          this.imgsArrayDark.push(icon)
     }*/
        // console.log(rules[2].schedule.interval.startDay)
      },
        err => {
          console.log(this.jsonWord.error.dataError + ' ' + this.jsonWord.text.device, err)//'Erro ao ler dados dos dispositivos: ', err);
          /*if (err.status === 401) {
            //this.router.navigate(['/login']);
            this.noInternetAlert()
          }
          if (err.status === 0) {
            console.log("ternet")
            this.noInternetAlert()
          }*/
          this.homeComponent.openErrorAlert(err)
        });
  }

  noInternetAlert() {
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.type = 'remove'
    this.modalRef.content.title = /*"<i class='fa fa-lg fa-times text-danger'> </i> " +*/ this.jsonWord.error.conectionError
    this.modalRef.content.message = this.jsonWord.error.noConnection
    this.modalRef.content.isDelete = false
    this.modalRef.content.typeB = 'refresh'
    //"Tempo limite de conexão com o servidor atingido. Verifique o status do servidor recarregue a página novamente."
    this.modalRef.content.buttonConfirm = { show: true, text: /*"<i class='fa fa-refresh'></i>" + '  ' + */this.jsonWord.label.reload }
    this.modalRef.content.onClose.subscribe(
      res => {
        if (res)
          window.location.reload()
      }
    )
    //this.modalRef.hide()
  }

  setRuleOnOff(rule) {
    let isActive
    if (rule.isActive) {
      isActive = false
    } else {
      isActive = true
    }
    //rule.isActive = !rule.isActive

    //const message = isActive //? false : true;
    this._ruleService.updateIsActiveRule({ isActive: isActive }, rule._id).subscribe(res => {
      rule.isActive = res.data.isActive;
    }, err => {
      rule.isActive = !isActive;
      let message = ''
      switch (err.status) {
        case 0:
          message = this.jsonWord.error.noConnection;//'Campos de email ou senha inválidos.'
          //  this.noInternetAlert()
          break
        case 400:
          message = this.jsonWord.error.invalidFormat//'Formato inválido.'
          break
        case 401:
          message = this.jsonWord.error.notAuthorized//'Você não tem permissão para editar este gateway'
          break
        case 404:
          message = this.jsonWord.error.ruleNotFound//'Gateway não encontrado.'
          break
        case 408:
          message = this.jsonWord.error.ruleNotFound//'Gateway não encontrado.'
          break
        case 500:
          message = this.jsonWord.label.rule//'Não foi possível editar fuso horário.'
          break
        default:
          message = this.jsonWord.error.failOperationy
      }
      this.modalRef.content.message = this.jsonWord.error.error + " " + message
      this.modalRef.content.success = false
      this.modalRef.content.waiting = false
      //this.openErrorDialog(err.message)
    })
  }

  resendCommand(rule) {
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
    // tslint:disable-next-line:max-line-length
    this.modalRef.content.title = this.jsonWord.alert.attention
    this.modalRef.content.type = 'warning'
    this.modalRef.content.message = this.jsonWord.alert.sendConfiguration
    this.modalRef.content.onClose.subscribe(result => {
      if (result) {
        let packRule = {
          "entityId": rule._id
        }
        this.gatewayService.resendMqtt(this.currentGateway, packRule)
          .subscribe(res => {
            if (res['data']) {
              rule.sync.status = 'PENDING'
            }
          })

      }
    })
  }

  editRuleModal(rule) {
    this.daysTrueFalse = [false, false, false, false, false, false, false];
    let devs = rule.outputs
    let weekdaysSort = []
    for (let day of rule.schedule.weekdays) {
      weekdaysSort.push(day.day)

    }
    let weekdaysBD = weekdaysSort.sort();
    for (let i = 0; i < weekdaysBD.length; i++) {
      this.daysTrueFalse[weekdaysBD[i]] = true;
    }

    this.arrayDevsRule = []
    this.arrayDevConfig = []
    for (let i = 0; i < devs.length; i++) {
      if ((devs[i].devId == 261 || devs[i].devId == 258) && devs[i].cmd == 'on') {
        this.arrayDevsRule.push(devs[i])
        this.arrayDevConfig.push([devs[i].cmd, devs[i + 1].zclData.hue, devs[i + 1].zclData.saturation, devs[i + 2].zclData.level])
        i = i + 2
      } else {
        this.arrayDevsRule.push(devs[i])
        this.arrayDevConfig.push(devs[i].cmd)
      }
    }
    let startDay = rule.schedule.interval.startDay
    let startHour = rule.schedule.interval.startHour
    let endHour = rule.schedule.interval.endHour
    this.modalRef = this.modalService.show(RuleCardModalComponent);
    this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
    this.modalRef.content.buttonEdit = { show: true, text: this.jsonWord.button.edit };
    this.modalRef.content.inputText1 = { show: true, value: rule };
    this.modalRef.content.startDay = startDay;
    this.modalRef.content.startHour = startHour
    this.modalRef.content.endHour = endHour
    // this.modalRef.content.weekdays = weekdaysSort.sort();
    this.modalRef.content.daysTrueFalse = this.daysTrueFalse;
    this.modalRef.content.outputs = this.arrayDevsRule
    this.modalRef.content.configs = this.arrayDevConfig
    this.modalRef.content.isDelete = false;
    this.modalRef.content.ruleOutputs = { show: true, value: rule.ruleOutputs }
    this.modalRef.content.sceneOutputs = { show: true, value: rule.sceneOutputs };
    this.modalRef.content.buttonRemove = { show: true, text: this.jsonWord.button.removeRule };
    this.modalRef.content.card = { show: true, type: "text", value: rule };
    this.modalRef.content.title = rule.name//'Vincular Dispositivos';
    this.modalRef.content.onClose.subscribe(res => {
      let rule = this.modalRef.content.buttonEdit
      this.updateAll()
      if (rule) {
        this.router.navigate(['edit/', res._id])
      }
    })
  }

  createNewRule() {
    console.log("todo")
  }

  deleteRule(rule, table) {
    let ruleResponse
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
    // tslint:disable-next-line:max-line-length
    this.modalRef.content.title = this.jsonWord.button.removeRule/*'\
    <br>\
    <span><img height="25" width="25" src="../assets/img/dashboard/icons/environments-icon.svg" alt="" style="margin-bottom: 5px;">&nbsp;&nbsp;\
    <span><b>' +  + '</b></span>\
    <br>\
    ';*/
    this.modalRef.content.message = this.jsonWord.alert.titleRemoveRule + ' ' + rule.name + '?'/*\
    <p>  '+ <b>';*/
    //if (this.modalRef.content.buttonOk){}
    this.modalRef.content.onClose.subscribe(result => {
      if (result) {
        this._ruleService.deleteRule(rule._id).subscribe(res => {
          ruleResponse = res
          //  let topic=config.gtwTopic + this.currentGateway + '/rule/' + result._id + '/status'
          // console.log(topic)
          //  this.responseService.responseStatusRule(ruleResponse, 'delete', 'status')
        })
      }
    })

  }
/*  onScroll(event: Event) {

    let scrollRight = <any>document.getElementById("scrollRight")
   // let el = event.srcElement
    let height;

    $("#scrollRight").each(function () {
      height = $(this).height()
    });
    if (!this.isFirefox) {
      let el = event.srcElement
      this.isAtTop = el.scrollTop < 3 ? true : false
      this.isAtBottom = (height + 1 > el.scrollHeight - el.scrollTop) ? true : false
    }else{
      this.isAtTop = event.target['scrollTop'] < 3 || event.target['deltaY'] < 52 ? true : false
      this.isAtBottom = event.target['deltaY'] > 50 || height + 1 > event.target['scrollHeight'] - event.target['scrollTop'] ? true : false
    }
  }*/

 
  goToConfig() {
    this.gatewayService.getMaxLimitEntity(this.currentGateway, 'rule').subscribe(res => {
      if (res.availableCreation) {
        this.router.navigate(['home/rules/config/'])
      } else {
        this.modalRef = this.modalService.show(AlertComponent);
        this.modalRef.content.buttonCancel = { show: false };
        this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
        // tslint:disable-next-line:max-line-length
        this.modalRef.content.type = 'warning'
        this.modalRef.content.title = this.jsonWord.alert.attention
        this.modalRef.content.message = this.jsonWord.toast.limitRuleHit
      }
    }, err => {
      console.log(err)
      let message = ''
      switch (err.status) {
        case 0:
          message = this.jsonWord.error.noConnection;//'Campos de email ou senha inválidos.'
          //  this.noInternetAlert()
          break
        case 400:
          message = this.jsonWord.error.invalidFormat//'Formato inválido.'
          break
        case 401:
          message = this.jsonWord.error.notAuthorized//'Você não tem permissão para editar este gateway'
          break
        case 404:
          message = this.jsonWord.error.ruleNotFound//'Gateway não encontrado.'
          break
          case 408:
            message = this.jsonWord.error.timeoutServer//'Gateway não encontrado.'
            break
        case 500:
          message = this.jsonWord.label.rule//'Não foi possível editar fuso horário.'
          break
        default:
          message = this.jsonWord.error.failOperationy
      }
    })

  }

  public unsafePublish(topic: string, message: string): void {
    this._mqttService.unsafePublish(topic, message, { qos: 1, retain: false });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
    this.ngUnsubscribe2.next()
    this.ngUnsubscribe2.complete()
  }
}

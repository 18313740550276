import { PopoverModule } from 'ngx-popover';
import { SceneConfigModule } from './scene-config/scene-config.module';
import { SceneComponent } from './scene.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {  RouterModule } from '@angular/router';
import { SceneEditModule } from './scene-edit/scene-edit.module';
import { DirectivesModule } from '../_directives/directives.module';


@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    ReactiveFormsModule,
    SceneConfigModule,
    SceneEditModule,
    RouterModule,
    FormsModule,
    PopoverModule,
  ],
  declarations: [SceneComponent],
  exports: [SceneComponent]
})
export class SceneModule { }

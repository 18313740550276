import { Device } from './../models/device.model';
import { Injectable } from '@angular/core';
//import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import * as config from '../config';
import { GatewayService } from './gateway.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import 'rxjs/add/operator/concat';
import { CookieService } from 'ngx-cookie-service';
import { timeout, timeoutWith } from 'rxjs/operators';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';

const url = config.serverURL;//+ 'ecomfort/v1';
const urlConfig = config.serverURL + '/gateway/'
const TIMEOUT: number = 15000 // 10 seconds

@Injectable()
export class DeviceService {

    public devices: Array<Device>
    public devicesObservable = new BehaviorSubject<Array<Device>>([])
    public device = new BehaviorSubject<any>(null)
    private refresh = new Subject()
    private periodicStatusSubscription: Subscription
    private gatewayId: string

    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
        private gatewayService: GatewayService,
        private _mqttService: MqttService
    ) {
        // Periodic status observer
        this.gatewayService.gatewayObservable.subscribe(gateway => {
            if (gateway) {
                this.gatewayId = gateway.id
                this.devicesObservable = this.http.get(urlConfig + gateway.id + '/device').pipe(timeout(TIMEOUT))
                    .map(
                        (res: Array<any>) => {
                            return res['data'].map(dev => new Device(dev)).sort(this.devicesOrder)
                        }) as BehaviorSubject<Device[]>
                /*.concat(
                    this._mqttService.observe(config.gtwTopic + gateway.id + '/status')
                        .map((IMQTTMessage: IMqttMessage) => {
                            let payload = JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
                            console.log(payload)
                            return payload.data.devices.map(dev => new Device(dev)).sort(this.devicesOrder)
                        })
                ) as BehaviorSubject<Device[]>*/
            }
        })
    }

    devicesOrder(a, b) {
        return Number(a.devId) < Number(b.devId) ? -1 : Number(a.devId) > Number(b.devId) ? 1 :
            a.name < b.name ? -1 : a.name > b.name ? 1 :
                a.created < b.created ? -1 : a.created > b.created ? 1 : 0
    }

    getDevices(gatewayId?: string): Observable<Array<Device>> {
        return this.devicesObservable.pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
            new Response(null, {
                status: 408, statusText: 'Tempo limite de resposta atingido'
            })
        )))
    }

    listDevices(gatewayId?: string): Observable<Array<Device>> {
        return this.getDevices(gatewayId).pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
            new Response(null, {
                status: 408, statusText: 'Tempo limite de resposta atingido'
            })
        )))
    }

    listDeviceById(deviceId, gatewayId?: string): Observable<any> {
        return this.http.get(urlConfig + (gatewayId ? gatewayId : this.gatewayService.currentGateway.id) + '/device/' + deviceId)
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
            .map(res => new Device(res['data']));
    }

    listDeviceByIeeeAddr(devIeeeAddr): Observable<any> {
        return this.http.get(urlConfig + this.gatewayService.currentGateway.id + '/device/ieeeAddr/' + devIeeeAddr)
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
    }

    listDevicesByEnvironment(environmentId, gatewayId?): Observable<any> {
        return this.http.get(urlConfig + (gatewayId ? gatewayId : this.gatewayService.currentGateway.id) + '/environment/' + environmentId)
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
            .map(res => res['data'].devices);
    }

    /* createDevice(device: Device): Observable<any> {
        return this.http.post(url + '/device', device);
    }*/

    updateDevice(deviceId, device): Observable<any> {
        return this.http.put(urlConfig + this.gatewayService.currentGateway.id + '/device/' + deviceId, device)
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
    }

    updateBlockDevice(deviceId, device): Observable<any> {
        return this.http.patch(urlConfig + this.gatewayService.currentGateway.id + '/device/' + deviceId, device)
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
    }

    deleteDeviceById(deviceId) {
        return this.http.delete(urlConfig + this.gatewayService.currentGateway.id + '/device/' + deviceId)
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
    }

    /* deleteDeviceByIeeeAddr(devIeeeAddr) {
         return this.http.delete(url + '/device/ieeeAddr/' + devIeeeAddr);
     }*/

    addNewBlockedUser(deviceId, blockedUserId) {
        return this.http.post(url + '/device/addNewBlockedUser/' + deviceId, blockedUserId)
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
    }

    removeBlockedUser(deviceId, blockedUserId) {
        return this.http.post(url + '/device/removeBlockedUser' + deviceId, blockedUserId)
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
    }

    /*setCurrentDeviceType(device) {
        console.log(device)
        localStorage.setItem('currentDeviceType', device ? (device) : null)
        let userId = localStorage.getItem('currentUserId')
        console.log(userId, this.cookieService.get(userId))
        let sessionData = JSON.parse(this.cookieService.get(userId))
        sessionData.currentDeviceType = device ? JSON.stringify(device) : null
        this.cookieService.set(userId, JSON.stringify(sessionData))
        this.device.next(device)
      }*/

}

import { ResponseService } from './../../_services/response.service';
import { SmartlockModalComponent } from './../smartlock-modal/smartlock-modal.component';
import { Subscription } from 'rxjs/Subscription';
import { DeviceService } from './../../_services/device.service';
import { LoadingModalComponent } from './../loading-modal/loading-modal.component';
import { AlertComponent } from './../../_directives/alert.component';
import { DevicesModalComponent } from './../../_directives/devices-modal/devices-modal.component';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { timeout, catchError, timeoutWith, finalize, concatMap, takeUntil } from 'rxjs/operators';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { EnvironmentService } from './../../_services/environment.service';
import { TranslateJsonService } from './../../_services/translate-json.service';
import { Component, OnInit, HostListener, ViewChild, ElementRef, QueryList, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { of, Observable } from 'rxjs';
import * as config from '../../config';
import { v4 as uuid } from 'uuid';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ColorhueScreenComponent } from '../colorhue-screen/colorhue-screen.component';

const SMARTLOCK_TIMEOUT = 25000
const COMMAND_TIMEOUT = 5000
const BIND_TIMEOUT = 10000
const CONFIG_TIMEOUT = 10000
const DELETE_TIMEOUT = 12000
const utf8Decoder = new TextDecoder("utf-8")


@Component({
  moduleId: module.id,
  selector: 'app-device-card-modal',
  templateUrl: 'device-card-modal.component.html',
  styleUrls: ['./device-card-modal.component.css'],

})

export class DeviceCardModalComponent implements OnInit {
  private ngUnsubscribe = new Subject();
  public modalRef: BsModalRef;
  @Output() sendSat = new EventEmitter();
  @Output() sendColor = new EventEmitter();
 // @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
  @ViewChild('canvas', {static: true}) canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('imgHue', {static: true}) imgHue: ElementRef
  @ViewChild('imagemhue', {static: true}) imagemhue: QueryList<any>;
  //@ViewChild(ElementRef, { static: false }) canvas: ElementRef<HTMLCanvasElement>;
  public title: any;
  public message: any;
  public devName: any;
  public devSerial: any;
  public language;
  public jsonWord;
  public envList = [];
  public globalClass: string
  public envListId = [];
  public card: any;
  public whiteLight;
  public correlId
  public devicesList: any;
  public filteredDevicesList: any;
  private selectedDevices: Array<any> = [];
  pixelData
  idEdit
  public buttonCancel: any = {
    show: false,
    text: 'CANCELAR'
  };
  public buttonUnblock: any = {
    show: false,
    text: 'DESBLOQUEAR DISPOSITIVO'
  };
  public buttonBlock: any = {
    show: false,
    text: 'BLOQUEAR DISPOSITIVO'
  };
  public buttonBind: any = {
    show: false,
    value: 'BIND',
  };
  public buttonCalibrate: any = {
    show: false,
    value: 'CALIBRAR',
  };
  public buttonConfirm: any = {
    show: false,
    text: 'SALVAR'
  };
  public buttonRemove: any = {
    show: false,
    text: 'REMOVER DISPOSITIVO'
  };

  public inputText1: any = {
    show: false,
    value: null,
    type: 'text',
    placeholder: 'Digite o valor'
  };
  public inputText2: any = {
    show: false,
    value: null,
    type: 'text',
    placeholder: 'Digite o valor'
  };
  public offset: any = {
    show: false,
    value: null,
    type: 'text',
  };

  public inputLevelLum: any = {
    show: false,
    value: null,
    type: 'text',
  };

  public inputLevel: any = {
    show: false,
    value: null,
    type: 'text',
  };
  public inputHue: any = {
    show: false,
    value: null,
    type: 'text',
  };
  /*  public whiteLight: any = {
      show: false,
      value: null
    }*/
  calButtonOk = false
  public deviceTopicSync;
  public devsSyncObserver;
  public devicesFound;
  private deviceStatusObserver;
  private deviceTopic;
  private deviceObserver
  public currentUserType;
  public currentUserId;
  public currentUser;
  public currentGateway: any;
  public isAtBottom: boolean = false
  public acceptTerm: boolean = false
  public showCloseButton: boolean = true
  public onClose: Subject<Boolean>;
  public nenhum = "Nenhum"
  public fusoChosen// = "Escolha um fuso horário";
  uuid = require('uuid/v4');
  public mqttSuccess: Subscription;
  public mqttError: Subscription;
  public deletingDevice;
  public gatewaySuccessObserver
  public gatewayErrorObserver
  public waiting: Boolean = true;
  public success: Boolean //= true;
  public buttomBlocked: Boolean = true;
  private subscription: Subscription;
  public devicesObserver = new Observable<any>()
  public devListLamp
  public devListLed
  deviceToDelete
  pack
  isFavorite
  advancedColor = false
  private ctx: CanvasRenderingContext2D;
    public colorhueScreenComponent:ColorhueScreenComponent
  @HostListener('window:keyup', ['$event'])
  enterKeyUpEvent(e) {
    e.stopPropagation()
    if (e.keyCode == 13 && this.buttonConfirm.show) {

      if (!this.inputText2.value) alert("Sem nome")
      else this.onConfirm()
    }
  }

  constructor(
    private _bsModalRef: BsModalRef,
    public translateJson: TranslateJsonService,
    private _environmentService: EnvironmentService,
    private modalService: BsModalService,
    public loadingModalRef: BsModalRef,
    private _deviceService: DeviceService,
    private _mqttService: MqttService,
    private toastr: ToastrService,
    private responseService: ResponseService) {
    // this.fusoChosen = this.jsonWord.label.choseTimezone
  }

  public ngOnInit(): void {
    this.language = localStorage.getItem('language')
    this.jsonWord = this.translateJson.changeLanguage(this.language)
    this.currentGateway = localStorage.getItem('currentGateway')
    //this.deviceTopic = `${config.gtwTopic}${this.currentGateway}/device/${this.card.devIeeeAddr}/`
    this.listDevices(this.currentGateway)
    this.currentUserType = JSON.parse(localStorage.getItem('currentRole'));
    // this.currentGateway = this.deviceNewComponent.currentGateway
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')).email;
    this.currentUserId = (localStorage.getItem('currentUserId'));
    this.deviceTopicSync = `ecomfort/iot/v1/sync/gateway/${this.currentGateway}/user/#`;
   // this.generateColorWheel()
   
    
    this.devsSyncObserver = this._mqttService.observe(this.deviceTopicSync)
      .map((IMQTTMessage: IMqttMessage) => {return JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload))});

    this.gatewaySuccessObserver = this._mqttService.observe(config.gtwTopic + this.currentGateway + '/success')
      .map((IMQTTMessage: IMqttMessage) => {
        let plainText = utf8Decoder.decode(IMQTTMessage.payload)
        return plainText ? JSON.parse(plainText) : null
      })
    this.waiting = false

    this.gatewayErrorObserver = this._mqttService.observe(config.gtwTopic + this.currentGateway + '/error')
      .map((IMQTTMessage: IMqttMessage) => {
        let plainText = utf8Decoder.decode(IMQTTMessage.payload)
        return plainText ? JSON.parse(plainText) : null
      })
    const allDevicesTopic = config.gtwTopic + this.currentGateway + '/device/#';

    this._mqttService.observe(allDevicesTopic)
      .map((IMQTTMessage: IMqttMessage) => {
        let message = utf8Decoder.decode(IMQTTMessage.payload)
        return message ? JSON.parse(message) : String(IMQTTMessage)
      })
      .filter(payload => payload.data && payload.data.ieeeAddr == this.card.devIeeeAddr)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(payload => {
        this.message = payload
        let op = payload.operation
        if (op === 'attReport') {
          this.messageDispatcher(this.message.data);
        }
      });

    this._environmentService.listEnvironments()
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        environments => {
          this.envList = environments
        })
    this.onClose = new Subject();
  }
  public onDeleteDevice(card): void {
  }
  public onConfirm(): void {
    if (!this.inputText2.value) alert("nome!")
    let checked = [], notChecked = [], allDev = [];
    if (this.card.devId == 6) {
      for (let i = 0; i < this.devicesList.length; i++) {
        allDev.push(<HTMLDListElement>document.getElementById(this.devicesList[i].ieeeAddr))
        if (allDev[i]){
          if(allDev[i].checked) {//classList[holdElemId[i].classList.length - 1] === "mat-checkbox-checked") {
          //  checked.push(this.devicesList[i])
          checked.push({ "ieeeAddr": this.devicesList[i].ieeeAddr, "epId": 1 })
        } else {
          let associatedDev = this.card.associatedDevices, dev;
          //if (this.devicesList[i].ieeeAddr == this.card.associatedDevices.ieeeAddr) {
          //   notChecked.push(this.devicesList[i])
          dev = associatedDev.filter(serial => serial.ieeeAddr == this.devicesList[i].ieeeAddr)
          if (dev.length) {
            notChecked.push({ "ieeeAddr": this.devicesList[i].ieeeAddr, "epId": 1 })
          }
        }
      }
      }
      if(checked.length > 0){
          this.bindDevice(checked, this.card)
      }
      if(notChecked.length > 0){
            this.unbindDevices(notChecked, this.card)
          }
    }
    if (!this.inputText2.value) alert("nome!")
    console.log(this.offset.value, this.card, this.isFavorite)
    this.onClose.next(this.card);
    this._bsModalRef.hide();
  }
  public onCancel(): void {
    //  this.onClose.next(false);
    this._bsModalRef.hide();
  }

  getEnvChosen(envs) {
    if (envs == "nenhum") {
      this.offset.value = "Nenhum"
    } else {
      this.offset.value = envs.name
    }
  }
  

  getFavorite(){
    this.isFavorite = !this.isFavorite
    console.log(this.isFavorite)
    this.card.favoriteDevice = this.isFavorite
  }


  colorScreenModal(){
    this.advancedColor = !this.advancedColor
    this.closeAllModals()
    setTimeout(() => {
      
    this.modalRef = this.modalService.show(ColorhueScreenComponent);
    this.modalRef.content.card = this.card
    this.modalRef.content.pixelData = this.hslToRgb(this.card.value1/255, (this.card.value2/255), 1)
    this.modalRef.content.value1 = this.card.value1
    this.modalRef.content.value2 = this.card.value2
    this.modalRef.content.isEdit=true
    }, 1000);
  //  this.modalRef.content.wheel = this.showWheel()
  }

  showWheel(){
    /*
    this.ctx = this.canvas.nativeElement.getContext('2d');
    console.log(this.canvas ,this.ctx)
    
    // var graphics = canvas.getContext("2d");
     
     var CX = this.canvas.nativeElement.width / 2,
         CY = this.canvas.nativeElement.height/ 2,
         sx = CX,
         sy = CY;
     
     for(var i = 0; i < 360; i+=0.1){
         var rad = i * (2*Math.PI) / 360;
         this.ctx.strokeStyle = "hsla("+i+", 100%, 50%, 1.0)";   
         this.ctx.beginPath();
         this.ctx.moveTo(CX, CY);
         this.ctx.lineTo(CX + sx * Math.cos(rad), CY + sy * Math.sin(rad));
         this.ctx.stroke();
     }
    // console.log(this.ctx,this.ctx.stroke(), this.canvas)
  /*  var img = new Image();
  img.src = '/assets/img/dashboard/ui/hue.png';
  var context = this.canvas.nativeElement.getContext('2d');
  context.drawImage(img, 0, 0);
  //
  console.log(img)
     return img*/
  }
 

 echoColor(e){ 
    $('#imgHue').mousedown(event=> {
          
    var a = this.canvas.nativeElement.getContext("2d").getImageData(event.offsetX, event.offsetY, 1, 1)
    this.pixelData = a.data;
    $('#output').html('R: ' + this.pixelData[0] + '<br>G: ' + this.pixelData[1] + '<br>B: ' + this.pixelData[2] + '<br>A: ' + this.pixelData[3]);
 
    
});

 }

  /* console.log(this.canvas)
   var context
  //
  /*var img = new Image();
  img.src = '/assets/img/dashboard/ui/hue.png';
  var context = this.canvas.nativeElement.getContext('2d');
  context.drawImage(img, 0, 0);
  var cvs = this.canvas.nativeElement//document.createElement('canvas'),
  var img = this.showWheel()//document.getElementsByTagName("img")[0];   // your image goes here
  // img = $('#yourImage')[0];                     // can use jquery for selection
cvs.width = img.width; cvs.height = img.height;
var ctx = cvs.getContext("2d");
console.log(ctx)
//ctx.drawImage(img,0,0,cvs.width,cvs.height);
var idt = ctx.getImageData(0,0,cvs.width,cvs.height);

// The magic®
//getPixel(idt, 852);  // returns array [red, green, blue, alpha]
//getPixelXY(idt,1,1); // same pixel using x,y
  var data = context.getImageData(70, 150, 1, 1).data;
 // var data = context.getImageData(this.canvas.nativeElement.scrollWidth, this.canvas.nativeElement.scrollHeight, 1, 1).data;
  // var context = this.canvas.nativeElement.getContext('2d').getImageData(this.canvas.nativeElement.scrollWidth, this.canvas.nativeElement.scrollHeight/2 , 1, 1);
   

  // Get the CanvasPixelArray from the given coordinates and dimensions.
  var imgd = data
  console.log(imgd,idt)
  var pix = imgd//.data;

  // Loop over each pixel and invert the color.
  for (var i = 0, n = pix.length; i < n; i += 4) {
      pix[i  ] = 255 - pix[i  ]; // red
      pix[i+1] = 255 - pix[i+1]; // green
      pix[i+2] = 255 - pix[i+2]; // blue
      // i+3 is alpha (the fourth element)
}
*/
// Draw the ImageData at the given (x,y) coordinates.
 //console.log(this.canvas.nativeElement.getContext('2d').putImageData(imgd, this.canvas.nativeElement.scrollWidth, this.canvas.nativeElement.scrollHeight));

   //https://stackoverflow.com/questions/1041399/how-to-use-javascript-or-jquery-to-read-a-pixel-of-an-image-when-user-clicks-it/27705693#27705693 
   //https://www.google.com/search?q=how+to+get+a+pixel+in+canvas+angular&oq=how+to+get+a+pixel+in+canvas+angular&aqs=chrome..69i57j33l2.13919j0j4&sourceid=chrome&ie=UTF-8
   //https://stackoverflow.com/questions/667045/getpixel-from-html-canvas

 /*  // var c = document.getElementById("myCanvas");
 console.log(e)
  var red, green, blue, alpha
var ctx = this.canvas.nativeElement.getContext("2d");
ctx.fillStyle = "black";
ctx.fillRect(10, 10, 50, 50);
    var imgData = ctx.getImageData(e.pageX / 2, e.nativeElement.height / 2, 1, 1);
    console.log(ctx)
    red = imgData.data[0];
    green = imgData.data[1];
    blue = imgData.data[2];
    alpha = imgData.data[3];
    console.log(red + " " + green + " " + blue + " " + alpha);  
    $(e).({
      onPreview: function (color) {
        $('#preview').css('background-color', color);
      },
      onSelect: function (color) {
        $('#selected').css('background-color', color);
      }
    });
}
  
  colorWheelMouse(evt) {
    console.log(this.canvas,evt)
    var p = document.body.appendChild(document.createElement("p"));
    //var ctx = this.canvas.nativeElement.getContext("2d");
  //  var data = this.ctx.getImageData(this.ctx.offsetWidth/2, this.canvas.nativeElement.offsetHeight/2, 1, 1);
   // p.innerHTML = "RGB: " + data.data.slice(0, 3).join(',');
console.log( p.innerHTML)
  }*/
  listDevices(gateway) {
    if (!this.currentGateway) {
      return
    }
    if (!this.devicesFound) {
      let hold = [], devSort = []
      this._deviceService.listDevices(gateway)
        .pipe(finalize(() => {
        }))
        .takeUntil(this.ngUnsubscribe)
        .subscribe(
          (deviceList) => {
            this.devicesFound = deviceList;
            this.selectedDevices = this.devicesFound.associatedDevices && this.devicesFound.associatedDevices.length > 0 ? this.devicesFound.associatedDevices.map(d => d.ieeeAddr) : []
            this.devicesList = this.devicesFound.
              filter(device => device.devId == '258' || device.devId == '261')
            this.devListLamp = this.devicesList.filter(device => device.devId == '258')
            this.devListLed = this.devicesList.filter(device => device.devId == '261')
          },
          err => console.log(this.jsonWord.error.error + ': ', err)
        );
    }
  }


  getRGB(x, y, canvas) {
    let context = canvas.getContext('2d');
    let [red, green, blue, alpha] = context.getImageData(x, y, 1, 1).data;
    return {red, green, blue, alpha};
  } 
 
 /**
 * degreesToRadians
 *
 * @param {number} degrees
 * @returns {number}  radians
 
 degreesToRadians(degrees) {
  return degrees * (Math.PI / 180);
}

* generateColorWheel
*
* @param {number} [size=400]
* @param {string} [centerColor="white"]
* @returns {HTMLCanvasElement}

 generateColorWheel() {
   var size = 400
   var centerColor = "white"
  if (size === void 0) { size = 400; }
  if (centerColor === void 0) { centerColor = "white"; }
  //Generate main canvas to return
  console.log(size, centerColor)
  var canvas = document.createElement("canvas");
  var ctx = canvas.getContext("2d");
  canvas.width = canvas.height = size;
  //Generate canvas clone to draw increments on
  var canvasClone = document.createElement("canvas");
  canvasClone.width = canvasClone.height = size;
  var canvasCloneCtx = canvasClone.getContext("2d");
  //Initiate variables
  var angle = 0;
  var hexCode = [255, 0, 0];
  var pivotPointer = 0;
  var colorOffsetByDegree = 4.322;
  //For each degree in circle, perform operation
  while (angle++ < 360) {
      //find index immediately before and after our pivot
      var pivotPointerbefore = (pivotPointer + 3 - 1) % 3;
      var pivotPointerAfter = (pivotPointer + 3 + 1) % 3;
      //Modify colors
      if (hexCode[pivotPointer] < 255) {
          //If main points isn't full, add to main pointer
          hexCode[pivotPointer] = (hexCode[pivotPointer] + colorOffsetByDegree > 255 ? 255 : hexCode[pivotPointer] + colorOffsetByDegree);
      }
      else if (hexCode[pivotPointerbefore] > 0) {
          //If color before main isn't zero, subtract
          hexCode[pivotPointerbefore] = (hexCode[pivotPointerbefore] > colorOffsetByDegree ? hexCode[pivotPointerbefore] - colorOffsetByDegree : 0);
      }
      else if (hexCode[pivotPointer] >= 255) {
          //If main color is full, move pivot
          hexCode[pivotPointer] = 255;
          pivotPointer = (pivotPointer + 1) % 3;
      }
      //clear clone
      canvasCloneCtx.clearRect(0, 0, size, size);
      //Generate gradient and set as fillstyle
      var grad = canvasCloneCtx.createRadialGradient(size / 2, size / 2, 0, size / 2, size / 2, size / 2);
      grad.addColorStop(0, centerColor);
      grad.addColorStop(1, "rgb(" + hexCode.map(function (h) { return Math.floor(h); }).join(",") + ")");
      canvasCloneCtx.fillStyle = grad;
      //draw full circle with new gradient
      canvasCloneCtx.globalCompositeOperation = "source-over";
      canvasCloneCtx.beginPath();
      canvasCloneCtx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
      canvasCloneCtx.closePath();
      canvasCloneCtx.fill();
      //Switch to "Erase mode"
      canvasCloneCtx.globalCompositeOperation = "destination-out";
      //Carve out the piece of the circle we need for this angle
      canvasCloneCtx.beginPath();
      canvasCloneCtx.arc(size / 2, size / 2, 0, this.degreesToRadians(angle + 1), this.degreesToRadians(angle + 1));
      canvasCloneCtx.arc(size / 2, size / 2, size / 2 + 1, this.degreesToRadians(angle + 1), this.degreesToRadians(angle + 1));
      canvasCloneCtx.arc(size / 2, size / 2, size / 2 + 1, this.degreesToRadians(angle + 1), this.degreesToRadians(angle - 1));
      canvasCloneCtx.arc(size / 2, size / 2, 0, this.degreesToRadians(angle + 1), this.degreesToRadians(angle - 1));
      canvasCloneCtx.closePath();
      canvasCloneCtx.fill();
      //Draw carved-put piece on main canvas
      ctx.drawImage(canvasClone, 0, 0);
  }
  //return main canvas
  return canvas;
}

/**TEST
//Get color wheel canvas
 colorWheel = this.generateColorWheel(300);
//Add color wheel canvas to document
document.body.appendChild(colorWheel);
//Add ouput field
 p = document.body.appendChild(document.createElement("p"));

* colorWheelMouse
*
* @param {MouseEvent} evt

 colorWheelMouse(evt) {
  var ctx = this.colorWheel.getContext("2d");
  var data = ctx.getImageData(evt.offsetX, evt.offsetY, 1, 1);
  this.p.innerHTML = "RGB: " + data.data.slice(0, 3).join(',');
}
//Bind mouse event
this.colorWheel.onmousemove = this.colorWheelMouse;*/
  /**
   * Send command MQTT
   * 
   */
  sendMQTTOnOffCommand(card) {
    if (!card.blocked) {
      this.waiting = true
      if ((card.devType === 'Router') || (card.devId === '10')) {
        let message;
        if ((card.devId === '81') || (card.devId === '258') || (card.devId === '771')) {
          message = {
            messageId: uuid(),
            user: this.currentUser,
            timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            epId: card.devEpList[0],
            //userId:'teste',//JSON.parse(localStorage.getItem('currentUserId'))._id,
            cId: 'genOnOff',
            cmd: 'toggle',
            zclData: {}
          };
        }
        if (card.isActive) {
          message = {
            messageId: uuid(),
            timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            epId: card.devEpList[0],
            user: this.currentUser,
            cId: (card.devId === '10') ? 'closuresDoorLock' : 'genOnOff',
            cmd: (card.devId === '10') ? 'unlockDoor' : 'off',
            zclData: (card.devId === '10') ? { pincodevalue: '00000000' } : {}
          };
        } else if (!card.isActive) {
          message = {
            messageId: uuid(),
            timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            epId: card.devEpList[0],
            user: this.currentUser,
            cId: (card.devId === '10') ? 'closuresDoorLock' : 'genOnOff',
            cmd: (card.devId === '10') ? 'lockDoor' : 'on',
            zclData: (card.devId === '10') ? { pincodevalue: '00000000' } : {}
          };
        }
        const topic = `${config.gtwTopic}${this.currentGateway}/device/${card.devIeeeAddr}/command`;
        this.unsafePublish(topic, JSON.stringify(message))

        //caso haja erro
        this.subscription =
          this.gatewayErrorObserver
            .filter(msg => this.correlId == msg['correlId'])
            .map(message => {
              this.success = false
              return message
            })
            .merge(
              this.gatewaySuccessObserver
                .filter(msg => this.correlId == msg['correlId'])
                .map(message => {
                  this.success = true
                  this.buttomBlocked = true;
                  return message
                })
            )
            .take(1)
            .pipe(finalize(() => {
              this.waiting = false
            }), timeoutWith(card.devId == '10' ? SMARTLOCK_TIMEOUT : COMMAND_TIMEOUT, ErrorObservable.throw(
              new Response(null, {
                status: 408, statusText: 'Tempo limite de resposta atingido'
              })
            )))
            .subscribe(_ => {
              this.correlId = null
            },
              err => {
                this.gatewayErrorToast(err)
              }
            )
      }
    }
  }

  gatewayErrorToast(err) {
    if (err.status == 408) {
      this.toastr.error('O gateway demorou muito pra responder. Verifique o status do gateway e tente novamente mais tarde.')
    }
  }

   /**changeColorFunc(event){
    console.log("evento",event,event.color.color)
    var r, g, b ;
    r = event.color.color[0]
    g = event.color.color[1]
    b = event.color.color[2]
    r = r/255, g = g/255, b = b/255;

    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, v = max;
  
    var d = max - min;
    s = max == 0 ? 0 : d / max;
  
    if (max == min) {
      h = 0; // achromatic
    } else {
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }
  
      h /= 6;
    }
    h = Math.floor(h*254)
    s = Math.floor(s*254)
    console.log(h,s,v)
    this.onSmartlightColorChange(this.card, h,s)
    return [ h, s, v ];
  }
 
   * Bind devices
   
  onBind(device, card) {
    let listeningTopic = `${config.gtwTopic}${this.currentGateway}/device/${card.devIeeeAddr}/event`;
    let batteryObserver = this._mqttService.observe(listeningTopic)
      .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
      .filter((res) => res['data'].type == 'batteryPercentageRemaining');

    let onOffObserver = this._mqttService.observe(listeningTopic)
      .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
      .filter((res) => res['data'].type == 'onOff');


    let listenerSubscripttion = batteryObserver.concatMap(
      (res) => {
        return onOffObserver
          .map(() => false)
          .pipe(timeout(1200),
            catchError(err => of(true))).take(1)
      }
    ).subscribe(res => {
      if (res && this.modalRef) {
        this.modalRef.content.isListening = true
        setTimeout(() => this.modalRef.content.isListening = false, 59 * 1000)
      }
    })

    listenerSubscripttion.unsubscribe()
    if (this.selectedDevices) {
      //'Vinculando dispositivos...' segure off por 10seg)
      if (device.associatedDevices[0] && device.associatedDevices[0].ieeeAddr == card.devIeeeAddr) {
        this.openLoadingModal(this.jsonWord.loading.unbinding)
        console.log("CARD DO CARAIO", card)
        this.unbindDevices(device, card)
        this._mqttService.observe(`${config.gtwTopic}${this.currentGateway}/device/${card.devIeeeAddr}/status`)
          .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
          .filter(message => message['operation'] == 'unbind')
          .map(message => {
            this.loadingModalRef.content.success = true
            this.loadingModalRef.content.message = this.jsonWord.toast.deviceUnbinded
            //this.updateAll(this.currentGateway)
            setTimeout(() => {
              if (this.loadingModalRef.content.success)
                this.loadingModalRef.hide()
              this.onCancel()
            }, 2000)
            return message
          })
          .merge(
            this._mqttService.observe(config.gtwTopic + this.currentGateway + '/error')
              .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
              .filter(message => message['operation'] == 'bind')
              .map(message => {
                this.loadingModalRef.content.waiting = false
                this.loadingModalRef.content.success = false
                this.loadingModalRef.content.message = this.jsonWord.toast.deviceNotUnbinded
                setTimeout(() => {
                  if (!this.loadingModalRef.content.success)
                    this.loadingModalRef.hide()
                  this.onCancel()
                }, 2000)

                return message
              })
          )
          .pipe(finalize(() => {
            this.loadingModalRef.content.waiting = false
            this.loadingModalRef.content.success = true
            setTimeout(() => {
              if (this.loadingModalRef)
                this.loadingModalRef.hide()
            }, 10000)
          }), timeoutWith(DELETE_TIMEOUT, ErrorObservable.throw(
            new Response(null, {
              status: 408, statusText: this.jsonWord.error.timeoutServer/*'Não foi possível completar a operação: o dispositivo demorou muito para responder.<br>\
              Verifique se o dispositivo está ligado e corretamente pareado com o gateway'
            })
          )))
          .take(1)
          .subscribe(_ => {
            this.deletingDevice = null
          })
      } else {
        this.openLoadingModal(this.jsonWord.loading.binding)
        this.bindDevice(device, card)
        this._mqttService.observe(`${config.gtwTopic}${this.currentGateway}/device/${card.devIeeeAddr}/status`)
          .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
          .filter(message => message['operation'] == 'bind')
          .map(message => {
            this.loadingModalRef.content.success = true
            this.loadingModalRef.content.message = this.jsonWord.toast.deviceBinded
            //this.updateAll(this.currentGateway)
            setTimeout(() => {
              if (this.loadingModalRef.content.success)
                this.loadingModalRef.hide()
              this.onCancel()
            }, 2000)
            return message
          })
          .merge(
            this._mqttService.observe(config.gtwTopic + this.currentGateway + '/error')
              .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
              .filter(message => message['operation'] == 'bind')
              .map(message => {
                this.loadingModalRef.content.waiting = false
                this.loadingModalRef.content.success = false
                this.loadingModalRef.content.message = this.jsonWord.toast.deviceNotBinded
                setTimeout(() => {
                  if (!this.loadingModalRef.content.success)
                    this.loadingModalRef.hide()
                  this.onCancel()
                }, 2000)

                return message
              })
          )
          .pipe(finalize(() => {
            this.loadingModalRef.content.waiting = false
            this.loadingModalRef.content.success = true
            setTimeout(() => {
              if (this.loadingModalRef)
                this.loadingModalRef.hide()
            }, 10000)
          }), timeoutWith(DELETE_TIMEOUT, ErrorObservable.throw(
            new Response(null, {
              status: 408, statusText: this.jsonWord.error.timeoutServer/*'Não foi possível completar a operação: o dispositivo demorou muito para responder.<br>\
                Verifique se o dispositivo está ligado e corretamente pareado com o gateway'
            })
          )))
          .take(1)
          .subscribe(_ => {
            this.deletingDevice = null
          })
      }
    }
  }*/


  hslToRgb(h, s, l) {
    var r, g, b;

    if (s == 0) {
      r = g = b = l; // achromatic
    } else {
      var hue2rgb = function hue2rgb(p, q, t) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      }

      var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      var p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }
    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  }

  bindDevice(device, card) {
    let holdDevs = [], holdData;
    const topic = `${config.gtwTopic}${this.currentGateway}/device/${card.devIeeeAddr}/bind`;
    holdData = { "ieeeAddr": card.devIeeeAddr, "epId": 1 }  //epId:6
    let message = {
      "messageId": this.uuid(),
      "timestamp": moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      "user": this.currentUser,
      "input": holdData,
      "outputs": device
    }
    this._mqttService.unsafePublish(topic, JSON.stringify(message));
  }

  onUnbindAll(card) {
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.title = this.jsonWord.button.unbind//'Desvincular'
    this.modalRef.content.message = this.jsonWord.text.unbindAll + card.title + '?'
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.unbind };
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
    this.modalRef.content.onClose
      .takeUntil(this.ngUnsubscribe)
      .subscribe(res => {
        if (res) {
          this.openLoadingModal(this.jsonWord.loading.unbinding)//'Desvinculando dispositivos...')
          this.unbindAllDevices(card.associatedDevices, card)
          this._mqttService.observe(`${config.gtwTopic}${this.currentGateway}/device/${card.devIeeeAddr}/status`)
            .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
            .filter(message => message['operation'] == 'unbind')
            .map(message => {
              this.loadingModalRef.content.success = true
              this.loadingModalRef.content.message = this.jsonWord.toast.deviceUnbinded
              //this.updateAll(this.currentGateway)
              setTimeout(() => {
                if (this.loadingModalRef.content.success)
                  this.loadingModalRef.hide()
                this.onCancel()
              }, 2000)
              return message
            })
            .merge(
              this._mqttService.observe(config.gtwTopic + this.currentGateway + '/error')
                .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
                .filter(message => message['operation'] == 'unbind')
                .map(message => {
                  this.loadingModalRef.content.waiting = false
                  this.loadingModalRef.content.success = false
                  this.loadingModalRef.content.message = this.jsonWord.toast.deviceNotUnbinded
                  setTimeout(() => {
                    if (!this.loadingModalRef.content.success)
                      this.loadingModalRef.hide()
                    this.onCancel()
                  }, 2000)

                  return message
                })
            )
            .pipe(finalize(() => {
              this.loadingModalRef.content.waiting = false
              this.loadingModalRef.content.success = true
              setTimeout(() => {
                if (this.loadingModalRef)
                  this.loadingModalRef.hide()
              }, 10000)
            }), timeoutWith(DELETE_TIMEOUT, ErrorObservable.throw(
              new Response(null, {
                status: 408, statusText: this.jsonWord.error.timeoutServer/*'Não foi possível completar a operação: o dispositivo demorou muito para responder.<br>\
                Verifique se o dispositivo está ligado e corretamente pareado com o gateway'*/
              })
            )))
            .take(1)
            .subscribe(_ => {
              this.deletingDevice = null
            })
        }
      })
  }


  unbindAllDevices(devsToUnbind, card) {
    let holdDevs = [], holdData;
    const topic = `${config.gtwTopic}${this.currentGateway}/device/${card.devIeeeAddr}/unbind`;
    holdData = { "ieeeAddr": card.devIeeeAddr, "epId": 1 }  //epId:6
    let message = {
      "messageId": this.uuid(),
      "timestamp": moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      "user": this.currentUser,
      "input": holdData,
      "outputs": devsToUnbind
    }
    this._mqttService.unsafePublish(topic, JSON.stringify(message));
  }

  unbindDevices(device, card) {
    let holdDevs = [], holdData;
    const topic = `${config.gtwTopic}${this.currentGateway}/device/${card.devIeeeAddr}/unbind`;
    holdData = { "ieeeAddr": card.devIeeeAddr, "epId": 1 }  //epId:6
    let message = {
      "messageId": this.uuid(),
      "timestamp": moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      "user": this.currentUser,
      "input": holdData,
      "outputs": device
    }
    this._mqttService.unsafePublish(topic, JSON.stringify(message));
  }
  /**
   * Cards fita de led e lampada wrgb
   */

  onSmartlightWhiteColorChecked(card) {
    const topic = `${config.gtwTopic}${this.currentGateway}/device/${card.devIeeeAddr}/command`;//`${this.deviceTopic}command`;
    this.whiteLight = !this.whiteLight;
    let command: any;
    if (this.whiteLight) {
      command = {
        messageId: uuid(),
        timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        ieeeAddr: card.devIeeeAddr,
        epId: card.devEpList[0],
        user: this.currentUser,
        cId: 'lightingColorCtrl',
        cmd: 'moveToSaturation',
        zclData: { saturation: 0, transtime: 0 }
      };
    } else if (!this.whiteLight) {
      command = {
        messageId: uuid(),
        timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        ieeeAddr: card.devIeeeAddr,
        user: this.currentUser,
        epId: card.devEpList[0],
        cId: 'lightingColorCtrl',
        cmd: 'moveToSaturation',
        zclData: { saturation: 254, transtime: 0 }
      };
    }
    if(!card.blocked) this.unsafePublish(topic, JSON.stringify(command));
  }

  onSmartlightColorChange(card, h,s) {
    this.whiteLight = false;
    const command = {
      messageId: uuid(),
      timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      ieeeAddr: card.devIeeeAddr,
      epId: card.devEpList[0],
      user: this.currentUser,
      cId: 'lightingColorCtrl',
      cmd: 'moveToHueAndSaturation',
      zclData: { hue: h, saturation: 254, transtime: 0 }
    };
    const topic = `${config.gtwTopic}${this.currentGateway}/device/${card.devIeeeAddr}/command`;
    if(!card.blocked) this.unsafePublish(topic, JSON.stringify(command));
  }

  onSmartlightLevelChange(card, value) {
    const command = {
      messageId: uuid(),
      timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      ieeeAddr: card.devIeeeAddr,
      epId: card.devEpList[0],
      user: this.currentUser,
      cId: 'genLevelCtrl',
      cmd: 'moveToLevel',
      zclData: { level: value, transtime: 0 }
    };
    const topic = `${config.gtwTopic}${this.currentGateway}/device/${card.devIeeeAddr}/command`;
    if(!card.blocked) this.unsafePublish(topic, JSON.stringify(command));
  }

  openLoadingModal(message: String) {
    this.loadingModalRef = this.modalService.show(
      LoadingModalComponent, {
        class: 'waiting-modal modal-sm',
        keyboard: false,
        backdrop: 'static'
      }
    )
    this.loadingModalRef.content.message = message;
  }
  /**
   * Block/Unblock devices 
   */
  blockDevice(dev, i) {
    this.openLoadingModal(this.jsonWord.text.updatingDevice)
    let device = {}
    if (dev.blocked) {
      device = {
        "blocked": false
      }
      this._deviceService.updateBlockDevice(dev._id, device).subscribe(res => {
        if (res) {
          this.loadingModalRef.content.waiting = false
          this.loadingModalRef.content.success = true
          this.loadingModalRef.content.message = this.jsonWord.toast.successUnlockDevice
          dev.blocked = false//res.data.devices[i].blocked
          setTimeout(() => {
            this.loadingModalRef.hide()
          }, 1500)
        } else {
          this.loadingModalRef.content.message = this.jsonWord.toast.errorEditDevice
          this.loadingModalRef.content.waiting = false
          this.loadingModalRef.content.success = false
          setTimeout(() => {
            this.loadingModalRef.hide()
          }, 1500)
        }
      })
    } else {
      //bloqueia
      device = {
        "blocked": true
      }
      this._deviceService.updateBlockDevice(dev._id, device).subscribe(res => {
        if (res) {
          this.loadingModalRef.content.message = this.jsonWord.toast.successBlockDevice
          this.loadingModalRef.content.waiting = false
          this.loadingModalRef.content.success = true
          dev.blocked = true//res.data.devices[i].blocked
          setTimeout(() => {
            this.loadingModalRef.hide()
          }, 1500)
        } else {
          this.loadingModalRef.content.message = this.jsonWord.toast.errorEditDevice
          this.loadingModalRef.content.waiting = false
          this.loadingModalRef.content.success = false
          setTimeout(() => {
            this.loadingModalRef.hide()
          }, 1500)
        }
      })
    }
    this.devsSyncObserver
      .takeUntil(this.ngUnsubscribe)
      .subscribe(payload => {
        this.message = payload
        let op = payload
        if (payload.blockedDevices && payload.blockedDevices == dev.ieeeAddr) {
          dev.blocked = true
        }
        if (payload.unblockedDevices && payload.unblockedDevices == dev.ieeeAddr) {
          dev.blocked = false
        }
      })
  }

  messageDispatcher(message) {
    var hue, sat;
    switch (message.cId) {
      case 'genLevelCtrl':
        if (message.type === 'currentLevel') {
          let value;
          value = message.value;
          hue = value
          //this.card.value1 = value.toFixed(1);
          if(this.card.devId== '6'){
            this.inputLevel.value = value ? value.toFixed(1) : '127'
          }else this.card.value1 = value.toFixed(1);
        }
       //this.colorhueScreenComponent.getAnswerColor('hue',hue)
        break;
      case 'lightingColorCtrl':
        if (message.type === 'currentHue') {
          let value;
          value = message.value;
          sat = value
          this.card.value2 = value.toFixed(1);
        } else if (message.type === 'currentSaturation') {
          let value;
          value = message.value;
          if (value > 1) {
            this.whiteLight = false;
          } else if (value <= 1) {
            this.whiteLight = true;
          }
        }
       // this.colorhueScreenComponent.getAnswerColor("sat",sat)
        break;
      case 'msOccupancySensing':
        if (message.type === 'occupancy') {
          let status: boolean;
          status = message.value ? true : false;
          this.card.isActive = status;
        }
        break;
    }
  }


  toggleSelect(device) {
    let selectDevice = this.selectedDevices.find(d => d == device.ieeeAddr)
    if (selectDevice) {
      this.selectedDevices = this.selectedDevices.filter(d => d != selectDevice)
    } else {
      this.selectedDevices.push(device.ieeeAddr)
    }
  }

  /*isSelected(device) {
    console.log("teste isSelected",device)
    return this.selectedDevices.includes(device.ieeeAddr)
  }*/

  /**
   * Smartlock
   */

  openConfirmDialog(card) {
    this.modalRef = this.modalService.show(AlertComponent);
    if (card.devId == '772') {
      this.modalRef.content.buttonConfirm = { show: false };
      this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
      this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.calibrate };
      this.modalRef.content.title = this.jsonWord.button.calibrateController + '?'//'Calibrar fechadura?';
      this.modalRef.content.message = this.jsonWord.text.startCalibrationController//'Clique em CALIBRAR para começar o processo de calibração da fechadura.';
      this.modalRef.content.controller = { show: true }
      this.modalRef.content.onClose
        .takeUntil(this.ngUnsubscribe)
        .subscribe(result => {
          let topico = `${config.gtwTopic}${this.currentGateway}/device/${card.devIeeeAddr}/edit`
          this.openLoadingModal(this.jsonWord.text.updatingDevice)
          let device = {}
          if (card.invertOnOff) {
            if (result == 'close' && card.isActive || result == 'open' && !card.isActive) {
              device = {
                "invertOnOff": false
              }
            }else{
              device = {
              "invertOnOff": true
            }
          }
              this._deviceService.updateBlockDevice(card._id, device).subscribe(res => {
                if (res) {
                  this.loadingModalRef.content.waiting = false
                  this.loadingModalRef.content.success = true
                  this.loadingModalRef.content.message = this.jsonWord.toast.successUnlockDevice
                  let payload =
                  {
                    "messageId": "c4fa3ef5-9049-4faa-ba52-fba25f1f5966",
                    "timestamp": 1529530255173,
                    "invertOnOff": false
                  }

                  this._mqttService.unsafePublish(topico, JSON.stringify(payload));
                  // card.isActive = card.isActive//res.data.devices[i].blocked
                  setTimeout(() => {
                    this.loadingModalRef.hide()
                    this.closeAllModals()
                  }, 1500)
                } else {
                  this.loadingModalRef.content.message = this.jsonWord.toast.errorEditDevice
                  this.loadingModalRef.content.waiting = false
                  this.loadingModalRef.content.success = false
                  setTimeout(() => {
                    this.loadingModalRef.hide()
                    this.closeAllModals()
                  }, 1500)
                }
              })
            
          } else {
            if (result == 'close' && card.isActive || result == 'open' && !card.isActive) {
            //esta invertido
            device = {
              "invertOnOff": true
            }
          }else{
            device = {
              "invertOnOff": false
            }
          }
            this._deviceService.updateBlockDevice(card._id, device).subscribe(res => {
              if (res) {
                this.loadingModalRef.content.message = this.jsonWord.toast.successBlockDevice
                this.loadingModalRef.content.waiting = false
                this.loadingModalRef.content.success = true
                // card.isActive = !card.isActive//res.data.devices[i].blocked
                let payload =
                {
                  "messageId": "c4fa3ef5-9049-4faa-ba52-fba25f1f5966",
                  "timestamp": 1529530255173,
                  "invertOnOff": true
                }

                this._mqttService.unsafePublish(topico, JSON.stringify(payload));
                setTimeout(() => {
                  this.loadingModalRef.hide()
                  this.closeAllModals()
                }, 1500)
              } else {
                this.loadingModalRef.content.message = this.jsonWord.toast.errorEditDevice
                this.loadingModalRef.content.waiting = false
                this.loadingModalRef.content.success = false
                setTimeout(() => {
                  this.loadingModalRef.hide()
                  this.closeAllModals()
                }, 1500)
              }
            })
        }
          this.devsSyncObserver
            .takeUntil(this.ngUnsubscribe)
            .subscribe(payload => {
              this.message = payload
              let op = payload
              if (payload.blockedDevices && payload.blockedDevices == card.ieeeAddr) {
                card.isActive = result == 'close' ? !card.isActive : card.isActive
              }
              if (payload.unblockedDevices && payload.unblockedDevices == card.ieeeAddr) {
                card.isActive = result == 'open' ? card.isActive = true : card.isActive = false
              }
            })
        });
    }
    if (card.devId == '10') {
      this.modalRef.content.buttonConfirm = { show: false };
      this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
      this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.calibrate };
      this.modalRef.content.title = this.jsonWord.button.calibrateLock + '?'//'Calibrar fechadura?';
      this.modalRef.content.message = this.jsonWord.text.startCalibrationProcess//'Clique em CALIBRAR para começar o processo de calibração da fechadura.';
      this.modalRef.content.onClose
        .takeUntil(this.ngUnsubscribe)
        .subscribe(result => {
          if (result) {
            this.openCalibrationModal(card);
          }
        });
    }
  }
  openCalibrationModal(card) {
    const topic = `${config.gtwTopic}${this.currentGateway}/device/${card.devIeeeAddr}/command`
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.buttonBlock = { show: true, value: this.calButtonOk }
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
    this.modalRef.content.title = this.jsonWord.label.startCalibration//'Calibração da fechadura';
    this.modalRef.content.message = this.jsonWord.label.keepClosedUnlockedDoor//'Mantenha a porta fechada e a fechadura destrancada. Aguarde o bipe.';
    let message = {
      "messageId": this.uuid(),
      "timestamp": (Date.now()),
      "name": card.title,
      "user": this.currentUser,
      "ieeeAddr": card.devIeeeAddr,//"0x00124b0008023185",
      "epId": 8,
      "cId": "closuresDoorLock",
      "cmd": 192,
      "zclData": { "pincodevalue": "00000000" }
    };
    this._mqttService.unsafePublish(topic, JSON.stringify(message));
    this.modalRef.content.onClose
      .takeUntil(this.ngUnsubscribe)
      .subscribe(result => {
        this.calButtonOk = true
        if (result) {
          let success, messageR
          this.openLoadingModal(this.jsonWord.loading.message)//'Aguarde...')
          this.openSecondCalibrationModal(card);
          this._mqttService.observe(`${config.gtwTopic}${this.currentGateway}/success`)
            .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload))).subscribe(res => { console.log(res) })
          this._mqttService.observe(`${config.gtwTopic}${this.currentGateway}/success`)
            .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
            .filter(message => message['operation'] == 'functional')//&& message.data['opereventcode'] == 2)
            .map(message => {
              success = true
              messageR = this.jsonWord.label.success
              this.modalRef.content.buttonBlock = { show: true, value: false }
              //this.updateAll(this.currentGateway)
              /* setTimeout(() => {
                 if (this.loadingModalRef.content.success)
                   this.loadingModalRef.hide()
                 console.log("primeiro calibrar")
               }, 2000)*/

              return message
            })
            .merge(
              this._mqttService.observe(config.gtwTopic + this.currentGateway + '/error')
                .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
                //.filter(message => message['operation'] == 'functional')
                .map(message => {
                  success = false
                  messageR = this.jsonWord.toast.failedCalibration

                  return message
                })
            )
            .pipe(finalize(() => {
              this.calButtonOk = false

              this.loadingModalRef.content.waiting = false
              this.loadingModalRef.content.message = messageR;//'Tempo limite de conexão atingido.'
              this.loadingModalRef.content.success = success
              setTimeout(() => {
                if (this.loadingModalRef)
                  this.loadingModalRef.hide()
              }, 2000)
            }), timeoutWith(15000, ErrorObservable.throw(
              new Response(null, {
                status: 408, statusText: this.jsonWord.error.timeoutServer
              })
            )))
            .take(1)
            .subscribe(_ => {
              this.deletingDevice = null
            })
        }
      });
  }


  openSecondCalibrationModal(card) {
    const topic = `${config.gtwTopic}${this.currentGateway}/device/${card.devIeeeAddr}/command`
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.buttonBlock = { show: true, value: this.calButtonOk }
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
    this.modalRef.content.title = this.jsonWord.label.startCalibration//'Calibração da fechadura';
    this.modalRef.content.message = this.jsonWord.label.lockManuallySmartLock;///'Tranque manualmente a fechadura e confirme quando a mesma estiver trancada.';
    let message = {
      'messageId': this.uuid(),
      'timestamp': (Date.now()),
      'name': card.title,
      "ieeeAddr": card.devIeeeAddr,//"0x00124b0008023185",
      "epId": card.devEpList[0],
      "user": this.currentUser,
      "cId": "closuresDoorLock",
      "cmd": "unlockDoor",
      "zclData": { "pincodevalue": "00000000" }
    };
    this._mqttService.unsafePublish(topic, JSON.stringify(message));

    this.modalRef.content.onClose
      .takeUntil(this.ngUnsubscribe)
      .subscribe(result => {
        this.calButtonOk = true
        if (result) {
          let success, messageR
          this.openLoadingModal(this.jsonWord.loading.message)
          this.openFinalCalibrationModal(card);
          this._mqttService.observe(`${config.gtwTopic}${this.currentGateway}/success`)
            .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload))).subscribe(res => { console.log(res) })
          this._mqttService.observe(`${config.gtwTopic}${this.currentGateway}/success`)
            .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
            .filter(message => message['operation'] == 'functional')// && message.data['opereventcode'] == 1)
            .map(message => {
              success = true
              messageR = this.jsonWord.label.successCalibration

              return message
            })
            .merge(
              this._mqttService.observe(config.gtwTopic + this.currentGateway + '/error')
                .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
                //.filter(message => message['operation'] == 'functional')
                .map(message => {
                  // this.loadingModalRef.content.waiting = false
                  success = false
                  messageR = this.jsonWord.toast.failedCalibration

                  return message
                })
            )
            .pipe(finalize(() => {
              this.calButtonOk = false
              this.loadingModalRef.content.waiting = false
              this.loadingModalRef.content.message = messageR;//'Tempo limite de conexão atingido.'
              this.loadingModalRef.content.success = success
              setTimeout(() => {
                if (this.loadingModalRef)
                  this.loadingModalRef.hide()
                setTimeout(() => {
                  this.closeAllModals()
                }, 1000);

              }, 2000)
            }), timeoutWith(15000, ErrorObservable.throw(
              new Response(null, {
                status: 408, statusText: this.jsonWord.error.timeoutServer
              })
            )))
            .take(1)
            .subscribe(_ => {
              this.deletingDevice = null
            })
        }
      });
  }

  openFinalCalibrationModal(card) {
    const topic = `${config.gtwTopic}${this.currentGateway}/device/${card.devIeeeAddr}/command`
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.buttonBlock = { show: true, value: this.calButtonOk }
    this.modalRef.content.buttonOk = { show: true, text: 'OK' };
    this.modalRef.content.title = this.jsonWord.label.startCalibration//'Calibração da fechadura';
    this.modalRef.content.message = this.jsonWord.loading.calibrating//'Finalizando.';
    let message = {
      'messageId': this.uuid(),
      'timestamp': (Date.now()),
      'name': card.title,
      "ieeeAddr": card.devIeeeAddr,//"0x00124b0008023185",
      "epId": card.devEpList[0],
      "user": this.currentUser,
      "cId": "closuresDoorLock",
      "cmd": "lockDoor",
      "zclData": { "pincodevalue": "00000000" }
    };
    this._mqttService.unsafePublish(topic, JSON.stringify(message));

  }

  openConfigDialog(card) {
    this.modalRef = this.modalService.show(SmartlockModalComponent);
    this.modalRef.content.card = card
    this.modalRef.content.option = this.card.value4 != "0" ? true : false
    this.modalRef.content.led = card.attributes && card.attributes['closuresDoorLock#ledSettings'] == '0' ? false : true;
    this.modalRef.content.buzzer = card.attributes && card.attributes['closuresDoorLock#soundVolume'] == '0' ? false : true;
    this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
    this.modalRef.content.onClose
      .switchMap(result => {
        if (result) {
        }
      })
  }

  /**
   * Delete device
   */

  deleteDevice(device) {
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.imagem = { show: true, text: '../assets/img/dashboard/card-content/' + device.devId + '_alt.svg' };
    this.modalRef.content.isDelete = true;
    this.modalRef.content.title = device.title
    this.modalRef.content.message = this.jsonWord.alert.messageRemoveDevice + ' ' + device.title + '?'
    this.modalRef.content.onClose.subscribe(result => {
      if (result) {
        //this.deletingDevice = device
        //Deleta dispositivo offline de gateway que funciona e nao deleta de gateway que nao funciona, ou seja, funciona! 28/05/19-Nath
        this._deviceService.deleteDeviceById(device._id).merge(
          this.responseService.responseStatusDevice(device, 'delete', '/status', result)

        ).subscribe(res => {
          console.log(res)
        })
        /*.pipe(finalize(()=>{
          this.loadingModalRef.content.waiting = false 
          setTimeout(() => {
            this.loadingModalRef.hide()
            
          }, 5000);
        })).take(2).subscribe(res=> { 
         this.loadingModalRef.content.success = true
             // this.loadingModalRef.content.waiting = false 
              this.loadingModalRef.content.message = this.jsonWord.toast.successRemoveDevice
        },
          err => {
            
            if (response || messageResponse=='success') {
              console.log("ta lindo sz")
              this.loadingModalRef.content.success = true
              this.loadingModalRef.content.waiting = false 
              switch (operation) {
                case 'edit':
                this.loadingModalRef.content.message = this.jsonWord.toast.editDeviceSucess
                break;
              case 'delete':
                this.loadingModalRef.content.message = this.jsonWord.toast.successRemoveDevice
                break;
              }
            } else {
              console.log("nao ta lindo sz")
        
             this.loadingModalRef.content.success = false
         //     this.loadingModalRef.content.waiting = false 
              this.loadingModalRef.content.message = this.jsonWord.toast.errorRemoveDevice
           
          /*  setTimeout(() => {
           }
            
          }
              this.loadingModalRef.hide()
              //this.responseService.closeAllModals()
              console.log(this.loadingModalRef)
            }, 5000)*/
        //  })
        //   this._deviceService.deleteDeviceById(device._id).subscribe(res=>console.log(res))
      }

    })
  }

  getDimmerSliderValue() {
    return this.card.value1;
  }

  getColorSliderValue() {
    return this.card.value2;
  }


  private closeAllModals() {
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    }
  }
  public unsafePublish(topic: string, message: string) {
    this.correlId = JSON.parse(message).messageId
    this._mqttService.unsafePublish(topic, message, { qos: 1, retain: false })
  }
}

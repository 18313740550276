import { TranslateJsonService } from './../../_services/translate-json.service';
import { AlertComponent } from './../../_directives/alert.component';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService, BsDatepickerConfig, BsLocaleService, defineLocale } from 'ngx-bootstrap';
import { Card } from './../../models/card.model';

import { DeviceService } from './../../_services/device.service';
import * as config from '../../config';
import { Device } from './../../models/device.model';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { Subscription } from 'rxjs/Subscription';
import { EnvironmentService } from './../../_services/environment.service';
import { OnInit, OnDestroy, ChangeDetectorRef, Component, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
//import { forEach } from '@angular/router/src/utils/collection';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ScenarioService } from '../../_services/scenario.service';
import { LoadingModalComponent } from '../../_directives/loading-modal/loading-modal.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { UserService } from '../../_services/user.service';
import { finalize, concatMap } from 'rxjs/operators';
import { enGbLocale, ptBrLocale, esLocale } from 'ngx-bootstrap/locale';
import 'rxjs/add/operator/concat';
import { GatewayService } from '../../_services/gateway.service';
import { BsNavigationDirection, BsNavigationEvent } from 'ngx-bootstrap/datepicker/models';

@Component({
	selector: 'vr-edit-user',
	templateUrl: './edit-user.component.html',
	styleUrls: ['./edit-user.component.css'],
	providers: [ScenarioService, DeviceService],
})

export class EditUserComponent implements OnInit, OnDestroy {
	//devicesService: DevicesService;

	_id: number;
	nome;
	onOff = [];
	output;
	uuid = require('uuid/v4');
	gtw;//= 'csZ1DuG92MTr1MKR'; //this.appService.localStorage()
	inputToCheck = [];
	devNameArray = [];
	devices;
	public modalRef: BsModalRef;
	public loading = false;
	currentGateway;
	devToClose;
	devName;
	card;
	scenesAll;
	scenesAllSubs = [];
	devsSceneClick;
	vectorTypes = [];
	checkedDevices = [];
	devicesArray = [];
	messageEdit;
	@Input() userName;
	@Input() value;
	devUser = [];
	option = "ADMIN";
	checkedScenario = [];
	datepickerModel
	daterangepickerModel
	datePickerConfig: Partial<BsDatepickerConfig>;
	datePickerEvent: Partial<BsNavigationEvent>;
	up: BsNavigationDirection = 0;
	bodyScn = []
	bodyDev;
	bodyScnP = []
	bodyP;
	bodyU
	userToClose;
	users;
	@Input() emailInput;
	optionCheck
	devsOutPermission = []
	scnsOutPermission = []
	optionStart = [];
	currentUser
	public language;
	public jsonWord;
	public inviteUserForm: FormGroup
	public email: FormControl

	constructor(private formBuilder: FormBuilder, private location: Location,
		devicesService: DeviceService, public sceneService: ScenarioService,
		private route: ActivatedRoute, public element: ElementRef,
		private translateJson: TranslateJsonService,
		private modalService: BsModalService,
		private localeService: BsLocaleService,
		private userService: UserService, private gatewayService: GatewayService,
		private cd: ChangeDetectorRef, private _mqttService: MqttService,
		private router: Router, ) {
		this.devices = devicesService.listDevices();
		if (localStorage.getItem('language') == 'pt') {
			ptBrLocale.invalidDate = 'Custom label';
			defineLocale('custom locale', ptBrLocale);
			this.localeService.use('custom locale');
		}
		if (localStorage.getItem('language') == 'es') {
			esLocale.invalidDate = 'Custom label';
			defineLocale('custom locale', esLocale);
			this.localeService.use('custom locale');
		}
		if (localStorage.getItem('language') == 'en') {
			enGbLocale.invalidDate = 'Custom label';
			defineLocale('custom locale', enGbLocale);
			this.localeService.use('custom locale');
		}

		this.email = new FormControl(null, [Validators.required,
		Validators.minLength(8),
		Validators.maxLength(60),
		Validators.pattern(/^([A-Za-z0-9._-]{2,})(@[A-Za-z0-9_-]{2,})(\.[A-Za-z]{2,6})+$/),
		])
		this.inviteUserForm = new FormGroup({
			email: this.email
		})
	}

	ngOnInit(): void {
		this.language = localStorage.getItem('language')
		this.jsonWord = this.translateJson.changeLanguage(this.language)
		this.loading = true
		this.location.replaceState('/')
		this.currentGateway = (localStorage.getItem('currentGateway'));
		this.currentUser = (localStorage.getItem('currentUserId'))
		this.route.params.forEach((params: Params) => {
			this._id = params['_id'];
		});
		this.datePickerConfig = Object.assign(
			{
				containerClass: 'theme-green',
				showWeekNumbers: false,
				rangeInputFormat: 'DD/MM/YYYY',
				minDate: new Date(),
			})
		//preciso
		this.devToClose = this.devices.concatMap(res => {
			this.devName = res//.devices;
			let resp: Device
			let teste = [];
			for (resp of res) {//.devices) {
				this.inputToCheck.push(resp)
				this.devUser.push(true)
				this.devNameArray.push(resp.ieeeAddr);
			}
			// this.getValueFromDevicesToBeEdited()
			this.checkDevsType(this.inputToCheck, this.devUser)

			return this.userService.listUsers().map(res => {
				for (let i = 0; i < res.length; i++) {
					if (res[i]._user._id === this._id) {
						this.emailInput = res[i].email;
						this.option = String(res[i].role)
						this.optionStart = res[i].role
						this.daterangepickerModel = res[i].startDate + '-' + res[i].endDate
					}
				}
				this.loading = false
			})
		}).subscribe(res => { console.log(res) }, err => {
			if (err.status == 0 || err.status == 408) {
				this.modalRef = this.modalService.show(AlertComponent);
				this.modalRef.content.type = 'remove'
				this.modalRef.content.title = /*"<i class='fa fa-lg fa-times text-danger'> </i> " +*/ this.jsonWord.error.conectionError
				this.modalRef.content.message = this.jsonWord.error.noConnection
				this.modalRef.content.isDelete = false
				this.modalRef.content.typeB = 'refresh'
				//"Tempo limite de conexão com o servidor atingido. Verifique o status do servidor recarregue a página novamente."
				this.modalRef.content.buttonConfirm = { show: true, text: /*"<i class='fa fa-refresh'></i>" + '  ' + */this.jsonWord.label.reload }
				this.modalRef.content.onClose.subscribe(
					res => {
						if (res)
							window.location.reload()
					}
				)
			}
		});

		this.scenesAll = this.sceneService.listScenarios()
			.subscribe(res => {
				//this.scenesAllSubs = res;
				let scnNoChecked
				for (let scn of res) {
					scnNoChecked = scn && scn.blockedUsers.filter(scn => scn == this._id).length > 0 ? false : true
					this.scenesAllSubs.push([scn, scnNoChecked])
				}
			});
		this.devsSceneClick = false;
		this.userService.listUserInGatewayById(this.currentUser).subscribe(res => {
		})
	}

	//PRECISO DISSO
	checkDevsType(inputToCheck, devUser) {
		let count0 = [], count1 = [], count2 = [], count3 = [], count4 = [], count5 = [], count6 = [], count7 = [], count8 = [], count9 = [], count10 = [];
		for (let i = 0; i < inputToCheck.length; i++) {
			devUser[i] = inputToCheck[i].blockedUsers && inputToCheck[i].blockedUsers.filter(id => id == this._id).length > 0 ? false : true
			if (this.inputToCheck[i].devId == '770') count0.push([inputToCheck[i], devUser[i]]);
			if (this.inputToCheck[i].devId == '12') count1.push([inputToCheck[i], devUser[i]]);  //3
			if (this.inputToCheck[i].devId == '263') count2.push([inputToCheck[i], devUser[i]]);
			if (this.inputToCheck[i].devId == '81' || this.inputToCheck[i].devId === '256') count3.push([inputToCheck[i], devUser[i]]);
			if (this.inputToCheck[i].devId == '2') count4.push([inputToCheck[i], devUser[i]]); //2
			if (this.inputToCheck[i].devId == '6') count5.push([inputToCheck[i], devUser[i]]); //2
			if (this.inputToCheck[i].devId == '258' || this.inputToCheck[i].devId === '528') count6.push([inputToCheck[i], devUser[i]]);
			if (this.inputToCheck[i].devId == '10') count7.push([inputToCheck[i], devUser[i]]); //1
			if (this.inputToCheck[i].devId == '262') count8.push([inputToCheck[i], devUser[i]]);
			if (this.inputToCheck[i].devId == '771') count9.push([inputToCheck[i], devUser[i]]);
			if (this.inputToCheck[i].devId == '261') count10.push([inputToCheck[i], devUser[i]]); //1
		}
		this.vectorTypes[0] = count0;
		this.vectorTypes[1] = count1;
		this.vectorTypes[2] = count2;
		this.vectorTypes[3] = count3;
		this.vectorTypes[4] = count4;
		this.vectorTypes[5] = count5;
		this.vectorTypes[6] = count6;
		this.vectorTypes[7] = count7;
		this.vectorTypes[8] = count8;
		this.vectorTypes[9] = count9;
		this.vectorTypes[10] = count10;
	}

	ngOnDestroy(): void {
		this.devToClose.unsubscribe();
		this.scenesAll.unsubscribe();
	}

	getValue() {
		let values = <any>document.getElementsByName('invite')
		for (let value of values) {
			if (value.checked) {
				this.option = value.value;
			}
		}
	}

	/**
	 * @author Nathalia
	 * @function saveScene() 
	 */

	onEnter($event) {//Press enter and save...but not working
		$event.preventDefault();
		this.editPermission();
	}
	/*editType() {

		if (this.option != this.optionStart) {
			if (this.option === 'INVITED') {
				console.log('INVITED')
				if(this.daterangepickerModel[0] && this.daterangepickerModel[1]){
				this.bodyU = [{
					"op": "replace",
					"path": "/registeredUsers/",
					"value": {
						"role": this.option,
						"startDate": moment(this.daterangepickerModel[0]).format('YYYY-MM-DD'),
						"endDate": moment(this.daterangepickerModel[1]).format('YYYY-MM-DD')
					}
				}]
			}else{
				console.log("coloque ma data")
			}
			} else {
				console.log('qqcoisa')
				this.bodyU = [{
					"op": "replace",
					"path": "/registeredUsers/",
					"value": {
						"role": this.option,
						"startDate": "2018-11-30",
						"endDate": "2030-12-01",
					}
				}]
			}
		
			this.loading = true
			this.openLoadingModal(this.jsonWord.loading.savingChanges)
			this.userService.invite(this.bodyU, this.emailInput)
				.pipe(finalize(() => {
					this.loading = false
					this.modalRef.content.waiting = false
				}))
				.subscribe(
					res => {
						this.modalRef.content.message = this.jsonWord.alert.successToAssociateUser
						this.modalRef.content.success = true
					},
					err => {
						console.log(err)
						let message = ''
						switch (err.status) {
							case 400:
								message =  this.jsonWord.error.gatewayInvalidFormat//'Formulário de convidar usuário inválido.'
								break
							case 401:
								message = this.jsonWord.error.notAuthorized//'Você não tem permissão para convidar usuários.'
								break
							case 403:
								message = this.jsonWord.error.userDisabled//'Usuário está desativado.'
								break
							case 404:
								message = this.jsonWord.error.userNotFound//'Usuário não encontrado ou número serial de gateway não registrado.'
								break
							case 409:
								message = this.jsonWord.error.userAlreadyInGtw//'Usuário já associado ao gateway.'
								break
							case 500:
								message = this.jsonWord.error.inviteNotSend//'Não foi possível enviar convite.'
								break
							default:
								message = err._body
						}
						this.modalRef.content.message = this.jsonWord.error.error+' ' + message
						this.modalRef.content.success = false
						this.modalRef.content.waiting = false
						//this.openErrorDialog(err.message)
					})
		}

	}*/

	editPermission() {
		let holdCheckedScn = [], holdScnId = [], holdScnOutPermission = [], holdElemId = [], holdChecked = [], holdOutPermission = [], holdAll = [];
		if (this.inputToCheck) {
			for (let i = 0; i < this.inputToCheck.length; i++) {
				holdElemId.push(<any>document.getElementById(this.inputToCheck[i].ieeeAddr))
				if (holdElemId[i] && holdElemId[i].checked) {//classList[holdElemId[i].classList.length - 1] === "mat-checkbox-checked") {
					holdChecked.push(this.inputToCheck[i].ieeeAddr)
				} else {
					holdOutPermission.push(this.inputToCheck[i].ieeeAddr)
				}
				this.checkedDevices = holdChecked
				this.devsOutPermission = holdOutPermission
			}
		}
		if (this.scenesAllSubs) {
			for (let i = 0; i < this.scenesAllSubs.length; i++) {
				holdScnId.push(<any>document.getElementById(this.scenesAllSubs[i][0]._id))
				if (holdScnId[i] && holdScnId[i].checked) {//classList[holdElemId[i].classList.length - 1] === "mat-checkbox-checked") {
					holdCheckedScn.push(this.scenesAllSubs[i][0]._id)
				} else {
					holdScnOutPermission.push(this.scenesAllSubs[i][0]._id)
				}
				this.checkedScenario = holdCheckedScn;
				this.scnsOutPermission = holdScnOutPermission;
			}
		}

		if (this.option != 'ADMIN') {
			if (this.option === 'INVITED') {
				if (this.daterangepickerModel[0] && this.daterangepickerModel[1]) {
					this.bodyU = [{
						"op": "replace",
						"path": "/registeredUsers/",
						"value": {
							"role": this.option,
							"startDate": moment(this.daterangepickerModel[0]).format('YYYY-MM-DD'),
							"endDate": moment(this.daterangepickerModel[1]).format('YYYY-MM-DD')
						}
					}]
					this.bodyDev = [{
						"op": "remove",
						"path": "/devices/",
						"value": {
							"idList": this.checkedDevices,
							"role": this.option,
							"startDate": moment(this.daterangepickerModel[0]).format('YYYY-MM-DD'),
							"endDate": moment(this.daterangepickerModel[1]).format('YYYY-MM-DD')
						}
					}]
					this.bodyScn = [{
						"op": "remove",
						"path": "/scenarios/",
						"value": {
							"idList": this.checkedScenario,
							"role": this.option,
							"startDate": moment(this.daterangepickerModel[0]).format('YYYY-MM-DD'),
							"endDate": moment(this.daterangepickerModel[1]).format('YYYY-MM-DD')
						}
					}]
					this.bodyP = [{
						"op": "add",
						"path": "/devices/",
						"value": {
							"idList": this.devsOutPermission,
							"role": this.option,
							"startDate": moment(this.daterangepickerModel[0]).format('YYYY-MM-DD'),
							"endDate": moment(this.daterangepickerModel[1]).format('YYYY-MM-DD')
						}
					}]
					this.bodyScnP = [{
						"op": "add",
						"path": "/scenarios/",
						"value": {
							"idList": this.scnsOutPermission,
							"role": this.option,
							"startDate": moment(this.daterangepickerModel[0]).format('YYYY-MM-DD'),
							"endDate": moment(this.daterangepickerModel[1]).format('YYYY-MM-DD')
						}
					}]
				} else { console.log("digite uma data") }
			} else if (this.option === 'COMMON') {
				this.bodyU = [{
					"op": "replace",
					"path": "/registeredUsers/",
					"value": {
						"role": this.option,
						"startDate": "2018-11-30",
						"endDate": "2030-12-01",
					}
				}]
				this.bodyDev = [{
					"op": "remove",
					"path": "/devices/",
					"value": {
						"idList": this.checkedDevices ? this.checkedDevices : [],
						"role": this.option,
						"startDate": "2018-11-30",
						"endDate": "2030-12-01",
					}
				}]
				this.bodyScn = [{
					"op": "remove",
					"path": "/scenarios/",
					"value": {
						"idList": this.checkedScenario ? this.checkedScenario : [],
						"role": this.option,
						"startDate": "2018-11-30",
						"endDate": "2030-12-01",
					}
				}]
				this.bodyP = [{
					"op": "add",
					"path": "/devices/",
					"value": {
						"idList": this.devsOutPermission ? this.devsOutPermission : [],
						"role": this.option,
						"startDate": "2018-11-30",
						"endDate": "2030-12-01",
					}
				}]
				this.bodyScnP = [{
					"op": "add",
					"path": "/scenarios/",
					"value": {
						"idList": this.scnsOutPermission ? this.scnsOutPermission : [],
						"role": this.option,
						"startDate": "2018-11-30",
						"endDate": "2030-12-01",
					}
				}]
			}

			this.userService.invite(this.bodyP, this.emailInput).subscribe(res => {
			}/*, err => {
				this.userService.invite(this.bodyP, this.emailInput).subscribe(res =>
					console.log("remove scn", res))
			}*/)
			this.userService.invite(this.bodyScnP, this.emailInput).subscribe(res => {
			}/*, err => {
				this.userService.invite(this.bodyScnP, this.emailInput).subscribe(res =>
					console.log("remove scn", res))
			}*/)
			this.userService.invite(this.bodyDev, this.emailInput).subscribe(res => {
			}/*, err => {
				this.userService.invite(this.bodyDev, this.emailInput).subscribe(res =>
					console.log("add dv", res))
	
			}*/)
			this.userService.invite(this.bodyScn, this.emailInput).subscribe(res => {
			}/*, err => {
				this.userService.invite(this.bodyScn, this.emailInput).subscribe(res => {
					console.log("addscn", res)
				})
			}*/)
		} else {
			this.bodyU = [{
				"op": "replace",
				"path": "/registeredUsers/",
				"value": {
					"role": this.option,
					"startDate": "2018-11-30",
					"endDate": "2030-12-01",
				}
			}]
		}
		//this.loading = true
		this.openLoadingModal(this.jsonWord.loading.savingChanges)
		this.userService.invite(this.bodyU, this.emailInput)
			.pipe(finalize(() => {
				//	this.loading = false
				/*setTimeout(() => {
					this.modalRef.content.waiting = false
					this.modalRef.hide()
				}, 2000);*/
			}))
			.subscribe(
				res => {
					this.modalRef.content.success = true
					this.modalRef.content.waiting = false
					this.modalRef.content.message = this.jsonWord.toast.savedSettings//"Configurações salvas com sucesso"
					setTimeout(() => {
						this.router.navigate(['/home/users']);
						this.modalRef.hide()
					}, 2000);
				}, err => {
					let message = ''
					switch (err.status) {
					  case 0:
						message = this.jsonWord.error.noConnection//'Formulário de convidar usuário inválido.'
						break
					  case 400:
						message = this.jsonWord.error.invalidFormat//'Formulário de convidar usuário inválido.'
						break
					  case 401:
						message = this.jsonWord.error.notAuthorized//'Você não tem permissão para convidar usuários.'
						break
					  case 403:
						message = this.jsonWord.error.userDisabled//'Usuário está desativado.'
						break
					  case 404:
						message = this.jsonWord.error.userNotFound//'Usuário não encontrado ou número serial de gateway não registrado.'
						break
					  case 408:
						message = this.jsonWord.error.timeoutServer;//'Tempo limite de conexão atingido.'
						break
					  case 409:
						message = this.jsonWord.error.userAlreadyInGtw//'Usuário já associado ao gateway.'
						break
					  case 412:
						message = this.jsonWord.error.limitUserExceeded//'Usuário já associado ao gateway.'
						break
					  case 500:
						message = this.jsonWord.error.errorEditUser//'Não foi possível enviar convite.'
						break
					  default:
						message = this.jsonWord.error.errorEditUser
		  
					}
					this.modalRef.content.message = this.jsonWord.error.error + ': ' + message
					this.modalRef.content.success = false
					this.modalRef.content.waiting = false
					setTimeout(() => {
						this.router.navigate(['/home/users']);
						this.modalRef.hide()
					}, 2000);
				})
		/*).pipe(finalize(() => {
			this.loading = false
			this.modalRef.content.waiting = false
		}))
			.subscribe(
				res => {
					console.log(res)
				},
				err => {
					console.log(err)
					let message = ''
					switch (err.status) {
						case 400:
							message = 'Formulário de convidar usuário inválido.'
							break
						case 401:
							message = 'Você não tem permissão para convidar usuários.'
							break
						case 403:
							message = 'Usuário está desativado.'
							break
						case 404:
							message = 'Usuário não encontrado ou número serial de gateway não registrado.'
							break
						case 409:
							message = 'Usuário já associado ao gateway.'
							break
						case 500:
							message = 'Não foi possível enviar convite.'
							break
						default:
							message = err._body
					}
					this.modalRef.content.message = "Erro: " + message
					this.modalRef.content.success = false
					//this.openErrorDialog(err.message)
				})*/

		/**/
	}

	inivteUser() {
		let holdCheckedScn = [], holdScnId = [], holdScnOutPermission = [], holdElemId = [], holdChecked = [], holdOutPermission = [], holdAll = [];
		if (this.inputToCheck) {
			for (let i = 0; i < this.inputToCheck.length; i++) {
				holdElemId.push(<any>document.getElementById(this.inputToCheck[i].ieeeAddr))
				if (holdElemId[i] && holdElemId[i].checked) {//classList[holdElemId[i].classList.length - 1] === "mat-checkbox-checked") {
					holdChecked.push(this.inputToCheck[i].ieeeAddr)
				} else {
					holdOutPermission.push(this.inputToCheck[i].ieeeAddr)
				}
				this.checkedDevices = holdChecked
				this.devsOutPermission = holdOutPermission
			}
		}
		if (this.scenesAllSubs) {
			for (let i = 0; i < this.scenesAllSubs.length; i++) {
				holdScnId.push(<any>document.getElementById(this.scenesAllSubs[i][0]._id))
				if (holdScnId[i] && holdScnId[i].checked) {//classList[holdElemId[i].classList.length - 1] === "mat-checkbox-checked") {
					holdCheckedScn.push(this.scenesAllSubs[i][0]._id)
				} else {
					holdScnOutPermission.push(this.scenesAllSubs[i][0]._id)
				}
				this.checkedScenario = holdCheckedScn;
				this.scnsOutPermission = holdScnOutPermission;
			}
		}
		if (this.option === 'INVITED') {
			if (this.daterangepickerModel) {
				this.bodyP = [{
					"op": "add",
					"path": "/registeredUsers/",
					"value": {
						"role": this.option,
						"startDate": moment(this.daterangepickerModel[0]).format('YYYY-MM-DD'),
						"endDate": moment(this.daterangepickerModel[1]).format('YYYY-MM-DD')
					}
				}, {
					"op": "remove",
					"path": "/devices/",
					"value": {
						"idList": this.checkedDevices
					}
				}, {
					"op": "remove",
					"path": "/scenarios/",
					"value": {
						"idList": this.checkedScenario
					}
				}, {
					"op": "add",
					"path": "/devices/",
					"value": {
						"idList": this.devsOutPermission
					}
				}, {
					"op": "add",
					"path": "/scenarios/",
					"value": {
						"idList": this.scnsOutPermission
					}
				}]
			} else { console.log("coloque um periodo") }
		} else if (this.option === 'COMMON') {
			this.bodyP = [{
				"op": "add",
				"path": "/registeredUsers/",
				"value": {
					"role": this.option,
					"startDate": "2018-11-30",
					"endDate": "2020-12-01",
				}
			}, {
				"op": "remove",
				"path": "/devices/",
				"value": {
					"idList": this.checkedDevices
				}
			}, {
				"op": "remove",
				"path": "/scenarios/",
				"value": {
					"idList": this.checkedScenario
				}
			}, {
				"op": "add",
				"path": "/devices/",
				"value": {
					"idList": this.devsOutPermission
				}
			}, {
				"op": "add",
				"path": "/scenarios/",
				"value": {
					"idList": this.scnsOutPermission
				}
			}]
		} else {
			this.bodyP = [{
				"op": "add",
				"path": "/registeredUsers/",
				"value": {
					"role": this.option,
					"startDate": "2018-11-30",
					"endDate": "2020-12-01",
				}
			}]
		}


		this.loading = true
		this.openLoadingModal(this.jsonWord.loading.sendingInvite)
		this.userService.invite(this.bodyP, this.inviteUserForm.value.email)
			.pipe(finalize(() => {
				this.loading = false
				this.modalRef.content.waiting = false
			}))
			.subscribe(
				res => {
					this.modalRef.content.message = this.jsonWord.toast.successToAssociateUser
					this.modalRef.content.success = true
					setTimeout(() => {
						this.modalRef.hide()
						this.router.navigate(['/home/users']);
					}, 1500);
				},
				err => {
					let message = ''
					switch (err.status) {
						case 0:
							message = this.jsonWord.error.noConnection//'Formulário de convidar usuário inválido.'
							break
						case 400:
							message = this.jsonWord.error.invalidFormat//'Formulário de convidar usuário inválido.'
							break
						case 401:
							message = this.jsonWord.error.notAuthorized//'Você não tem permissão para convidar usuários.'
							break
						case 403:
							message = this.jsonWord.error.userDisabled//'Usuário está desativado.'
							break
						case 404:
							message = this.jsonWord.error.userNotFound//'Usuário não encontrado ou número serial de gateway não registrado.'
							break
						case 408:
							message = this.jsonWord.error.timeoutServer;//'Tempo limite de conexão atingido.'
							break
						case 409:
							message = this.jsonWord.error.userAlreadyInGtw//'Usuário já associado ao gateway.'
							break
						case 412:
							message = this.jsonWord.error.limitUserExceeded//'Usuário já associado ao gateway.'
							break
						case 500:
							message = this.jsonWord.error.inviteNotSend//'Não foi possível enviar convite.'
							break
						default:
							message = this.jsonWord.error.failOperation

					}
					this.modalRef.content.message = this.jsonWord.error.error + ': ' + message
					this.modalRef.content.success = false
					this.modalRef.content.waiting = false
					setTimeout(() => {
						this.modalRef.hide()
					}, 2000);
					//this.openErrorDialog(err.message)
				})
	}

	changeCheck(device, checkDoor) {
		device[1] = checkDoor.checked
	}

	changeCheckScn(scn, checkScn) {
		scn = checkScn.checked
	}
	openLoadingModal(message) {
		this.modalRef = this.modalService.show(
			LoadingModalComponent,
			{
				keyboard: false,
				backdrop: 'static',
				class: 'waiting-modal modal-sm'
			})
		this.modalRef.content.message = message;
	}
}
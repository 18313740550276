import { Directive, HostListener, Renderer2, Input, Output, EventEmitter } from '@angular/core';
import { HomeComponent } from '../home/home.component';

@Directive({
  selector: '[appCollapseElement]'
})
export class CollapseElementDirective {

  constructor(
  ) {
    console.log('open right menu directive')
  }

  @HostListener('click', ['$event']) onClickEvent(event: Event) {
    //if (event.srcElement.id == 'content') {
     // console.log(event.srcElement.id) 
      if (event.target['id'] == 'content') {
      let button = document.getElementById("right-menu-opener")
      let menu = document.getElementById("right-menu")
      if (menu.style.display != 'none')
        button.dispatchEvent(new Event('click'))

      let permitJoinButton = document.getElementById("permit-join-button")
      //console.log(permitJoinButton.getAttribute('isExpanded'))
      if (permitJoinButton && permitJoinButton.getAttribute('isExpanded') == 'true')
        permitJoinButton.dispatchEvent(new Event('click'))

    }
  }
}
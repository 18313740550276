import { IconsScenarioModalComponent } from './icons-scenario-modal/icons-scenario-modal.component';
import { ColorhueCanvasComponent } from './colorhue-screen/colorhue-canvas/colorhue-canvas.component';
import { IntensityCanvasComponent } from './colorhue-screen/intensity-canvas/intensity-canvas.component';
import { ColorhueScreenComponent } from './colorhue-screen/colorhue-screen.component';
import { GroupModalComponent } from './group-modal/group-modal.component';
import { RuleCardModalComponent } from './rule-card-modal/rule-card-modal.component';
import { EnvironmentCardModalComponent } from './environment-card-modal/environment-card-modal.component';
import { RouterModule } from '@angular/router';
import { ScenarioCardModalComponent } from './scenario-card-modal/scenario-card-modal.component';
import { DeviceCardModalComponent } from './device-card-modal/device-card-modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertComponent } from './alert.component';
import { CollapseElementDirective } from './collapse-element.directive';
import { LoadingModalComponent } from './loading-modal/loading-modal.component';
import { CameraModalComponent } from './camera-modal/camera-modal.component';
import { DevicesModalComponent } from './devices-modal/devices-modal.component';
import { WebcamModule } from 'ngx-webcam';
import { FieldFeedbackComponent } from './field-feedback/field-feedback.component';
import { PhoneMaskDirective } from './phone-mask.directive';
import { ZipCodeMaskDirective } from './zip-code-mask.directive';
import { PermitJoinButtonDirective } from './permit-join-button.directive';
import { ChangePasswordComponent } from './change-password/change-password.component';

import {ImageCropperModule} from 'ngx-image-cropper'
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
//import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { SmartlockModalComponent } from './smartlock-modal/smartlock-modal.component';
import { CreateGroupAlertComponent } from './create-group-alert/create-group-alert.component';
import { CollapseComponent } from './collapse-component/collapse.component';
import { RuleOpstCardModalComponent } from './ruleopst-card-modal/ruleopst-card-modal.component';

//export function HttpLoaderFactory(http: HttpClient) {
  //return new TranslateHttpLoader(http);
//}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    WebcamModule,
    ReactiveFormsModule,
    RouterModule,ImageCropperModule,
    
    /*TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),*/
  ],
  declarations: [
    AlertComponent,
    CreateGroupAlertComponent,
    CollapseComponent,
    CollapseElementDirective,
    IntensityCanvasComponent,
    ColorhueCanvasComponent,
    FieldFeedbackComponent,
    LoadingModalComponent,
    CameraModalComponent,
    ColorhueScreenComponent,
    DevicesModalComponent,IconsScenarioModalComponent,
    ScenarioCardModalComponent,RuleCardModalComponent,
    RuleOpstCardModalComponent,
    DeviceCardModalComponent,
    EnvironmentCardModalComponent,
    PhoneMaskDirective,
    ZipCodeMaskDirective,
    PermitJoinButtonDirective,
    ChangePasswordComponent,
    SmartlockModalComponent,
   // GroupModalComponent,
  ],
  entryComponents: [
    AlertComponent,
    CreateGroupAlertComponent,
    CollapseComponent,
    DevicesModalComponent,
    DeviceCardModalComponent,
    IconsScenarioModalComponent,
    ColorhueScreenComponent,
    ScenarioCardModalComponent,
    RuleCardModalComponent,
    RuleOpstCardModalComponent,
    EnvironmentCardModalComponent,
    LoadingModalComponent,
    CameraModalComponent,
    ChangePasswordComponent,
    GroupModalComponent,
    SmartlockModalComponent
  ],
  exports : [
    CollapseElementDirective,
    CollapseComponent,
    FieldFeedbackComponent,
    WebcamModule,CreateGroupAlertComponent,
    PhoneMaskDirective,RuleOpstCardModalComponent,
    ZipCodeMaskDirective,
    PermitJoinButtonDirective,
    TranslateModule
  ]
})
export class DirectivesModule { }

import { ClickOutsideModule } from 'ng-click-outside';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GatewayComponent } from './gateway.component';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '../_directives/directives.module';
//import { RouterLink } from '@angular/router';


@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    ReactiveFormsModule,
    RouterModule,ClickOutsideModule,
    FormsModule
  ],
  declarations: [GatewayComponent],
  exports: [GatewayComponent]
})
export class GatewayModule { }

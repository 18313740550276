import { IconsScenarioModalComponent } from './../../_directives/icons-scenario-modal/icons-scenario-modal.component';
import { LoadingModalComponent } from './../../_directives/loading-modal/loading-modal.component';
import { ResponseService } from './../../_services/response.service';
import { GatewayService } from './../../_services/gateway.service';
import { TranslateJsonService } from './../../_services/translate-json.service';
import { AlertComponent } from './../../_directives/alert.component';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap';
import { Card } from './../../models/card.model';

import { DeviceService } from './../../_services/device.service';
import * as config from '../../config';
import { Device } from './../../models/device.model';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { Subscription } from 'rxjs/Subscription';
import { EnvironmentService } from './../../_services/environment.service';
import { OnInit, OnDestroy, ChangeDetectorRef, Component, Input, ViewChild, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';
import { Location } from '@angular/common';
//import { forEach } from '@angular/router/src/utils/collection';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ScenarioService } from '../../_services/scenario.service';
import { FormBuilder } from '@angular/forms';
import { timeoutWith, finalize } from 'rxjs/operators';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { HomeComponent } from '../../home/home.component';
import { RuleService } from 'app/_services/rule.service';

const utf8Decoder = new TextDecoder("utf-8")
const TIMEOUT = 10000

@Component({
	selector: 'vr-scene-edit',
	templateUrl: './scene-edit.component.html',
	styleUrls: ['./scene-edit.component.css'],
	providers: [ScenarioService, DeviceService],
})

export class SceneEditComponent implements OnInit, OnDestroy {
	//devicesService: DevicesService;
	editCheck = [];
	whiteLight = [];
	readOneTimeColor = 0;
	readOneTimeLevel = 0;
	selectedIndex = 0;
	lastIndex = 2;
	bar: boolean = true;
	circle: boolean = false;
	devs = [];
	actions = [];
	devices;
	devChecked;
	checkedDevices;
	devName;
	_id: number;
	gateway;
	dispON;
	devConfigd;
	newScn;
	component = [];
	isActive;
	scenesAll;
	scenesAllSubs;
	sceneActionName = [];
	colorText = [];
	lightLevel = [];
	isAllChecked = false;
	isAllChecked2 = false;
	devsNoScene = ["1", "2"];
	devNameArray = [];
	devsSceneClick: boolean;
	devsAllowedForScene: any;
	dataDevsScn = [];
	devsScnToEdited;
	idDevsScn = [];
	idDevsOffScn = [];
	whiteOrColorful = [];
	sat = [];
	saturacao;
	trueForDevsScene = true;
	colorWasntTouch;
	levelWasntTouch;
	devToClose;
	nome = false;
	devId;
	allZclData = [];
	//@Input() checkMark;
	//@Input() dvNSc;
	//@Input() dev;
	onOff = [];
	output;
	uuid = require('uuid/v4');
	gtw;//= 'csZ1DuG92MTr1MKR'; //this.appService.localStorage()
	white = [];
	colorful = [];
	inputToCheck = [];
	saveClicked = 0;
	//card: Card;
	flipCard = [];
	cardFlipTimer: any;
	vectorTypes = [[], [], [], [], []];
	currentGateway;
	messageEdit;
	page = 1;
	index = ""
	card
	devOffOutput2;
	nameScn;
	inputStart;
	inScn = []
	cardInScn = []
	getOnlyOnOffOfDevs = [];
	holdCheckedRule = []
	cdmsArray = [];
	clustersArray;
	devicesArray = [];
	cmdsArray;
	nameCMD;
	cmdOn;
	cmdOff;
	cmdColor;
	cmdLevel;
	isDevActive = [];
	levelToShow = []
	countFinal = 0;
	public modalRef: BsModalRef;
	public loadingModalRef: BsModalRef;
	language;
	jsonWord;
	loading = false;
	arrowTF = [false, false, false, false, false];
	ruleArray = []
	iconScenario
	rulesToOutput = []
	imgsScenario = []
	allRules;
	countPage1 = 0
	isActiveRule = []
	inputRule = []
	iconScenarioWhite
	/*@HostListener('window:keyup', ['$event'])
	enterKeyUpEvent(e) {
		e.stopPropagation()
		if (e.keyCode == 13 && this.page < 3) {
			this.btnNext()
		}
		if (e.keyCode == 13 && this.page == 3) {
			this.saveScene()
		}
	}*/

	constructor(
		private formBuilder: FormBuilder,
		private location: Location,
		private devicesService: DeviceService,
		public sceneService: ScenarioService,
		private route: ActivatedRoute,
		public element: ElementRef,
		private modalService: BsModalService,
		private translateJson: TranslateJsonService,
		private cd: ChangeDetectorRef,
		private responseService: ResponseService,
		private _mqttService: MqttService,
		private gatewayService: GatewayService,
		private rulesService: RuleService,
		private router: Router,
		private homeComponent: HomeComponent) {
		//this.devices = devicesService.listDevices();
	}

	ngOnInit(): void {
		for (let i = 1; i <= 33; i++) {
			var img = 'icon_' + i + '_white.svg'
			this.imgsScenario.push(img)

		}
		this.iconScenarioWhite = 'icon_1_white.svg'
		this.language = localStorage.getItem('language')
		this.location.replaceState('/')
		this.jsonWord = this.translateJson.changeLanguage(this.language)
		this.devConfigd = null;
		this.currentGateway = (localStorage.getItem('currentGateway'));
		this.gatewayService.gatewayObservable.subscribe(
			gateway => {
				if (gateway) {
					this.currentGateway = gateway.id
					this.loading = true
				}
			}
		)
		this.devices = this.devicesService.listDevices();

		this.devices.concatMap(res => {
			this.getAllDevices(res)
			return this.route.params
		}).concatMap((params) => {
			this._id = params['_id'];
			return this.sceneService.listScenarioById(params._id)
		})
			.concatMap(scene => {
				this.nameScn = scene.name;
				this.inputStart = scene.devices;
				this.iconScenario = scene.icon
				if (scene.icon) {
					if (scene.icon.length > 8) {
						var icon = (scene.icon.length < 11 ? scene.icon.slice(0, 6) : scene.icon.slice(0, 7))
						this.iconScenarioWhite = icon + '_white.svg';
					} else {
						var icon = (scene.icon.length < 6 ? scene.icon.slice(0, 6) : scene.icon.slice(0, 7))
						this.iconScenarioWhite = icon + '_white.svg';
					}
				} else {
					this.iconScenarioWhite = 'icon_1_white.svg'
				}
				this.inputRule = scene.ruleOutputs
				//Compare scenario devices with all databank (validated to scenarios)
				//If there's a device in scenario equals to device from all devices so set array to true, else the array remains itself.
				for (let j = 0; j < this.inputToCheck.length; j++) {
					for (let i = 0; i < this.inputStart.length; i++) {
						if (this.inputToCheck[j].devIeeeAddr === this.inputStart[i]._device) {
							//this.verifyStateStartOutput(i, this.inputStart)
							this.inScn[j] = (true)
						/*	let hue, level
							if(this.inputToCheck[j].devId=='258' || this.inputToCheck[j].devId=='261'){
								if(this.inputStart[i].clusters[0].name === "genOnOff") {
									if (this.inputStart[i].clusters[0].cmds[0].cmdApi === "on") {
										this.isDevActive[j] = true;
										this.onOff[j] = true;
										if (this.inputStart[i].clusters.length > 2) {
											hue =this.inputStart[i].clusters[1].cmds[0].attributes;
											level = this.inputStart[i].clusters[2].cmds[0].attributes;
											this.colorText[j] = hue['hue'];
											this.lightLevel[j] = level['level']
											console.log(hue.saturation)
											if (hue.saturation == '0') {
												this.whiteLight[j] = true;
											} else if (hue.saturation == '254') {
												this.whiteLight[j] = false;
											}
										}
									} else {
										this.isDevActive[j] = false;
										this.onOff[j] = false;
									}
									this.flipCard.push(true)
								}
							}*/

						}
							//else {this.whiteLight[j] = false;}
						this.cardInScn.push(this.inputToCheck[j])
					}
				}
				this.checkDevsType(this.inputToCheck) //check device type and if it's checked in case it's already in scenario

				/*}, err => {
					*/
				return this.rulesService.listRules()
			})
			.pipe(finalize(() => { })).map(res => {
				this.allRules = res;
				for (let i = 0; i < res.length; i++) {
					//this.isActiveRule[i] = res[i].ruleOutputs.isActive

					//for(let j=0;j< this.inputRule.length;j++){
					//this.rulesToOutput[i] = ([res[i], false])
					this.rulesToOutput[i] = this.inputRule.find(inputR => inputR.ruleId == res[i]._id) ? [res[i], true] : [res[i], false]
					//	}

				}
			}, err => {
				if (err.status == 0) {
					this.modalRef = this.modalService.show(AlertComponent);
					this.modalRef.content.type = 'remove'
					this.modalRef.content.title = /*"<i class='fa fa-lg fa-times text-danger'> </i> " +*/ this.jsonWord.error.conectionError
					this.modalRef.content.message = this.jsonWord.error.noConnection
					this.modalRef.content.isDelete = false
					this.modalRef.content.typeB = 'refresh'
					//"Tempo limite de conexão com o servidor atingido. Verifique o status do servidor recarregue a página novamente."
					this.modalRef.content.buttonConfirm = { show: true, text: /*"<i class='fa fa-refresh'></i>" + '  ' + */this.jsonWord.label.reload }
					this.modalRef.content.onClose.subscribe(
						res => {
							if (res)
								window.location.reload()
						}
					)
				}
			}).subscribe(_ => { });

		//catch all scenarios to compare names later (when it's gonna be save)
		this.scenesAll = this.sceneService.listScenarios()
			.pipe(finalize(() => { this.loading = false }))
			.subscribe(res => {
				this.scenesAllSubs = res;
			})
		this.devsSceneClick = false;
		//this.rulesService.listRules().subscribe(
	}
	setRuleOnOff(rule, i) {
		this.isActiveRule[i] = !this.isActiveRule[i]
	}
	getAllDevices(res) {
		this.devName = res//.devices;
		let resp: Device
		let teste = [], a = 0;
		for (resp of res) {
			this.card = new Card(resp);
			if (this.card.devId != '770' && this.card.devId != '12' && this.card.devId != '263' && this.card.devId != '262' &&
				this.card.devId != '10' && this.card.devId != '6') {
				this.card.isActive = (this.card.isActive && !this.card.invertOnOff) || (!this.card.isActive && this.card.invertOnOff)
				this.inputToCheck.push(this.card)
				this.inScn.push(false)
				//this.checkDevsType(this.inputToCheck)
				this.devNameArray.push(this.card.devIeeeAddr);
				teste.push(this.card);
				this.isDevActive[a] = false;
				this.onOff[a] = false;
				//this.whiteOrColorful.push(2);
				this.flipCard.push(true)
				a++;
			}
		}
		this.devsAllowedForScene = teste;
		this.cd.markForCheck()
	}
	//NEED THIS. WORKING
	//Check devices type and set array with device and if it's checked or not, because it's already in scenario
	checkDevsType(devOffOutput2) {
		this.vectorTypes = [[], [], [], [], [], [], [], []]
		let count0 = [], count1 = [], count2 = [], count3 = [], count4 = [], count5 = [], count6 = [], count7 = [];
		for (let i = 0; i < devOffOutput2.length; i++) {
			if (devOffOutput2[i].devId == '81' || devOffOutput2[i].devId === '256') count0.push([devOffOutput2[i], this.inScn[i]]);
			if (devOffOutput2[i].devId == '2') count1.push([devOffOutput2[i], this.inScn[i]]); //2
			if (devOffOutput2[i].devId == '258' || devOffOutput2[i].devId === '528') {
				count2.push([devOffOutput2[i], this.inScn[i]]); this.whiteLight[i] = this.whiteLight[i] ? this.whiteLight[i] : false;
			}
			if (devOffOutput2[i].devId == '771') count3.push([devOffOutput2[i], this.inScn[i]]);
			if (devOffOutput2[i].devId == '261') {
				count4.push([devOffOutput2[i], this.inScn[i]]); this.whiteLight[i] = this.whiteLight[i] ? this.whiteLight[i] : false;
			}//1
			if (this.inputToCheck[i].devId == '772') { count5.push([devOffOutput2[i], this.inScn[i]]); }//1
			if (this.inputToCheck[i].devId == '265') { count6.push([devOffOutput2[i], this.inScn[i]]); }//1
			if (this.inputToCheck[i].devId == '264') { count7.push([devOffOutput2[i], this.inScn[i]]); }//1
			this.flipCard.push(true)
		}
		this.vectorTypes[0] = count0;
		this.vectorTypes[1] = count1;
		this.vectorTypes[2] = count2;
		this.vectorTypes[3] = count3;
		this.vectorTypes[4] = count4;
		this.vectorTypes[5] = count5;
		this.vectorTypes[6] = count6;
		this.vectorTypes[7] = count7;
	}


	//NEED THIS!WORKING
	//Verify input values of scenario devices  
	verifyStateStartOutput(j, inputStart) {
		let hue, level;
		let a = 0;
		let arrayIn = this.inputStart.filter(dev => dev._device == inputStart._id)
		if (arrayIn[0]) {
			if (arrayIn[0].clusters[0].name === "genOnOff") {
				if (arrayIn[0].clusters[0].cmds[0].cmdApi === "on") {
					this.isDevActive[j] = true;
					this.onOff[j] = true;
					if (arrayIn[0].clusters.length > 2) {
						hue = arrayIn[0].clusters[1].cmds[0].attributes;
						level = arrayIn[0].clusters[2].cmds[0].attributes;
						this.colorText[j] = hue['hue'];
						this.lightLevel[j] = level['level']
						if (hue.saturation == '0') {
							this.whiteLight[j] = true;
						} else if (hue.saturation == '254') {
							this.whiteLight[j] = false;
						}
					}
				} else {
					this.isDevActive[j] = false;
					this.onOff[j] = false;
				}
				this.flipCard.push(true)
			}
		}
	}


	setIcon() {
		this.modalRef = this.modalService.show(IconsScenarioModalComponent);
		this.modalRef.content.imgsScenario = this.imgsScenario
		this.modalRef.content.onClose.subscribe(res => {
			this.iconScenario = res
			this.iconScenarioWhite = (res.length < 7 ? res.slice(0, 6) + '_white.svg' : res.slice(0, 7) + '_white.svg')
		})
	}

	ngOnDestroy(): void {
	}

	/**
	 * @author Nathalia
	 * Control  next and previous template page.
	 * @function nextPage() get by class name, the checked elements and show them in next page.
	 */
	previousPage() {
		this.readOneTimeColor = 0;
		this.readOneTimeLevel = 0;
		this.selectedIndex -= 1;
	}

	showSimpleDialog(message) {
		this.modalRef = this.modalService.show(AlertComponent);
		this.modalRef.content.buttonConfirm = { show: false };
		this.modalRef.content.buttonCancel = { show: false, text: this.jsonWord.button.cancel };
		this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
		this.modalRef.content.type = 'warning'
		this.modalRef.content.title = this.jsonWord.alert.attention
		this.modalRef.content.message = message
		this.modalRef.content.onClose.subscribe(result => { })
	}


	//Ok
	getValueFromDevicesToBeEdited() {
		for (let i = 0; i < this.onOff.length; i++) {
			this.onOff[i] = (this.isDevActive[i] ? true : false)
		}
	}
	rotateCard(i) {
		this.flipCard[i] = !this.flipCard[i];
		if (this.flipCard[i]) {
			clearTimeout(this.cardFlipTimer);
		}
		if (!this.flipCard[i]) {
			this.cardFlipTimer = setTimeout(() => {
				this.rotateCard(i);
			}, 60000);
		}
		this.cd.markForCheck();
	}

	//WORKING!NEED THIS!
	//funciona-testara com lampada
	outputConfigEnd(holdOut) {
		this.getValueFromDevicesToBeEdited()
		for (let i = 0; i < this.checkedDevices.length; i++) {//dispositivos output

			this.verifyStateStartOutput(i, this.checkedDevices)
			let hold = [];
			hold[0] = this.checkedDevices[i];
			hold[1] = this.isDevActive[i] ? "Ativa" : "Desativa"
			hold[2] = (!this.lightLevel[i] ? "127" : this.lightLevel[i]);
			hold[4] = (this.whiteLight[i] ? "0" : "254")
			this.white[i] = (this.whiteLight[i] ? "100" : "50")
			//this.levelToShow[i] = (hold[2] / 254) * 100
			//this.levelToShow[i] = parseInt(this.levelToShow[i])
			hold[3] = this.colorText[i]
			this.colorful[i] = (this.colorText[i] / 254) * 360;
			this.levelToShow[i] = (hold[2] / 254) * 100
			this.levelToShow[i] = parseInt(this.levelToShow[i])
			holdOut[i] = (hold);
		}
		this.output = holdOut;
		return this.output;
	}
	changeCheck(device, checkDoor) {
		device[1] = checkDoor.checked
	}

	setArrow(i) {
		this.arrowTF[i] = !this.arrowTF[i]
		switch (i) {
			case (0):
				document.getElementById('arrowSP').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (1):
				document.getElementById('arrowS').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (2):
				document.getElementById('arrowRGB').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (3):
				document.getElementById('arrowSPP').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (4):
				document.getElementById('arrowLed').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (5):
				document.getElementById('arrowTurn').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (6):
				document.getElementById('arrowSPul').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (7):
				document.getElementById('arrowSSw').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
		}
	}

	messageNoDevs() {
		this.modalRef = this.modalService.show(AlertComponent);
		this.modalRef.content.buttonConfirm = { show: false };
		this.modalRef.content.buttonCancel = { show: false, text: this.jsonWord.button.cancel };
		this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
		this.modalRef.content.type = 'warning'
		this.modalRef.content.title = this.jsonWord.alert.attention
		this.modalRef.content.message = this.jsonWord.label.selectDeviceScenario + '!'
		this.modalRef.content.onClose.subscribe(result => {
			if (result) {
				console.log("ok")
			}
		});
		return this.modalRef
	}
	goToScenario() {
		this.router.navigate(['home/scenarios']);
	}
	goToPage(page) {
		switch (page) {
			case 1:
				this.countFinal = 0;
				this.index = "#1a"
				this.page = 1;
				break;
			case 2:
				this.flipCard = []
				let holdElemId = [], holdChecked = [], holdElemOut = [], holdCheckedOut = [];
				if (!this.nameScn) {
					this.showSimpleDialog(this.jsonWord.error.requiredName)
					this.nome = false;
					return;
				} else {
					this.nome = true;
				}
				//Verify if scenario name alredy exists, and an alert will be sent
				if (this.nameScn) {
					for (let item of this.scenesAllSubs) {
						if (item.name === this.nameScn) {
							if (item._id != this._id) {
								this.nome = false;
								this.showSimpleDialog(this.jsonWord.toast.scenarioAlreadyRegistered)
								return;
							} else {
								this.nome = true;
							}
						}
					}
				}

				if (this.inputToCheck) {
					let isInInput, arrayInput = [], arrayIndex = [];
					/*for (let j = 0; j < this.inputStart.length; j++) {
						isInInput = (this.checkedDevices.filter(dev => dev._id == this.inputStart[j]._device))
						//var index = this.checkedDevices.indexOf(arrayInput[j]);
						arrayInput.push(isInInput[0])
						console.log("isInInput", isInInput, arrayInput)
					}
					for (let j = 0; j < this.inputStart.length; j++) {
						var index = (this.checkedDevices.indexOf(arrayInput[j]));
						console.log(index)
	
						arrayIndex.push(index)
					}*/
					for (let i = 0; i < this.inputToCheck.length; i++) {
						this.flipCard[i] = true
						holdElemId.push(<any>document.getElementById(this.inputToCheck[i].devIeeeAddr))
						if (holdElemId[i] && holdElemId[i].checked) {
							holdChecked.push(this.inputToCheck[i])
							this.countFinal = 1
						}
					}
					if (holdChecked) {
						this.onOff = [], this.isDevActive = []
						for (let j = 0; j < this.inputStart.length; j++) {
							isInInput = (holdChecked.filter(dev => dev._id == this.inputStart[j]._device))
							arrayInput.push(isInInput[0])
						}
						for (let j = 0; j < holdChecked.length; j++) {
							var index = (arrayInput.indexOf(holdChecked[j]));
							if (index < 0) {
								this.isDevActive[j] = holdChecked[j].isActive ? true : false
								this.onOff[j] = holdChecked[j].isActive ? true : false
								this.colorText[j] = holdChecked[j].value2;
								this.lightLevel[j] = holdChecked[j].value1;
								//this.whiteLight[j] = holdChecked[j].value3
							} else {
								this.verifyStateStartOutput(j, arrayInput[index])
							}
						}
					}
				}
				this.checkedDevices = holdChecked

				let holdRule = [], holdCheckedRule = [];
				for (let i = 0; i < this.rulesToOutput.length; i++) {
					holdRule.push(<any>document.getElementById(this.rulesToOutput[i][0]._id))
					if (holdRule[i] && holdRule[i].checked) {
						holdCheckedRule.push(this.rulesToOutput[i][0])

						let index = this.inputRule.findIndex(inputR => inputR.ruleId == this.rulesToOutput[i][0]._id)
						if (index >= 0) this.isActiveRule[i] = this.inputRule[index].isActive
						else this.isActiveRule[i] = this.rulesToOutput[i][0].isActive
						this.countPage1 = 1;
					}
				}
				this.holdCheckedRule = holdCheckedRule;
				for (let i = 0; i < this.checkedDevices.length; i++) {
					//this.whiteLight[i] = this.checkedDevices[i].value3
				}
				if (this.checkedDevices.length < 1 && this.holdCheckedRule.length < 1) {
					this.countFinal = 0;
					this.countPage1 = 0;
				}
				if (this.checkedDevices.length < 1 && this.countPage1 == 0) {
					this.countFinal = 0
				}
				if ((this.countFinal == 0 && this.countPage1 == 0) || !this.nome) {
					this.showSimpleDialog(this.jsonWord.text.noScenarioActuatorRegistered)
					this.index = "#1a"
					this.page = 1;
				} else {
					if ((this.checkedDevices.length > 0 && this.holdCheckedRule.length > 0) || (this.checkedDevices.length > 0 && this.holdCheckedRule.length < 1)) { //se tem dispositivo
						this.page = 2;
						this.index = "#2a"
					}
					if (this.checkedDevices.length < 1 && this.holdCheckedRule.length > 0) { //ou tem regra e disp ou so tem regra
						this.page += 1
						this.page = 3;
						this.index = "#3a"
					}
				}
				break;
			case 3:
				this.holdCheckedRule = this.holdCheckedRule
				if (this.holdCheckedRule.length > 0) { //se tem regra
					this.page = 3;
					this.index = "#3a"
					break;
				} else {                   //não tem regra
					this.page = 4;
					this.index = "#4a"
					page += 1
					this.goToPage(page)
					//break;
				}
				break;
			case 4:
				//this.countFinal = 1;
				this.page = 2;
				let holdOut = [];
				this.colorWasntTouch = 0;
				this.levelWasntTouch = 0;
				this.devConfigd = null;
				//Working!! caso seja configuraçao de novo cenario, chama  a funçao
				if (this.countFinal == 1) {
					this.devConfigd = this.outputConfigEnd(holdOut)
				} this.page = 4;
				this.index = "#4a"

				break;
			default:
				break;
		}
	}

	btnNext() {
		//this.page = this.page + 1;
		if (this.page < 4) {
			this.goToPage(this.page + 1)
		}
	}

	btnBefore() {
		if (this.page > 1) {
			/*this.page = this.page - 1;*/
			if (this.page == 4) {
				if (this.holdCheckedRule.length < 1) {
					if (this.checkedDevices.length < 1) //se não tem disp, nem regra, ou seja, apenas cenário
						this.page = this.page - 3;
					else//se não tem regra, mas tem disp
						this.page = this.page - 2;
				} else {
					this.page = this.page - 1;//se tem regra
				}
			} else if (this.page == 3) {
				if (this.checkedDevices.length > 0) { //se tem disp
					this.page = this.page - 1;
				}
				else {//se não tem disp
					this.page = this.page - 2;
				}
			} else {
				this.page = this.page - 1
			}
		}
	}

	onSmartlightLevelChange(levelV, i) {
		var level;
		this.lightLevel[i] = levelV;
		return this.lightLevel;
	}
	onSmartlightColorChange(colorV, i) {
		this.colorText[i] = colorV;
		return this.colorText;
	}
	sendOnOff(card, i) {
		//card.isActive = !card.isActive;
		this.isDevActive[i] = !this.isDevActive[i];
	}

	getColor(i, value2) {
		this.colorText[i] = this.colorText[i] ? this.colorText[i] : value2;
		return this.colorText[i]
	}
	getLevel(i, value1) {
		//this.lightLevel[i] = (this.lightLevel[i]/254) * 100;
		this.lightLevel[i] = this.lightLevel[i] ? this.lightLevel[i] : value1;
		return this.lightLevel[i];
	}

	whiteClicked(i) {
		//this.whiteClicked[i]=false
		this.whiteLight[i] = !this.whiteLight[i];
		//this.whiteOrColorful[i] = (this.whiteLight[i] ? 0 : 1)
	}

	/**
	 * WORKING!NEED THIS
	 * Pack to JSON, functions to devices
	 */
	actionsCreateJSON(dispositivo, i) {
		if (this.output[i][1] === "Desativa") { //If it's to turn any devices off
			this.clustersArray = [{
				name: "genOnOff",
				cmds: [{
					cmdApi: !dispositivo.invertOnOff ? "off" : "on",
					cmdId: dispositivo.devEpList[0],
					attributes: {}
				}]
			}]
		} else { //If it's to turn any devices on
			if (dispositivo.devId === "258" || dispositivo.devId === "528" || dispositivo.devId === "261") {//If colorful lamps and if it's turn them on
				this.clustersArray = [{
					name: "genOnOff",
					cmds: [{
						cmdApi: "on",
						cmdId: dispositivo.devEpList[0],
						attributes: {}
					}]
				}, {
					name: "lightingColorCtrl",
					cmds: [{
						cmdApi: "moveToHueAndSaturation",
						cmdId: "6",//dispositivo.devEpList[0],
						attributes: { hue: parseInt(this.output[i][3]), saturation: this.output[i][4], transtime: 0 }
					}]
				}, {
					name: "genLevelCtrl",
					cmds: [{
						cmdApi: "moveToLevel",
						cmdId: "0",//dispositivo.devEpList[0],
						attributes: { level: this.output[i][2], transtime: 0 }
					}]
				}]
			} else {
				this.clustersArray = [{
					name: "genOnOff",
					cmds: [{
						cmdApi: !dispositivo.invertOnOff ? "on" : "off",
						cmdId: dispositivo.devEpList[0],
						attributes: {}
					}]
				}]
			}
		}
		this.devicesArray[i] = {
			_device: dispositivo._id,
			clusters: this.clustersArray
		}
	}

	/**
	 * @author Nathalia
	 * @function saveScene() 
	 */

	onEnter($event) {//Press enter and save...but not working
		$event.preventDefault();
		this.saveScene();
	}

	formatRules() {
		for (let i = 0; i < this.holdCheckedRule.length; i++) {
			this.ruleArray.push({
				"ruleId": this.holdCheckedRule[i]._id,
				"isActive": this.isActiveRule[i]
			})
		}
	}
	saveScene() {
		//	const topic = config.gtwTopic + this.currentGateway + "/scenario/" + this._id + "/edit";
		//	let nameScn = <any>document.getElementsByClassName("newScn");
		// If name exists, an alert will be sent and the variable name is set to false.
		if (this.output && this.output.length) {
			for (let i = 0; i < this.output.length; i++) {
				this.actionsCreateJSON(this.output[i][0], i)
			}

		}
		if (this.nome) {
			//	let scenarioResponse
			this.saveClicked = 1;
			this.formatRules()
			const topic2 = config.gtwTopic + this.currentGateway + '/scenario/#';
			let message = {
				name: this.nameScn,
				icon: this.iconScenario,
				devices: this.devicesArray,
				ruleOutputs: this.ruleArray
			}
			//const topic2 = config.gtwTopic + this.currentGateway + '/scenario/#';
			/*let message = {
				name: this.nameScn,
				icon:"",
				devices: this.devicesArray,
				ruleOutputs: []
			}*/
			this.openLoadingModal(this.jsonWord.loading.message)

			this.sceneService.updateScenario(message, this._id).subscribe(res => {
				//	scenarioResponse = res.data
				if (res) {
					this.loadingModalRef.content.success = true
					this.loadingModalRef.content.waiting = false
					this.loadingModalRef.content.message = this.jsonWord.toast.successEditScenario
					setTimeout(() => {
						this.loadingModalRef.hide()
						this.router.navigate(['home/scenarios']);
					}, 2000);
				}
			}, err => {
				let message = ''
				switch (err.status) {
					case 0:
						message = this.jsonWord.error.noConnection//'Formulário de convidar usuário inválido.'
						//this.noInternetAlert()
						break
					case 400:
						message = this.jsonWord.error.invalidFormat//'Formulário de convidar usuário inválido.'
						break
					case 401:
						message = this.jsonWord.error.notAuthorized//'Você não tem permissão para convidar usuários.'
						break
					/*	case 403:
							message = this.jsonWord.error.userDisabled//'Usuário está desativado.'
							break*/
					case 404:
						message = this.jsonWord.error.scenarioNotFound//'Usuário não encontrado ou número serial de gateway não registrado.'
						break
					case 408:
						message = this.jsonWord.error.timeoutServer;//'Tempo limite de conexão atingido.'
						break
					/*	case 409:
							message = this.jsonWord.error.userAlreadyInGtw//'Usuário já associado ao gateway.'
							break
						case 412:
							message = this.jsonWord.error.limitUserExceeded//'Usuário já associado ao gateway.'
							break*/
					case 500:
						message = this.jsonWord.toast.gatewayCommunicationError//'Não foi possível enviar convite.'
						break
					default:
						message = this.jsonWord.toast.errorEditScenario

				}
				this.loadingModalRef.content.success = false
				this.loadingModalRef.content.waiting = false
				this.loadingModalRef.content.message = message
				setTimeout(() => {
					this.loadingModalRef.hide()
					this.router.navigate(['home/scenarios']);
				}, 2000)
			})

		}
	}

	changeCheckRule(rule, checkRule) {
		rule[1] = checkRule.checked
	}

	openLoadingModal(message) {
		this.loadingModalRef = this.modalService.show(
			LoadingModalComponent,
			{
				keyboard: false,
				backdrop: 'static',
				class: 'waiting-modal modal-sm'
			})
		this.loadingModalRef.content.message = message;
	}
	goBack(): void {
		this.location.back();
	}
}
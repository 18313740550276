import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import { Headers, RequestOptions } from '@angular/http';
//import { LoginService } from '../login/login.service';
import { User } from '../models/user.model';
import * as config from '../config';
import { stringify } from 'querystring';
import { Gateway } from '../models/gateway.model';
import { GatewayService } from './gateway.service';
import { timeout, timeoutWith } from 'rxjs/operators';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { of } from 'rxjs';

const url = config.serverURL;//+ 'ecomfort/v1';
const urlConfig = config.serverURL + '/gateway/'
const TIMEOUT: number = 10000 // 10 seconds

@Injectable()
export class UserService {
    constructor(
        private http: HttpClient,
        private gatewayService: GatewayService) {
    }

    /*  getUserByID(id) {
          return this.http.get(url + '/users/' + id);
      }
  
      listUsers(): Observable<any> {
          return this.http.get(url + '/users');
      }*/

    //API Inatel
    getUserByID(id) {
        return this.http.get(url + '/user/' + id).map(res => res['data'])
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
    }

    listUsers(): Observable<any> {
        if (!this.gatewayService.currentGateway.id) return of()
        return this.http.get(urlConfig + this.gatewayService.currentGateway.id)
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
            .map(res =>
                res['data'].registeredUsers
            );
    }

    listUserById(userId): Observable<any> {
        return this.http.get(url + '/user/' + userId)
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
            .map(res =>
                res['data']
            );
    }

    listUserInGatewayById(userId): Observable<Gateway[]> {
        return this.http.get(url + '/user/' + userId + '/gateway')
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
            .map((res: any) =>
                res['data'].map(gtw => new Gateway(gtw))
            );
    }

    invite(body, email) {
        let gateway = localStorage.getItem("currentGateway")
        return this.http.patch(urlConfig + gateway, body, { params: { email } })
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
    }

    createUser(user: User): Observable<any> {
        return this.http.post(url + '/user', user)
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
    }

    updateUser(userId, user: any, invite?: boolean): Observable<any> {
        return this.http.put(url + '/user/' + userId, user, { params: { invite: invite ? `${invite}` : "false" } })
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
    }

    updateSessionUser(id,body){
        return this.http.patch(url + '/user/' + id, body) 
        .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
            new Response(null, {
                status: 408, statusText: 'Tempo limite de resposta atingido'
            }))))
        }

    deleteUser(userId) {
        return this.http.delete(urlConfig + this.gatewayService.currentGateway.id + '/user/' + userId)
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
    }

    deactivateUser() {
        return this.http.post(url + '/user/deactivate', null)
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
    }
    refreshToken(body){
        return this.http.post(url + '/user/refresh-token', body) 
        .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
            new Response(null, {
                status: 408, statusText: 'Tempo limite de resposta atingido'
            }))))
        }
    
  /*  deactivateUserById(userId) {
        return this.http.post(url + '/user/deactivate', null)
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
    }*/
}

import { ResponseService } from "./../../_services/response.service";
import { SmartlockModalComponent } from "./../smartlock-modal/smartlock-modal.component";
import { Subscription } from "rxjs/Subscription";
import { DeviceService } from "./../../_services/device.service";
import { LoadingModalComponent } from "./../loading-modal/loading-modal.component";
import { AlertComponent } from "./../../_directives/alert.component";
import { DevicesModalComponent } from "./../../_directives/devices-modal/devices-modal.component";
import { ErrorObservable } from "rxjs/observable/ErrorObservable";
import {
  timeout,
  catchError,
  timeoutWith,
  finalize,
  concatMap,
  takeUntil
} from "rxjs/operators";
import { IMqttMessage, MqttService } from "ngx-mqtt";
import { EnvironmentService } from "./../../_services/environment.service";
import { TranslateJsonService } from "./../../_services/translate-json.service";
import {
  Component,
  OnInit,
  HostListener,
  Input,
  EventEmitter,
  Output
} from "@angular/core";
import { Subject } from "rxjs/Subject";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { of, Observable } from "rxjs";
import * as config from "../../config";
import { v4 as uuid } from "uuid";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { GroupService } from "app/_services/group.service";
import { GatewayService } from "app/_services/gateway.service";
import { Gateway } from "app/models/gateway.model";
import { ColorhueScreenComponent } from "../colorhue-screen/colorhue-screen.component";

const SMARTLOCK_TIMEOUT = 25000;
const COMMAND_TIMEOUT = 5000;
const BIND_TIMEOUT = 10000;
const CONFIG_TIMEOUT = 10000;
const DELETE_TIMEOUT = 12000;
const utf8Decoder = new TextDecoder("utf-8");

@Component({
  moduleId: module.id,
  selector: "app-group-modal",
  templateUrl: "group-modal.component.html",
  styleUrls: ["./group-modal.component.css"]
})
export class GroupModalComponent implements OnInit {
  private ngUnsubscribe = new Subject();
  public modalRef: BsModalRef;

  public title: any;
  public message: any;
  public devName: any;
  public devSerial: any;
  public language;
  public jsonWord;
  public envList = [];
  public globalClass: string;
  public envListId = [];
  public card: any;
  public whiteLight;
  public correlId;
  public devicesList: any;
  public filteredDevicesList: any;
  private selectedDevices: Array<any> = [];

  public devicesToEdit;
  public buttonCancel: any = {
    show: false,
    text: "CANCELAR"
  };
  public buttonUnblock: any = {
    show: false,
    text: "DESBLOQUEAR DISPOSITIVO"
  };
  public buttonBlock: any = {
    show: false,
    text: "BLOQUEAR DISPOSITIVO"
  };
  public buttonBind: any = {
    show: false,
    value: "BIND"
  };
  public buttonCalibrate: any = {
    show: false,
    value: "CALIBRAR"
  };
  public buttonConfirm: any = {
    show: false,
    text: "SALVAR"
  };
  public buttonRemove: any = {
    show: false,
    text: "REMOVER GRUPO"
  };

  public inputText1: any = {
    show: false,
    value: null,
    type: "text",
    placeholder: "Digite o valor"
  };
  public inputText2: any = {
    show: false,
    value: null,
    type: "text",
    placeholder: "Digite o valor"
  };
  public inputLevelLum: any = {
    show: false,
    value: null,
    type: "text"
  };

  public inputLevel: any = {
    show: false,
    value: null,
    type: "text"
  };
  public inputHue: any = {
    show: false,
    value: null,
    type: "text"
  };
  public groups: any = {
    show: false,
    value: null,
    type: "text"
  };
  groupId;
  calButtonOk = false;

  public deviceTopicSync;
  public devsSyncObserver;
  public devicesFound;
  private deviceStatusObserver;
  private deviceTopic;
  private deviceObserver;
  public currentUserType;
  public currentUserId;
  public currentUser;
  public currentGateway: any;
  public isAtBottom: boolean = false;
  public acceptTerm: boolean = false;
  public showCloseButton: boolean = true;
  public onClose: Subject<Boolean>;
  public nenhum = "Nenhum";
  public fusoChosen; // = "Escolha um fuso horário";
  uuid = require("uuid/v4");
  public mqttSuccess: Subscription;
  public mqttError: Subscription;
  public deletingDevice;
  public gatewaySuccessObserver;
  public gatewayErrorObserver;
  public waiting: Boolean = true;
  public success: Boolean; //= true;
  public buttomBlocked: Boolean = true;
  private subscription: Subscription;
  public devicesObserver = new Observable<any>();
  public devListLamp;
  public devListLed;
  deviceToDelete;
  pack;
  @Input() sendValue;
  @Output() feedBackCards = new EventEmitter();

  @HostListener("window:keyup", ["$event"])
  enterKeyUpEvent(e) {
    e.stopPropagation();
    if (e.keyCode == 13 && this.buttonConfirm.show) {
      if (!this.inputText2.value) alert("Sem nome");
      else this.onConfirm();
    }
  }

  constructor(
    private _bsModalRef: BsModalRef,
    public translateJson: TranslateJsonService,
    private _environmentService: EnvironmentService,
    private modalService: BsModalService,
    public loadingModalRef: BsModalRef,
    private _deviceService: DeviceService,
    private _mqttService: MqttService,
    private toastr: ToastrService,
    private responseService: ResponseService,
    private groupService: GroupService,
    private gatewayService: GatewayService
  ) {
    // this.fusoChosen = this.jsonWord.label.choseTimezone
  }

  public ngOnInit(): void {
    this.language = localStorage.getItem("language");
    this.jsonWord = this.translateJson.changeLanguage(this.language);
    this.currentGateway = localStorage.getItem("currentGateway");
    //this.deviceTopic = `${config.gtwTopic}${this.currentGateway}/device/${this.card.devIeeeAddr}/`
    this.listDevices(this.currentGateway);
    this.groupId = this.card;
    this.currentUserType = JSON.parse(localStorage.getItem("currentRole"));
    // this.currentGateway = this.deviceNewComponent.currentGateway
    this.currentUser = JSON.parse(localStorage.getItem("currentUser")).email;
    this.currentUserId = localStorage.getItem("currentUserId");
    this.deviceTopicSync = `ecomfort/iot/v1/sync/gateway/${this.currentGateway}/user/#`;
    this.devsSyncObserver = this._mqttService
      .observe(this.deviceTopicSync)
      .map((IMQTTMessage: IMqttMessage) =>
        JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload))
      );

    this.gatewaySuccessObserver = this._mqttService
      .observe(config.gtwTopic + this.currentGateway + "/success")
      .map((IMQTTMessage: IMqttMessage) => {
        let plainText = utf8Decoder.decode(IMQTTMessage.payload);
        return plainText ? JSON.parse(plainText) : null;
      });
    this.waiting = false;

    this.gatewayErrorObserver = this._mqttService
      .observe(config.gtwTopic + this.currentGateway + "/error")
      .map((IMQTTMessage: IMqttMessage) => {
        let plainText = utf8Decoder.decode(IMQTTMessage.payload);
        return plainText ? JSON.parse(plainText) : null;
      });

    this._environmentService
      .listEnvironments()
      .takeUntil(this.ngUnsubscribe)
      .subscribe(environments => {
        this.envList = environments;
      });
    this.onClose = new Subject();
  }
  public onDeleteDevice(card): void {}
  public onConfirm(): void {
    if (!this.inputText2.value) alert("nome!");
    let checked = [],
      notChecked = [],
      allDev = [];

    if (!this.inputText2.value) alert("nome!");
    this.onClose.next(this.card);
    this._bsModalRef.hide();
  }
  public onCancel(): void {
    //  this.onClose.next(false);
    this._bsModalRef.hide();
  }

  listDevices(gateway) {
    if (!this.currentGateway) {
      return;
    }
    if (!this.devicesFound) {
      let hold = [],
        devSort = [];
      this._deviceService
        .listDevices(gateway)
        .pipe(finalize(() => {}))
        .takeUntil(this.ngUnsubscribe)
        .subscribe(
          deviceList => {
            this.devicesFound = deviceList;
            this.selectedDevices =
              this.devicesFound.associatedDevices &&
              this.devicesFound.associatedDevices.length > 0
                ? this.devicesFound.associatedDevices.map(d => d.ieeeAddr)
                : [];
            this.devicesList = this.devicesFound.filter(
              device => device.devId == "258" || device.devId == "261"
            );
            this.devListLamp = this.devicesList.filter(
              device => device.devId == "258"
            );
            this.devListLed = this.devicesList.filter(
              device => device.devId == "261"
            );
          },
          err => console.log(this.jsonWord.error.error + ": ", err)
        );
    }
  }

  /**
   * Send command MQTT
   *
   */
  sendMQTTOnOffCommand(card) {
    if (!card.blocked) {
      this.waiting = true;
      if (card.devType === "Router" || card.devId === "10") {
        let message;
        if (
          card.devId === "81" ||
          card.devId === "258" ||
          card.devId === "771"
        ) {
          message = {
            messageId: uuid(),
            user: this.currentUser,
            timestamp: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            epId: card.devEpList[0],
            //userId:'teste',//JSON.parse(localStorage.getItem('currentUserId'))._id,
            cId: "genOnOff",
            cmd: "toggle",
            zclData: {}
          };
        }
        if (card.isActive) {
          message = {
            messageId: uuid(),
            timestamp: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            epId: card.devEpList[0],
            user: this.currentUser,
            cId: "genOnOff",
            cmd: "off",
            zclData: {}
          };
        } else if (!card.isActive) {
          message = {
            messageId: uuid(),
            timestamp: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            epId: card.devEpList[0],
            user: this.currentUser,
            cId: "genOnOff",
            cmd: "on",
            zclData: {}
          };
        }
        const topic = `${config.gtwTopic}${this.currentGateway}/device/${card.devIeeeAddr}/command`;
        this.unsafePublish(topic, JSON.stringify(message));

        //caso haja erro
        this.subscription = this.gatewayErrorObserver
          .filter(msg => this.correlId == msg["correlId"])
          .map(message => {
            this.success = false;
            return message;
          })
          .merge(
            this.gatewaySuccessObserver
              .filter(msg => this.correlId == msg["correlId"])
              .map(message => {
                this.success = true;
                this.buttomBlocked = true;
                return message;
              })
          )
          .take(1)
          .pipe(
            finalize(() => {
              this.waiting = false;
            }),
            timeoutWith(
              card.devId == "10" ? SMARTLOCK_TIMEOUT : COMMAND_TIMEOUT,
              ErrorObservable.throw(
                new Response(null, {
                  status: 408,
                  statusText: "Tempo limite de resposta atingido"
                })
              )
            )
          )
          .subscribe(
            _ => {
              this.correlId = null;
            },
            err => {
              this.gatewayErrorToast(err);
            }
          );
      }
    }
  }

  gatewayErrorToast(err) {
    if (err.status == 408) {
      this.toastr.error(
        "O gateway demorou muito pra responder. Verifique o status do gateway e tente novamente mais tarde."
      );
    }
  }

  /**
   * Cards fita de led e lampada wrgb
   */

  onSmartlightWhiteColorChecked(card) {
    this.whiteLight = !this.whiteLight;
    for (let i = 0; i < card.devices.length; i++) {
      const topic = `${config.gtwTopic}${this.currentGateway}/device/${card.devices[i]}/command`; //`${this.deviceTopic}command`;
      let command: any;
      if (this.whiteLight) {
        command = {
          messageId: uuid(),
          timestamp: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          ieeeAddr: card.devices[i],
          epId: [1], //verificar o eplist ao enviar o comando mqtt
          user: this.currentUser,
          cId: "lightingColorCtrl",
          cmd: "moveToSaturation",
          zclData: { saturation: 0, transtime: 0 }
        };
      } else if (!this.whiteLight) {
        command = {
          messageId: uuid(),
          timestamp: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          user: this.currentUser,
          ieeeAddr: card.devices[i],
          epId: [1], //verificar o eplist ao enviar o comando mqtt
          cId: "lightingColorCtrl",
          cmd: "moveToSaturation",
          zclData: { saturation: 254, transtime: 0 }
        };
      }
      this.unsafePublish(topic, JSON.stringify(command));
    }
  }

  onSmartlightColorChange(card, value) {
    this.whiteLight = false;
    for (let i = 0; i < card.devices.length; i++) {
      const command = {
        messageId: uuid(),
        timestamp: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        ieeeAddr: card.devices[i],
        epId: [1], //verificar o eplist ao enviar o comando mqtt
        user: this.currentUser,
        cId: "lightingColorCtrl",
        cmd: "moveToHueAndSaturation",
        zclData: { hue: value, saturation: 254, transtime: 0 }
      };
      const topic = `${config.gtwTopic}${this.currentGateway}/device/${card.devices[i]}/command`;
      this.unsafePublish(topic, JSON.stringify(command));
    }
  }

  onSmartlightLevelChange(card, value) {
    for (let i = 0; i < card.devices.length; i++) {
      const command = {
        messageId: uuid(),
        timestamp: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        ieeeAddr: card.devices[i],
        epId: [1], //verificar o eplist ao enviar o comando mqtt
        user: this.currentUser,
        cId: "genLevelCtrl",
        cmd: "moveToLevel",
        zclData: { level: value, transtime: 0 }
      };
      const topic = `${config.gtwTopic}${this.currentGateway}/device/${card.devices[i]}/command`;
      this.unsafePublish(topic, JSON.stringify(command));
    }
  }

  openLoadingModal(message: String) {
    this.loadingModalRef = this.modalService.show(LoadingModalComponent, {
      class: "waiting-modal modal-sm",
      keyboard: false,
      backdrop: "static"
    });
    this.loadingModalRef.content.message = message;
  }

  messageDispatcher(message) {
    switch (message.cId) {
      case "genLevelCtrl":
        if (message.type === "currentLevel") {
          let value;
          value = message.value;
          this.card.value1 = value.toFixed(1);
        }
        break;
      case "lightingColorCtrl":
        if (message.type === "currentHue") {
          let value;
          value = message.value;
          this.card.value2 = value.toFixed(1);
        } else if (message.type === "currentSaturation") {
          let value;
          value = message.value;
          if (value > 1) {
            this.whiteLight = false;
          } else if (value <= 1) {
            this.whiteLight = true;
          }
        }
        break;
      case "msOccupancySensing":
        if (message.type === "occupancy") {
          let status: boolean;
          status = message.value ? true : false;
          this.card.isActive = status;
        }
        break;
    }
  }

  toggleSelect(device) {
    let selectDevice = this.selectedDevices.find(d => d == device.ieeeAddr);
    if (selectDevice) {
      this.selectedDevices = this.selectedDevices.filter(
        d => d != selectDevice
      );
    } else {
      this.selectedDevices.push(device.ieeeAddr);
    }
  }

  /*isSelected(device) {
    console.log("teste isSelected",device)
    return this.selectedDevices.includes(device.ieeeAddr)
  }*/

  deleteDevice(device) {
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonCancel = {
      show: true,
      text: this.jsonWord.button.cancel
    };
    this.modalRef.content.buttonOk = {
      show: true,
      text: this.jsonWord.button.ok
    };
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.imagem = {
      show: true,
      text: "../assets/img/dashboard/card-content/" + device.devId + "_alt.svg"
    };
    this.modalRef.content.isDelete = true;
    this.modalRef.content.title = device.name;
    this.modalRef.content.message =
      this.jsonWord.alert.messageRemoveDevice + " " + device.name + "?";
    this.modalRef.content.onClose.subscribe(result => {
      if (result) {
        this.openLoadingModal(this.jsonWord.loading.message);
        //this.deletingDevice = device
        //Deleta dispositivo offline de gateway que funciona e nao deleta de gateway que nao funciona, ou seja, funciona! 28/05/19-Nath
        this.groupService.removeGroup(device._id).subscribe(res => {
          if (res) {
            this.gatewayService.getGatewayById(this.currentGateway).subscribe(
              res => {
                let result = new Gateway(res["data"]);
                this.gatewayService.setGateway(result);
                this.loadingModalRef.content.success = true;
                this.loadingModalRef.content.waiting = false;
                this.loadingModalRef.content.message = this.jsonWord.toast.editDeviceSucess;
                setTimeout(() => {
                  this.closeAllModals();
                }, 2000);
              },
              err => {
                this.loadingModalRef.content.success = false;
                this.loadingModalRef.content.waiting = false;
                this.loadingModalRef.content.message = this.jsonWord.toast.errorEditDevice;
                setTimeout(() => {
                  this.closeAllModals();
                }, 2000);
              }
            );
          }
        });
      }
    });
  }

  getDimmerSliderValue() {
    return this.card.value1;
  }

  getColorSliderValue() {
    return this.card.value2;
  }
  cardUpdater(event) {
    if (event.op == "attReport") {
      let devs = [];
      /*devs = this.devicesToEdit.find(dev=>dev._id != event.device._id)
      let isOff = []
      isOff = devs.length>1 ? devs.find(dev=>dev.attributes['genOnOff#onOff'] == 0 ) : []
      console.log(isOff, event.message.data.value)
      if(isOff.length <1 && event.message.data.value == 0){
        console.log("td off")
        this.feedBackCards.emit({status:false})

      }else{
        console.log("tem on")
        this.feedBackCards.emit({status:true})
        console.log(isOff, event.message.data.value)
      }*/
      //for(devicesToEdit)
    }
    var index = this.devicesToEdit.find(dev => dev._id == event.id);
    if (index) {
      this.devicesToEdit.splice(index, 1);
    }
  }

  colorScreenModal() {
    //this.advancedColor = !this.advancedColor
    this.closeAllModals();
    setTimeout(() => {
      this.modalRef = this.modalService.show(ColorhueScreenComponent);
      this.modalRef.content.card = this.card;
      this.modalRef.content.pixelData = this.hslToRgb(
        this.card.value1 / 255,
        this.card.value2 / 255,
        1
      );
      this.modalRef.content.value1 = this.card.value1;
      this.modalRef.content.value2 = this.card.value2;
      this.modalRef.content.isEdit = true;
    }, 1000);
    //  this.modalRef.content.wheel = this.showWheel()
  }
  hslToRgb(h, s, l) {
    var r, g, b;

    if (s == 0) {
      r = g = b = l; // achromatic
    } else {
      var hue2rgb = function hue2rgb(p, q, t) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      var p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }
    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  }

  private closeAllModals() {
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    }
  }
  public unsafePublish(topic: string, message: string) {
    this.correlId = JSON.parse(message).messageId;
    this._mqttService.unsafePublish(topic, message, { qos: 1, retain: false });
  }
}

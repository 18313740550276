import { Scenario } from './../models/scenario.model';
import { Injectable } from '@angular/core';
//import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import * as config from '../config';
import { GatewayService } from './gateway.service';
import { timeout, timeoutWith } from 'rxjs/operators';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';

const url = config.serverURL;//+ 'ecomfort/v1';
const urlConfig = config.serverURL //+ '/gateway/' + (this.gatewayService.currentGateway.id)
const TIMEOUT: number = 15000 // 10 seconds

@Injectable()
export class ScenarioService {

    constructor(private http: HttpClient,
        private gatewayService: GatewayService
    ) { }

    listScenarios(): Observable<any> {
        return this.http.get(urlConfig + '/gateway/' + (this.gatewayService.currentGateway.id) + '/scenario')
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
            .map(res => res['data']);
    }

    listScenarioById(scenarioId): Observable<any> {
        return this.http.get(urlConfig + '/gateway/' + (this.gatewayService.currentGateway.id) + '/scenario/' + scenarioId)
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            ))).map(res => res['data']);
    }

    createScenario(scenario): Observable<any> {
        return this.http.post((urlConfig + '/gateway/' + (this.gatewayService.currentGateway.id) + '/scenario/'), scenario)
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
    }

    updateScenario(scenario, scenarioId): Observable<any> {
        return this.http.put(urlConfig + '/gateway/' + (this.gatewayService.currentGateway.id) + '/scenario/' + scenarioId, scenario)
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
    }

    deleteScenarioById(scenarioId) {
        return this.http.delete(urlConfig + '/gateway/' + (this.gatewayService.currentGateway.id) + '/scenario/' + scenarioId)
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
    }
}

import { RuleService } from './../../_services/rule.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs/Subscription';
import { DeviceService } from './../../_services/device.service';
import { LoadingModalComponent } from './../loading-modal/loading-modal.component';
import { AlertComponent } from './../../_directives/alert.component';
import { DevicesModalComponent } from './../../_directives/devices-modal/devices-modal.component';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { timeout, catchError, timeoutWith, finalize } from 'rxjs/operators';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { EnvironmentService } from './../../_services/environment.service';
import { TranslateJsonService } from './../../_services/translate-json.service';
import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import * as config from '../../config';
import { v4 as uuid } from 'uuid';
import * as moment from 'moment';
import { ResponseService } from '../../_services/response.service';
import { runInThisContext } from 'vm';

const SMARTLOCK_TIMEOUT = 16000
const COMMAND_TIMEOUT = 5000
const BIND_TIMEOUT = 10000
const CONFIG_TIMEOUT = 10000
const DELETE_TIMEOUT = 15000
const TIMEOUT = 10000
const utf8Decoder = new TextDecoder("utf-8")


@Component({
  moduleId: module.id,
  selector: 'app-ruleopst-card-modal',
  templateUrl: 'ruleopst-card-modal.component.html',
  styleUrls: ['./ruleopst-card-modal.component.css'],

})

export class RuleOpstCardModalComponent implements OnInit {
  private ngUnsubscribe = new Subject();
  public modalRef: BsModalRef;

  public language;
  public jsonWord;
  public correlId

  public buttonOk: any = {
    show: false,
    text: 'OK'
  };
  public buttonCancel: any = {
    show: false,
    text: 'CANCELAR'
  };
  public name
  public buttonEdit: any = {
    show: false,
    text: 'EDITAR'
  };
  public buttonRemove: any = {
    show: false,
    value: null,
    text: 'REMOVER DISPOSITIVO'
  };
  public ruleConfig
  public inputs: any
  public outputs
  public configs: any
  public card: any = {
    show: false,
    value: null,
  };
  public nameRule
  public ruleOutputs: any

  public startDay: any
  public startHour: any
  public endHour: any
  public weekdays: any;
  public ruleJson: any
  public white: any = {
    show: false,
    value: null
  }
  nameOriginal
  contenteditable: Boolean = false;
  showName = true;
  focusedIndex;
  editedIndex;
  public allRules
  public deviceTopicSync;
  public devsSyncObserver;
  public devicesFound;
  public currentUserType;
  public currentUserId;
  public currentUser;
  public currentGateway: any;
  public isAtBottom: boolean = false
  public acceptTerm: boolean = false
  public showCloseButton: boolean = true
  public onClose: Subject<any>;
  uuid = require('uuid/v4');
  public mqttSuccess: Subscription;
  public mqttError: Subscription;
  public deletingDevice;
  public gatewaySuccessObserver
  public gatewayErrorObserver
  toFixedNumber = Math.round
  daysTrueFalse
  constructor(
    private _bsModalRef: BsModalRef,
    public translateJson: TranslateJsonService,
    private _environmentService: EnvironmentService,
    private modalService: BsModalService,
    public loadingModalRef: BsModalRef,
    private _deviceService: DeviceService,
    private _ruleService: RuleService,
    private router: Router,
    private responseService: ResponseService,
    private toastr: ToastrService,
    private _mqttService: MqttService, ) {
    // this.fusoChosen = this.jsonWord.label.choseTimezone
  }

  public ngOnInit(): void {
    this.daysTrueFalse = []
    this.language = localStorage.getItem('language')
    this.jsonWord = this.translateJson.changeLanguage(this.language)
    this.currentGateway = localStorage.getItem('currentGateway')
    // this.deviceTopic = `${config.gtwTopic}${this.currentGateway}/device/${this.card.devIeeeAddr}/`
    this.weekdays = [this.jsonWord.label.sundayInitial, this.jsonWord.label.mondayInitial, this.jsonWord.label.tuesdayInitial, this.jsonWord.label.wednesdayInitial,
    this.jsonWord.label.thursdayInitial, this.jsonWord.label.fridayInitial, this.jsonWord.label.saturdayInitial];

    this.daysTrueFalse = [false, false, false, false, false, false, false];
    this.currentUserType = JSON.parse(localStorage.getItem('currentRole'));
    // this.currentGateway = this.deviceNewComponent.currentGateway
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')).email
    this.currentUserId = (localStorage.getItem('currentUserId'))
    this.deviceTopicSync = `ecomfort/iot/v1/sync/gateway/${this.currentGateway}/user/#`

    this.devsSyncObserver = this._mqttService.observe(this.deviceTopicSync)
      .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))

    this.gatewaySuccessObserver = this._mqttService.observe(config.gtwTopic + this.currentGateway + '/success')
      .map((IMQTTMessage: IMqttMessage) => {
        let plainText = utf8Decoder.decode(IMQTTMessage.payload)
        return plainText ? JSON.parse(plainText) : null
      })

    this.gatewayErrorObserver = this._mqttService.observe(config.gtwTopic + this.currentGateway + '/error')
      .map((IMQTTMessage: IMqttMessage) => {
        let plainText = utf8Decoder.decode(IMQTTMessage.payload)
        return plainText ? JSON.parse(plainText) : null
      })

    this.onClose = new Subject();


  }

  public onDeleteDevice(card): void {
  }
  public deleteScn(card): void {
    this.deleteRule(card)
  }
  public onEdit(rule): void {
    //this.onClose.next(this.card);
    this.router.navigate(['home/rules/edit/', rule._id])
    this._bsModalRef.hide();
  }
  public onCancel(): void {
    this.onClose.next(false);
    this._bsModalRef.hide();
  }
  setLetterBGColor(i) {
    if (this.daysTrueFalse[i]) {
      return "rgba(146, 202, 83, 0.8)";
    } else {
      return "rgba(255,255,255,0.2)";
    }
  }
  setLetterColor(i) {
    if (this.daysTrueFalse[i]) {
      return "whitesmoke";
    } else {
      return "rgba(255,255,255,0.5)";
    }
  }

  onEnterName(name) {
    if (name) {
      if (name == 'x') {
        this.name = this.nameOriginal+ " ("+ this.jsonWord.label.opposite + ")"
        this.contenteditable = false;
        this.showName = true;
      } else {
        let index = this.allRules.findIndex(rule => rule.name == this.nameOriginal)
        if (index > -1) this.showSimpleDialog(this.jsonWord.toast.ruleAlreadyRegistered)
        else {
          this.name = name
          this.contenteditable = false;
          this.showName = true;
        }
      }
    } else {
      let message = this.jsonWord.placeholder.name;//"Coloque um nome!!";
      this.showSimpleDialog(message)
      // alert(message);
    }
  }

  onTransformTextinInput(index, devIn): void {
    this.contenteditable = true;
    this.showName = false;
    this.editedIndex = index;
    // devIn.focus();
    setTimeout(() => {
      let devFocus = <HTMLElement>document.getElementById('devIn');
      this.focusedIndex = index;
      devFocus.focus();
    });
  }

  listDevices(gateway) {
    if (!this.currentGateway) {
      return
    }
    if (!this.devicesFound) {
      let hold = [], devSort = []
      this._deviceService.listDevices(gateway)
        .pipe(finalize(() => {
        }))
        .takeUntil(this.ngUnsubscribe)
        .subscribe(
          (deviceList) => {
            this.devicesFound = deviceList;
          },
          err => console.log(this.jsonWord.error.error + ': ', err)
        );
    }
  }

  inputRuleOpt(input) {
    let inputOpt = [], condicao = input.condition
    if (input.condition == '>') condicao = '<'; else condicao = '>'
    inputOpt.push({
      "cmd": input.cmd == "on" ? "off" : "on",
      "name": input.name,
      "ieeeAddr": input.ieeeAddr,
      "devId": input.devId,
      "epId": input.epId,
      "cId": input.cId, //cId
      "value": condicao == '=' ? (input.valor == 1 ? 0 : 1) : input.valor, //verificar isso
      "type": input.type,
      "condition": condicao
    })
    return inputOpt[0]
  }

  outputRuleOpt(output) {
    let outputOpt = []
    //if (output. === "Desativa") { //If it's to turn any devices off
    outputOpt.push({
      name: output.name,
      ieeeAddr: output.ieeeAddr,
      epId: output.epId,
      devId: output.devId,
      cId: "genOnOff",
      cmd: output.cmd == "off" ? "on" : "off",
      zclData: {}
    })
    if (output.cmd == "off" && (output.devId === "258" || output.devId === "528" || output.devId === "261")) {//If colorful lamps and if it's turn them on
      outputOpt.push({
        name: output.name,
        ieeeAddr: output.ieeeAddr,
        epId: output.epId,
        devId: output.devId,
        cId: "lightingColorCtrl",
        cmd: "moveToHueAndSaturation",
        zclData: { hue: 127, saturation: 0, transtime: 0 }
      }, {
          name: output.name,
          ieeeAddr: output.ieeeAddr,
          epId: output.epId,
          devId: output.devId,
          cId: "genLevelCtrl",
          cmd: "moveToLevel",
          zclData: { level: 254, transtime: 0 }
        });
    }
    return outputOpt
  }

  ruleOutputJson(rule) {
    let ruleOpt = []
    ruleOpt.push({
      "ruleId": rule.ruleId,
      "isActive": !rule.isActive
    })
    return ruleOpt[0]
  }

  onSaveRules(ruleJson) {
    let ruleOptJson, inputOpt = [], outputsOpt = [], ruleOutputsOpt = []
    //TODO for
    for (let i=0;i < ruleJson.outputs.length;i++) {
      if(ruleJson.outputs[i].cmd == "on" && (ruleJson.outputs[i].devId === "258" || ruleJson.outputs[i].devId === "528" || ruleJson.outputs[i].devId === "261")){
        outputsOpt.push(this.outputRuleOpt(ruleJson.outputs[i])[0])
        i=i+2
      }else{
        outputsOpt.push(this.outputRuleOpt(ruleJson.outputs[i])[0])
        if((ruleJson.outputs[i].devId === "258" || ruleJson.outputs[i].devId === "528" || ruleJson.outputs[i].devId === "261")){
        outputsOpt.push(this.outputRuleOpt(ruleJson.outputs[i])[1])
        outputsOpt.push(this.outputRuleOpt(ruleJson.outputs[i])[2])
        }
      }
      
    }
    //TODO for
    for (let input of ruleJson.inputs) {
      inputOpt.push(this.inputRuleOpt(input))
    }
    //TODO for
    for (let rule of ruleJson.ruleOutputs) {
      ruleOutputsOpt.push(this.ruleOutputJson(rule))
    }

    //editar nome, lapis e editable
    ruleOptJson = {
      icon: "icon_1",
      inputs: inputOpt,
      isActive: true,
      name: this.name,
      outputs: outputsOpt,//(3) [{…}, {…}, {…}],
      ruleOutputs: ruleOutputsOpt,//[],
      sceneOutputs: [],
      schedule: ruleJson.schedule//{interval: {…}, weekdays: Array(7)}
    }

    this.onClose.next(ruleOptJson);
    this._bsModalRef.hide();
  }

  deleteRule(rule) {
    this.modalRef = this.modalService.show(AlertComponent);
    let ruleResponse = rule
    this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.object = rule
    this.modalRef.content.imagem = { show: true, text: '../assets/img/dashboard/icons/rules-icon.svg' }
    this.modalRef.content.isDelete = true;
    this.modalRef.content.title = this.jsonWord.alert.titleRemoveRule
    this.modalRef.content.message = this.jsonWord.alert.removeRuleConfirmation + ' ' + rule.name + '?';
    this.modalRef.content.onClose.subscribe(result => {
      //ruleResponse=result
      if (result) {
        this.openLoadingModal(this.jsonWord.loading.message)
        this._ruleService.deleteRule(rule._id).subscribe(res => {
          if (res) {
            this.loadingModalRef.content.success = true
            this.loadingModalRef.content.waiting = false
            this.loadingModalRef.content.message = this.jsonWord.toast.deleteRuleSuccess
            setTimeout(() => {
              this.loadingModalRef.hide()
              this._bsModalRef.hide()
            }, 2000);
          }
        }, err => {
          let message = ''
          switch (err.status) {
            case 0:
              message = this.jsonWord.error.noConnection//'Formulário de convidar usuário inválido.'
              //this.noInternetAlert()
              break
            case 400:
              message = this.jsonWord.error.invalidFormat//'Formulário de convidar usuário inválido.'
              break
            case 401:
              message = this.jsonWord.error.notAuthorized//'Você não tem permissão para convidar usuários.'
              break
            /*	case 403:
                message = this.jsonWord.error.userDisabled//'Usuário está desativado.'
                break*/
            case 404:
              message = this.jsonWord.error.ruleNotFound//'Usuário não encontrado ou número serial de gateway não registrado.'
              break
            case 408:
              message = this.jsonWord.error.timeoutServer;//'Tempo limite de conexão atingido.'
              break
            /*	case 409:
                message = this.jsonWord.error.userAlreadyInGtw//'Usuário já associado ao gateway.'
                break
              case 412:
                message = this.jsonWord.error.limitUserExceeded//'Usuário já associado ao gateway.'
                break*/
            case 500:
              message = this.jsonWord.toast.gatewayCommunicationError//'Não foi possível enviar convite.'
              break
            default:
              message = this.jsonWord.toast.errorToRemoveRule

          }
          this.loadingModalRef.content.success = false
          this.loadingModalRef.content.waiting = false
          this.loadingModalRef.content.message = message//this.jsonWord.toast.errorToRemoveRule
          setTimeout(() => {
            this.loadingModalRef.hide()
            this._bsModalRef.hide()
          }, 2000)
        })
      }
    });
  }
  openLoadingModal(message: String) {
    this.loadingModalRef = this.modalService.show(
      LoadingModalComponent, {
        class: 'waiting-modal modal-sm',
        keyboard: false,
        backdrop: 'static'
      }
    )
    this.loadingModalRef.content.message = message;
  }
  showSimpleDialog(message) {
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.imagem = { show: false };
    this.modalRef.content.buttonCancel = { show: false, text: this.jsonWord.button.cancel };
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
    this.modalRef.content.type = 'warning'
    this.modalRef.content.title = this.jsonWord.alert.attention
    this.modalRef.content.message = message
    this.modalRef.content.onClose.subscribe(result => { })
  }
  gatewayErrorToast(err) {
    if (err.status == 408) {
      this.toastr.error('O gateway demorou muito pra responder. Verifique o status do gateway e tente novamente mais tarde.')
    }
  }
  public unsafePublish(topic: string, message: string) {
    this.correlId = JSON.parse(message).messageId
    this._mqttService.unsafePublish(topic, message, { qos: 1, retain: false })
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }
}

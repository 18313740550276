import { Directive, HostListener, SimpleChanges, ElementRef } from '@angular/core';

@Directive({
  selector: '[appZipCodeMask]'
})
export class ZipCodeMaskDirective {

  inputField: HTMLInputElement
  cursorOffset: number

  @HostListener('input', ['$event']) onInputEvent(event: Event) {
    var inputValue: String = this.inputField['value'];
    this.cursorOffset = this.inputField.selectionStart
    this.applyMask(inputValue)
  }

  applyMask(inputValue: String) {
    switch (inputValue.length) {
      case 4:
        //console.log(`case ${inputValue.length}`, inputValue)
        if (inputValue.match(/^\-\d{3}$/)) break
        inputValue = inputValue.replace(/[^\d]/g, '');
        if (inputValue.length == 4)
          this.inputField['value'] = `${inputValue.slice(0, 1)}-${inputValue.slice(1)}`
        else this.applyMask(inputValue);
        break
      case 5:
        //console.log(`case ${inputValue.length}`, inputValue)
        if (inputValue.match(/^\d\-\d{3}$/)) break
        inputValue = inputValue.replace(/[^\d]/g, '');
        if (inputValue.length == 5)
          this.inputField['value'] = `${inputValue.slice(0, 2)}-${inputValue.slice(2)}`
        else this.applyMask(inputValue);
        break
      case 6:
        //console.log(`case ${inputValue.length}`, inputValue)
        if (inputValue.match(/^\d{2}\-\d{3}$/)) break
        inputValue = inputValue.replace(/[^\d]/g, '');
        if (inputValue.length == 6)
          this.inputField['value'] = `${inputValue.slice(0, 3)}-${inputValue.slice(3)}`
        else
          this.applyMask(inputValue);
        break
      case 7:
        //console.log(`case ${inputValue.length}`, inputValue)
        if (inputValue.match(/^\d{3}\-\d{3}$/)) break
        inputValue = inputValue.replace(/[^\d]/g, '');
        if (inputValue.length == 7)
          this.inputField['value'] = `${inputValue.slice(0, 4)}-${inputValue.slice(4)}`
        else
          this.applyMask(inputValue);
        break
      case 8:
        //console.log(`case ${inputValue.length}`, inputValue)
        if (inputValue.match(/^\d{4}\-\d{3}$/)) break
        inputValue = inputValue.replace(/[^\d]/g, '');
        if (inputValue.length == 8)
          this.inputField['value'] = `${inputValue.slice(0, 5)}-${inputValue.slice(5)}`
        else
          this.applyMask(inputValue);
        break
      case 9:
        //console.log(`case ${inputValue.length}`, inputValue)
        if (inputValue.match(/^\d{5}\-\d{3}$/)) break
        inputValue = inputValue.replace(/[^\d]/g, '');
        if (inputValue.length > 8)
          inputValue = inputValue.slice(0, 8)
        this.applyMask(inputValue)
        break
      case 10:
      //  console.log(`case ${inputValue.length}`, inputValue)
        inputValue = inputValue.replace(/[^\d]/g, '');
        if (inputValue.length > 8)
          inputValue = inputValue.slice(0, 8)
        this.applyMask(inputValue)
        break
      default:
        //console.log(`default`, inputValue)
        //if (inputValue.match(/^\d*$/)) break
        this.inputField['value'] = inputValue.replace(/[^\d]/g, '');
        break
    }
  }

  constructor(private inputFieldRef: ElementRef) {
    this.inputField = this.inputFieldRef.nativeElement as HTMLInputElement
  }

}

import { AppRoutingModule } from './../../app-routing.module';
import { NgModule, Pipe, PipeTransform} from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupComponent } from './group.component';
import { DeviceService } from './../../_services/device.service';
import { DirectivesModule } from '../../_directives/directives.module';


@NgModule({
  imports: [
    AppRoutingModule,
    CommonModule,
    DirectivesModule
  ],
  declarations: [
    GroupComponent,

  ],
  providers: [DeviceService],
  exports: [GroupComponent]
})
export class GroupModule { }

import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { GatewayService } from './gateway.service';
import { Injectable } from '@angular/core';

import * as config from '../config';
import { timeoutWith } from 'rxjs/operators';
const url = config.serverURL;//+ 'ecomfort/v1';
const urlConfig = config.serverURL + '/gateway/';
const TIMEOUT: number = 10000 // 10 seconds

@Injectable()

export class GroupService {
    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
        private gatewayService: GatewayService){}

        listGroups(){
          return this.http.get(urlConfig + this.gatewayService.currentGateway.id + '/group')
        .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
            new Response(null, {
              status: 408, statusText: 'Tempo limite de resposta atingido'
            })
          )))
        }
    createGroup(group){
        return this.http.post(urlConfig + this.gatewayService.currentGateway.id + '/group/', group)
        .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
            new Response(null, {
              status: 408, statusText: 'Tempo limite de resposta atingido'
            })
          )))
    }

    updateGroup(groupId, group){
        return this.http.patch(urlConfig + this.gatewayService.currentGateway.id + '/group/' + groupId, group) 
        .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
            new Response(null, {
              status: 408, statusText: 'Tempo limite de resposta atingido'
            })
          )))
    }

    removeGroup(groupId){
        return this.http.delete(urlConfig + this.gatewayService.currentGateway.id + '/group/' + groupId) 
        .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
            new Response(null, {
              status: 408, statusText: 'Tempo limite de resposta atingido'
            })
          )))
    }
}

import { MqttService } from 'ngx-mqtt';
import { DashboardModule } from './../dashboard/dashboard.module';
import { PrivacyComponent } from './../privacy/privacy-component';
import { LogsService } from './../_services/logs.services';
import { SanitizeHtmlPipe } from './../_directives/sanitizeHtmlPipe';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RuleModule } from './../rule/rule.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelModule } from './../panel/panel.module';
import { UserService } from './../_services/user.service';
import { EnvironmentService } from './../_services/environment.service';
import { GatewayService } from './../_services/gateway.service';
import { LogComponent } from './../log/log.component';
import { SettingsComponent } from './../settings/settings.component';
import { AppRoutingModule } from '../app-routing.module';
import { DeviceService } from './../_services/device.service';
import { EnvironmentModule } from '../environment/environment.module';
import { SceneModule } from '../scene/scene.module';
import { JwtModule } from '@auth0/angular-jwt';
import { HomeComponent } from './home.component';
import { ProfileComponent } from '../profile/profile.component';
import { DirectivesModule } from '../_directives/directives.module';
import { BsDatepickerModule, TimepickerModule } from 'ngx-bootstrap';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside'
import { ColorPickerModule } from 'ngx-color-picker';
import { BrowserModule } from '@angular/platform-browser';
import { GatewayModule } from '../gateway/gateway.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Safe } from '../_directives/alert.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { GroupService } from 'app/_services/group.service';
import { GroupModalModule } from 'app/_directives/group-modal/group-modal.module';
import { CardGroupModule } from 'app/_directives/group-modal/card/card-group.module';

export function tokenGetter() {
  return localStorage.getItem('currentUserToken');
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    AppRoutingModule,
    CommonModule,
    DragDropModule,
    DirectivesModule,
    PanelModule,ColorPickerModule,
    RouterModule,ImageCropperModule,
    DashboardModule,
    EnvironmentModule,
    GatewayModule,
    SceneModule,
    RuleModule,
    Ng2SearchPipeModule,
    ReactiveFormsModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    BrowserModule,
    HttpClientModule,
    ClickOutsideModule,
    GroupModalModule,CardGroupModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['localhost:3001'],
        blacklistedRoutes: ['localhost:3001/auth/']
      }
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  declarations: [
    HomeComponent,
    ProfileComponent,
    SettingsComponent,
    PrivacyComponent,
    LogComponent,
  ],
  providers: [
    GatewayService,
    EnvironmentService,
    UserService,
    DeviceService,
    LogsService,
    GroupService,
    MqttService
  ],
  exports: []
})
export class HomeModule { }

import { LoadingModalComponent } from './../../_directives/loading-modal/loading-modal.component';
import { ResponseService } from './../../_services/response.service';
import { GatewayService } from './../../_services/gateway.service';
import { TranslateJsonService } from './../../_services/translate-json.service';
import { AlertComponent } from './../../_directives/alert.component';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap';
import { Card } from './../../models/card.model';

import { DeviceService } from './../../_services/device.service';
import * as config from '../../config';
import { Device } from './../../models/device.model';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { Subscription } from 'rxjs/Subscription';
import { EnvironmentService } from './../../_services/environment.service';
import { OnInit, OnDestroy, ChangeDetectorRef, Component, Input, ViewChild, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';
import { Location } from '@angular/common';
//import { forEach } from '@angular/router/src/utils/collection';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ScenarioService } from '../../_services/scenario.service';
import { FormBuilder } from '@angular/forms';
import { timeoutWith, finalize } from 'rxjs/operators';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { HomeComponent } from '../../home/home.component';
import { RuleService } from 'app/_services/rule.service';
import { IconsScenarioModalComponent } from 'app/_directives/icons-scenario-modal/icons-scenario-modal.component';

const utf8Decoder = new TextDecoder("utf-8")
const TIMEOUT = 10000

@Component({
	selector: 'vr-scene-config',
	templateUrl: './scene-config.component.html',
	styleUrls: ['./scene-config.component.css'],
	providers: [ScenarioService, DeviceService],
})

export class SceneConfigComponent implements OnInit, OnDestroy {
	//devicesService: DevicesService;
	editCheck = [];
	whiteLight = [];
	readOneTimeColor = 0;
	readOneTimeLevel = 0;
	selectedIndex = 0;
	lastIndex = 2;
	bar: boolean = true;
	circle: boolean = false;
	devs = [];
	actions = [];
	devices;
	devChecked;
	checkedDevices;
	devName;
	_id: number;
	gateway;
	dispON;
	devConfigd;
	newScn;
	component = [];
	isActive;
	scenesAll;
	scenesAllSubs;
	sceneActionName = [];
	colorText = [];
	lightLevel = [];
	isAllChecked = false;
	isAllChecked2 = false;
	devsNoScene = ["1", "2"];
	devNameArray = [];
	devsSceneClick: boolean;
	devsAllowedForScene: any;
	dataDevsScn = [];
	devsScnToEdited;
	idDevsScn = [];
	idDevsOffScn = [];
	whiteOrColorful = [];
	sat = [];
	saturacao;
	trueForDevsScene = true;
	colorWasntTouch;
	levelWasntTouch;
	devToClose;
	nome = false;
	devId;
	allZclData = [];
	ruleArray = []
	//@Input() checkMark;
	//@Input() dvNSc;
	//@Input() dev;
	onOff = [];
	output;
	uuid = require('uuid/v4');
	gtw;//= 'csZ1DuG92MTr1MKR'; //this.appService.localStorage()
	white = [];
	colorful = [];
	inputToCheck = [];
	saveClicked = 0;
	//card: Card;
	flipCard = [];
	cardFlipTimer: any;
	vectorTypes = [];
	currentGateway;
	messageEdit;
	page = 1;
	card
	index = "";
	cdmsArray = [];
	clustersArray;
	devicesArray = [];
	cmdsArray;
	nameCMD;
	cmdOn;
	cmdOff;
	cmdColor;
	cmdLevel;
	levelToShow = [];
	inScn = [];
	countFinal = 0;
	public modalRef: BsModalRef;
	language;
	jsonWord;
	public loading: Boolean
	nameScn;
	arrowTF = [false, false, false, false, false, false, false, false];
	allRules;
	rulesToOutput = []
	holdCheckedRule = []
	countPage1 = 0
	imgsScenario = []
	iconScenario
	iconScenarioWhite
	isActiveRule = []
	/*	@HostListener('window:keyup', ['$event'])
		enterKeyUpEvent(e) {
			e.stopPropagation()
			if (e.keyCode == 13 && this.page != 3) {
				this.nextPage()
			}
			else if (e.keyCode == 13 && this.page == 3) {
				this.saveScene()
			}
		}*/

	constructor(private formBuilder: FormBuilder,
		private location: Location,
		devicesService: DeviceService,
		private sceneService: ScenarioService,
		private translateJson: TranslateJsonService,
		private route: ActivatedRoute,
		public element: ElementRef,
		private modalService: BsModalService,
		private cd: ChangeDetectorRef,
		private _mqttService: MqttService,
		private router: Router,
		private rulesService: RuleService,
		private responseService: ResponseService,
		private gatewayService: GatewayService,
		private homeComponent: HomeComponent
	) {
		this.devices = devicesService.listDevices();
	}

	ngOnInit(): void {
		for (let i = 1; i <= 33; i++) {
			var img = 'icon_' + i + '_white.svg'
			this.imgsScenario.push(img)

		}
		this.iconScenario='icon_1'
		this.iconScenarioWhite = 'icon_1_white.svg'
		this.language = localStorage.getItem('language')
		this.location.replaceState('/')
		this.jsonWord = this.translateJson.changeLanguage(this.language)
		this.devConfigd = null;
		this.currentGateway = (localStorage.getItem('currentGateway'));
		this.route.params.forEach((params: Params) => {
			this._id = params['_id'];
		});
		this.gatewayService.gatewayObservable.subscribe(
			gateway => {
				if (gateway) {
					this.currentGateway = gateway.id
					this.loading = true
				}
			}
		)

		//preciso
		this.devToClose = this.devices.subscribe(res => {
			this.devName = res//.devices;
			let resp: Device, a = 0
			let teste = [];
			for (resp of res) {//.devices) {
				this.card = new Card(resp);
				if (this.card.devId != '770' && this.card.devId != '12' && this.card.devId != '263' && this.card.devId != '262' &&
					this.card.devId != '10' && this.card.devId != '6') {
					this.card.isActive = (this.card.isActive && !this.card.invertOnOff) || (!this.card.isActive && this.card.invertOnOff)
					this.inputToCheck.push(this.card)
					this.checkDevsType(this.inputToCheck)
					this.devNameArray.push(this.card.devIeeeAddr);
					teste.push(this.card);
					if (this.card.devId == '258' || this.card.devId == '528' || this.card.devId == '261') {
						this.whiteOrColorful.push(this.card.value3 ? 0 : 1);
					} else {
						this.whiteOrColorful.push(2)
					}
					this.flipCard.push(true)
					this.inScn.push(false)
				}
			}
			this.devsAllowedForScene = teste;
		});
		//preciso

		this.scenesAll = this.sceneService.listScenarios()
			.pipe(finalize(() => { this.loading = false }))
			.subscribe(res => {
				this.scenesAllSubs = res;
			});
		this.devsSceneClick = false;
		this.rulesService.listRules().subscribe(res => {
			this.allRules = res;
			for (let i = 0; i < res.length; i++) {
				this.rulesToOutput[i] = ([res[i], false])
				this.isActiveRule[i] = res[i].isActive
			}
		})
		//	$("#imgScn2").css({"mask":"url(assets/img/icons-rule-scenario/'+`${iconScenario}`+')","fill":"white"});

	}

	//PRECISO DISSO
	checkDevsType(inputToCheck) {
		this.vectorTypes = [null, null, null, null, null, null, null, null]
		let count0 = [], count1 = [], count2 = [], count3 = [], count4 = [], count5 = [], count6 = [], count7 = [];

		for (let i = 0; i < inputToCheck.length; i++) {
			if (this.inputToCheck[i].devId == '81' || this.inputToCheck[i].devId === '256') { count0.push([inputToCheck[i], this.inScn[i]]); }
			if (this.inputToCheck[i].devId == '2') { count1.push([inputToCheck[i], this.inScn[i]]); }//2
			if (this.inputToCheck[i].devId == '258' || this.inputToCheck[i].devId === '528') { count2.push([inputToCheck[i], this.inScn[i]]); }
			if (this.inputToCheck[i].devId == '771') { count3.push([inputToCheck[i], this.inScn[i]]); }
			if (this.inputToCheck[i].devId == '261') { count4.push([inputToCheck[i], this.inScn[i]]); }//
			if (this.inputToCheck[i].devId == '772') { count5.push([inputToCheck[i], this.inScn[i]]); }//1
			if (this.inputToCheck[i].devId == '265') { count6.push([inputToCheck[i], this.inScn[i]]); }//1
			if (this.inputToCheck[i].devId == '264') { count7.push([inputToCheck[i], this.inScn[i]]); }//1
		}
		this.vectorTypes[0] = count0;
		this.vectorTypes[1] = count1;
		this.vectorTypes[2] = count2;
		this.vectorTypes[3] = count3;
		this.vectorTypes[4] = count4;
		this.vectorTypes[5] = count5;
		this.vectorTypes[6] = count6;
		this.vectorTypes[7] = count7;
	}

	ngOnDestroy(): void {
		this.devToClose.unsubscribe();
		this.scenesAll.unsubscribe();
	}

	setIcon() {
		this.modalRef = this.modalService.show(IconsScenarioModalComponent);
		this.modalRef.content.imgsScenario = this.imgsScenario
		this.modalRef.content.onClose.subscribe(res => {
			this.iconScenario = res
			this.iconScenarioWhite = (res.length < 7 ? res.slice(0, 6) + '_white.svg' : res.slice(0, 7) + '_white.svg')
		})
	}

	//Ok
	getValueFromDevicesToBeEdited() {
		for (let i = 0; i < this.devsScnToEdited.length; i++) {
			this.onOff[i] = (this.devsScnToEdited[i].cmd === "on" ? this.editCheck[i] = true : this.editCheck[i] = false)
		}
	}
	checkAll() {
		this.isAllChecked = !this.isAllChecked;
		this.trueForDevsScene = this.isAllChecked;
	}
	rotateCard(i) {
		this.flipCard[i] = !this.flipCard[i];
		if (this.flipCard[i]) {
			clearTimeout(this.cardFlipTimer);
		}
		if (!this.flipCard[i]) {
			this.cardFlipTimer = setTimeout(() => {
				this.rotateCard(i);
			}, 60000);
		}
		this.cd.markForCheck();
	}


	//funciona-testara com lampada
	outputConfigEnd(holdOut) {
		for (let i = 0; i < this.checkedDevices.length; i++) {//dispositivos output
			let hold = [];
			hold[0] = this.checkedDevices[i];
			hold[1] = this.checkedDevices[i].isActive ? "Ativa" : "Desativa"
			hold[2] = (!this.lightLevel[i] ? this.checkedDevices[i].value1 : this.lightLevel[i])
			this.whiteOrColorful[i] = (this.whiteLight[i] ? 0 : 1)
			hold[4] = (this.whiteOrColorful[i] === 0 ? "0" : "254")
			this.white[i] = (this.whiteOrColorful[i] === 0 ? "100" : "50")
			hold[3] = this.colorText[i];
			this.colorful[i] = (this.colorText[i] / 254) * 360; //se cor foi clicada 
			this.levelToShow[i] = (hold[2] / 254) * 100
			this.levelToShow[i] = parseInt(this.levelToShow[i])
			holdOut[i] = (hold);
		}
		this.output = holdOut;
		return this.output;
	}
	messageNoDevs() {
		this.modalRef = this.modalService.show(AlertComponent);
		this.modalRef.content.buttonConfirm = { show: false };
		this.modalRef.content.buttonCancel = { show: false, text: this.jsonWord.button.cancel };
		this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
		this.modalRef.content.type = 'warning'
		this.modalRef.content.title = this.jsonWord.alert.attention
		this.modalRef.content.message = this.jsonWord.label.selectDeviceScenario
		this.modalRef.content.onClose.subscribe(result => {
			if (result) {
				console.log("ok")
			}
		});
		return this.modalRef
	}

	gotToPage(page) {
		switch (page) {
			case 1:
				this.countPage1 = 0;
				this.countFinal = 0
				this.index = "#1a"
				this.page = 1;
				break;
			case 2:
				if (!this.nameScn) {
					this.showSimpleDialog(this.jsonWord.error.requiredName)
					this.nome = false;
					return;
				} else {
					this.nome = true;
				}
				//Verify if scenario name alredy exists, and an alert will be sent
				if (this.nameScn) {
					for (let item of this.scenesAllSubs) {
						if (item.name === this.nameScn) {
							this.nome = false;
							this.showSimpleDialog(this.jsonWord.toast.scenarioAlreadyRegistered)
							return;
						} else {
							this.nome = true;
						}
					}
				}
				let holdElemId = [], holdChecked = [];
				if (this.inputToCheck) {
					for (let i = 0; i < this.inputToCheck.length; i++) {
						holdElemId.push(<any>document.getElementById(this.inputToCheck[i].devIeeeAddr))
						if (holdElemId[i] && holdElemId[i].checked) {//classList[holdElemId[i].classList.length - 1] === "mat-checkbox-checked") {
							holdChecked.push(this.inputToCheck[i])
							this.inScn[i] = true
							this.whiteLight[i] = this.inputToCheck[i].value3
							this.countFinal = 1
						}
					}
				}
				this.checkedDevices = holdChecked

				let holdRule = [], holdCheckedRule = [];
				for (let i = 0; i < this.rulesToOutput.length; i++) {
					holdRule.push(<any>document.getElementById(this.rulesToOutput[i][0]._id))
					if (holdRule[i] && holdRule[i].checked) {
						holdCheckedRule.push(this.rulesToOutput[i][0])
						this.countPage1 = 1;
					}
				}
				this.holdCheckedRule = holdCheckedRule;
				for (let i = 0; i < this.checkedDevices.length; i++) {
					this.whiteLight[i] = this.checkedDevices[i].value3
				}
				if (this.checkedDevices.length < 1 && this.holdCheckedRule.length < 1) {
					this.countFinal = 0;
					this.countPage1 = 0;
				}
				if (this.checkedDevices.length < 1 && this.countPage1 == 0) {
					this.countFinal = 0
				}
				if ((this.countFinal == 0 && this.countPage1 == 0) || !this.nome) {
					this.showSimpleDialog(this.jsonWord.text.noScenarioActuatorRegistered)
					this.index = "#1a"
					this.page = 1;
				} else {
					if ((this.checkedDevices.length > 0 && this.holdCheckedRule.length > 0) || (this.checkedDevices.length > 0 && this.holdCheckedRule.length < 1)) { //se tem dispositivo
						this.page = 2;
						this.index = "#2a"
					}
					if (this.checkedDevices.length < 1 && this.holdCheckedRule.length > 0) { //ou tem regra e disp ou so tem regra
						this.page += 1
						this.page = 3;
						this.index = "#3a"
					}
				}
				break;
			case 3:
				this.holdCheckedRule = this.holdCheckedRule
				if (this.holdCheckedRule.length > 0) { //se tem regra
					this.page = 3;
					this.index = "#3a"
					break;
				} else {                   //não tem regra
					this.page = 4;
					this.index = "#4a"
					page += 1
					this.gotToPage(page)
					//break;
				}
				break;
			case 4:
				//this.page = 3;   
				let holdOut = [];
				this.colorWasntTouch = 0;
				this.levelWasntTouch = 0;
				this.devConfigd = null;
				//Working!! caso seja configuraçao de novo cenario, chama  a funçao
				if (this.countFinal == 1) {
					this.devConfigd = this.outputConfigEnd(holdOut)
				} this.page = 4;
				this.index = "#4a"
				/*/	if (this.devConfigd.length < 1) {
						this.messageNoDevs()
					} else {
						//Working!! caso seja configuraçao de novo cenario, chama  a funçao
						this.page = 4;
						this.index = "#4a"
					}*/
				break;
			default:
				break;
		}
	}

	setRuleOnOff(rule, i) {
		this.isActiveRule[i] = !this.isActiveRule[i]
	}

	setArrow(i) {
		this.arrowTF[i] = !this.arrowTF[i]
		switch (i) {
			case (0):
				document.getElementById('arrowSP').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (1):
				document.getElementById('arrowS').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (2):
				document.getElementById('arrowRGB').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (3):
				document.getElementById('arrowSPP').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (4):
				document.getElementById('arrowLed').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (5):
				document.getElementById('arrowTurn').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (6):
				document.getElementById('arrowSPul').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (7):
				document.getElementById('arrowSSw').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
			case (8):
				document.getElementById('arrowRule').className = this.arrowTF[i] ? 'fa fa-chevron-up rotate-counterclockwise fa-sm' : 'fa fa-chevron-down rotate-clockwise fa-sm dropdown-arrow';
				break;
		}
	}

	showSimpleDialog(message) {
		this.modalRef = this.modalService.show(AlertComponent);
		this.modalRef.content.buttonConfirm = { show: false };
		this.modalRef.content.buttonCancel = { show: false, text: this.jsonWord.button.cancel };
		this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
		this.modalRef.content.type = 'warning'
		this.modalRef.content.title = this.jsonWord.alert.attention
		this.modalRef.content.message = message
		this.modalRef.content.onClose.subscribe(result => { })
	}

	goToScenario() {
		this.router.navigate(['home/scenarios']);
	}

	/**
	 * @author Nathalia
	 * Control  next and previous template page.
	 * @function nextPage() get by class name, the checked elements and show them in next page.
	 */
	nextPage() {
		if (this.page < 4) {
			this.gotToPage(this.page + 1)
		}
	}

	previousPage() {
		if (this.page > 1){
			if (this.page == 4) {
				if (this.holdCheckedRule.length < 1) {
					if (this.checkedDevices.length < 1) //se não tem disp, nem regra, ou seja, apenas cenário
						this.page = this.page - 3;
					else//se não tem regra, mas tem disp
						this.page = this.page - 2;
				} else {
					this.page = this.page - 1;//se tem regra
				}
			} else if (this.page == 3) {
				if (this.checkedDevices.length > 0) { //se tem disp
					this.page = this.page - 1;
				}
				else {//se não tem disp
					this.page = this.page - 2;
				}
			} else {
				this.page = this.page - 1
			}
		}
	}

	onSmartlightLevelChange(levelV, i) {
		var level;
		this.lightLevel[i] = levelV;
		return this.lightLevel;
	}
	onSmartlightColorChange(colorV, i) {
		this.colorText[i] = colorV;
		return this.colorText;
	}
	sendOnOff(check, i) {
		check.isActive = !check.isActive;
	}

	getColor(i, value2) {
		this.colorText[i] = this.colorText[i] ? this.colorText[i] : value2;
		return this.colorText[i]
	}
	getLevel(i, value1) {
		//this.lightLevel[i] = (this.lightLevel[i]/254) * 100;
		this.lightLevel[i] = this.lightLevel[i] ? this.lightLevel[i] : value1;
		return this.lightLevel[i];
	}

	whiteClicked(i) {
		this.whiteLight[i] = !this.whiteLight[i];
	}

	changeCheck(device, checkDoor) {
		device[1] = checkDoor.checked
	}

	/**
	 * Pack to JSON, functions to devices
	 */
	cmdsCreateJSON(dispositivo, i) {
		if (this.output[i][1] === "Desativa") { //If it's to turn any devices off
			this.clustersArray = [{
				name: "genOnOff",
				cmds: [{
					cmdApi: !dispositivo.invertOnOff ? "off" : "on",
					cmdId: dispositivo.devEpList[0],
					attributes: {}
				}]
			}]
		} else { //If it's to turn any devices on
			if (dispositivo.devId === "258" || dispositivo.devId === "528" || dispositivo.devId === "261") {//If colorful lamps and if it's turn them on
				this.clustersArray = [{
					name: "genOnOff",
					cmds: [{
						cmdApi: "on",
						cmdId: dispositivo.devEpList[0],
						attributes: {}
					}]
				}, {
					name: "lightingColorCtrl",
					cmds: [{
						cmdApi: "moveToHueAndSaturation",
						cmdId: "6",//dispositivo.devEpList[0],
						attributes: { hue: parseInt(this.output[i][3]), saturation: this.output[i][4], transtime: 0 }
					}]
				}, {
					name: "genLevelCtrl",
					cmds: [{
						cmdApi: "moveToLevel",
						cmdId: "0",//dispositivo.devEpList[0],
						attributes: { level: this.output[i][2], transtime: 0 }
					}]
				}]
			} else {
				this.clustersArray = [{
					name: "genOnOff",
					cmds: [{
						cmdApi: !dispositivo.invertOnOff ? "on" : "off",
						cmdId: dispositivo.devEpList[0],
						attributes: {}
					}]
				}]
			}
		}
		this.devicesArray[i] = {
			_device: dispositivo._id,
			clusters: this.clustersArray
		}
	}

	/**
	 * @author Nathalia
	 * @function saveScene() 
	 */

	onEnter($event) {//Press enter and save...but not working
		$event.preventDefault();
		this.saveScene();
	}

	formatRules() {
		for (let i = 0; i < this.holdCheckedRule.length; i++) {
			this.ruleArray.push({
				"ruleId": this.holdCheckedRule[i]._id,
				"isActive": this.isActiveRule[i]
			})
		}
	}

	saveScene() {
		const topic = config.gtwTopic + this.currentGateway + "/scenario/config";
		// If name exists, an alert will be sent and the variable name is set to false.
		if (this.output && this.output.length) {
			for (let i = 0; i < this.output.length; i++) {
				this.cmdsCreateJSON(this.output[i][0], i)
			}
		}
		if (this.nome) {
			let scenarioResponse
			this.saveClicked = 1;
			this.formatRules()
			const topic2 = config.gtwTopic + this.currentGateway + '/scenario/#';
			let message = {
				name: this.nameScn,
				icon: this.iconScenario,
				devices: this.devicesArray,
				ruleOutputs: this.ruleArray
			}
			this.openLoadingModal(this.jsonWord.loading.message)

			this.sceneService.createScenario(message).subscribe(res => {
				scenarioResponse = res.data
				if (res) {
					this.modalRef.content.success = true
					this.modalRef.content.waiting = false
					this.modalRef.content.message = this.jsonWord.toast.successScenario
					setTimeout(() => {
						this.modalRef.hide()
						this.router.navigate(['home/scenarios']);
					}, 2000);
				}
			}, err => {
				let message = ''
				switch (err.status) {
					case 0:
						message = this.jsonWord.error.noConnection//'Formulário de convidar usuário inválido.'
						//this.noInternetAlert()
						break
					case 400:
						message = this.jsonWord.error.invalidFormat//'Formulário de convidar usuário inválido.'
						break
					case 401:
						message = this.jsonWord.error.notAuthorized//'Você não tem permissão para convidar usuários.'
						break
					/*	case 403:
							message = this.jsonWord.error.userDisabled//'Usuário está desativado.'
							break*/
					case 404:
						message = this.jsonWord.error.scenarioNotFound//'Usuário não encontrado ou número serial de gateway não registrado.'
						break
					case 408:
						message = this.jsonWord.error.timeoutServer;//'Tempo limite de conexão atingido.'
						break
					/*	case 409:
							message = this.jsonWord.error.userAlreadyInGtw//'Usuário já associado ao gateway.'
							break
						case 412:
							message = this.jsonWord.error.limitUserExceeded//'Usuário já associado ao gateway.'
							break*/
					case 500:
						message = this.jsonWord.toast.gatewayCommunicationError//'Não foi possível enviar convite.'
						break
					default:
						message = this.jsonWord.toast.errorScenario

				}
				this.modalRef.content.success = false
				this.modalRef.content.waiting = false
				this.modalRef.content.message = message
				setTimeout(() => {
					this.modalRef.hide()
					this.router.navigate(['home/scenarios']);
				}, 2000)
			})
		}
	}
	changeCheckRule(rule, checkRule) {
		rule[1] = checkRule.checked
	}
	openLoadingModal(message) {
		this.modalRef = this.modalService.show(
			LoadingModalComponent,
			{
				keyboard: false,
				backdrop: 'static',
				class: 'waiting-modal modal-sm'
			})
		this.modalRef.content.message = message;
	}
	goBack(): void {
		this.location.back();
	}
}
import { Device } from './../models/device.model';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DeviceService } from './../_services/device.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { AlertComponent } from '../_directives/alert.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import * as config from './../config';
import { Location } from '@angular/common';
import * as moment from 'moment';

import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/map';
import { BehaviorSubject, Subject, Observable, Subscription } from 'rxjs';
import { HomeComponent } from '../home/home.component';
import { LoadingModalComponent } from '../_directives/loading-modal/loading-modal.component';
import { GatewayService } from '../_services/gateway.service';
import { finalize, timeoutWith } from 'rxjs/operators';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { UserService } from '../_services/user.service';
import * as data from './../fusohorario.json';
import { TranslateJsonService } from '../_services/translate-json.service';

const word = data.default;

const PERMITJOIN_TIMEOUT = 5000
const DELETE_TIMEOUT = 12000

@Component({
  selector: 'app-gateway',
  templateUrl: './gateway.component.html',
  styleUrls: ['./gateway.component.css']
})
export class GatewayComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  public modalRef: BsModalRef;
  gateways: any = [];
  loading = false;
  uuid = require('uuid/v4');
  message;
  currentGateway;
  topicSubscriptions = new Array<Subscription>(2)
  public currentUser: any;
  public currentUserId: any;
  refreshSubject = new BehaviorSubject(true);
  contenteditable: Boolean = false;
  showName = true;
  focusedIndex;
  editedIndex;
  contenteditableF: Boolean = false;
  showFuso = true;
  focusedIndexF;
  editedIndexF;
  newGtwName;
  state = []
  public fusoChosen;
  public fusoHorario = word;
  currentUserType;
  public loadingModalRef: BsModalRef
  public language;
  public jsonWord
  mqttSuccess: Subscription
  gatewaysUser
  devicesFound = [];
  theresModal:Boolean = true
  inEnv = [];
  notSendDevs = []
  vectorTypes = [null, null, null, null, null, null, null, null, null, null, null]
  arrowTF = [false, false, false, false, false, false, false, false, false, false, false];
  constructor(
    private _mqttService: MqttService,
    private modalService: BsModalService,
    private router: Router,
    public _gatewayService: GatewayService,
    private cd: ChangeDetectorRef,
    private _userService: UserService,
    private translateJson: TranslateJsonService,
    private readonly location: Location,
    public homeComponent: HomeComponent,
    private gatewayService: GatewayService
  ) { }

  ngOnInit() {
    this.location.replaceState('/');
    this.language = localStorage.getItem('language')
    this.jsonWord = this.translateJson.changeLanguage(this.language)
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')).email
    this.currentUserId = JSON.parse(localStorage.getItem('currentUser'))._id
    this.currentUserType = this.homeComponent.currentUserType;
    this.gatewayService.gatewayObservable
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        gateway => {
          if (gateway) {
            if (this.currentGateway != gateway.id) {
              this.currentGateway = gateway.id
              this.loading = true
              this.updateAll()
            }
          }
        }
      )
    this.listGateways()
  }

  updateAll() {
    this.refresh()
    this.topicSubscriptions.forEach(sub => sub.unsubscribe())
    this.topicSubscriptions[0] = this.homeComponent.gatewaySuccessObserver
      .subscribe(message => {
        this.message = message;
        if (message['operation'] === 'add') {
          this.refresh()
        }
      });
  }

  refresh() {
    this.listGateways()
  }

  listGateways() {
    this.loading = true
    this._userService.listUserById(this.currentUserId).subscribe(res => {
      let gtws = res._gateways
      this.gatewaysUser = gtws.filter(gtw => gtw.status == 'DISABLED' && gtw.type == "GATEWAY")
    })
    this._userService.listUserInGatewayById(this.currentUserId)
      .pipe(finalize(() => this.loading = false))
      .takeUntil(this.ngUnsubscribe)
      .subscribe(gtwsArray => {
        this.gateways = gtwsArray.filter(gtw => gtw.type == "GATEWAY")
      },
        err => {
          this.homeComponent.openErrorAlert(err)
        });
  }
  openLoadingModal(message: String) {
    this.loadingModalRef = this.modalService.show(
      LoadingModalComponent, {
        class: 'waiting-modal modal-sm',
        keyboard: false,
        backdrop: 'static'
      }
    )
    this.loadingModalRef.content.message = message;
  }

  deleteGateway(gateway, table) {
    this.openConfirmDialog(gateway)
      .subscribe(res => {
        if (res) {
          this.openLoadingModal(this.jsonWord.loading.message)//'Cadastrando usuário...')
          this.gatewayService.deleteGateway(gateway.id, this.currentUserId)
            .subscribe(res => {
              if (res) {
                setTimeout(() => {
                  // this.router.navigateByUrl('/home/gateways')
                  this.loadingModalRef.content.message = this.jsonWord.success.disassociatedUser
                  this.loadingModalRef.content.waiting = false
                  this.loadingModalRef.content.success = true
                  this.loadingModalRef.hide()
                  this.router.navigate(['/home/panel']);
                }, 2000)
              }
            }, err => {
              let message = ''
              switch (err.status) {
                case 0:
                  message = this.jsonWord.error.noConnection;//'Campos de email ou senha inválidos.'
                  //  this.noInternetAlert()
                  break
                case 400:
                  message = this.jsonWord.error.gatewayInvalidRedef//'Formulário para redefinição do gateway inválido.'
                  break
                case 401:
                  message = this.jsonWord.error.notAuthorized//'Não autorizado.'
                  break
                case 403:
                  message = this.jsonWord.error.userNotAccept//'Usuário não aceitou o convite enviado no e-mail registrado.'
                  break
                case 404:
                  message = this.jsonWord.error.gatewayNotFound//'Gateway não encontrado.'
                  break
                case 408:
                  message = this.jsonWord.error.timeoutServer//'Gateway não encontrado.'
                  break
                case 412:
                  message = this.jsonWord.toast.onlyOneAdminError//'Gateway não encontrado.'
                  break
                case 500:
                  message = this.jsonWord.toast.gatewayCommunicationError//'Não foi possível realizar a autenticação.'
                  break
              }
              this.loadingModalRef.content.message = message
              this.loadingModalRef.content.success = false
              this.loadingModalRef.content.waiting = false

              //this.openErrorDialog(err.message)
              setTimeout(() => {
                this.loadingModalRef.hide()
                this.router.navigate(['/home/panel']);
              }, 2000);
            })
        }
      })
  }
  public unsafePublish(topic: string, message: string): void {
    this._mqttService.unsafePublish(topic, message, { qos: 1, retain: false });
  }

  openInputDialog(gateway) {
    let modalRef = this.modalService.show(AlertComponent, { class: "modal-sm" })
    // modalRef.content.inputText1 = { show: true, placeholder: this.jsonWord.label.password, type: 'password' }
    modalRef.content.message = this.jsonWord.alert.messageDisassociateGateway + ' ' + gateway//"Digite sua senha:"
    modalRef.content.title = this.jsonWord.text.disconnectFromGateway//"Adicionar Gateway"
    modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok }
    modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel }
    modalRef.content.buttonConfirm = { show: false };
    return modalRef.content.onClose.map(res => { if (res) return modalRef.content.message; else return false })
  }
  openConfirmDialog(gateway): Observable<any> {
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.unlink };
    this.modalRef.content.type = 'warning'
    this.modalRef.content.title = this.jsonWord.alert.attention/*'<b><i class="fa fa-warning" style="color: yellow"> </i> Atenção</b>'*/;
    this.modalRef.content.message = this.jsonWord.alert.messageDisassociateGateway + ' ' + gateway.name + '?'/*'Essa operação irá remover TODOS os dados associados ao gateway, como dispositivos, ' +
      'ambientes, cenários, regras e usuários (incluindo o usuário administrador).<br>' +
      'Tem certeza que deseja restaurar o Gateway <b>' + this.currentGateway.name + '</b> para as configurações de fábrica?';*/
    return this.modalRef.content.onClose;
  }
  ngOnDestroy() {
    this.topicSubscriptions.forEach(sub => sub.unsubscribe())
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  openInputAlertEdit(gateway) {
    if(this.theresModal){
      this.theresModal=false
    this.notSendDevs = gateway.sendPushNotification.notSendDevices
    this.devicesFound = [];
    let notification;
    if (!gateway.sendPushNotification.error || gateway.sendPushNotification.event || gateway.sendPushNotification.alert) {
      notification = false
    }
    if (gateway.sendPushNotification.error && gateway.sendPushNotification.event && gateway.sendPushNotification.alert) {
      notification = true
    }
    this.gatewayService.listGatewayById(gateway.serialNumber)
      .subscribe(
        (deviceList) => {

          this.devicesFound = deviceList['data'].devices;
          let lengthDevs = this.devicesFound.length
          this.inEnv = Array(lengthDevs).fill(true)

          this.vectorTypes = [null, null, null, null, null, null, null, null, null, null, null]
          let count0 = [], count1 = [], count2 = [], count3 = [], count4 = [], count5 = [], count6 = [], count7 = [], count8 = [], count9 = [], count10 = [],
            count11 = [], count12 = [], count13 = [], count14 = [];

          for (let j = 0; j < (this.devicesFound.length); j++) {
            for (let i = 0; i < this.notSendDevs.length; i++) {
              if (this.devicesFound[j].ieeeAddr == this.notSendDevs[i]) {
                this.inEnv[j] = false
              }
            }
            if (this.devicesFound[j].devId == '770') count0.push([this.devicesFound[j], this.inEnv[j]]);
            if (this.devicesFound[j].devId == '12') count1.push([this.devicesFound[j], this.inEnv[j]]);  //3
            if (this.devicesFound[j].devId == '263') count2.push([this.devicesFound[j], this.inEnv[j]]);
            if (this.devicesFound[j].devId == '81' || this.devicesFound[j].devId === '256') count3.push([this.devicesFound[j], this.inEnv[j]]);
            if (this.devicesFound[j].devId == '2') count4.push([this.devicesFound[j], this.inEnv[j]]); //2
            if (this.devicesFound[j].devId == '6') count5.push([this.devicesFound[j], this.inEnv[j]]); //2
            if (this.devicesFound[j].devId == '258' || this.devicesFound[j].devId === '528') count6.push([this.devicesFound[j], this.inEnv[j]]);
            if (this.devicesFound[j].devId == '10') count7.push([this.devicesFound[j], this.inEnv[j]]); //1
            if (this.devicesFound[j].devId == '262') count8.push([this.devicesFound[j], this.inEnv[j]]);
            if (this.devicesFound[j].devId == '771') count9.push([this.devicesFound[j], this.inEnv[j]]);
            if (this.devicesFound[j].devId == '261') count10.push([this.devicesFound[j], this.inEnv[j]]); //1
            if (this.devicesFound[j].devId == '264') count11.push([this.devicesFound[j], this.inEnv[j]]); //1
            if (this.devicesFound[j].devId == '265') count12.push([this.devicesFound[j], this.inEnv[j]]); //1
            if (this.devicesFound[j].devId == '772') count13.push([this.devicesFound[j], this.inEnv[j]]); //1
            if (this.devicesFound[j].devId == '774') count14.push([this.devicesFound[j], this.inEnv[j]]); //1
          }
          this.vectorTypes[0] = count0;
          this.vectorTypes[1] = count1;
          this.vectorTypes[2] = count2;
          this.vectorTypes[3] = count3;
          this.vectorTypes[4] = count4;
          this.vectorTypes[5] = count5;
          this.vectorTypes[6] = count6;
          this.vectorTypes[7] = count7;
          this.vectorTypes[8] = count8;
          this.vectorTypes[9] = count9;
          this.vectorTypes[10] = count10;
          this.vectorTypes[11] = count11;
          this.vectorTypes[12] = count12;
          this.vectorTypes[13] = count13;
          this.vectorTypes[14] = count14;
            this.modalRef = this.modalService.show(AlertComponent, { class: "modal-md" })
            this.modalRef.content.inputText1 = { show: true, type: "text", placeholder: this.jsonWord.placeholder.name, value: gateway.name }
            this.modalRef.content.inputText2 = { show: true, type: "text", placeholder: this.jsonWord.label.serialNumber, value: gateway.id, disabled: true }
            this.modalRef.content.offset = { show: true, type: "text", value: gateway.timezone.name, }
            this.modalRef.content.isDst = { show: true, type: "checkbox", value: gateway.timezone.isdst }
            this.modalRef.content.notification = { show: true, type: "checkbox", value: notification }
            this.modalRef.content.errorNot = { show: true, type: "checkbox", value: gateway.sendPushNotification.error }
            this.modalRef.content.eventNot = { show: true, type: "checkbox", value: gateway.sendPushNotification.event }
            this.modalRef.content.alertNot = { show: true, type: "checkbox", value: gateway.sendPushNotification.alert }
            this.modalRef.content.offsetOff = { show: true, type: "text", value: gateway.timezone.offset }
            this.modalRef.content.edit = true
            this.modalRef.content.isEdit={ show: true }
            this.modalRef.content.role = gateway.role
            this.modalRef.content.vectorTypes = this.vectorTypes
            this.modalRef.content.devicesFound = this.devicesFound
            this.modalRef.content.title = this.jsonWord.page.gatewayEdit//"Adicionar Gateway"
            this.modalRef.content.buttonSave = { show: true, text: this.jsonWord.button.save }
            this.modalRef.content.buttonConfirm = { show: false };
            this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel }
            this.modalRef.content.onClose.subscribe(res => {
              this.editGatewayByModal(gateway, res)
            });
          if(this.modalService.getModalsCount()){this.theresModal=true}else this.theresModal=false
        }, err => {
          if (err.status == 0 || err.status == 408) {
            this.modalRef = this.modalService.show(AlertComponent);
            this.modalRef.content.type = 'remove'
            this.modalRef.content.title = /*"<i class='fa fa-lg fa-times text-danger'> </i> " +*/ this.jsonWord.error.conectionError
            this.modalRef.content.message = this.jsonWord.error.noConnection
            this.modalRef.content.isDelete = false
            this.modalRef.content.typeB = 'refresh'
            //"Tempo limite de conexão com o servidor atingido. Verifique o status do servidor recarregue a página novamente."
            this.modalRef.content.buttonConfirm = { show: true, text: /*"<i class='fa fa-refresh'></i>" + '  ' + */this.jsonWord.label.reload }
            this.modalRef.content.onClose.subscribe(
              res => {
                if (res)
                  window.location.reload()
              }
            )
          }
        }
      );
    }
  }

  //topic.replace(/.*gateway\/(.{13,16})\/.*/, '$1')
  editGatewayByModal(gateway, res) {
    let gtwJson = {
      "type": gateway.type, //testar
      "name": res.name,
      "timezone": {
        "name": res.offset,
        "isdst": false,
        "offset": res.offsetOff
      },
      "sendPushNotification": {
        "error": res.notification.error,
        "alert": res.notification.alert,
        "event": res.notification.event,
        "notSendDevices": res.notification.notSendDevices
      }
    }
    this.gatewayService.updateGateway(gtwJson, gateway.id).subscribe(res => {
      this.openLoadingModal(this.jsonWord.loading.message)
      this.loadingModalRef.content.message = this.jsonWord.loading.message
      if (res) {
        this.loadingModalRef.content.message = this.jsonWord.success.gatewayEdit
        this.loadingModalRef.content.waiting = false
        this.loadingModalRef.content.success = true
        setTimeout(() => {
          if (this.loadingModalRef.content.success)
            this.loadingModalRef.hide()
          this.updateAll()
        }, 2000)

      }
    },
      err => {
        let message = ''
        switch (err.status) {
          case 0:
            message = this.jsonWord.error.noConnection;//'Campos de email ou senha inválidos.'
            //  this.noInternetAlert()
            break
          case 400:
            message = this.jsonWord.error.gatewayInvalidFormat//'Formulário de criação de gateway inválido.'
            break
          case 401:
            message = this.jsonWord.error.notAuthorized//'Você não tem permissão para adicionar um gateway'
            break
          case 404:
            message = this.jsonWord.error.gatewayNotFound//'Usuário não encontrado ou número serial de gateway não registrado.'
            break
          case 408:
            message = this.jsonWord.error.timeoutServer//'Usuário não encontrado ou número serial de gateway não registrado.'
            break
          case 409:
            message = this.jsonWord.toast.serialGtwAlreadyExists//'Já existe um gateway registrado com esse serial.'
            break
          case 412:
            message = this.jsonWord.toast.limitUserGatewayExceeded//'Usuário não pode se vincular a mais gateways.'
            break
          case 500:
            message = this.jsonWord.toast.gatewayCommunicationError//'Não foi possível adicionar gateway.'
            break
          default:
            message = this.jsonWord.error.gatewayEdit
        }
        this.loadingModalRef.content.message = message//this.jsonWord.error.gatewayEdit
        this.loadingModalRef.content.waiting = false
        this.loadingModalRef.content.success = false
        setTimeout(() => {
          if (!this.loadingModalRef.content.success)
            this.loadingModalRef.hide()
        }, 2000)
      });
  }

  
  openInputAlert() {
    this.modalRef = this.modalService.show(AlertComponent, { class: "modal-md" })
    this.modalRef.content.inputText1 = { show: true, type: "text", placeholder: this.jsonWord.placeholder.name }
    this.modalRef.content.inputText2 = { show: true, type: "text", placeholder: this.jsonWord.label.serialNumber }
    this.modalRef.content.offset = { show: true, type: "text" }
    this.modalRef.content.offsetOff = { show: true, type: "text" }
    this.modalRef.content.isDst = { show: true, type: "checkbox" }
    this.modalRef.content.role = 'ADMIN'
    this.modalRef.content.notification = { show: true, type: "checkbox", value: true }
    this.modalRef.content.errorNot = { show: true, type: "checkbox", value: true }
    this.modalRef.content.eventNot = { show: true, type: "checkbox", value: true }
    this.modalRef.content.alertNot = { show: true, type: "checkbox", value: true }
    this.modalRef.content.title = this.jsonWord.page.gatewayAdd//"Adicionar Gateway"
    this.modalRef.content.buttonSave = { show: true, text: this.jsonWord.button.save }
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel }
  }
  addGateway() {
    this.openInputAlert()
    this.modalRef.content.onClose
      .concatMap(
        res => {
          if (res) {//: resposta.notification
            let resposta = (res)
            let data = {
              "type": "GATEWAY", //testar
              "name": resposta.name,
              "serialNumber": resposta.serial,
              "isOnline": true,
              "timezone": {
                "name": resposta.offset,
                "offset": resposta.offsetOff,
                "isdst": false
              },
              "sendPushNotification": {
                "error": resposta.notification.error,
                "alert": resposta.notification.alert,
                "event": resposta.notification.event,
             //   "notSendDevices": resposta.notification.notSendDevices
              }
            }
            this.openLoadingModal(this.jsonWord.toast.addingGateway)//'Adicionando gateway...')
            return this._gatewayService.addGateway(data)
          }
          else
            return ErrorObservable.create('Reset canceled by user.')
        })
      .map(
        res => {
          this.loadingModalRef.content.message = this.jsonWord.success.gatewayRegistration//"Gateway adicionado com sucesso."
          this.loadingModalRef.content.success = true
          this.loadingModalRef.content.waiting = false
          return res
        }
      )
      .takeUntil(this.ngUnsubscribe)
      .subscribe(res => {
        let timer = setTimeout(() => {
          // this.loadingModalRef.hide()
          //this.ngOnInit()
          this.loadingModalRef.hide()
          // this._gatewayService.gatewayObservable.next(res)
          // localStorage.setItem("currentGateway",data['serialNumber'])
          //  this._gatewayService.setGateway(res)
          this.router.navigateByUrl('/home/panel')
          this.homeComponent.ngOnInit()
        }, 1500)
      },
        err => {
          let message = ''
          switch (err.status) {
            case 0:
              message = this.jsonWord.error.noConnection;//'Campos de email ou senha inválidos.'
              //  this.noInternetAlert()
              break
            case 400:
              message = this.jsonWord.error.gatewayInvalidFormat//'Formulário de criação de gateway inválido.'
              break
            case 401:
              message = this.jsonWord.error.notAuthorized//'Você não tem permissão para adicionar um gateway'
              break
            case 404:
              message = this.jsonWord.error.gatewayNotFound//'Usuário não encontrado ou número serial de gateway não registrado.'
              break
            case 408:
              message = this.jsonWord.error.timeoutServer//'Usuário não encontrado ou número serial de gateway não registrado.'
              break
            case 409:
              message = this.jsonWord.toast.serialGtwAlreadyExists//'Já existe um gateway registrado com esse serial.'
              break
            case 412:
              message = this.jsonWord.toast.limitUserGatewayExceeded//'Usuário não pode se vincular a mais gateways.'
              break
            case 500:
              message = this.jsonWord.toast.gatewayCommunicationError//'Não foi possível adicionar gateway.'
              break
            default:
              message = this.jsonWord.error.failOperation
          }
          this.loadingModalRef.content.message = this.jsonWord.error.error + ": " + message
          this.loadingModalRef.content.success = false
          this.loadingModalRef.content.waiting = false
          //this.openErrorDialog(err.message)
          setTimeout(() => {
            if (!this.loadingModalRef.content.success)
              this.loadingModalRef.hide()
          }, 2000)
        });
  }
  

}

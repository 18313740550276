import { ColorhueScreenComponent } from "./../../_directives/colorhue-screen/colorhue-screen.component";
import { GroupService } from "app/_services/group.service";
import { EnvironmentService } from "./../../_services/environment.service";
import { DeviceCardModalComponent } from "./../../_directives/device-card-modal/device-card-modal.component";
import { ResponseService } from "./../../_services/response.service";
import { TranslateJsonService } from "./../../_services/translate-json.service";
import { UserService } from "./../../_services/user.service";
import { DeviceService } from "./../../_services/device.service";
import {
  Component,
  OnInit,
  Input,
  Output,
  OnDestroy,
  EventEmitter,
  ChangeDetectorRef,
  SimpleChanges,
  SimpleChange
} from "@angular/core";
import { Card } from "./../../models/card.model";
import { Device } from "./../../models/device.model";
import { MqttService, IMqttMessage } from "ngx-mqtt";
import * as config from "../../config";
import { AlertComponent } from "../../_directives/alert.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { v4 as uuid } from "uuid";
import * as moment from "moment";
import { Router, ActivatedRoute, NavigationStart } from "@angular/router";
import { DevicesModalComponent } from "../../_directives/devices-modal/devices-modal.component";
import { PanelComponent } from "../panel.component";
import { LoadingModalComponent } from "../../_directives/loading-modal/loading-modal.component";
import { Subscription, Subject, of, NEVER, Observable, empty } from "rxjs";
import "rxjs/add/operator/filter";
import "rxjs/add/operator/take";
import "rxjs/add/operator/catch";
import {
  timeout,
  catchError,
  finalize,
  timeoutWith,
  onErrorResumeNext
} from "rxjs/operators";
import { HomeComponent } from "../../home/home.component";
import { SmartlockModalComponent } from "../../_directives/smartlock-modal/smartlock-modal.component";
import { ErrorObservable } from "rxjs/observable/ErrorObservable";
import { Group } from "app/models/grouping.model";
import { GroupModalComponent } from "app/_directives/group-modal/group-modal.component";

const SMARTLOCK_TIMEOUT = 16000;
const COMMAND_TIMEOUT = 5000;
const BIND_TIMEOUT = 10000;
const CONFIG_TIMEOUT = 10000;
const utf8Decoder = new TextDecoder("utf-8");

@Component({
  selector: "app-group",
  templateUrl: "./group.component.html",
  styleUrls: ["./group.component.css", "./csshake.css"]
})
export class GroupComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  public modalRef: BsModalRef;
  public loadingModalRef: BsModalRef;
  private subscription: Subscription;
  private deviceTopic;
  private deviceObserver;
  private deviceStatusObserver;
  public message: any;
  public loading = false;
  @Input() dragDropActive;
  @Input() groupsToShow;
  @Input() group;
  @Input() pathNameDev;
  @Input() isLast: boolean;
  @Input() devsToBind: Array<any>;
  @Output() devFeedback = new EventEmitter();
  @Input() deviceToGroup;
  devicesExist;
  devInGroup = [];
  card: Card;
  backgroundColor = "#d5d5d5";
  textColor = "#ffffff";
  flipCard = true;
  cardFlipTimer: any;
  whiteLight;
  currentGateway;
  uuid = require("uuid/v4");
  deviceTopicSync;
  devsSyncObserver;
  currentUserToken;
  userId;
  public correlId: string;
  public waiting: Boolean = true;
  public success: Boolean; //= true;
  public currentUser: any;
  public buttomBlocked: Boolean = true;
  currentUserId;
  currentUserType;
  isActive = false;
  count = 0;
  sendValue;
  isOffline;
  toFixedNumber = Math.round;
  groupArray = [];
  @Input() busca: string;
  @Output() buscaChange: EventEmitter<string> = new EventEmitter<string>();
  private termosDaBusca: Subject<any> = new Subject<any>();
  public language;
  public jsonWord;
  constructor(
    public panelComponent: PanelComponent,
    public homeComponent: HomeComponent,
    private _mqttService: MqttService,
    private modalService: BsModalService,
    private groupService: GroupService,
    private translateJson: TranslateJsonService,
    private _deviceService: DeviceService
  ) {}

  ngOnInit() {
    this.language = localStorage.getItem("language");
    this.jsonWord = this.translateJson.changeLanguage(this.language);
    /* if((this.card.isActive && this.card.invertOnOff) || (!this.card.isActive && this.card.invertOnOff)){
       this.card.isActive = !this.card.isActive
     }*/
    //this.card.isActive = (this.card.isActive && !this.card.invertOnOff) || (!this.card.isActive && this.card.invertOnOff)
    this.groupArray = this.group;
    this.group = new Group(this.group);
    //verifica o browse
    var isSafari = /^((?!chrome|android).)*firefox/i.test(navigator.userAgent);
    this.currentUserType = this.homeComponent.currentUserType;
    this.currentGateway = this.panelComponent.currentGateway;
    this.currentUser = JSON.parse(localStorage.getItem("currentUser")).email;
    this.currentUserId = localStorage.getItem("currentUserId");
    this.deviceTopic = `${config.gtwTopic}${this.currentGateway}/device/${this.group.devices[0]}/`;
    this.deviceTopicSync = `ecomfort/iot/v1/sync/gateway/${this.currentGateway}/user/#`;

    this.deviceObserver = this._mqttService
      .observe(this.deviceTopic)
      .map((IMQTTMessage: IMqttMessage) => {
        return JSON.parse(
          new TextDecoder("utf-8").decode(IMQTTMessage.payload)
        );
      });

    this.devsSyncObserver = this._mqttService
      .observe(this.deviceTopicSync)
      .map((IMQTTMessage: IMqttMessage) => {
        return JSON.parse(
          new TextDecoder("utf-8").decode(IMQTTMessage.payload)
        );
      });

    this.deviceStatusObserver = this._mqttService
      .observe(`${this.deviceTopic}status`)
      .map((IMQTTMessage: IMqttMessage) => {
        return JSON.parse(
          new TextDecoder("utf-8").decode(IMQTTMessage.payload)
        );
      });

    this.currentUserToken == localStorage.getItem("currentUserToken");

    this.waiting = false;

    // General listener to all events from unknown source
    this.panelComponent.devicesObserver
      // .filter(payload => payload.data && payload.data.ieeeAddr == this.card.devIeeeAddr)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(payload => {
        this.message = payload;
        let op = payload.operation;
        if (op === "attReport") {
          if (this.devicesExist) this.messageDispatcher(this.message.data);
          let devs = [],
            devdev = [],
            devChegou = [];
          let devices = this.groupArray["devices"];

          devs = devices.find(dev => dev != this.message.data.ieeeAddr);
          devChegou = devices.find(dev => dev == this.message.data.ieeeAddr);
          if (devs && devChegou) {
            // devChegou['isActive'] = this.message.data.value ==1 ? true: false
            for (let i = 0; i < devices.length; i++) {
              let index = this.devicesExist.findIndex(
                dev => dev._id == devices[i]
              );
              if (index > -1) {
                devdev.push(this.devicesExist[index]);
              }
            }

            let index1 = devdev.findIndex(
              dev => dev._id == this.message.data.ieeeAddr
            );
            let devToEdit = devdev[index1];
            devToEdit = new Device(devToEdit);
            devToEdit.isActive = this.message.data.value == 1 ? true : false;
            //this.isroupActive(devs, this.groupArray['devices'])
            let newDev = this.devicesExist.findIndex(
              dev => dev._id == devToEdit._id
            );
            this.devicesExist[newDev] = devToEdit;
            let devdev2 = [];
            for (let i = 0; i < devices.length; i++) {
              let index = this.devicesExist.findIndex(
                dev => dev._id == devices[i]
              );
              if (index > -1) {
                devdev2.push(this.devicesExist[index]);
              }
            }
            let count = devdev2.find(dev => dev.isActive);
            this.isActive = count ? true : false;
          }
        }
        //Operações de delete e add ficam a cargo do pai (panel)
        else if (op === "delete") {
          this.devFeedback.emit({ op, device: this.group });
        } else if (op === "edit") {
          this.devFeedback.emit({ op, device: this.group });
        }
      });
    this._deviceService
      .listDevices(this.currentGateway)
      .pipe(finalize(() => {}))
      .takeUntil(this.ngUnsubscribe)
      .subscribe((deviceList: Array<any>) => {
        this.devicesExist = deviceList;
        let group = this.group.devices;
        this.isGroupActive(deviceList, group);
      });
    /*   this.devsSyncObserver
         .takeUntil(this.ngUnsubscribe)
         .subscribe(payload => {
           this.message = payload
           let op = payload
           if (payload.blockedDevices && payload.blockedDevices == this.card.devIeeeAddr) {
             this.card.blocked = true
           }
           if (payload.unblockedDevices && payload.unblockedDevices == this.card.devIeeeAddr) {
             this.card.blocked = false
           }
         })
       this.doorState = this.card.value1;
       console.log(this.card.value3)
       this.whiteLight = this.card.value3;*/
  }

  isGroupActive(device, group) {
    let count = 0;
    let devInGroup = [];
    for (let i = 0; i < group.length; i++) {
      let index = this.devicesExist.findIndex(dev => dev._id == group[i]);
      if (index > -1) {
        devInGroup.push(this.devicesExist[index]);
      }
    }
    count = devInGroup.find(dev => dev.isActive);
    let isOff = devInGroup.find(dev => dev.status == "offline");
    count;
    if (isOff) {
      this.isOffline = true;
    } else {
      this.isOffline = false;
      this.isActive = count ? true : false;
    }

    /*  let isInGroup = this.group.devices.filter(dev => dev == device.ieeeAddr && device.isActive)
      if (isInGroup) { this.group.isActive = true } else this.group.isActive = false
      /*for (let i = 0; i < this.group.devices.length; i++) {
        if (dev.ieeeAddr == this.group.devices[i]) {
          if (dev.isActive) {
            this.group.isActive = true
            count++
          }
          if (count < 1) this.group.isActive = false
        }
      }*/
  }
  removeDevice() {
    this.devFeedback.emit({ operation: "delete", devId: this.group.devId });
  }

  feedBackCards(event) {}

  messageDispatcher(message) {
    for (let i = 0; i < this.devicesExist.length; i++) {
      switch (message.cId) {
        case "genOnOff":
          if (message.type === "onOff") {
            let status: boolean;
            status = message.value == 1 ? true : false;
            // this.isActive = status//this.devicesExist[i].invertOnOff ? status : !status;
            this.waiting = false;
            this.success = true;
          }
          break;
        case "genLevelCtrl":
          if (message.type === "currentLevel") {
            let value;
            value = message.value;
            this.devicesExist[i].value1 = value.toFixed(1);
          }
          break;
        case "lightingColorCtrl":
          if (message.type === "currentHue") {
            let value;
            value = message.value;
            this.devicesExist[i].value2 = value.toFixed(1);
          } else if (message.type === "currentSaturation") {
            let value;
            value = message.value;
            if (value > 1) {
              this.whiteLight = false;
              this.devicesExist[i].value3 = false;
            } else if (value <= 1) {
              this.whiteLight = true;
              this.devicesExist[i].value3 = true;
            }
          }
          break;
      }
      this.sendValue = {
        operation: "change",
        devId: this.group.devId,
        value: message.value
      };
    }
  }
  sendMQTTOnOffCommand(card) {
    if (!this.dragDropActive) {
      this.waiting = true;
      let message;
      for (let i = 0; i < card.devices.length; i++) {
        message = {
          messageId: uuid(),
          user: this.currentUser,
          timestamp: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          epId: [1],
          cId: "genOnOff",
          cmd: "toggle",
          zclData: {}
        };
        if (card.isActive && !card.blocked) {
          message = {
            messageId: uuid(),
            timestamp: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            epId: [1],
            user: this.currentUser,
            cId: "genOnOff",
            cmd: "off",
            zclData: {}
          };
        } else if (!card.isActive && !card.blocked) {
          message = {
            messageId: uuid(),
            timestamp: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            epId: [1],
            user: this.currentUser,
            cId: "genOnOff",
            cmd: "on",
            zclData: {}
          };
        }
        const topic = `${config.gtwTopic}${this.currentGateway}/device/${card.devices[i]}/command`;
        this.unsafePublish(topic, JSON.stringify(message));
      }

      this.group.isActive = !this.group.isActive;
      this.isActive = !this.isActive;
      //caso haja erro
      this.subscription = this.homeComponent.gatewayErrorObserver
        .filter(msg => this.correlId == msg["correlId"])
        .map(message => {
          this.success = false;
          return message;
        })
        .merge(
          this.homeComponent.gatewaySuccessObserver
            .filter(msg => this.correlId == msg["correlId"])
            .map(message => {
              this.success = true;
              this.buttomBlocked = true;
              return message;
            })
        )
        .take(1)
        .pipe(
          finalize(() => {
            this.waiting = false;
          }),
          timeoutWith(
            COMMAND_TIMEOUT,
            ErrorObservable.throw(
              new Response(null, {
                status: 408,
                statusText: "Tempo limite de resposta atingido"
              })
            )
          )
        )
        .subscribe(
          _ => {
            this.correlId = null;
          },
          err => {
            this.homeComponent.gatewayErrorToast(err);
          }
        );
    }
  }

  public unsafePublish(topic: string, message: string) {
    this.correlId = JSON.parse(message).messageId;
    this._mqttService.unsafePublish(topic, message, { qos: 1, retain: false });
  }

  rotateCard() {
    this.flipCard = !this.flipCard;
    if (this.flipCard) {
      clearTimeout(this.cardFlipTimer);
    }
    if (!this.flipCard) {
      this.cardFlipTimer = setTimeout(() => {
        this.rotateCard();
      }, 60000);
    }
  }

  getDimmerSliderValue() {
    //return this.card.value1
    //return this.card.attributes['genLevelCtrl#currentLevel'];
  }

  getColorSliderValue() {
    //   return this.card.value2//this.card.attributes['lightingColorCtrl#currentHue'];
  }

  colorScreenModal(card) {
    //this.closeAllModals()
    //  setTimeout(() => {
    this.modalRef = this.modalService.show(ColorhueScreenComponent);
    this.modalRef.content.card = card;
    this.modalRef.content.pixelData = this.hslToRgb(
      card.value1 / 255,
      card.value2 / 255,
      1
    );
    this.modalRef.content.value1 = card.value1;
    this.modalRef.content.value2 = card.value2;
    this.modalRef.content.onClose.subscribe(res => {
      //this.editDeviceModal(card)
    });
    //  }, 1000);
    //  this.modalRef.content.wheel = this.showWheel()
  }
  hslToRgb(h, s, l) {
    var r, g, b;

    if (s == 0) {
      r = g = b = l; // achromatic
    } else {
      var hue2rgb = function hue2rgb(p, q, t) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      var p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }
    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  }
  onSmartlightWhiteColorChecked(card) {
    this.whiteLight = !this.whiteLight;
    for (let i = 0; i < card.devices.length; i++) {
      let command: any;
      if (this.whiteLight) {
        command = {
          messageId: uuid(),
          timestamp: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          ieeeAddr: card.devices[i],
          epId: [1], //verificar o eplist ao enviar o comando mqtt
          user: this.currentUser,
          cId: "lightingColorCtrl",
          cmd: "moveToSaturation",
          zclData: { saturation: 0, transtime: 0 }
        };
      } else if (!this.whiteLight) {
        command = {
          messageId: uuid(),
          timestamp: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          ieeeAddr: card.devices[i],
          epId: [1], //verificar o eplist ao enviar o comando mqtt
          user: this.currentUser,
          cId: "lightingColorCtrl",
          cmd: "moveToSaturation",
          zclData: { saturation: 254, transtime: 0 }
        };
      }
      const topic = `${config.gtwTopic}${this.currentGateway}/device/${card.devices[i]}/command`;
      this.unsafePublish(topic, JSON.stringify(command));
    }
  }

  onSmartlightColorChange(card, value) {
    this.whiteLight = false;
    for (let i = 0; i < card.devices.length; i++) {
      const command = {
        messageId: uuid(),
        timestamp: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        ieeeAddr: card.devices[i],
        epId: [1], //verificar o eplist ao enviar o comando mqtt
        user: this.currentUser,
        cId: "lightingColorCtrl",
        cmd: "moveToHueAndSaturation",
        zclData: { hue: value, saturation: 254, transtime: 0 }
      };
      const topic = `${config.gtwTopic}${this.currentGateway}/device/${card.devices[i]}/command`;
      this.unsafePublish(topic, JSON.stringify(command));
    }
  }

  onSmartlightLevelChange(card, value) {
    for (let i = 0; i < card.devices.length; i++) {
      const command = {
        messageId: uuid(),
        timestamp: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        ieeeAddr: card.devices[i],
        epId: [1], //verificar o eplist ao enviar o comando mqtt
        user: this.currentUser,
        cId: "genLevelCtrl",
        cmd: "moveToLevel",
        zclData: { level: value, transtime: 0 }
      };
      const topic = `${config.gtwTopic}${this.currentGateway}/device/${card.devices[i]}/command`;
      this.unsafePublish(topic, JSON.stringify(command));
    }
  }
  showArray() {
    var txt = "";
    for (let dev in this.devsToBind) {
      txt += this.devsToBind[dev].name + "," + " ";
    }
    document.getElementById("p1").innerHTML = txt;
  }

  editGroupModal(card) {
    //this.cardToBeEdited = card
    let devs = card.devices;
    let devicesToEdit = this.deviceToGroup.filter(
      dev => devs.filter(number => number == dev.ieeeAddr).length
    );
    this.modalRef = this.modalService.show(GroupModalComponent);
    this.modalRef.content.buttonCancel = {
      show: true,
      text: this.jsonWord.button.cancel
    };
    this.modalRef.content.buttonConfirm = {
      show: true,
      text: this.jsonWord.button.save
    };
    // this.modalRef.content.whiteLight = card.value3;
    this.modalRef.content.buttonRemove = {
      show: true,
      text: this.jsonWord.button.removeGroup
    };
    this.modalRef.content.inputText1 = {
      show: true,
      type: "text",
      placeholder: card.ieeeAddr,
      value: card._id
    };
    this.modalRef.content.inputText2 = {
      show: true,
      type: "text",
      placeholder: this.jsonWord.label.name,
      value: card.name
    };
    this.modalRef.content.inputLevelLum = { show: true, type: "text" };
    this.modalRef.content.inputLevel = { show: true, type: "text" };
    this.modalRef.content.inputHue = { show: true, type: "text" };
    this.modalRef.content.card = card;
    this.devInGroup = card;
    this.modalRef.content.devicesToEdit = devicesToEdit;
    this.modalRef.content.groups = this.groupsToShow;
    this.modalRef.content.title = card.name; //'Vincular Dispositivos';
  }

  editDevice(card) {
    this.editGroupModal(card);
    this.modalRef.content.onClose.subscribe(res => {
      if (res) {
        let data = [
          {
            op: "replace",
            path: "/name/",
            value: {
              name: this.modalRef.content.inputText2.value
            }
          }
        ];
        this.openLoadingModal(this.jsonWord.loading.message);
        this.groupService.updateGroup(card._id, data).subscribe(
          res => {
            if (res) {
              this.loadingModalRef.content.success = true;
              this.loadingModalRef.content.waiting = false;
              this.loadingModalRef.content.message = this.jsonWord.toast.editDeviceSucess;
              setTimeout(() => {
                this.closeAllModals();
              }, 2000);
            }
          },
          err => {
            this.loadingModalRef.content.success = false;
            this.loadingModalRef.content.waiting = false;
            this.loadingModalRef.content.message = this.jsonWord.toast.errorEditDevice;
            setTimeout(() => {
              this.closeAllModals();
            }, 2000);
          }
        );
      }
    });
  }

  openLoadingModal(message: String) {
    this.loadingModalRef = this.modalService.show(LoadingModalComponent, {
      class: "waiting-modal modal-sm",
      keyboard: false,
      backdrop: "static"
    });
    this.loadingModalRef.content.message = message;
  }

  private closeAllModals() {
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

import { TranslateJsonService } from './../../_services/translate-json.service';
import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../_services/user.service';
import { LoadingModalComponent } from '../loading-modal/loading-modal.component';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoginService } from '../../login/login.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  private email: string
  public passwordForm: FormGroup
  public onClose: Subject<boolean>;
  language;
  jsonWord;
  private profileForm;

  @HostListener('window:keyup', ['$event'])
  enterKeyUpEvent(e) {
  }

  constructor(
    private modalService: BsModalService,
    private thisModalRef: BsModalRef,
    private loadingModalRef: BsModalRef,
    public translateJson: TranslateJsonService,
    private fb: FormBuilder,
    private userService: UserService,
    private loginService: LoginService
  ) {
    this.passwordForm = fb.group({
      password: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(10),
      ]],
      newPassword: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(10),
      ]],
      passwordConf: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(10),
      ]],
    })
  }

  public ngOnInit(): void {
    this.onClose = new Subject();
    this.language = localStorage.getItem('language')
    this.jsonWord = this.translateJson.changeLanguage(this.language)

    this.passwordForm.get('password').valueChanges.subscribe(
      (value) => this.matchFields('newPassword', 'passwordConf'))
    this.passwordForm.get('passwordConf').valueChanges.subscribe(
      (value) => this.matchFields('newPassword', 'passwordConf'))
  }

  matchFields(field1, field2) {
    let field1Control = this.passwordForm.get(field1)
    let field2Control = this.passwordForm.get(field2)
    if (field1Control.value !== field2Control.value) {
      //field1Control.setErrors({ 'matchOther': true })
      field2Control.setErrors({ 'matchOther': true })
    } else {
      if (field2Control.errors && field2Control.errors['matchOther'])
        delete field2Control.errors['matchOther']
    }
  }

  public submit(passwordForm: any): void {
    delete passwordForm.passwordConf
    Object.assign(this.profileForm, passwordForm)

    var data = this.profileForm
    delete data.passwordConf
    delete data.emailConf
    data.buildingNumber = Number(data.buildingNumber)

    Object.keys(data).forEach(prop => {
      if (!data[prop] || data[prop] == '') delete data[prop]
    })

    this.userService.updateUser(localStorage.getItem('currentUserId'), data)
      .map(res => {
        this.loadingModalRef.content.message = this.jsonWord.toast.passwordEdited
        this.loadingModalRef.content.success = true
        this.loadingModalRef.content.waiting = false
        let timer = setTimeout(() => {
          this.loadingModalRef.hide()
          this.thisModalRef.hide()
        }, 2000)
      }).merge(this.openLoadingModal(this.jsonWord.loading.savingProfile))
      .subscribe(null,
        err => {
          this.loadingModalRef.content.waiting = false
          let message = ''
          switch (err.status) {
            case 0:
              message = this.jsonWord.error.noConnection;//'Campos de email ou senha inválidos.'
              //  this.noInternetAlert()
              break
            case 400:
              message = this.jsonWord.error.gatewayInvalidFormat
              break
            case 401:
              message = this.jsonWord.error.invalidPass
              break
            case 404:
              message = this.jsonWord.error.userNotFound
              break
            case 408:
              message = this.jsonWord.error.timeoutServer;//'Tempo limite de conexão atingido.'
              break
            case 409:
              message = this.jsonWord.error.userAlreadyInGtw
              break
            case 500:
              message = this.jsonWord.error.errorEditUser
              break
            default:
              message = err._body
          }
          this.loadingModalRef.content.message = this.jsonWord.error.error + " " + message
          this.loadingModalRef.content.success = false
          let timer = setTimeout(() => {
            this.loadingModalRef.hide()
          }, 2000)
        })
  }

  openLoadingModal(message) {
    this.loadingModalRef = this.modalService.show(
      LoadingModalComponent,
      {
        keyboard: false,
        backdrop: 'static',
        class: 'waiting-modal modal-sm'
      })
    this.loadingModalRef.content.message = message;
    return this.loadingModalRef.content.onClose as Observable<any>
  }

  fieldHasErrors(field) {
    let control = this.passwordForm.get(field)
    if (control.errors && Object.keys(control.errors).length && control.invalid && control.touched && control.dirty)
      return "has-errors"
    return ""
  }

  public onConfirm(): void {
    this.onClose.next(true);
    this.thisModalRef.hide();
  }

  public onCancel(): void {
    // this.onClose.next(false);
    this.thisModalRef.hide();
  }

}

import { ImageCroppedEvent, ImageCropperComponent, } from 'ngx-image-cropper';
import { Component, OnInit, HostListener, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { LoginService } from '../login.service';
import { LoadingModalComponent } from '../../_directives/loading-modal/loading-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { CameraModalComponent } from '../../_directives/camera-modal/camera-modal.component';
import { MatchFieldsValidatorDirective } from './match-fields-validator.directive';
import { WebcamUtil } from 'ngx-webcam';
import { EULA } from './term.js';
import { AlertComponent } from '../../_directives/alert.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from '../../_services/user.service';
import { HttpClient } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { TranslateJsonService } from '../../_services/translate-json.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  public language;
  public jsonWord;
  public registerForm: FormGroup
  public modalRef: BsModalRef
  public webcamsAvailable: boolean
  public isInviteConfirm: boolean
  public gettingAddress: boolean = false
  private confirmToken //quando for registrar usuário convidado
  private userId //quando for registrar usuário convidado
  public today = new Date()


  imageChangedEvent: any = '';
  croppedImage: any = '';
  isCropped:Boolean=false

  keys = Object.keys

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private modalService: BsModalService,
    private router: Router,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private translateJson: TranslateJsonService,
    public jwtHelper: JwtHelperService,
    public userService: UserService,
    private http: HttpClient
  ) {
    renderer.setStyle(
      document.getElementsByTagName('body')[0],
      'background-image',
      'url(/assets/img/bg/3.jpg)'
      //'url(/assets/img/bg/xmas.png)'
    )

    this.registerForm = fb.group({
      name: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(60),
        Validators.pattern(/^(([a-zA-Z\u00C0-\u00FF][\s\w\u00C0-\u00FF]{0,59})|([A-Za-z0-9._-]{2,})(@[A-Za-z0-9_-]{2,})(\.[A-Za-z]{2,6})+)$/)
      ]],
      lastName: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(60),
        Validators.pattern(/^[a-zA-Z\u00C0-\u00FF][\s\w\u00C0-\u00FF]{0,59}$/)
      ]],
      email: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(60),
        Validators.pattern(new RegExp(/^([A-Za-z0-9._-]{2,})(@[A-Za-z0-9_-]{2,})(\.[A-Za-z]{2,26})+$/)),
        // Validators.pattern(/^([A-Za-z0-9._-]{2,})(@[A-Za-z0-9_-]{2,})(\.[A-Za-z]{2,})(\.[A-Za-z]{2,6})+$/),
      ]],
      emailConf: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(60),
        Validators.pattern(new RegExp(/^([A-Za-z0-9._-]{2,})(@[A-Za-z0-9_-]{2,})(\.[A-Za-z]{2,26})+$/)),
        // Validators.pattern(/^([A-Za-z0-9._-]{2,})(@[A-Za-z0-9_-]{2,})(\.[A-Za-z]{2,})(\.[A-Za-z]{2,6})+$/),
      ]],
      cellPhoneNumber: ['', [
        Validators.required,
        Validators.pattern(/^(\(1[1-9]\)\s?(?:7|9\d)\d{3}-\d{4}|(?:\(2[12478]\)|\(3[1-578]\)|\([4689][1-9]\)|\(5[13-5]\)|\(7[13-579]\))\s?(?:[6-8]|9\d?)\d{3}-\d{4})$/)
      ]],
      phoneNumber: ['', [
        Validators.pattern(/^((?:\([14689][1-9]\)|\(2[12478]\)|\(3[1-578]\)|\(5[13-5]\)|\(7[13-579]\))\s?[2-5]\d{3}-\d{4})?$/)
      ]],
      address: ['', [
        Validators.minLength(2),
        Validators.maxLength(60),
        Validators.pattern(/^([\u0021-\u007e\u00a0-\u00ff][\u0020-\u007e\u00a0-\u00ff]{0,59})?$/)
      ]],
      buildingNumber: ['', [
        Validators.minLength(2),
        Validators.maxLength(60),
        Validators.pattern(/^(\d){0,5}?$/)
      ]],
      complement: ['', [
        Validators.minLength(2),
        Validators.maxLength(60),
        Validators.pattern(/^([\u0021-\u007e\u00a0-\u00ff][\u0020-\u007e\u00a0-\u00ff]{0,59})?$/)
      ]],
      neighborhood: ['', [
        Validators.minLength(2),
        Validators.maxLength(60),
        Validators.pattern(/^([\u0021-\u007e\u00a0-\u00ff][\u0020-\u007e\u00a0-\u00ff]{0,59})?$/)
      ]],
      zipCode: ['', [
        Validators.minLength(9),
        Validators.maxLength(9),
        Validators.pattern(/^(\d{5}-\d{3})?$/)
      ]],
      city: ['', [
        Validators.minLength(2),
        Validators.maxLength(60),
        Validators.pattern(/^([\u0021-\u007e\u00a0-\u00ff][\u0020-\u007e\u00a0-\u00ff]{0,59})?$/)
      ]],
      state: ['', [
        Validators.minLength(2),
        Validators.maxLength(2),
      ]],
      password: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(30),
      ]],
      passwordConf: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(30),
      ]],
      profileImage: '',
    },
    )
    if (route.snapshot.params && route.snapshot.params['_token']) {
      this.confirmToken = route.snapshot.params['_token']
      this.isInviteConfirm = true
      this.userId = this.jwtHelper.decodeToken(this.confirmToken)._id;
      localStorage.setItem('currentUserToken', this.confirmToken)
      userService.getUserByID(this.userId).subscribe(user => {
        this.registerForm.get('email').setValue(user['email'])
        localStorage.removeItem('currentUserToken')
      })
    }
  }

  ngOnInit() {
    this.language = localStorage.getItem('language') ? localStorage.getItem('language') : 'pt';
    this.jsonWord = this.translateJson.changeLanguage(this.language);

    (new Date()).getMonth()
    this.registerForm.get('password').valueChanges.subscribe(
      (value) => this.matchFields('password', 'passwordConf'))
    this.registerForm.get('passwordConf').valueChanges.subscribe(
      (value) => this.matchFields('password', 'passwordConf'))

    if (this.isInviteConfirm) {
      this.registerForm.removeControl('emailConf')
      this.registerForm.get('email').clearValidators()
    } else {
      this.registerForm.get('email').valueChanges.subscribe(
        (value) => this.matchFields('email', 'emailConf'))
      this.registerForm.get('emailConf').valueChanges.subscribe(
        (value) => this.matchFields('email', 'emailConf'))
    }
    this.croppedImage ='/assets/img/dashboard/icons/camera.svg'
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.webcamsAvailable = mediaDevices && mediaDevices.length > 0;
      });
  }

  matchFields(field1, field2) {
    let field1Control = this.registerForm.get(field1)
    let field2Control = this.registerForm.get(field2)
    if (field1Control.value !== field2Control.value) {
      //field1Control.setErrors({ 'matchOther': true })
      field2Control.setErrors({ 'matchOther': true })
    } else {
      if (field2Control.errors && field2Control.errors['matchOther'])
        delete field2Control.errors['matchOther']
    }
  }

  submit() {
    var data = this.registerForm.value
    delete data.passwordConf
    delete data.emailConf
    data.buildingNumber = Number(data.buildingNumber)

    Object.keys(data).forEach(prop => {
      if (data[prop] == '') delete data[prop]
    })

    if (this.registerForm.invalid) {
      (Object.keys(this.registerForm.controls)).forEach(key => {
        let control = this.registerForm.get(key)
        control.markAsPending()
        control.markAsDirty()
        control.markAsTouched()
        control.updateValueAndValidity()
      });
      return
    }

   //this.openTermDialog(EULA)
    this.openTermDialog(this.jsonWord.alert.messageTermsAndConditions)
    this.modalRef.content.onClose.concatMap(
      res => {
        if (res) {
          this.openLoadingModal(this.jsonWord.loading.message)//'Cadastrando usuário...')

          if (this.isInviteConfirm) {
            localStorage.setItem('currentUserToken', this.confirmToken)
            var registerRequest = this.userService.updateUser(this.userId, data, true)
          } else
            var registerRequest = this.loginService.register(data)

          return registerRequest
        } else
          return ErrorObservable.create('Registry canceled by user.')
      }).map(
        res => {
          this.modalRef.content.message = this.jsonWord.alert.titleToCreateAccount + //"Usuário cadastrado com sucesso. " +
            (!this.isInviteConfirm ? this.jsonWord.alert.successToCreateAccount : "")//"Um e-mail com um link para confirmação da sua conta foi enviado para o seu e-mail." : "") +
           // "Redirecionando para a página de login..."
          this.modalRef.content.waiting = false
          this.modalRef.content.success = true
        }
      )
      .subscribe(
        res => {
          let timer = setTimeout(() => {
            this.router.navigateByUrl('/login'),
              this.modalRef.hide()
          }, 2000)
        },
        err => {
          let message = ''
          switch (err.status) {
            case 0:
              message = this.jsonWord.error.noConnection;//'Campos de email ou senha inválidos.'
              //  this.noInternetAlert()
              break
            case 400:
              message = this.jsonWord.error.invalidFormat//'Formulário de criação de conta inválido.'
              break
            case 401:
              message = this.jsonWord.error.notAuthorized; //'Você não tem permissão para criar uma conta.'
              break
            case 404:
              message = this.jsonWord.error.userNotFound;//'Usuário não encontrado ou número serial de usuário não está registrado.'
              break
            case 408:
              message = this.jsonWord.error.timeoutServer//'Usuário não encontrado ou número serial de gateway não registrado.'
              break
            case 409:
              message = this.jsonWord.toast.userAlreadyExist; //'Já existe uma conta cadastrada nesse e-mail.'
              break
            case 412:
              message = this.jsonWord.toast.userAlreadyInGtw
              break
            case 500:
              message = this.jsonWord.toast.errorCreateAccount//'Não foi possível criar usuário.'
              break
            default:
              message = this.jsonWord.error.failOperation
          }
          this.modalRef.content.message = this.jsonWord.error.error + " " + message
          this.modalRef.content.waiting = false
          this.modalRef.content.success = false
          setTimeout(() => {
            this.modalRef.hide()
          }, 2000);
          //this.openErrorDialog(err.message)
        }
      )
  }

  onFileInput(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.registerForm.get('profileImage').setValue(
          "data:image/jpeg;base64," + String(reader.result).split(',')[1]
        )
      };
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    if(this.imageChangedEvent){
      this.isCropped = true
    }else{this.isCropped = false}
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64
    this.registerForm.get('profileImage').setValue(this.croppedImage)
    //(this.user.profileImage || this.profileForm.get('profileImage').value ? this.profileForm.get('profileImage').value : '../../assets/img/avatar.jpg').base64;
  }
  croppedImageFunction(){
    this.isCropped = false
  }
  imageLoaded() {
  /*  // show cropper
    this.croppedImage = this.croppedImage
    console.log(this.croppedImage)*/
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  openCameraModal() {
    this.modalRef = this.modalService.show(
      CameraModalComponent,
      {}
    )
    this.modalRef.content.onClose.subscribe(
      res => {
        if (res)
          this.registerForm.get('profileImage').setValue(res)
          //this.imageChangedEvent = res.imageAsDataUrl;
        // this.fileChangeEvent(res.event)
          this.croppedImage = res;
         
      }
    )
  }

  openLoadingModal(message) {
    this.modalRef = this.modalService.show(
      LoadingModalComponent,
      {
        keyboard: false,
        backdrop: 'static',
        class: 'waiting-modal modal-sm'
      })
    this.modalRef.content.message = message;
  }

  openErrorDialog(message) {
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonConfirm = {show: true, text: this.jsonWord.button.ok };//'OK'
    this.modalRef.content.buttonOk = { show: false };
    this.modalRef.content.title = this.jsonWord.toast.errorCreateAccount//'Erro ao cadastrar usuário';
    this.modalRef.content.message = message;
  }

  openTermDialog(message) {
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonConfirm = {show: true, text: this.jsonWord.button.ok };//'OK'
    this.modalRef.content.buttonOk = { show: false };
    this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };//'cancelar'
    this.modalRef.content.checkbox = { show: true, text: this.jsonWord.text.readTermsConditions };//'Li e aceito os termos e condições'
    this.modalRef.content.title = this.jsonWord.text.termsConditions; //'Termos e Condições';
    this.modalRef.content.message = message;
  }

  clearProfilePicture() {
    this.registerForm.get('profileImage').setValue('')
  }

  fieldHasErrors(field) {
    let control = this.registerForm.get(field)
    if (control.errors && Object.keys(control.errors).length && control.invalid && control.touched && control.dirty)
      return "has-errors"
    return ""
  }

  autoCompleteAddress(event) {
    let rawCep: string = this.registerForm.get('zipCode').value
    let cep = rawCep.replace(/\-/, '')
    if (cep.length == 8) {
      this.gettingAddress = true
      this.http.get(`https://viacep.com.br/ws/${cep}/json/`)
        .pipe(finalize(() => this.gettingAddress = false))
        .subscribe(
          (res: any) => {
            this.registerForm.patchValue({
              city: res.localidade,
              state: res.uf,
              address: res.logradouro,
              neighborhood: res.bairro,
            })
          }, err => {
            console.log(err)
          })
    }
  }

}

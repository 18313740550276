import { Subject } from 'rxjs/Subject';
import { ScenarioCardModalComponent } from './../_directives/scenario-card-modal/scenario-card-modal.component';
import { TranslateJsonService } from './../_services/translate-json.service';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { AlertComponent } from './../_directives/alert.component';
import { Scenario } from './../models/scenario.model';
import { ScenarioService } from './../_services/scenario.service';
import {
  Component, OnInit, Injectable, ElementRef, SimpleChanges,
  SimpleChange, EventEmitter, Output, Input, HostListener
} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import * as config from './../config';
import { BsModalService } from 'ngx-bootstrap';
import * as moment from 'moment';
import { GatewayService } from '../_services/gateway.service';
import { finalize, timeoutWith, concatMap } from 'rxjs/operators';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { HomeComponent } from '../home/home.component';
import { RuleService } from 'app/_services/rule.service';


const utf8Decoder = new TextDecoder("utf-8")
const TIMEOUT = 10000

@Component({
  selector: 'app-scene',
  templateUrl: './scene.component.html',
  styleUrls: ['./scene.component.css']
})

export class SceneComponent implements OnInit {
  @Output() mouseWheelUp = new EventEmitter();
  @Output() mouseWheelDown = new EventEmitter();
  /*@HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    this.onScroll(event);
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    this.onScroll(event);
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    this.onScroll(event);
  }*/

  private ngUnsubscribe = new Subject();
  scenarios: any = [];
  public modalRef: BsModalRef;
  uuid = require('uuid/v4');
  message;
  currentGateway;
  scnL;
  public loading: Boolean
  currentUserType;
  public language;
  public jsonWord;
  public currentUser;
  arrayDevsScenario = []
  arrayDevConfig = []
  public isAtBottom: Boolean = false;
  public isAtTop: Boolean = true;
  isFirefox
  imgsArrayDark=[]
  constructor(
    private _scenarioService: ScenarioService,
    private router: Router,
    private _mqttService: MqttService,
    private modalService: BsModalService,
    private gatewayService: GatewayService,
    private readonly location: Location,
    private translateJson: TranslateJsonService,
    public homeComponent: HomeComponent
  ) { }

  ngOnInit() {
    this.language = localStorage.getItem('language')
    this.jsonWord = this.translateJson.changeLanguage(this.language)
    this.location.replaceState('/');
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')).email
    this.currentUserType = this.homeComponent.currentUserType;
    this.isFirefox = /^((?!chrome|android).)*firefox/i.test(navigator.userAgent);
    this.gatewayService.gatewayObservable.subscribe(
      gateway => {
        if (gateway) {
          this.currentGateway = gateway.id
          this.loading = true
          this.updateAll()
        }
      }
    )
    let resposta = `${config.gtwTopic}${this.currentGateway}/scenario/#`;
    let teste = null

    let sync = 'ecomfort/iot/v1/sync/gateway/'+this.currentGateway+'/scenario/#'
    this._mqttService.observe(sync)
      /* .map((IMQTTMessage: IMqttMessage) => {
       try {
          return JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload))
        } catch (error) {
          console.log("ERROR ", new TextDecoder("utf-8").decode(IMQTTMessage.payload), IMQTTMessage.payload)

        }
      })
      .takeUntil(this.ngUnsubscribe).subscribe(res => {*/
      .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
      .takeUntil(this.ngUnsubscribe).subscribe(res => {
        //if(res){
          console.log("sync",res)
       //   let resposta = 
        if (res.code == "ERROR_SYNC") {
          for (let scenario of this.scenarios) {
            if (scenario._id == res.entityId) {
              scenario.sync.status = 'FAIL'
            }
          }
        } else {
          if (res.op == 'EDIT') {
            this._scenarioService.listScenarioById(res.entityId).subscribe(
              updatedScenario => {
                let outdatedIndex = this.scenarios.findIndex(scenario => scenario._id == res.entityId)
                //let outdatedDevice = this.devicesFound[outdatedIndex]
                this.scenarios.splice(outdatedIndex, 1, updatedScenario)
              })
          }
          if (res.op == 'DELETE' && sync == 'SUCCESS') {
            let indexToRemove = this.scenarios.findIndex(scenario => scenario._id == res.entityId)
            this.scenarios.splice(indexToRemove, 1)
          }
        }
        //  }
       
      })

    this._mqttService.observe(resposta)
      .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload))).subscribe(res => {
        teste = res
        if(res.data){
        if (res.operation == 'add' || res.operation == 'edit' || res.operation == 'delete') {
          if (res['operation'] == 'add') {
              let hold = Array.from(this.scenarios)
  
              this._scenarioService.listScenarioById(res['data'].scenarioId)
                .pipe(finalize(() => this.loading = false))
                .takeUntil(this.ngUnsubscribe)
                .subscribe(
                  scenario => {
                    hold.push(scenario)
                    let index = hold.indexOf(scenario)
                    this.scenarios.splice(index, 0, scenario)
                  })
            }
          if (res['operation'] == 'edit') {
            this._scenarioService.listScenarioById(res['data'].scenarioId).subscribe(
              updatedScenario => {
                let outdatedIndex = this.scenarios.findIndex(scenario => scenario._id == res['data'].scenarioId)
                //let outdatedDevice = this.devicesFound[outdatedIndex]
                this.scenarios.splice(outdatedIndex, 1, updatedScenario)
              })
          }
          if (res['operation'] == 'delete') {
            let indexToRemove = this.scenarios.findIndex(scenario => scenario._id == res['data'].scenarioId)
            this.scenarios.splice(indexToRemove, 1)
          }
          // this.updateAll()
        } else {
          console.log("link error", res)
        }
      }
      }, err => {
        if (teste && (teste['operation'] == 'add' || teste['operation'] == 'edit' || teste['operation'] == 'delete')) {
          //  this.updateAll()
        }
      })

  }

  public updateAll() {
    this._scenarioService.listScenarios()
      .pipe(finalize(() => { this.loading = false }))
      .subscribe(scenarios => {
        this.scenarios = scenarios//.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
        this.scnL = this.scenarios.length;
        //for(let i=0;i<this.scnL;i++){
        //  var hasIcon = this.scenarios[i].icon && this.scenarios[i].icon.length ? this.scenarios[i].icon : "icon_1"
          //var icon = (hasIcon.length<11 ? hasIcon.slice(0, 6)+'_dark.svg' : hasIcon.slice(0, 7)+'_dark.svg')
         // console.log(icon)
         // this.imgsArrayDark.push(icon)
       // }
      },
        err => {
         /* if (err.status === 401) {
            //this.router.navigate(['/login']);
          }*/
          this.homeComponent.openErrorAlert(err)
        });
  }

  /*onScroll(event: Event) {

    let scrollRight = <any>document.getElementById("scrollRight")
    let el = event.srcElement
    let height;

    $("#scrollRight").each(function () {
      height = $(this).height()
    });
    this.isAtTop = el.scrollTop < 3 ? true : false
    this.isAtBottom = (height + 1 > el.scrollHeight - el.scrollTop) ? true : false
  }*/


  runScenario(scenario: any) {
    const topic = config.gtwTopic + this.currentGateway + '/scenario/' + scenario._id + '/start';
    const message = {
      messageId: this.uuid(),
      user: this.currentUser,
      timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    };
    this.unsafePublish(topic, JSON.stringify(message));
  }

  resendCommand(scenario) {
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
    // tslint:disable-next-line:max-line-length
    this.modalRef.content.title = this.jsonWord.alert.attention
    this.modalRef.content.type = 'warning'
    this.modalRef.content.message = this.jsonWord.alert.sendConfiguration
    this.modalRef.content.onClose.subscribe(result => {
      if (result) {
        let packScenario = {
          "entityId": scenario._id
        }
        this.gatewayService.resendMqtt(this.currentGateway, packScenario)
          .subscribe(res => {
            if (res['data']) {
              scenario.sync.status = 'PENDING'
            }
          })
      }
    })
  }

  showArray() {
    var txt = "";
    for (let dev in this.scenarios) {
      txt += this.scenarios[dev].name + "," + " ";

    }
    document.getElementById("p1").innerHTML = txt;
  }

  editScenarioModal(scenario) {
    //this.updateAll()
    let devs = scenario.devices, oprulescn=[]
    this.arrayDevsScenario = []
    this.arrayDevConfig = []
    for (let i = 0; i < devs.length; i++) {
      if ((devs[i].devId == 261 || devs[i].devId == 258 || devs[i].devId == '261' || devs[i].devId == '258') && devs[i].clusters[0].cmds[0].cmdApi == 'on') {
        this.arrayDevsScenario.push(devs[i])
        if (devs[i].clusters[1].cmds[0].attributes.level) {
          this.arrayDevConfig.push([devs[i].clusters[0].cmds[0].cmdApi,
          devs[i].clusters[2].cmds[0].attributes.hue, devs[i].clusters[2].cmds[0].attributes.saturation,
          devs[i].clusters[1].cmds[0].attributes.level])
        } else {
          this.arrayDevConfig.push([devs[i].clusters[0].cmds[0].cmdApi,
          devs[i].clusters[1].cmds[0].attributes.hue, devs[i].clusters[1].cmds[0].attributes.saturation,
          devs[i].clusters[2].cmds[0].attributes.level])
        }
      } else {
        this.arrayDevsScenario.push(devs[i])
        this.arrayDevConfig.push([devs[i].clusters[0].cmds[0].cmdApi])
      }
    }
   
    this.modalRef = this.modalService.show(ScenarioCardModalComponent);
    this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
    this.modalRef.content.buttonConfirm = { show: true, text: this.jsonWord.button.edit };
    this.modalRef.content.inputText1 = { show: true, type: "text", value: scenario.devices };
    this.modalRef.content.buttonRemove = { show: true, text: this.jsonWord.button.removeScenario };
    this.modalRef.content.card = scenario
    this.modalRef.content.isDelete = false;
    this.modalRef.content.outputs = this.arrayDevsScenario
    this.modalRef.content.outputsRules = scenario.ruleOutputs
    this.modalRef.content.configs = this.arrayDevConfig
    this.modalRef.content.title = scenario.name//'Vincular Dispositivos';
    this.modalRef.content.onClose.subscribe(res => {
      let scenario = this.modalRef.content.buttonConfirm
      //this.updateAll()
      /*  if (scenario) {
          console.log(res, scenario)
          this.router.navigate(['home/scenarios/edit/', res._id])
        }*/
    })
  }

  deleteScenario(scenario, table) {
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
    // tslint:disable-next-line:max-line-length
    this.modalRef.content.title = this.jsonWord.alert.titleRemoveScenario/*'\
    <br>\
    <span><img height="25" width="25" src="../assets/img/dashboard/icons/environments-icon.svg" alt="" style="margin-bottom: 5px;">&nbsp;&nbsp;\
    <span><b>' +  + '</b></span>\
    <br>\
    ';*/
    this.modalRef.content.message = this.jsonWord.alert.titleRemoveScenario + ' ' + scenario.name + '?'/*\
    <p>'+ <b>';*/
    //if (this.modalRef.content.buttonOk){}
    this.modalRef.content.onClose.subscribe(result => {

      //this.updateAll()
      if (result) {
        this._scenarioService.deleteScenarioById(scenario._id).subscribe(res => {
        }, err => { console.log("Deu erro delete cenario", err) });
      }
    });
  }
  goToConfig() {
    //if(this.scnL<=9)
    this.gatewayService.getMaxLimitEntity(this.currentGateway, 'scenario').subscribe(res => {

      if (res.availableCreation) {
        this.router.navigate(['home/scenarios/config/'])
      } else {
        this.modalRef = this.modalService.show(AlertComponent);
        this.modalRef.content.buttonCancel = { show: false };
        this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
        this.modalRef.content.title = this.jsonWord.alert.attention
        this.modalRef.content.type = 'warning'
        this.modalRef.content.message = this.jsonWord.toast.limitScenarioHit
        //}*/
      }
    }, err => {
      if (err.status == 0 || err.status == 408) {
        this.modalRef = this.modalService.show(AlertComponent);
        this.modalRef.content.type = 'remove'
        this.modalRef.content.title = /*"<i class='fa fa-lg fa-times text-danger'> </i> " +*/ this.jsonWord.error.conectionError
        this.modalRef.content.message = this.jsonWord.error.noConnection
        this.modalRef.content.isDelete = false
        this.modalRef.content.typeB = 'refresh'
        //"Tempo limite de conexão com o servidor atingido. Verifique o status do servidor recarregue a página novamente."
        this.modalRef.content.buttonConfirm = { show: true, text: /*"<i class='fa fa-refresh'></i>" + '  ' + */this.jsonWord.label.reload }
        this.modalRef.content.onClose.subscribe(
          res => {
            if (res)
              window.location.reload()
          }
        )
      }
    })
  }
  public unsafePublish(topic: string, message: string): void {
    this._mqttService.unsafePublish(topic, message, { qos: 1, retain: false });
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

}

import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { environment } from './../../../environments/environment.prod';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs/Subscription';
import { DeviceService } from './../../_services/device.service';
import { LoadingModalComponent } from './../loading-modal/loading-modal.component';
import { AlertComponent } from './../../_directives/alert.component';
import { DevicesModalComponent } from './../../_directives/devices-modal/devices-modal.component';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { timeout, catchError, timeoutWith, finalize } from 'rxjs/operators';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { EnvironmentService } from './../../_services/environment.service';
import { TranslateJsonService } from './../../_services/translate-json.service';
import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import * as config from '../../config';
import { v4 as uuid } from 'uuid';
import * as moment from 'moment';
import { ResponseService } from '../../_services/response.service';

const utf8Decoder = new TextDecoder("utf-8")


@Component({
  selector: 'app-icons-scenario-modal',
  templateUrl: 'icons-scenario-modal.component.html',
  styleUrls: ['./icons-scenario-modal.component.css'],

})

export class IconsScenarioModalComponent implements OnInit {
  
  public icons=[];
  imageChosen
  language
  jsonWord
  greenImg
  imgsScenario=[]
  public environmentToOperation
  public loadingModalRef: BsModalRef;
  
  public onClose:Subject<any>

  constructor(
    private _bsModalRef: BsModalRef,
    public translateJson: TranslateJsonService,
    private _environmentService: EnvironmentService,
    private modalService: BsModalService, ) {
    // this.fusoChosen = this.jsonWord.label.choseTimezone
  }

  public ngOnInit(): void {
    this.language = localStorage.getItem('language')
    this.jsonWord = this.translateJson.changeLanguage(this.language)
    this.onClose = new Subject();
   
  }

 
  public getImageToScenario(img): void {
    this.imageChosen = (img.length<11 ? img.slice(0, 6) : img.slice(0, 7))
    this.greenImg =  (img.length<11 ? img.slice(0, 6)+'_green.svg' : img.slice(0, 7)+'_green.svg')
  }

  public onConfirm(){
    this.onClose.next(this.imageChosen);
    this._bsModalRef.hide();
  }
  public onCancel(){
    this._bsModalRef.hide();
  }
  

  private closeAllModals() {
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    }
  }
}

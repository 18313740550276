import { RuleEditModule } from './rule-edit/scene-edit.module';
import { RuleConfigModule } from './rule-config/scene-config.module';
import { RuleComponent } from './rule.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '../_directives/directives.module';


@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    ReactiveFormsModule,
    RuleConfigModule,
    RuleEditModule,
    RouterModule,
    FormsModule
  ],
  declarations: [RuleComponent],
  exports: [RuleComponent]
})
export class RuleModule { }

export class User {
    id: string;
    token: string;
    email: string;
    password: string;
    name: string;
    //lastName: string;
    profileId: string;
    status: string;
    role: string;
}

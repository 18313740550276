import { TranslateJsonService } from './../../_services/translate-json.service';
import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-field-feedback',
  templateUrl: './field-feedback.component.html',
  styleUrls: ['./field-feedback.component.css']
})
export class FieldFeedbackComponent implements OnInit {

  errors: any
  message: string
  language;
  jsonWord;

  @Input() field: string
  @Input() control: AbstractControl

  keys = Object.keys

  constructor(public translateJson : TranslateJsonService) { }

  ngOnInit() {
    this.language = localStorage.getItem('language')
    this.jsonWord = this.translateJson.changeLanguage(this.language)
  }

  getMessage() {
    let errors = this.control.errors
    if (this.control.touched && this.control.invalid && this.control.dirty) {
      var message : string = ''
      if (!errors) 
        return null
      for (let key in errors) {
        let error = errors[key]
        switch (key) {
          case 'pattern':
            message = this.jsonWord.error.invalidFormat//' tem formato ou caracteres inválidos'
            break
          case 'required':
            message = this.jsonWord.error.required//Campo é obrigatório'
            break
          case 'minlength':
            message = this.jsonWord.error.minlength +' '+ error.requiredLength + ' '+this.jsonWord.error.character
            break
          case 'maxlength':
            message = this.jsonWord.error.maxlength +' '+error.requiredLength + ' '+this.jsonWord.error.character
            break
          case 'matchOther':
            message = this.jsonWord.error.pattern
            break
          default:
            message = this.jsonWord.error.failOperation
        }
      }
      return message
    }
  }

}

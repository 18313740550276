import { GatewayService } from './../../../_services/gateway.service';
import { Gateway } from './../../../models/gateway.model';
import { GroupService } from 'app/_services/group.service';
import { GroupModalComponent } from './../group-modal.component';
import { PanelComponent } from './../../../panel/panel.component';
import { EnvironmentService } from './../../../_services/environment.service';
import { DeviceCardModalComponent } from './../../../_directives/device-card-modal/device-card-modal.component';
import { ResponseService } from './../../../_services/response.service';
import { TranslateJsonService } from './../../../_services/translate-json.service';
import { UserService } from './../../../_services/user.service';
import { DeviceService } from './../../../_services/device.service';
import { Component, OnInit, Input, Output, OnDestroy, EventEmitter, ChangeDetectorRef, SimpleChanges, SimpleChange } from '@angular/core';
import { Card } from './../../../models/card.model';
import { Device } from './../../../models/device.model';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import * as config from '../../../config';
import { AlertComponent } from '../../../_directives/alert.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { v4 as uuid } from 'uuid';
import * as moment from 'moment';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { DevicesModalComponent } from '../../../_directives/devices-modal/devices-modal.component';
import { LoadingModalComponent } from '../../../_directives/loading-modal/loading-modal.component';
import { Subscription, Subject, of, NEVER, Observable, empty } from 'rxjs';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/catch';
import { timeout, catchError, finalize, timeoutWith, onErrorResumeNext } from 'rxjs/operators';
import { HomeComponent } from '../../../home/home.component';
import { SmartlockModalComponent } from '../../../_directives/smartlock-modal/smartlock-modal.component';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';

const SMARTLOCK_TIMEOUT = 16000
const COMMAND_TIMEOUT = 5000
const BIND_TIMEOUT = 10000
const CONFIG_TIMEOUT = 10000
const utf8Decoder = new TextDecoder("utf-8")

@Component({
  selector: 'app-card-group',
  templateUrl: './card-group.component.html',
  styleUrls: [
    './card-group.component.css',
    './csshake.css'
  ]
})

export class CardGroupComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  public modalRef: BsModalRef;
  public loadingModalRef: BsModalRef;
  private subscription: Subscription;
  private deviceTopic;
  private deviceObserver
  private deviceStatusObserver
  public message: any;
  public loading = false;
  public dev: Device
  @Input() dragDropActive
  @Input() set deviceSetter(dev: Device) {
    this.dev = dev;
    this.card = new Card(dev)
  };
  @Input() sendValue
  @Input() pathNameDev
  @Input() isLast: boolean;
  @Input() devsToBind: Array<any>
  @Output() cardFeedback = new EventEmitter();
  @Input() devicesToEdit
  card: Card;
  backgroundColor = '#d5d5d5';
  textColor = '#ffffff';
  flipCard = true;
  cardFlipTimer: any;
  whiteLight;
  buzzer = true;
  led = true;
  autoLock = true;
  batteryLevelIcon;
  lockState;
  doorState;
  currentGateway;
  uuid = require('uuid/v4');
  devsCheckedBind;
  deviceTopicSync;
  devsSyncObserver
  currentUserToken;
  devicesObserver;
  userId;
  public correlId: string
  public waiting: Boolean = true;
  public success: Boolean //= true;
  public currentUser: any;
  public buttomBlocked: Boolean = true;
  currentUserId
  currentUserType

  btnClicked = false
  doorIsActive
  count = 0
  @Input() groupId
  @Output() devFeedback = new EventEmitter();

  toFixedNumber = Math.round
  @Input() busca: string;
  @Output() buscaChange: EventEmitter<string> = new EventEmitter<string>();
  private termosDaBusca: Subject<any> = new Subject<any>();
  public language;
  public jsonWord;
  constructor(
    public groupComponent: GroupModalComponent,
    //public homeComponent: HomeComponent,
    private _mqttService: MqttService, private groupService:GroupService,
    private modalService: BsModalService,
    private responseService: ResponseService,
    private userService: UserService,
    private gatewayService:GatewayService,
    private router: Router, private route: ActivatedRoute,
    private devicesService: DeviceService,
    private translateJson: TranslateJsonService,
    private _environmentService: EnvironmentService,
    private cd: ChangeDetectorRef, private _deviceService: DeviceService) {
  }

  ngOnInit() {
    this.language = localStorage.getItem('language')
    this.jsonWord = this.translateJson.changeLanguage(this.language)
    this.card = new Card(this.dev);
    /* if((this.card.isActive && this.card.invertOnOff) || (!this.card.isActive && this.card.invertOnOff)){
       this.card.isActive = !this.card.isActive
     }*/
    this.card.isActive = (this.card.isActive && !this.card.invertOnOff) || (!this.card.isActive && this.card.invertOnOff)
    //verifica o browse
    var isSafari = /^((?!chrome|android).)*firefox/i.test(navigator.userAgent);
    console.log("Verifica browse ", isSafari);
    this.getBatteryLevel(this.card.batteryLevel)
    this.currentUserType = localStorage.getItem('currentRole')//this.homeComponent.currentUserType;
    this.currentGateway = localStorage.getItem('currentGateway')//this.panelComponent.currentGateway
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')).email
    this.currentUserId = (localStorage.getItem('currentUserId'))
    this.deviceTopic = `${config.gtwTopic}${this.currentGateway}/device/${this.card.devIeeeAddr}/`
    let devicesTopic = `${config.gtwTopic}${this.currentGateway}/device/#`
    this.deviceTopicSync = `ecomfort/iot/v1/sync/gateway/${this.currentGateway}/user/#`

    this.deviceObserver = this._mqttService.observe(this.deviceTopic)
      .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))

    this.devsSyncObserver = this._mqttService.observe(this.deviceTopicSync)
      .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))

    this.devicesObserver = this._mqttService.observe(devicesTopic)
      .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))

    this.deviceStatusObserver = this._mqttService.observe(`${this.deviceTopic}status`)
      .map((IMQTTMessage: IMqttMessage) => JSON.parse(new TextDecoder("utf-8").decode(IMQTTMessage.payload)))
      
      const allDevicesTopic = config.gtwTopic + this.currentGateway + '/device/#';
      this.deviceObserver = this._mqttService.observe(allDevicesTopic)
        .map((IMQTTMessage: IMqttMessage) => {
          let message = utf8Decoder.decode(IMQTTMessage.payload)
          return message ? JSON.parse(message) : String(IMQTTMessage)
        })
        //.filter(payload => payload.data && payload.data.ieeeAddr == this.card.devIeeeAddr)
        .takeUntil(this.ngUnsubscribe)
        .subscribe(payload => {
          this.message = payload
          let op = payload.operation
          if (op === 'attReport') {
            if(this.card.devIeeeAddr == this.message.data.ieeeAddr){
              this.cardFeedback.emit({ op, device: this.card, message:this.message });
              this.messageDispatcher(this.message.data);
            }
          }
        });
  

    this.currentUserToken == localStorage.getItem('currentUserToken');

    this.waiting = false

    // General listener to all events from unknown source
   /* this.devicesObserver
      .filter(payload => payload.data && payload.data.ieeeAddr == this.card.devIeeeAddr)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(payload => {
        this.message = payload
        let op = payload.operation
        if (op === 'attReport') {
          this.messageDispatcher(this.message.data);
        }
        //Operações de delete e add ficam a cargo do pai (panel)
        else if (op === 'delete') {
          this.devFeedback.emit({ op, device: this.dev });
        }
        else if (op === 'edit') {
          this.devFeedback.emit({ op, device: this.dev });
        }
      });*/

    this.devsSyncObserver
      .takeUntil(this.ngUnsubscribe)
      .subscribe(payload => {
        this.message = payload
        let op = payload
        if (payload.blockedDevices && payload.blockedDevices == this.card.devIeeeAddr) {
          this.card.blocked = true
        }
        if (payload.unblockedDevices && payload.unblockedDevices == this.card.devIeeeAddr) {
          this.card.blocked = false
        }
      })
    this.doorState = this.card.value1;
    this.whiteLight = this.card.value3;
  }

 /* deviceUpdater(event) {
    switch (event.op) {
      case 'edit':
        this.updateDevice(event.device._id)
        break
      case 'delete':
        this.removeDevice(event.device.ieeeAddr)
        break
      default:
        break
    }
  }*/
 /* removeDevice() {
    this.devFeedback.emit({ operation: 'delete', ieeeAddr: this.dev.ieeeAddr });
  }
*/
  messageDispatcher(message) {
    switch (message.cId) {
      case 'genOnOff':
        if (message.type === 'onOff') {
          let status: boolean;
          status = message.value ? true : false;
          this.card.isActive = !this.card.invertOnOff ? status : !status;
          this.waiting = false
          this.success = true
        }
        break;
      case 'seMetering':
        if (message.type === 'instantaneousDemand') {
          if (!this.card.isActive) {
            this.card.value1 = '0.0';
          } else {
            const value = (message.value / 1000);
            this.card.value1 = value.toFixed(1);
          }
        }
        break;
      case 'genLevelCtrl':
        if (message.type === 'currentLevel') {
          let value;
          value = message.value;
          this.card.value1 = value.toFixed(1);
        }
        break;
      case 'lightingColorCtrl':
        if (message.type === 'currentHue') {
          let value;
          value = message.value;
          this.card.value2 = value.toFixed(1);
        } else if (message.type === 'currentSaturation') {
          let value;
          value = message.value;
          if (value > 1) {
            this.whiteLight = false;
            this.card.value3 = false
          } else if (value <= 1) {
            this.whiteLight = true;
            this.card.value3 = true
          }
        }
        break;
    }
  }
  /*clickedSL() {
    this.btnClicked = !this.btnClicked
  }
  openCloseSmarLock(card) {
    let message
    console.log(this.btnClicked)
    let deviceTopic = `${config.gtwTopic}${this.currentGateway}/device/${card.devIeeeAddr}/`
    const topic = `${deviceTopic}command`;
    this.doorIsActive = this.count == 0 ? card.isActive : this.doorIsActive
    if (this.btnClicked) {
      if (this.doorIsActive) {
        console.log(card, deviceTopic, this.btnClicked)
        message = {
          messageId: uuid(),
          timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          epId: card.devEpList[0],
          user: this.currentUser,
          cId: 'closuresDoorLock',
          cmd: 'unlockDoor',
          zclData: { pincodevalue: '00000000' }
        };
        this.unsafePublish(topic, JSON.stringify(message));
      } else {
        console.log(card, deviceTopic, this.btnClicked)
        message = {
          messageId: uuid(),
          timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          epId: card.devEpList[0],
          user: this.currentUser,
          cId: 'closuresDoorLock',
          cmd: 'lockDoor',
          zclData: { pincodevalue: '00000000' }
        };
        this.unsafePublish(topic, JSON.stringify(message));
      }
    }
  }*/
  openCloseObserver(card) {
    /*this.homeComponent.gatewayErrorObserver
      .subscribe(message => {
        console.log("TESTE ERRO:", message)
        this.count++;
        if (this.btnClicked) {
          if (this.count < 4) {
            this.openCloseSmarLock(card)
          } else {
            alert("Saiu da rede")
          }
        }
      })
    this.groupComponent.devicesObserver
      .filter(payload => payload.data && payload.data.ieeeAddr == card.devIeeeAddr)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(payload => {
        let message = payload
        let op = payload.operation
        if (op === 'attReport') {
          console.log("MENSAGEM", message)
          if (message.data.type == "lockState" && message.data.value == 1 && this.btnClicked) {
            this.doorIsActive = true
            this.count = 1
            this.openCloseSmarLock(card)
          } else if (message.data.type == "lockState" && message.data.value == 2 && this.btnClicked) {
            this.doorIsActive = false
            console.log("TESTE", this.doorIsActive)
            this.openCloseSmarLock(card)
            this.count = 1
          }

        }
      })*/
  }
  sendMQTTOnOffCommand(card) {
    if (!card.blocked) {
      this.waiting = true
      if ((card.devType === 'Router') || (card.devId === '10')) {
        let message;
        if ((card.devId === '81') || (card.devId === '258') || (card.devId === '771') ||
          (card.devId === '264') || (card.devId === '265') || (card.devId === '772')) {
          message = {
            messageId: uuid(),
            user: this.currentUser,
            timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            epId: card.devEpList[0],
            //userId:'teste',//JSON.parse(localStorage.getItem('currentUserId'))._id,
            cId: 'genOnOff',
            cmd: 'toggle',
            zclData: {}
          };
        }
        if (!this.card.invertOnOff) {
          if (card.isActive) {
            message = {
              messageId: uuid(),
              timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
              epId: card.devEpList[0],
              user: this.currentUser,
              cId: (card.devId === '10') ? 'closuresDoorLock' : 'genOnOff',
              cmd: (card.devId === '10') ? 'unlockDoor' : 'off',
              zclData: (card.devId === '10') ? { pincodevalue: '00000000' } : {}
            };
          } else if (!card.isActive) {
            message = {
              messageId: uuid(),
              timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
              epId: card.devEpList[0],
              user: this.currentUser,
              cId: (card.devId === '10') ? 'closuresDoorLock' : 'genOnOff',
              cmd: (card.devId === '10') ? 'lockDoor' : 'on',
              zclData: (card.devId === '10') ? { pincodevalue: '00000000' } : {}
            };
          }
        } else {
          if (card.isActive) {
            message = {
              messageId: uuid(),
              timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
              epId: card.devEpList[0],
              user: this.currentUser,
              cId: 'genOnOff',
              cmd: 'on',
              zclData: {}
            };
          } else if (!card.isActive) {
            message = {
              messageId: uuid(),
              timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
              epId: card.devEpList[0],
              user: this.currentUser,
              cId: 'genOnOff',
              cmd: 'off',
              zclData: {}
            };
          }
        }
        const topic = `${this.deviceTopic}command`;
        this.unsafePublish(topic, JSON.stringify(message))

        //caso haja erro
       /* this.subscription =
          this.homeComponent.gatewayErrorObserver
            .filter(msg => this.correlId == msg['correlId'])
            .map(message => {
              this.success = false
              return message
            })
            .merge(
              this.homeComponent.gatewaySuccessObserver
                .filter(msg => this.correlId == msg['correlId'])
                .map(message => {
                  this.success = true
                  this.buttomBlocked = true;
                  return message
                })
            )
            .take(1)
            .pipe(finalize(() => {
              this.waiting = false
            }), timeoutWith(this.card.devId == '10' ? SMARTLOCK_TIMEOUT : COMMAND_TIMEOUT, ErrorObservable.throw(
              new Response(null, {
                status: 408, statusText: 'Tempo limite de resposta atingido'
              })
            )))
            .subscribe(_ => {
              this.correlId = null
            },
              err => {
                console.log(err)
                this.homeComponent.gatewayErrorToast(err)
              }
            )*/
      }
    }
 
  }

  deleteDevice(card) {
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.imagem = { show: true, text: '../assets/img/dashboard/card-content/' + card.devId + '_alt.svg' };
    this.modalRef.content.isDelete = true;
    this.modalRef.content.title = card.title
    this.modalRef.content.message = this.jsonWord.alert.messageRemoveDevice + ' ' + card.title + '?'
    this.modalRef.content.onClose.subscribe(result => {
      if (result) {
        //this.deletingDevice = device
        //Deleta dispositivo offline de gateway que funciona e nao deleta de gateway que nao funciona, ou seja, funciona! 28/05/19-Nath
        this.confirmDelete(card)
      }
    })
  }
  confirmDelete(card){
    let groupArray = [{
      op: "remove",
      path: "/device/",
      value: {
        device: String(card.devIeeeAddr)
      }
    }]
    /*console.log("card to remove", card,this.groupId)
    let devs = this.devicesToEdit
      let cardNow = card
    var index = this.devicesToEdit.indexOf(cardNow);
      if (index > -1) {
        this.devicesToEdit.splice(index, 1);
      }*/
    this.groupService.updateGroup(this.groupId,groupArray).subscribe(res => {
      if(res){
      this.cardFeedback.emit({ id:card.devIeeeAddr})
      this.gatewayService.getGatewayById(this.currentGateway).subscribe(res=>{
        let result = new Gateway(res['data'])
      this.gatewayService.setGateway(result)
      },err=>{
      console.log(err)
    })}
    })
  }

  public unsafePublish(topic: string, message: string) {
    this.correlId = JSON.parse(message).messageId
    this._mqttService.unsafePublish(topic, message, { qos: 1, retain: false })
  }

  getBatteryLevel(voltage) {
    let level;
    if (voltage <= 25) {
      this.batteryLevelIcon = 'fa-battery-quarter';
      // level = '5';
    }
    if ((voltage > 25) && (voltage < 50)) {
      this.batteryLevelIcon = 'fa-battery-half';
      //  level = '25';
    }
    if ((voltage >= 50) && (voltage < 75)) {
      this.batteryLevelIcon = 'fa-battery-three-quarters';
      // level = '50';
    }
    if ((voltage >= 75) && (voltage <= 100)) {
      this.batteryLevelIcon = 'fa-battery-full';
      //  level = '75';
    }
    return this.batteryLevelIcon;
  }

  getBatteryPercentage(value) {
    return value + ' %';
  }

  rotateCard() {
    this.flipCard = !this.flipCard;
    if (this.flipCard) {
      clearTimeout(this.cardFlipTimer);
    }
    if (!this.flipCard) {
      this.cardFlipTimer = setTimeout(() => {
        this.rotateCard();
      }, 60000);
    }
  }

  getDimmerSliderValue() {
    return this.card.value1
    //return this.card.attributes['genLevelCtrl#currentLevel'];
  }

  getColorSliderValue() {
    return this.card.value2//this.card.attributes['lightingColorCtrl#currentHue'];
  }

  onSmartlightWhiteColorChecked(card) {
    const topic = `${this.deviceTopic}command`;
    this.whiteLight = !this.whiteLight;
    let command: any;
    if (this.whiteLight) {
      command = {
        messageId: uuid(),
        timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        ieeeAddr: card.devIeeeAddr,
        epId: card.devEpList[0],
        user: this.currentUser,
        cId: 'lightingColorCtrl',
        cmd: 'moveToSaturation',
        zclData: { saturation: 0, transtime: 0 }
      };
    } else if (!this.whiteLight) {
      command = {
        messageId: uuid(),
        timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        ieeeAddr: card.devIeeeAddr,
        user: this.currentUser,
        epId: card.devEpList[0],
        cId: 'lightingColorCtrl',
        cmd: 'moveToSaturation',
        zclData: { saturation: 254, transtime: 0 }
      };
    }
    this.unsafePublish(topic, JSON.stringify(command));
  }

  onSmartlightColorChange(card, value) {
    this.whiteLight = false;
    const command = {
      messageId: uuid(),
      timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      ieeeAddr: card.devIeeeAddr,
      epId: card.devEpList[0],
      user: this.currentUser,
      cId: 'lightingColorCtrl',
      cmd: 'moveToHueAndSaturation',
      zclData: { hue: value, saturation: 254, transtime: 0 }
    };
    const topic = `${this.deviceTopic}command`;
    this.unsafePublish(topic, JSON.stringify(command));
  }

  onSmartlightLevelChange(card, value) {
    const command = {
      messageId: uuid(),
      timestamp: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      ieeeAddr: card.devIeeeAddr,
      epId: card.devEpList[0],
      user: this.currentUser,
      cId: 'genLevelCtrl',
      cmd: 'moveToLevel',
      zclData: { level: value, transtime: 0 }
    };
    const topic = `${this.deviceTopic}command`;
    this.unsafePublish(topic, JSON.stringify(command));
  }
  showArray() {
    var txt = "";
    for (let dev in this.devsToBind) {
      txt += this.devsToBind[dev].name + "," + " ";
    }
    document.getElementById("p1").innerHTML = txt;
  }

 /* deleteDeviceModal(card) {
    //this.cardToBeEdited = card
    console.log(card)
    this.modalRef = this.modalService.show(DeviceCardModalComponent);
    this.modalRef.content.buttonCancel = { show: true, text: this.jsonWord.button.cancel };
    this.modalRef.content.buttonConfirm = { show: true, text: this.jsonWord.button.save };
    this.modalRef.content.buttonBlock = { show: true, text: this.jsonWord.button.blockDevice };
    this.modalRef.content.buttonUnblock = { show: card.blocked ? true : false, text: this.jsonWord.button.unblockDevice };
    this.modalRef.content.buttonBind = { show: false };
    this.modalRef.content.buttonCalibrate = { show: false };
    this.modalRef.content.buttonUnbind = { show: false };
    this.modalRef.content.whiteLight = card.value3;
    this.modalRef.content.buttonRemove = { show: true, text: this.jsonWord.button.removeDevice };
    this.modalRef.content.inputText1 = { show: true, type: "text", placeholder: card.devIeeeAddr, value: card.devIeeeAddr };
    this.modalRef.content.inputText2 = { show: true, type: "text", placeholder: this.jsonWord.label.name, value: card.title };
    this.modalRef.content.inputLevelLum = { show: true, type: "text" };
    this.modalRef.content.inputLevel = { show: true, type: "text" };
    this.modalRef.content.inputHue = { show: true, type: "text" };
    this.modalRef.content.input = { show: true, type: "text" };
    this.modalRef.content.offset = { show: false };
    this.modalRef.content.card = card
    this.modalRef.content.title = card.title//'Vincular Dispositivos';
  }

  editDevice(card) {
    console.log('card, ', card)
    this.editDeviceModal(card)
    this.modalRef.content.onClose
      .subscribe(
        res => {
          console.log(res, this.modalRef.content.offset.value)
          if (res) {
            let envId
            let cardRes = res._id

            if (this.modalRef.content.offset.value == "Nenhum") {
              envId = "nowhere"
              let data = {
                name: this.modalRef.content.inputText2.value,
                environment: { _id: envId },
                calibrated: true
              }
              /*  this._deviceService.updateDevice(card._id, data).subscribe(res => {
                  console.log(res)
                  this.modalRef.content.success = true
                  this.modalRef.content.message = this.jsonWord.toast.successRemoveDevice
                },
                  err => {
                    console.log(err)
                    this.modalRef.content.success = false
                    this.modalRef.content.message = this.jsonWord.toast.errorRemoveDevice
  
                  })
              this.openLoadingModal(this.jsonWord.loading.message)
              this._deviceService.updateDevice(card._id, data).subscribe(res => {
                console.log("edit: ", res)
                if (res) {
                  this.loadingModalRef.content.success = true
                  this.loadingModalRef.content.waiting = false
                  this.loadingModalRef.content.message = this.jsonWord.toast.editDeviceSucess
                  setTimeout(() => {
                    this.loadingModalRef.hide()
                    this.modalRef.hide()
                  }, 2000);
                }
              }, err => {
                this.loadingModalRef.content.success = false
                this.loadingModalRef.content.waiting = false
                this.loadingModalRef.content.message = this.jsonWord.toast.errorEditDevice
                setTimeout(() => {
                  this.loadingModalRef.hide()
                  this.modalRef.hide()
                }, 2000)
              })
            } else {
              this._environmentService.listEnvironments().subscribe(res => {
                let env = res.filter(env => env.name == String(this.modalRef.content.offset.value))
                envId = env[0]._id
                console.log(envId, env)
                let data = {
                  name: this.modalRef.content.inputText2.value,
                  environment: { _id: env[0]._id },
                  calibrated: true
                }
                this.openLoadingModal(this.jsonWord.loading.message)
                this._deviceService.updateDevice(card._id, data).subscribe(res => {
                  console.log("edit: ", res)
                  if (res) {
                    this.loadingModalRef.content.success = true
                    this.loadingModalRef.content.waiting = false
                    this.loadingModalRef.content.message = this.jsonWord.toast.editDeviceSucess
                    setTimeout(() => {
                      //this.loadingModalRef.hide()
                      // this.modalRef.hide()
                      this.closeAllModals()
                    }, 2000);
                  }
                }, err => {
                  this.loadingModalRef.content.success = false
                  this.loadingModalRef.content.waiting = false
                  this.loadingModalRef.content.message = this.jsonWord.toast.errorEditDevice
                  setTimeout(() => {
                    //this.loadingModalRef.hide()
                    //this.modalRef.hide()
                    this.closeAllModals()
                  }, 2000)
                })
                /* res.forEach(env => {
                   console.log(env, this.modalRef.content.offset.value, env.name)
                   if (String(this.modalRef.content.offset.value) == env.name) {
                    
                     console.log(data)
                     //this.openLoadingModal(this.jsonWord.text.updatingDevice)//'Adicionando gateway...')
                     
                   }
                 })
              })
            }

          }
        })
  }*/

  openLoadingModal(message: String) {
    this.loadingModalRef = this.modalService.show(
      LoadingModalComponent, {
        class: 'waiting-modal modal-sm',
        keyboard: false,
        backdrop: 'static'
      }
    )
    this.loadingModalRef.content.message = message;
  }

  private closeAllModals() {
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

}
import { GatewayService } from './../_services/gateway.service';
import { TranslateJsonService } from './../_services/translate-json.service';
import { Component, OnInit, HostListener, ViewChild, Input, ContentChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
import { AlertComponent } from '../_directives/alert.component';
import { LoadingModalComponent } from '../_directives/loading-modal/loading-modal.component';
import { CameraModalComponent } from '../_directives/camera-modal/camera-modal.component';
import { WebcamUtil } from 'ngx-webcam';
import { UserService } from '../_services/user.service';
import { HomeComponent } from '../home/home.component';
import { HttpClient } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { ChangePasswordComponent } from '../_directives/change-password/change-password.component';
import { TouchSequence } from 'selenium-webdriver';
import { Subject } from 'rxjs';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { Location } from '@angular/common';
import { ImageCroppedEvent, ImageCropperComponent, } from 'ngx-image-cropper';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  public profileImage: String
  public profileForm: FormGroup
  public passwordForm: FormGroup
  public modalRef: BsModalRef
  public webcamsAvailable: boolean
  public user: any
  private userID: String
  public gettingAddress: boolean
  public today = new Date()
  loading: Boolean = true
  currentGateway
  language;
  jsonWord;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  isCropped: Boolean = false

  // @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  @ContentChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;


  keys = Object.keys
  @HostListener('window:keyup', ['$event'])
  enterKeyUpEvent(e) {
    e.stopPropagation()
    if (e.keyCode == 13) {
      //this.submit()
    }
  }
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private translateJson: TranslateJsonService,
    private readonly location: Location,
    private modalService: BsModalService,
    private router: Router,
    private gatewayService: GatewayService,
    private homeComponent: HomeComponent,
    private http: HttpClient
  ) {
    this.profileForm = fb.group({
      name: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(60),
        Validators.pattern(/^(([a-zA-Z\u00C0-\u00FF][\s\w\u00C0-\u00FF]{0,59})|([A-Za-z0-9._-]{2,})(@[A-Za-z0-9_-]{2,})(\.[A-Za-z]{2,6})+)$/)
      ]],
      lastName: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(60),
        Validators.pattern(/^[a-zA-Z\u00C0-\u00FF][\s\w\u00C0-\u00FF]{0,59}$/)
      ]],
      email: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(60),
        Validators.pattern(/^([A-Za-z0-9._-]{2,})(@[A-Za-z0-9_-]{2,})(\.[A-Za-z]{2,6})+$/),
      ]],
      emailConf: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(60),
        Validators.pattern(/^([A-Za-z0-9._-]{2,})(@[A-Za-z0-9_-]{2,})(\.[A-Za-z]{2,6})+$/),
      ]],
      cellPhoneNumber: ['', [
        Validators.required,
        Validators.pattern(/^(\(1[1-9]\)\s?(?:7|9\d)\d{3}-\d{4}|(?:\(2[12478]\)|\(3[1-578]\)|\([4689][1-9]\)|\(5[13-5]\)|\(7[13-579]\))\s?(?:[6-8]|9\d?)\d{3}-\d{4})$/)
      ]],
      phoneNumber: ['', [
        Validators.pattern(/^((?:\([14689][1-9]\)|\(2[12478]\)|\(3[1-578]\)|\(5[13-5]\)|\(7[13-579]\))\s?[2-5]\d{3}-\d{4})?$/)
      ]],
      address: ['', [
        Validators.minLength(2),
        Validators.maxLength(60),
        Validators.pattern(/^([\u0021-\u007e\u00a0-\u00ff][\u0020-\u007e\u00a0-\u00ff]{0,59})?$/)
      ]],
      buildingNumber: ['', [
        Validators.minLength(2),
        Validators.maxLength(60),
        Validators.pattern(/^(\d){0,5}?$/)
      ]],
      complement: ['', [
        Validators.minLength(2),
        Validators.maxLength(60),
        Validators.pattern(/^([\u0021-\u007e\u00a0-\u00ff][\u0020-\u007e\u00a0-\u00ff]{0,59})?$/)
      ]],
      neighborhood: ['', [
        Validators.minLength(2),
        Validators.maxLength(60),
        Validators.pattern(/^([\u0021-\u007e\u00a0-\u00ff][\u0020-\u007e\u00a0-\u00ff]{0,59})?$/)
      ]],
      zipCode: ['', [
        Validators.minLength(9),
        Validators.maxLength(9),
        Validators.pattern(/^(\d{5}-\d{3})?$/)
      ]],
      city: ['', [
        Validators.minLength(2),
        Validators.maxLength(60),
        Validators.pattern(/^([\u0021-\u007e\u00a0-\u00ff][\u0020-\u007e\u00a0-\u00ff]{0,59})?$/)
      ]],
      state: ['', [
        Validators.minLength(2),
        Validators.maxLength(2),
      ]],
      profileImage: '',
    })

    this.passwordForm = fb.group({
      password: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(30),
      ]],
      passwordConf: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(30),
      ]]
    })
    this.userID = JSON.parse(localStorage.getItem('currentUser'))._id
  }

  ngOnInit() {
    this.language = localStorage.getItem('language');
    this.jsonWord = this.translateJson.changeLanguage(this.language);
    this.location.replaceState('/');
    this.gatewayService.gatewayObservable.subscribe(gateway => {
      if (gateway) {
        this.currentGateway = gateway.id
        this.loading = true
        ///this.updateAll()
      }
    });
    (new Date()).getMonth()
    this.passwordForm.get('password').valueChanges.subscribe(
      (value) => this.matchFields('password', 'passwordConf'))
    this.passwordForm.get('passwordConf').valueChanges.subscribe(
      (value) => this.matchFields('password', 'passwordConf'))

    this.profileForm.get('email').valueChanges.subscribe(
      (value) => this.matchFields('email', 'emailConf'))
    this.profileForm.get('emailConf').valueChanges.subscribe(
      (value) => this.matchFields('email', 'emailConf'))

    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.webcamsAvailable = mediaDevices && mediaDevices.length > 0;
      });

    this.userService.getUserByID(this.userID).pipe(finalize(() => { this.loading = false }))
      .subscribe(
        user => {
          this.user = user
          this.croppedImage = user.profileImage ? user.profileImage : '../../assets/img/avatar.jpg'
          // (this.user.profileImage || this.profileForm.get('profileImage').value ? this.profileForm.get('profileImage').value : '../../assets/img/avatar.jpg').base64;
          this.fillForm()
        }, err => { console.log(err) }
      )
  }


  evento($event) {
  }

  matchFields(field1, field2) {
    let field1Control = this.profileForm.get(field1)
    let field2Control = this.profileForm.get(field2)
    if (field1Control.value !== field2Control.value) {
      //field1Control.setErrors({ 'matchOther': true })
      field2Control.setErrors({ 'matchOther': true })
    } else {
      if (field2Control.errors && field2Control.errors['matchOther'])
        delete field2Control.errors['matchOther']
    }
  }

  fillForm() {
    this.profileForm.setValue({
      name: this.user.name ? this.user.name : null,
      lastName: this.user.lastName ? this.user.lastName : null,
      email: this.user.email ? this.user.email : null,
      emailConf: this.user.email ? this.user.email : null,
      cellPhoneNumber: this.user.cellPhoneNumber ? this.user.cellPhoneNumber : null,
      phoneNumber: this.user.phoneNumber ? this.user.phoneNumber : null,
      address: this.user.address ? this.user.address : null,
      buildingNumber: this.user.buildingNumber ? this.user.buildingNumber : null,
      complement: this.user.complement ? this.user.complement : null,
      neighborhood: this.user.neighborhood ? this.user.neighborhood : null,
      zipCode: this.user.zipCode ? this.user.zipCode : null,
      city: this.user.city ? this.user.city : null,
      state: this.user.state ? this.user.state : null,
      profileImage: this.user.profileImage ? this.user.profileImage : null
    })
  }

  submit() {
    var data = this.profileForm.value
    delete data.passwordConf
    delete data.emailConf
    data.buildingNumber = Number(data.buildingNumber)

    Object.keys(data).forEach(prop => {
      if (!data[prop] || data[prop] == '') delete data[prop]
    })


    this.openLoadingModal(this.jsonWord.loading.savingProfile)//'Salvando dados de perfil...')
    this.userService.updateUser(this.userID, data).subscribe(
      res => {
        this.homeComponent.getUserData()
        let timer = setTimeout(() => {
          this.modalRef.hide()
        }, 2000)
        this.modalRef.content.message = this.jsonWord.toast.successEditProfile//"Perfil alterado com sucesso."
        this.modalRef.content.waiting = false
        this.modalRef.content.success = true
        this.router.navigate(['/home/panel']);
      },
      err => {
        let message = ''
        switch (err.status) {
          case 0:
            message = this.jsonWord.error.noConnection;//'Campos de email ou senha inválidos.'
            //  this.noInternetAlert()
            break
          case 400:
            message = this.jsonWord.error.invalidFormat//'Formulário de criação de conta inválido.'
            break
          case 401:
            message = this.jsonWord.error.notAuthorized//'Você não tem permissão para criar uma conta.'
            break
          case 404:
            message = this.jsonWord.error.userNotFound//'Usuário não encontrado ou número serial de usuário não está registrado.'
            break
          case 408:
            message = this.jsonWord.error.timeoutServer //tempo expirado
            break
          case 409:
            message = this.jsonWord.toast.userAlreadyExist//'Já existe uma conta cadastrada nesse e-mail.'
            break
          case 500:
            message = this.jsonWord.toast.errorCreateAccount//'Não foi possível criar usuário.'
            break
          default:
            message = this.jsonWord.error.failOperation
        }
        this.modalRef.content.message = this.jsonWord.error.error + ": " + message
        this.modalRef.content.waiting = false
        this.modalRef.content.success = false
        //this.openErrorDialog(err.message)
      }
    )
  }

  onFileInput(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.profileForm.get('profileImage').setValue(
          "data:image/jpeg;base64," + String(reader.result).split(',')[1]
        )
      };
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    if (this.imageChangedEvent) {
      this.isCropped = true
    } else { this.isCropped = false }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64

    this.profileForm.get('profileImage').setValue(this.croppedImage)
  }
  croppedImageFunction() {
    this.isCropped = false
  }
  imageLoaded() {
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  /* rotateLeft() {
     this.transformBase64(8);
 }
 
 
 private transformBase64(exifOrientation: number): void {
   if (this.croppedImage) {
       transformBase64BasedOnExifRotation(this.croppedImage, exifOrientation)
           .then((rotatedBase64: string) => this.imageCropped(rotatedBase64));
   }
 }
 rotateRight() {
     this.transformBase64();
 }
 
 flipHorizontal() {
     this.transformBase64(2);
 }
 
 flipVertical() {
     this.transformBase64(4);
 }*/
  rotateLeftImg() {
    this.imageCropper.rotateLeft();
  }
  rotateRightImg() {
    this.imageCropper.rotateRight();
  }
  flipHorizontalImg() {
    this.imageCropper.flipHorizontal();
  }
  flipVerticalImg() {
    this.imageCropper.flipVertical();
  }
  openCameraModal(event) {
    this.modalRef = this.modalService.show(
      CameraModalComponent,
      {}
    )
    this.modalRef.content.onClose.subscribe(
      res => {
        if (res)
          this.profileForm.get('profileImage').setValue(res)
        this.croppedImage = res;

      }
    )
  }

  openLoadingModal(message) {
    this.modalRef = this.modalService.show(
      LoadingModalComponent,
      {
        keyboard: false,
        backdrop: 'static',
        class: 'waiting-modal modal-sm'
      })
    this.modalRef.content.message = message;
    return this.modalRef.content.onClose
  }

  openErrorDialog(message) {
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.buttonOk = { show: true, text: 'OK' };
    this.modalRef.content.title = this.jsonWord.toast.errorCreateAccount//'Erro ao salvar perfil';
    this.modalRef.content.message = message;
  }

  openChangePasswordModal() {
    this.modalRef = this.modalService.show(ChangePasswordComponent);
    this.modalRef.content.email = this.user.email
    this.modalRef.content.profileForm = this.profileForm.value
  }

  clearProfilePicture() {
    this.profileForm.get('profileImage').setValue('')
  }

  fieldHasErrors(field) {
    let control = this.profileForm.get(field)
    if (control.errors && Object.keys(control.errors).length && control.invalid && control.touched)
      return "has-errors"
    return ""
  }

  autoCompleteAddress(event) {
    let rawCep: string = this.profileForm.get('zipCode').value
    let cep = rawCep.replace(/\-/, '')
    if (cep.length == 8) {
      this.gettingAddress = true
      this.http.get(`https://viacep.com.br/ws/${cep}/json/`)
        .pipe(finalize(() => this.gettingAddress = false))
        .subscribe(
          (res: any) => {
            this.profileForm.patchValue({
              city: res.localidade,
              state: res.uf,
              address: res.logradouro,
              neighborhood: res.bairro,
            })
          }, err => {
            console.log(err)
          })
    }
  }

  openConfirmDialog(message): Subject<any> {
    let modalRef = this.modalService.show(AlertComponent)
    modalRef.content.buttonConfirm = { show: false };
    modalRef.content.type = 'warning'
    modalRef.content.title = this.jsonWord.alert.attention//'Atenção'
    modalRef.content.message = message
    modalRef.content.buttonOk.show = true
    modalRef.content.buttonCancel.show = true
    return modalRef.content.onClose
  }

  disableAccount() {
    this.openConfirmDialog(this.jsonWord.alert.messageDeactivateAccount)//'Tem certeza que deseja desativar sua conta?')
      .concatMap(res => {
        if (res == true) {
          this.openLoadingModal(this.jsonWord.loading.deactivatingAccount)//'Desativando conta...')
          return this.userService.deactivateUser()
        }
        else return ErrorObservable.create('Canceled by user')
      })
      .subscribe(
        res => {
          setTimeout(() => {
            this.modalRef.hide()
            localStorage.removeItem('currentUserToken')
            this.router.navigateByUrl('login')
          }, 2000)
          this.modalRef.content.message = this.jsonWord.toast.desactiveAccountSuccess//"Conta desativada com sucesso."
          this.modalRef.content.waiting = false
          this.modalRef.content.success = true
        },
        err => {
          let message = ''
          switch (err.status) {
            case 0:
              message = this.jsonWord.error.noConnection;//'Campos de email ou senha inválidos.'
              //  this.noInternetAlert()
              break
            case 400:
              message = this.jsonWord.error.invalidFormat//'Formulário de criação de conta inválido.'
              break
            case 401:
              message = this.jsonWord.error.notAuthorized//'Você não tem permissão para criar uma conta.'
              break
            case 404:
              message = this.jsonWord.error.userNotFound//'Usuário não encontrado ou número serial de usuário não está registrado.'
              break
            case 408:
              message = this.jsonWord.error.timeoutServer //tempo expirado
              break
            case 409:
              message = this.jsonWord.toast.userAlreadyExist//'Já existe uma conta cadastrada nesse e-mail.'
              break
            case 412:
              message = this.jsonWord.toast.onlyOneAdminError//'Já existe uma conta cadastrada nesse e-mail.'
              break
            case 500:
              message = this.jsonWord.toast.errorCreateAccount//'Não foi possível criar usuário.'
              break
            default:
              message = this.jsonWord.error.failOperation
          }
          this.modalRef.content.message = this.jsonWord.error.error + ": " + message
          this.modalRef.content.success = false
            this.modalRef.content.waiting = false
          setTimeout(() => {
            this.modalRef.hide()
          }, 2000);
        })
  }

}

import { Device } from '../models/device.model';

export class Card {
  _id: string;
  title: string;
  devIeeeAddr: string;
  devNwkAddr: string;
  devEpList: any;
  devType: string;
  type: string;
  status: string;
  isActive: boolean;
  batteryLevel: string;
  devId: string;
  environment: {
    environemntId: String,
    name: String
  };
  shake: boolean;
  bgColor: string;
  value1: any;
  value2: any;
  value3: any;
  value4: any;
  hasFeatures: boolean;
  creation: string;
  modification: string;
  blocked: boolean;
  attributes: any;
  associatedDevices: any;
  blockedUsers: any;
  invertOnOff:boolean
  favoriteDevice:boolean

  constructor(device: Device) {
    this.setupCard(device);
  }

  setupCard(device) {

    // this.value1 = '0.0';
    // this.value2 = '0.0';
    // this.value3 = false;
    // this.value4 = false;
    this._id = device._id ? device._id : device.ieeeAddr; //device._id
    this.title = device.name;
    this.devIeeeAddr = device.ieeeAddr;
    this.devNwkAddr = device.nwkAddr;
    this.devEpList = device.epList;
    this.devType = device.type;
    this.devId = device.devId;
    this.blockedUsers = device.blockedUsers
    this.status = device.status ? device.status.toLowerCase() : device.status;
    this.isActive = device.isActive;
    this.environment = device.environment;
    this.attributes = device.attributes;
    this.shake = false;
    this.blocked = device.blocked;
    this.associatedDevices = device.associatedDevices
    this.bgColor = this.getCardStyle(device);
    this.invertOnOff = device.invertOnOff
    this.favoriteDevice = device.favoriteDevice
    // console.log(this.attributes)
    //this.getCardStyle(device)
  }

  getCardStyle(device) {
    switch (device.devId) {
      case '81':
        this.hasFeatures = false;
        // this.isActive = (device.clusters.genOnOff.attrs.onOff === 1) ? true : false;
        this.isActive = (device.attributes && (device.attributes['genOnOff#onOff'] === 1)) ? true : false;
        this.value1 = !this.isActive ? '0.0' : (device.attributes && (device.attributes["seMetering#instantaneousDemand"] / 1000) <= 5000) ?
          String(((device.attributes["seMetering#instantaneousDemand"] / 1000)).toFixed(1)) : '5000';
        return '#66cc66';

      case '2':
        this.hasFeatures = false;
        this.shake = true;
        this.isActive = (device.attributes && (device.attributes['genOnOff#onOff'] === 1)) ? true : false;
        return '#c76b6c';

      case '770':
        this.hasFeatures = false;
        this.isActive = true;
        let v1, v2
        v1 = (device.attributes && (device.attributes["msTemperatureMeasurement#measuredValue"]!=null || device.attributes["msTemperatureMeasurement#measuredValue"]!=undefined)) ? device.attributes["msTemperatureMeasurement#measuredValue"] / 100 : 0;
        v2 = (device.attributes && device.attributes["msRelativeHumidity#measuredValue"]) ? device.attributes["msRelativeHumidity#measuredValue"] / 100 : 0;
        v1 = v1.toFixed(1)
        v2 = v2.toFixed(1)
        // this.value1 = device.clusters.msTemperatureMeasurement.attrs.measuredValue;
        // this.value2 = device.clusters.msRelativeHumidity.attrs.measuredValue;
        this.value1 = String(v1)
        this.value2 = String(v2)
        this.batteryLevel = (device.attributes && device.attributes["genPowerCfg#batteryPercentageRemaining"]) ? device.attributes["genPowerCfg#batteryPercentageRemaining"] : ""
        return '#6b81c7';

      case '12':
        this.isActive = (device.attributes && (device.attributes['genOnOff#onOff'] === 1)) ? true : false; //alterado
        this.hasFeatures = false;
        this.shake = true;
        this.batteryLevel = device.attributes && device.attributes["genPowerCfg#batteryPercentageRemaining"] ? device.attributes["genPowerCfg#batteryPercentageRemaining"] : ""
        return '#c76b90';

      case '10':
        if (device.attributes) {
          if (device.attributes['closuresDoorLock#lockState'] === 1) {
            this.isActive = true;
          } else if (device.attributes['closuresDoorLock#lockState'] === 2) {
            this.isActive = false;
          }
          this.value1 = device.attributes['closuresDoorLock#doorState'];
          this.value2 = device.attributes && device.attributes['closuresDoorLock#soundVolume'] == '2' ? true : false;
          this.value3 = device.attributes && device.attributes['closuresDoorLock#ledSettings'] == '2' ? true : false;
          this.value4 = device.attributes['closuresDoorLock#autolock'];
        }
        this.hasFeatures = false;
        this.batteryLevel = device.attributes && device.attributes["genPowerCfg#batteryPercentageRemaining"] ? device.attributes["genPowerCfg#batteryPercentageRemaining"] : ""
        return '#ce45b1';

      case '263':
        this.hasFeatures = false;
        this.batteryLevel = device.attributes && device.attributes["genPowerCfg#batteryPercentageRemaining"] ? device.attributes["genPowerCfg#batteryPercentageRemaining"] : ""
        this.shake = true;
        this.isActive = (device.attributes && (device.attributes["msOccupancySensing#occupancy"] == 1)) ? true : false;

        return '#b06bc7';

      case '258':
        if (device.attributes) { //bug com a lâmpada que não carrega o card
          this.isActive = (device.attributes && device.attributes['genOnOff#onOff'] === 1) ? true : false;
          this.value1 = device.attributes ? device.attributes['genLevelCtrl#currentLevel'] : "";
          this.value2 = device.attributes ? device.attributes['lightingColorCtrl#currentHue'] : "";
          this.value3 = (device.attributes && device.attributes['lightingColorCtrl#currentSaturation'] === 0) ? true : false;
        }
        this.hasFeatures = true;
        return '#e0e052';

      case '261':
        if (device.attributes) {
          this.isActive = (device.attributes['genOnOff#onOff'] === 1) ? true : false;
          this.value1 = device.attributes['genLevelCtrl#currentLevel'];
          this.value2 = device.attributes['lightingColorCtrl#currentHue'];
          this.value3 = (device.attributes['lightingColorCtrl#currentSaturation'] === 0) ? true : false;
        }
        this.hasFeatures = true;
        return '#fef784';

      case '262':
        this.hasFeatures = false;
        this.isActive = true;
       // console.log(device.attributes["msIlluminanceMeasurement#measuredValue"])
        // this.isActive = (device.attributes['genOnOff#onOff'] === 1) ? true : false; 
        this.value1 = (device.attributes && device.attributes["msIlluminanceMeasurement#measuredValue"]) ? device.attributes["msIlluminanceMeasurement#measuredValue"] : 0;
        //   this.isActive = (device.attributes && (device.attributes['genOnOff#onOff'] === 1)) ? true : false;
        this.batteryLevel = (device.attributes && device.attributes["genPowerCfg#batteryPercentageRemaining"]) ? device.attributes["genPowerCfg#batteryPercentageRemaining"] : ""
        return '#eeb89d';

      case '6':
        // this.isActive = (device.attributes['genOnOff#onOff'] === 1) ? true : false;
        this.isActive = (device.attributes && (device.attributes['genOnOff#onOff'] === 1)) ? true : false;
        this.hasFeatures = false;
        this.batteryLevel = (device.attributes && device.attributes["genPowerCfg#batteryPercentageRemaining"]) ? device.attributes["genPowerCfg#batteryPercentageRemaining"] : ""
        return '#cc9966';

      case '771':
        // this.isActive = (device.attributes['genOnOff#onOff'] === 1) ? true : false;
        /* this.isActive = (device.attributes && (device.attributes['genOnOff#onOff'] === 1)) ? true : false;
         this.hasFeatures = false;
         return '#4ef997';*/
        this.hasFeatures = false;
        // this.isActive = (device.clusters.genOnOff.attrs.onOff === 1) ? true : false;
        this.isActive = (device.attributes && (device.attributes['genOnOff#onOff'] === 1)) ? true : false;
        this.value1 = !this.isActive ? '0.0' : device.attributes ? device.attributes["seMetering#instantaneousDemand"] ?
          String(((device.attributes["seMetering#instantaneousDemand"] / 1000)).toFixed(1)) : '0.0' : '0.0';
        
        this.value2 = !this.isActive ? '0.0' : device.attributes ? device.attributes["haElectricalMeasurement#instantaneousPower"] ?
          String(((device.attributes["haElectricalMeasurement#instantaneousPower"])).toFixed(1)) : '0.0' : '0.0';
        return '#4ef997';

      case '264':
        this.hasFeatures = false;
        this.isActive = (device.attributes && (device.attributes['genOnOff#onOff'] === 1)) ? true : false;
        return '#61ac53';
      case '265':
        this.hasFeatures = false;
        this.isActive = (device.attributes && (device.attributes['genOnOff#onOff'] === 1)) ? true : false;
        return '#2F887A';
      case '772':
        this.hasFeatures = false;
        this.isActive = (device.attributes && (device.attributes['genOnOff#onOff'] === 1)) ? true : false;
        return '#B83B4B';
        case '774':
        this.isActive = (device.attributes && (device.attributes['genOnOff#onOff'] === 1)) ? true : false; //alterado
        this.hasFeatures = false;
        this.shake = true;
        this.batteryLevel = device.attributes && device.attributes["genPowerCfg#batteryPercentageRemaining"] ? device.attributes["genPowerCfg#batteryPercentageRemaining"] : ""
        return '#6bb5c7';

      default:
        this.hasFeatures = false;
        this.title = 'DESCONHECIDO';
        this.devId = '999';
        return '#c5c5c5';
    }
  }
}

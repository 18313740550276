import { HomeComponent } from './../home/home.component';
import { GatewayService } from './../_services/gateway.service';
import { EnvironmentService } from './../_services/environment.service';
import { concatMap, finalize } from 'rxjs/operators';
import { TranslateJsonService } from './../_services/translate-json.service';
import { LogsService } from './../_services/logs.services';
import { Component, OnInit, ɵConsole } from '@angular/core';
import 'rxjs/add/operator/takeWhile';
import { Location } from '@angular/common';
import { logs as sample } from './sample-logs-list';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.css'],
  //providers:[LogsService]
})

export class LogComponent implements OnInit {

  logs
  filter: String;
  filteredSortedLogs: Array<any>;
  paginatedLogs//: Array<any>;
  page: number;
  pages: number;
  logsPerPage: number;
  language;
  jsonWord;
  type;
  loading: Boolean = false
  totalPages
  maxPage
  currentGateway;
  currentUserType

  private ngUnsubscribe = new Subject();
  constructor(private readonly location: Location,
    private _logsService: LogsService,
    private _environmentService: EnvironmentService,
    public homeComponent: HomeComponent,
    private gatewayService: GatewayService,
    private translateJson: TranslateJsonService) {
    this.page = 1
    this.logsPerPage = 50
    this.type = 'all';
    // this.loading=true

  }

  ngOnInit() {
    this.location.replaceState('/');
    this.language = localStorage.getItem('language')
    this.loading = true
    this.jsonWord = this.translateJson.changeLanguage(this.language)
    this.gatewayService.gatewayObservable
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        gateway => {
          if (gateway) {
            if (this.currentGateway != gateway.id) {
              this.currentGateway = gateway.id
              this.loading = false
              this.updateLogsList()
            }
          }
        }
      )
    this.currentUserType = this.homeComponent.currentUserType;
    this._logsService.listLogs(this.type, this.page, this.logsPerPage).subscribe(res => {
      this.paginatedLogs = res['data'].data;
      this.totalPages = res['data'].total
      this.maxPage = res['data'].pages
    },err=>{

      this.loading = false
    })
  }

  filterAndUpdate(filter: String) { //working
    this.type = filter
    this.page = 1
    this._logsService.listLogs(filter, this.page, this.logsPerPage).subscribe(res => {
      this.paginatedLogs = res['data'].data;
      this.maxPage = res['data'].pages
    })
  }

  updateLogsList() { //quantidade por pagina >select<
    this._logsService.listLogs(this.type, this.page, this.logsPerPage)
      .pipe(finalize(() => { console.log('finalizei'); this.loading = false }))
      .subscribe(res => {
        this.paginatedLogs = res['data'].data;
        this.totalPages = res['data'].total
      })
  }

  previousPage() {
    if (this.page > 1) {
      this.page--
      this._logsService.listLogs(this.type, this.page, this.logsPerPage).subscribe(res => {
        this.paginatedLogs = res['data'].data;
      })
    }
  }

  nextPage() {
    if (this.page < this.maxPage) {
      this.page++
      this._logsService.listLogs(this.type, this.page, this.logsPerPage).subscribe(res => {
        this.paginatedLogs = res['data'].data;
      })
    }
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }
}

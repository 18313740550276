import { AppModule } from './app.module';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Component, OnInit } from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  showGatewayDropdown: boolean;
  title = 'E-Comfort';

  constructor(
    private translate: TranslateService
  ) {
    setTheme('bs3');
    translate.setDefaultLang('pt');
   /* if (localStorage.getItem('language'))
      translate.use(localStorage.getItem('language'))
    else translate.use('pt')*/
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }
}


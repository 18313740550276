import { AlertComponent } from './../../_directives/alert.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Validators, Validator } from '@angular/forms';
import { DeviceService } from './../../_services/device.service';
import * as config from '../../config';
import { Device } from './../../models/device.model';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { Subscription } from 'rxjs/Subscription';
import { EnvironmentService } from './../../_services/environment.service';
import { OnInit, OnDestroy, ChangeDetectorRef, Component, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';

//import { forEach } from '@angular/router/src/utils/collection';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { timeoutWith } from 'rxjs/operators';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { HomeComponent } from '../../home/home.component';
import { TranslateJsonService } from '../../_services/translate-json.service';

const utf8Decoder = new TextDecoder("utf-8")
const TIMEOUT = 10000

@Component({
	selector: 'vr-environment-config',
	templateUrl: './environment-config.component.html',
	styleUrls: ['./environment-config.component.css'],
	providers: [EnvironmentService, DeviceService],
})

export class EnvironmentConfigComponent implements OnInit, OnDestroy {
	//devicesService: DevicesService;
	devToUpdate: Device;
	devicesDB;
	devicesSub;
	name: string;
	devices = [];
	gateway: any;
	_id: number;
	id: number;
	environments;
	availableForEnvironment: boolean;
	environmentsSub;
	teste;
	testeSub;
	nameSub;
	devsEnv;
	isAllChecked = false;
	image: string;
	ieeeAddr;
	devAvEnv = [];
	@Input() device;
	envSubsToClose;
	nome;
	imageEnv;
	clicked = 0;
	environmentId;
	trueForDevsEnv = true;
	typeDevices = 10;
	saveClicked = 0;
	messageEdit
	vectorTypes = [[], [], [], [], [], [], [], [], [], [], []]
	uuid = require('uuid/v4');
	currentGateway;
	gtw;//= 'csZ1DuG92MTr1MKR'; //this.appService.localStorage()
	envName;
	public modalRef: BsModalRef;
	language;
	jsonWord;

	inEnv = [];
	constructor(private location: Location,
		private environmentService: EnvironmentService,
		private devicesService: DeviceService,
		private route: ActivatedRoute,
		private _mqttService: MqttService,
		private translateJson: TranslateJsonService,
		private router: Router,
		private cd: ChangeDetectorRef,
		private modalService: BsModalService,
		private homeComponent: HomeComponent
	) {
		this.environments = environmentService.listEnvironments();
		if (this.environments === null) { console.log("NÃO HÁ AMBIENTES") }
		this.devicesDB = devicesService.listDevices();
		this.envSubsToClose = this.environments.subscribe(res => { this.environmentsSub = res; });
	}

	ngOnInit() {
		this.language = localStorage.getItem('language')
		this.jsonWord = this.translateJson.changeLanguage(this.language);

		this.devToUpdate = new Device();
		this.location.replaceState('/');
		this.currentGateway = (localStorage.getItem('currentGateway'));
		this.gtw = config.gtwTopic;
		this.route.params.forEach((params: Params) => {
			this.id = params['_id'];
		});
		if (this.id) {
			this.environmentService.listEnvironmentsByEnvId(this.id).concatMap(res => {
				this.testeSub = res;
				//this.imageEnv = res.data.image;
				this.devsEnv = this.testeSub.devices;
				this.nameSub = this.testeSub.name;
				this.envName = this.testeSub.name;

				return this.devicesDB.map(res => {
					this.devicesSub = res//.devices;
					for (let j = 0; j < (this.devicesSub.length); j++) {
						if (this.devicesSub[j].environment == "" || this.devicesSub[j].environment == null || !this.devicesSub[j].environment.name) {
							this.devAvEnv.push(this.devicesSub[j]);
							this.inEnv.push(false)
						}
						if (this.devicesSub[j].environment.name == this.nameSub) {
							this.devAvEnv.push(this.devicesSub[j]);
							this.inEnv.push(true)
						}
					}

					/*let lengthAvEnv = this.devAvEnv.length;
					if (this.devsEnv) {
						for (let i = 0; i < this.devsEnv.length; i++) {
							this.devAvEnv.push(this.devsEnv[i])
							console.log(this.devAvEnv)
							this.inEnv[lengthAvEnv + i] = true
						}
					}*/
					this.checkDevsType(this.devAvEnv, this.inEnv)
				});
			}).subscribe(res => {

			});
		} else {
			this.devicesDB.subscribe(res => {
				this.devicesSub = res//.devices;
				for (let j = 0; j < (this.devicesSub.length); j++) {
					if (this.devicesSub[j].environment == "" || this.devicesSub[j].environment == null || !this.devicesSub[j].environment.name) {
						this.devAvEnv.push(this.devicesSub[j]);
						this.inEnv.push(false)
					}
				}
				this.checkDevsType(this.devAvEnv, this.inEnv)
			})
		}
	}

	ngOnDestroy() {
		this.envSubsToClose.unsubscribe();
	}

	/**
	 * @author Nathalia
	 * Increments count inside the vector position that correspondends to device epId described bellow.
	 */
	checkDevsType(devAvEnv, inEnv) {
		this.vectorTypes = [null, null, null, null, null, null, null, null, null, null, null]
		let count0 = [], count1 = [], count2 = [], count3 = [], count4 = [], count5 = [], count6 = [], count7 = [], count8 = [], count9 = [], count10 = [];
		for (let i = 0; i < devAvEnv.length; i++) {
			if (this.devAvEnv[i].devId == '770') count0.push([devAvEnv[i], inEnv[i]]);
			if (this.devAvEnv[i].devId == '12') count1.push([devAvEnv[i], inEnv[i]]);  //3
			if (this.devAvEnv[i].devId == '263') count2.push([devAvEnv[i], inEnv[i]]);
			if (this.devAvEnv[i].devId == '81' || this.devAvEnv[i].devId === '256') count3.push([devAvEnv[i], inEnv[i]]);
			if (this.devAvEnv[i].devId == '2') count4.push([devAvEnv[i], inEnv[i]]); //2
			if (this.devAvEnv[i].devId == '6') count5.push([devAvEnv[i], inEnv[i]]); //2
			if (this.devAvEnv[i].devId == '258' || this.devAvEnv[i].devId === '528') count6.push([devAvEnv[i], inEnv[i]]);
			if (this.devAvEnv[i].devId == '10') count7.push([devAvEnv[i], inEnv[i]]); //1
			if (this.devAvEnv[i].devId == '262') count8.push([devAvEnv[i], inEnv[i]]);
			if (this.devAvEnv[i].devId == '771') count9.push([devAvEnv[i], inEnv[i]]);
			if (this.devAvEnv[i].devId == '261') count10.push([devAvEnv[i], inEnv[i]]); //1
		}
		this.vectorTypes[0] = count0;
		this.vectorTypes[1] = count1;
		this.vectorTypes[2] = count2;
		this.vectorTypes[3] = count3;
		this.vectorTypes[4] = count4;
		this.vectorTypes[5] = count5;
		this.vectorTypes[6] = count6;
		this.vectorTypes[7] = count7;
		this.vectorTypes[8] = count8;
		this.vectorTypes[9] = count9;
		this.vectorTypes[10] = count10;
	}

	/**
	 * @author Nathalia
	 * Catch by checkbox property className "mat-checkbox-check" that shows this checkbox is checked and got the checkbox name by .outerText.
	 * Then i catch by mdinput property className "newEnv"and .value gives me its value. 
	 * So, through the for, i got the checkboxes names and add in devices array.  
	 */
	saveEnv(devices): void {
		let holdElemId = [], holdChecked = [];
		let message;
		let newEnv = <any>document.getElementsByClassName("newEnv");
		//this.devices = [];
		let m = 0;
		this.nome = true;
		// If name is empty, an alert will be sent. works
		this.name = newEnv[0].value;
		if (!this.name) {
			let message = this.jsonWord.placeholder.name;//"Coloque um nome!!";
			//alert("Coloque um nome!!");
			this.showSimpleDialog(message)
			this.nome = false;
			return;
		} else {
			this.nome = true;
		}
		if (this.environmentsSub) {
			this.environmentsSub.forEach(item => {
				if (item.name === newEnv[0].value) {
					if (item._id != this.id) {
						this.showSimpleDialog(this.jsonWord.toast.environmentAlreadyRegistered)
						//alert(this.jsonWord.toast.environmentAlreadyRegistered)//"Nome já existe!!");
						return this.nome = false;
					} else {
						this.nome = true;
						return;
					}
				}
			});
		}
		if (this.nome) {
			this.saveClicked = 1;
			if (this.devsEnv) {
				for (let i = 0; i < this.devsEnv.length; i++) {
					this.devAvEnv.push(this.devsEnv[i])
				}
			}
			for (let i = 0; i < this.devAvEnv.length; i++) {
				holdElemId.push(<any>document.getElementById(this.devAvEnv[i].ieeeAddr))
				if (holdElemId[i] && holdElemId[i].checked) {//classList[holdElemId[i].classList.length - 1] === "mat-checkbox-checked") {
					holdChecked.push(this.devAvEnv[i])
					this.devices.push(/*{
							"name": (String(this.devAvEnv[i].name)),
							"devId": (String(this.devAvEnv[i].devId)),
							"ieeeAddr": */(String(this.devAvEnv[i].ieeeAddr))
					);
				}
			}
			if (this.id) {
				//const topic = config.gtwTopic + this.currentGateway + "/environment/" + this.id + "/edit";

				//'messageId': this.uuid(),
				//'timestamp': moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
				//op: "replace",
				//path: "/name/",
				//value: {
				//name: this.name,
				//devices: this.devices
				//}
				/*},{
					op: "add",
					path: "/devices/",
					value: {
						devices: this.devices
					}
				}]*/
				if (this.nameSub === this.envName) {
					message = {
						op: "update",
						path: "/devices/",
						value: {
							devices: this.devices ? this.devices : []
						}
					}
				} else {
					message = {
						op: "replace",
						path: "/name/",
						value: {
							name: this.name,
						}
					}, {
						op: "update",
						path: "/devices/",
						value: {
							devices: this.devices ? this.devices : []
						}
					}
				}


				//this._mqttService.unsafePublish(topic, JSON.stringify(message));
				this.environmentService.updateEnvironment(message, this.id).subscribe(res => {
				})
			} else {
				const message = {
					name: this.name,
					devices: this.devices
				}
				this.environmentService.createEnvironment((message)).subscribe(res => {
				})
				/*	const topic = config.gtwTopic + this.currentGateway + "/environment/config";
					const message = {
						'messageId': this.uuid(),
						'timestamp': moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
						"environmentId": this.uuid(),
						"name": this.name,
						"devices": this.devices
					}
					console.log(message)
					this._mqttService.unsafePublish(topic, JSON.stringify(message));*/
				//setTimeout(() => this.goBack(), 2000);
			}
			const topic2 = config.gtwTopic + this.currentGateway + '/environment/#';
			this._mqttService.observe(topic2)
				.map((IMQTTMessage: IMqttMessage) => {
					let plainText = utf8Decoder.decode(IMQTTMessage.payload)
					return plainText ? JSON.parse(plainText) : null
				}).filter(message => message.operation == 'edit' || message.operation == 'add')
				.pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
					new Response(null, {
						status: 408, statusText: this.jsonWord.error.timeoutServer/*'Tempo limite de resposta atingido'*/
					})
				))
				).take(1)
				.subscribe(message => this.router.navigate(['home/environments']),
					err => this.homeComponent.gatewayErrorToast(err))

		}
	}
	showSimpleDialog(message) {
		this.modalRef = this.modalService.show(AlertComponent);
		this.modalRef.content.buttonConfirm = { show: false };
		this.modalRef.content.buttonCancel = { show: false, text: this.jsonWord.button.cancel };
		this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
		// tslint:disable-next-line:max-line-length
    this.modalRef.content.type = 'warning'
		this.modalRef.content.title = this.jsonWord.alert.attention/*'\
		<br>\
		<span></span>&nbsp;&nbsp;\
		<span><b>'+  + '!</b></span>\
		<br>\
		';*/
		this.modalRef.content.message = message/*'\
		<p>'+  + '</p>\
		';*/
		this.modalRef.content.onClose.subscribe(result => { })
	}

	messageNameExists() {
		this.modalRef = this.modalService.show(AlertComponent);
		this.modalRef.content.buttonConfirm = { show: false };
		this.modalRef.content.buttonCancel = { show: false, text: this.jsonWord.button.cancel };
		this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
		// tslint:disable-next-line:max-line-length
		this.modalRef.content.title = this.jsonWord.toast.environmentAlreadyRegistered/*'\
			<br>\
			<span><b>'++'</b></span>\
			<br>\
			';*/
		this.modalRef.content.message = this.jsonWord.toast.environmentAlreadyRegistered+'!'/*\
			<p> '+<b>';*/
		//if (this.modalRef.content.buttonOk){}
		this.modalRef.content.onClose.subscribe(result => {
			if (result) {
				console.log("ok")
			}
		});
		return this.modalRef
	}

	checkAll() {
		this.isAllChecked = !this.isAllChecked;
		this.trueForDevsEnv = this.isAllChecked;
	}

	goBack(): void {
		this.router.navigate(['home/environments']);
	}

	onEnter(i, device) {
		//$event.preventDefault()
		this.saveEnv(device)
	}
}
import { Injectable } from '@angular/core';
//import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import * as config from '../config';
import { catchError, timeout, timeoutWith } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { GatewayService } from './gateway.service';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';

const url = config.serverURL;//+ 'ecomfort/v1';
const urlConfig = config.serverURL + '/gateway/'
const TIMEOUT: number = 10000 // 10 seconds

@Injectable()
export class EnvironmentService {

  public environment = new BehaviorSubject<any>(null)

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private gatewayService: GatewayService
  ) { }

  listEnvironments() {
    //console.log(urlConfig + this.gatewayService.currentGateway.id + '/environment')
    return this.http.get(urlConfig + this.gatewayService.currentGateway.id + '/environment')
      .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
        new Response(null, {
          status: 408, statusText: 'Tempo limite de resposta atingido'
        })
      )))
      .map(res => res['data']);
  }

  listEnvironmentsByEnvId(envId): Observable<any> {
    return this.http.get(urlConfig + this.gatewayService.currentGateway.id + '/environment/' + envId)
      .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
        new Response(null, {
          status: 408, statusText: 'Tempo limite de resposta atingido'
        })
      )))
      .map(res => res['data']);
  }

  createEnvironment(environment): Observable<any> {
    return this.http.post(urlConfig + this.gatewayService.currentGateway.id + '/environment', environment)
      .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
        new Response(null, {
          status: 408, statusText: 'Tempo limite de resposta atingido'
        })
      )))
  }

  updateEnvironment(environment, envId): Observable<any> {
    return this.http.patch(urlConfig + this.gatewayService.currentGateway.id + '/environment/' + envId, environment)
      .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
        new Response(null, {
          status: 408, statusText: 'Tempo limite de resposta atingido'
        })
      )))
  }

  deleteEnvironmentByIeeeAddr(envId): Observable<any> {
    return this.http.delete(urlConfig + this.gatewayService.currentGateway.id + '/environment/' + envId)
      .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
        new Response(null, {
          status: 408, statusText: 'Tempo limite de resposta atingido'
        })
      )))
  }

  setCurrentEnvironment(environment) {
    if(environment == "nowhere"){
      localStorage.setItem('currentEnvironment', "nowhere")
    }else{
      localStorage.setItem('currentEnvironment',  null)
    }
    let userId = localStorage.getItem('currentUserId')
  //  console.log(userId, this.cookieService.get(userId), environment)
    /*let sessionData = JSON.parse(this.cookieService.get(userId))
    sessionData.currentEnvironment = environment ? environment._id : null
    this.cookieService.set(userId, JSON.stringify(sessionData))*/
    this.environment.next(environment)
  }

}

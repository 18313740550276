import { TranslateJsonService } from './../../_services/translate-json.service';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { LoginService } from '../login.service';
import { LoadingModalComponent } from '../../_directives/loading-modal/loading-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertComponent } from '../../_directives/alert.component';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.css']
})

export class RecoveryComponent implements OnInit {

  public recoveryForm: FormGroup
  public modalRef: BsModalRef
  public loading = false
  public today = new Date()
  language;
  jsonWord;

  keys = Object.keys

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private modalService: BsModalService,
    private translateJson: TranslateJsonService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2
  ) {
    this.language = localStorage.getItem('language') ? localStorage.getItem('language') : 'pt'
    this.jsonWord = this.translateJson.changeLanguage(this.language)
    renderer.setStyle(
      document.getElementsByTagName('body')[0],
      'background-image',
      'url(/assets/img/bg/3.jpg)'
      //'url(/assets/img/bg/xmas.png)'
    )
    this.loading = false
   /* loginService.checkRecoveryToken(route.snapshot.params['_token'])
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        _ => {
          this.openAlert(this.jsonWord.error.invalidToken)//'Token inválido ou expirado!')
          router.navigateByUrl('/login')
        },
        err => {
          if (err.status == 401) {
            console.log('Redirecting', err)
            this.openAlert(this.jsonWord.error.invalidToken)//'Token inválido ou expirado!')
            router.navigateByUrl('/login')
          }
        }
      )*/

    this.recoveryForm = fb.group({
      password: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(30),
      ]],
      passwordConf: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(30),
      ]],
    })
  }

  openAlert(message) {
    this.modalRef = this.modalService.show(AlertComponent);
    this.modalRef.content.buttonConfirm = { show: false };
    this.modalRef.content.buttonOk = { show: true, text: this.jsonWord.button.ok };
    this.modalRef.content.type = 'remove'
    this.modalRef.content.title = this.jsonWord.label.redefine + ' ' + this.jsonWord.label.password;//'Redefinir Senha';//this.jsonWord.label.redefine +' '+this.jsonWord.label.password;///'Redefinir Senha';
    this.modalRef.content.message = /*'<i class="fa fa-lg fa-times text-danger"> </i> ' +*/ message;
  }

  ngOnInit() {
    this.language = localStorage.getItem('language') ? localStorage.getItem('language') : 'pt';
    this.jsonWord = this.translateJson.changeLanguage(this.language);

    this.recoveryForm.get('password').valueChanges.subscribe(
      (value) => this.matchFields('password', 'passwordConf'))
    this.recoveryForm.get('passwordConf').valueChanges.subscribe(
      (value) => this.matchFields('password', 'passwordConf'))
  }

  matchFields(field1, field2) {
    let field1Control = this.recoveryForm.get(field1)
    let field2Control = this.recoveryForm.get(field2)
    if (field1Control.value !== field2Control.value) {
      //field1Control.setErrors({ 'matchOther': true })
      field2Control.setErrors({ 'matchOther': true })
    } else {
      if (field2Control.errors && field2Control.errors['matchOther'])
        delete field2Control.errors['matchOther']
    }
  }

  submit(form) {
    this.openLoadingModal(this.jsonWord.loading.message)//'Alterando senha...')
    delete form.passwordConf
    this.loginService.recovery(form, this.route.snapshot.params['_token']).subscribe(
      res => {
        let timer = setTimeout(() => {
          this.router.navigateByUrl('/login'),
            this.modalRef.hide()
        }, 2000)
        this.modalRef.content.message = this.jsonWord.alert.recoverPasswordSuccess//"Senha alterada com sucesso.<br>Redirecionando para a página de login..."
        this.modalRef.content.waiting = false
        this.modalRef.content.success = true
      },
      err => {
        let message = ''
        switch (err.status) {
          case 0:
            message = this.jsonWord.error.noConnection;//'Campos de email ou senha inválidos.'
            //  this.noInternetAlert()
            break
          case 400:
            message = this.jsonWord.error.invalidFormat//'Formulário de recuperação de conta inválido.'
            break
          case 401:
            message = this.jsonWord.error.notAuthorized +' '+ this.jsonWord.error.invalidLink //'Você não tem permissão para recuperar conta.'
            break
          case 404:
            message = this.jsonWord.error.userNotFound//'Usuário não encontrado ou número serial de gateway não registrado.'
            break
          case 408:
            message = this.jsonWord.error.timeoutServer//'Usuário não encontrado ou número serial de gateway não registrado.'
            break
          case 500:
            message = this.jsonWord.toast.errorToRecoverPassword//'Não foi possível redefinir senha.'
            break
          default:
            message = this.jsonWord.error.failOperation
        }
        this.modalRef.content.message = this.jsonWord.error.error + ": " + message
        this.modalRef.content.waiting = false
        this.modalRef.content.success = false
        //this.openErrorDialog(err.message)
      }
    )
  }
  openLoadingModal(message) {
    this.modalRef = this.modalService.show(
      LoadingModalComponent,
      {
        keyboard: false,
        backdrop: 'static',
        class: 'waiting-modal modal-sm'
      })
    this.modalRef.content.message = message;
  }

}

import { Pipe, Injectable, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({name: "dateTimeFormatFilter"})
	@Injectable()
	export class DateTimeFormatPipe implements PipeTransform {
		transform(value: any): any {
			if (value) {
					if (value.toString().indexOf(' ') === -1) {
							value = value + ' 12:00:00';
					}
					const temp = value.toString().replace(' ', 'T');
					return new Date(temp);
			} else {
					return null;
			}
	}
	}
export class Gateway {
    id: string;
    name: string;
    ipv4: object;
    groups:object;
    hwVersion: string;
    fwVersion: string;
    devices:any
    status: string;
    serialNumber: string;
    created: string;
    lastUpdate: string;
    role: string;
    isOnline: boolean;
    type:string;
    permitJoin: {
        time: number
        counter: NodeJS.Timer
    };
    timezone: {
        name: String;
        isdst: Boolean;
        offset: Number
    };
    sortedDevices: [];
    sendPushNotification;
    settings:{}

    constructor(gateway?: any) {
        if (gateway) { 

            this.id = gateway.serialNumber ? gateway.serialNumber : '';
            this.name = gateway.name ? gateway.name : '';
            this.type = gateway.type ? gateway.type : '';
            this.ipv4 = gateway.ipv4 ? gateway.ipv4 : {};
            this.isOnline = gateway.isOnline ? true : false;
            this.groups = gateway.groups ? gateway.groups : {};
            this.devices = gateway.devices ? gateway.devices : {}
            this.hwVersion = gateway.hwVersion ? gateway.hwVersion : '';
            this.fwVersion = gateway.fwVersion ? gateway.fwVersion : '';
            this.status = gateway.status ? gateway.status : '';
            this.serialNumber = gateway.serialNumber ? gateway.serialNumber : '';
            this.created = gateway.createdAt ? gateway.createdAt : '';
            this.lastUpdate = gateway.updatedAt ? gateway.updatedAt : '';
            this.role = gateway.role ? gateway.role : '';
            this.sortedDevices = gateway.sortedDevices ? gateway.sortedDevices : [];
            this.settings = gateway.settings ? gateway.settings : {};
            this.timezone = gateway.timezone ? {
                name: gateway.timezone.name ? gateway.timezone.name : '',
                isdst: gateway.timezone.isdst,
                offset: gateway.timezone.offset ? gateway.timezone.offset : 0
            } : { name: '', isdst: '', offset: '' },
            this.sendPushNotification = gateway.sendPushNotification//.length > 0 ? gateway.sendPushNotification : true
        }
        this.permitJoin = {
            time: 0,
            counter: null
        }

    }

}

import { AppRoutingModule } from './../../../app-routing.module';
import { DeviceService } from './../../../_services/device.service';
import { DirectivesModule } from './../../directives.module';
import { NgModule, Pipe, PipeTransform, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardGroupComponent } from './card-group.component';


@NgModule({
  imports: [
    AppRoutingModule,
    CommonModule,
    DirectivesModule
  ],
  declarations: [
    CardGroupComponent,

  ],
  providers: [DeviceService],
  exports: [CardGroupComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
]
})
export class CardGroupModule { }

import { Directive, ElementRef, HostListener, HostBinding, Renderer2, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appPermitJoinButton]'
})
export class PermitJoinButtonDirective {

  private classList = ''
  private showHideclass = ''
  private isShowingTimes = false

  @Output('appPermitJoinButton') isShowingTimesOutput = new EventEmitter<boolean>()
  @Output('clickOutside') isShowingTimesOutputOutside = new EventEmitter<boolean>()

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.classList = (this.el.nativeElement as HTMLElement).classList.toString()
    //console.log(this.classList)
  }

  @HostListener('click', ['$event']) expandTimes(event: Event) {
    //console.log( event,"aqui")
    let el :Element
    el = event.srcElement as Element
    let idFirefox = event.target['originalTarget'] ? event.target['originalTarget'].classList[3] : event.target['offsetParent']
   // console.log(event.target['offsetParent'] ? event.target['offsetParent'].parentNode.offsetParent.id : event.target['offsetParent'])
    let id = this.getElementId(el)//event.target['offsetParent'] ? event.target['offsetParent'].parentNode.offsetParent :)
    let timeButtons = document.getElementsByClassName('button-time') as HTMLCollectionOf<Element>
    if(this.isShowingTimesOutputOutside){
      this.isShowingTimes = false
    }
   // console.log(id)
   if ((id && id != 'permit-join-button') || (idFirefox && idFirefox != 'permit-join-button')) {
    let selectedTimeButton = document.getElementById(id) as Element
   // console.log(selectedTimeButton)
    Array.from(timeButtons).forEach(el => {
      if (el.id == id) {
       // console.log('selected', el)
        this.renderer.removeClass(el, 'show-time')
        this.renderer.addClass(el, 'select-time')
        this.renderer.addClass(el, 'hide-time')
      }
      else {
       // console.log('nao sao iguais',el)
        this.renderer.removeClass(el, 'select-time')
        this.renderer.removeClass(el, 'show-time')
        this.renderer.addClass(el, 'hide-time')
      }
    })
  } else {
      //console.log(this.isShowingTimes)
      let pb = document.getElementById('permit-join-button')
      if (this.isShowingTimes) {
        this.isShowingTimesOutput.emit(false)
     //   console.log("is expanded false")
        pb.setAttribute('isExpanded', 'false')
        Array.from(timeButtons).forEach(el => {
          this.renderer.removeClass(el, 'select-time')
          this.renderer.removeClass(el, 'show-time')
          this.renderer.addClass(el, 'hide-time')
        })
      } else {
        pb.setAttribute('isExpanded', 'true')
        this.isShowingTimesOutput.emit(true)
       // console.log("is expanded true")
        Array.from(timeButtons).forEach(el => {
          this.renderer.removeClass(el, 'select-time')
          this.renderer.removeClass(el, 'hide-time')
          this.renderer.addClass(el, 'show-time')
        })
      }
      this.isShowingTimes = !this.isShowingTimes
      //this.el.nativeElement.
    }
  }

  getElementId(el:Element) {
   // console.log(el)
    if (el)
      if (el.id)
        return el.id
      else {
        return this.getElementId(el.parentElement)
      }
    return null
  }
}

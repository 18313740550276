import { ImageCroppedEvent } from 'ngx-image-cropper';
import { TranslateJsonService } from './../../_services/translate-json.service';
import { Component, OnInit } from '@angular/core';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-camera-modal',
  templateUrl: './camera-modal.component.html',
  styleUrls: ['./camera-modal.component.css']
})
export class CameraModalComponent implements OnInit {
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  language;
  jsonWord;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  isCropped:Boolean=false
 
  public onClose: Subject<any>;
  webcamsAvailable: boolean
  
  constructor(
    public modalRef: BsModalRef,public translateJson : TranslateJsonService
  ) {
    this.onClose = new Subject();
  }

  ngOnInit() {
    this.language = localStorage.getItem('language') //? localStorage.getItem('language') : 'pt'
    this.jsonWord = this.translateJson.changeLanguage(this.language)
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.webcamsAvailable = mediaDevices && mediaDevices.length > 0;
      });
  }

  takeSnapshot() {
    if (this.webcamImage)
      this.webcamImage = null
    this.trigger.next()
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
    this.imageChangedEvent = webcamImage.imageAsDataUrl
    if(this.webcamImage){
      this.isCropped = true
    }else{this.isCropped = false}
  }

 /* fileChangeEvent(webcamImage: WebcamImage): void {
    console.log(event)
    this.imageChangedEvent = event;
    console.log(this.imageChangedEvent)
    if(this.imageChangedEvent){
      this.isCropped = true
    }else{this.isCropped = false}
  }*/
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64
    }
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  imageLoaded() {
      // show cropper
    }
    cropperReady() {
      // cropper ready
    }
    loadImageFailed() {
      // show message
    }
  onConfirm() {
    this.onClose.next(this.croppedImage)
    this.modalRef.hide()
  }

  onCancel() {
   // this.onClose.next(null)
    this.modalRef.hide()
  }

  ngOnDestroy() {
    //this.onClose.next(false)
  }

}

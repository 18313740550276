import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import * as dataPt from './../pt.json';
import * as dataEs from './../es.json';
import * as dataEn from './../en.json';

const jsonPt = dataPt.default[0];
const jsonEn = dataEn.default;
const jsonEs = dataEs.default;
@Injectable()
export class TranslateJsonService{

    constructor(
        private router: Router,
  ) { }

  changeLanguage(language) {
          //  console.log(language)
            localStorage.setItem("language",language)
        
        switch (language) {
            case 'pt':
                return jsonPt;
             case 'es':
                 return jsonEs;
            case 'en':
                return jsonEn;
            default:
                break;
        }
      //  return null
    this.router.navigate(['home/panel']);
    }
}


import { Device } from '../models/device.model';

export class Group {  
  name: string
  _id: string
  devId: string;
  devices: [{}];
  bgColor;
  isActive:boolean
  
  constructor(newGroup) {
    this.name= newGroup.name
    this._id= newGroup._id
    this.devId= newGroup.devId;
    this.devices= newGroup.devices;
    this.bgColor = this.getCardStyle(newGroup);
  
 //   this.setupCard(device);
  }

  getCardStyle(group) {
    switch (group.devId) {
      case '81':
        return '#66cc66';
      case '2':
        return '#c76b6c';
      case '10':
        return '#ce45b1';
      case '258':
        return '#e0e052';
      case '261':
        return '#fef784';
      case '6':
        return '#cc9966';
      case '771':
        return '#4ef997';
      case '264':
      return '#61ac53';
      case '265':
       return '#2F887A';
      case '772':
        return '#B83B4B';
      default:
        return '#c5c5c5';
    }
  }
}

import { TranslateJsonService } from './../../_services/translate-json.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Device } from 'app/models/device.model';

@Component({
  selector: 'app-devices-modal',
  templateUrl: './devices-modal.component.html',
  styleUrls: ['./devices-modal.component.css']
})
export class DevicesModalComponent implements OnInit {

  language;
  jsonWord;
  public title: any;
  public message: any;
  public devicesList: any;
  public filteredDevicesList: any;
  private selectedDevices: Array<any> = [];
  public isListening : boolean;

  public buttonCancel: any = {
    show: true,
    text: 'Cancelar'
  };
  public buttonOk: any = {
    show: true,
    text: 'Vincular'
  };
  public onClose: Subject<Array<any>>;

  constructor(private _bsModalRef: BsModalRef,public translateJson : TranslateJsonService) {
  }

  public ngOnInit(): void {
    this.language = localStorage.getItem('language')
    this.jsonWord = this.translateJson.changeLanguage(this.language)
    //this.filteredDevicesList = this.devicesList.filter(device => device.devId == 25 || device.devId == 6)
    this.onClose = new Subject();
  }

  @HostListener('window:keyup', ['$event'])
  keyUpEvent(e) {
    e.stopPropagation()
    if (e.keyCode == 13) {
      this.onConfirm()
    }
  }

  @HostListener('window:dblclick', ['$event'])
  doubleClick(e) {
    e.stopPropagation()
    this.onConfirm()
  }

  toggleSelect(device) {
    let selectDevice = this.selectedDevices.find(d => d == device.ieeeAddr)
    if (selectDevice) {
      this.selectedDevices = this.selectedDevices.filter(d => d != selectDevice)
      //document.getElementById(device.ieeeAddr).classList.remove("active")
    } else {
      this.selectedDevices.push(device.ieeeAddr)
      //document.getElementById(device.ieeeAddr).classList.add("active")
    }
  }

  isSelected(device) {
    return this.selectedDevices.find(device.ieeeAddr) //testar isso!!
  }

  public onConfirm(): void {
    this.onClose.next(this.selectedDevices);
    this._bsModalRef.hide();
  }

  public onCancel(): void {
   // this.onClose.next(null);
    this._bsModalRef.hide();
  }

  onUnbindAll(): void {
    
  }
}

import { UmidchartModule } from "./umidchart/umidchart.module";
import { ChartsModule } from "ng2-charts";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { AppRoutingModule } from "./../app-routing.module";
import { RouterModule } from "@angular/router";
import { AppComponent } from "./../app.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";

import { DashboardComponent } from "./dashboard.component";
import { TempchartModule } from "./tempchart/tempchart.module";
import { SpchartModule } from "./spchart/spchart.module";
import { LumchartModule } from "./lumchart/lumchart.module";
// Use fcRoot function to inject FusionCharts library, and the modules you want to use

@NgModule({
  imports: [
    AppRoutingModule,
    RouterModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    ChartsModule,
    UmidchartModule,
    TempchartModule,
    LumchartModule,
    SpchartModule
    // Include in imports
  ],
  declarations: [DashboardComponent],
  providers: [],
  exports: [DashboardComponent]
})
export class DashboardModule {}

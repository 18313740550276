import { Rule } from './../models/rule.model';
import { Injectable } from '@angular/core';
//import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import * as config from '../config';
import { GatewayService } from './gateway.service';
import { timeoutWith } from 'rxjs/operators';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';

const url = config.serverURL;//+ 'ecomfort/v1';
const urlConfig = config.serverURL + '/gateway/'
const TIMEOUT: number = 10000 // 10 seconds

@Injectable()
export class RuleService {

    constructor(private http: HttpClient,
        private gatewayService: GatewayService
    ) { }

    listRules(): Observable<any> {
        return this.http.get(urlConfig + (this.gatewayService.currentGateway.id) + '/rule')
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
            .map(res => res['data']);
    }

    listRuleById(ruleId): Observable<any> {
        return this.http.get(urlConfig + (this.gatewayService.currentGateway.id) + '/rule/' + ruleId)
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
            .map(res => res['data']);
    }

    createRule(rule): Observable<any> {
        return this.http.post(urlConfig + (this.gatewayService.currentGateway.id) + '/rule', rule)
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
    }

    updateRule(ruleId, rule): Observable<any> {
        return this.http.put(urlConfig + (this.gatewayService.currentGateway.id) + '/rule/' + ruleId, rule)
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
    }

    updateIsActiveRule(message, id): Observable<any> {
        return this.http.patch(urlConfig + (this.gatewayService.currentGateway.id) + '/rule/' + id, message)
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
    }

    deleteRule(ruleId) {
        return this.http.delete(urlConfig + (this.gatewayService.currentGateway.id) + '/rule/' + ruleId)
            .pipe(timeoutWith(TIMEOUT, ErrorObservable.throw(
                new Response(null, {
                    status: 408, statusText: 'Tempo limite de resposta atingido'
                })
            )))
    }
}

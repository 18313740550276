import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EnvironmentService } from './../../_services/environment.service';
import { AppRoutingModule } from './../../app-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnvironmentConfigComponent } from './environment-config.component';


@NgModule({
  imports: [
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  declarations: [
    EnvironmentConfigComponent
  ],
  providers: [EnvironmentService],
  exports: [EnvironmentConfigComponent]
})
export class EnvironmentConfigModule { }
